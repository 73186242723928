export default {
  address: '新北市三重區三信路27號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.3674832637431!2d121.48722002919259!3d25.085948998996756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9f06c46adb7%3A0x55520768f401d542!2z5qi66Lyd5b-D5LiW55WM!5e0!3m2!1szh-TW!2stw!4v1591532876667!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/ye99gUXbkxhE5Hyh8',
  phone: '02-28576999',
  fbLink: 'https://www.facebook.com/樺輝心世界-103235034587425/',
  fbMessage: 'https://m.me/103235034587425',
  caseName: '樺輝心世界',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '樺輝建設股份有限公司'],
    ['責任施工', '柏威營造(甲級)股份有限公司'],
    ['建築規劃', '葛介南建築事務所<br>王德生建築事務所'],
    ['結構設計', '國興結構技師事務所'],
    ['門廳設計', '奧迪設計'],
    ['企劃銷售', '大元廣告有限公司'],
    ['建照號碼', '新北市104重建字第00100號'],
  ],

  gtmCode: ['534P63H'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}