<template>
  <div class="relative">
    <!-- v-if="showIcon"  -->
    <a
      href=" https://m.591.com.tw/show/housing/124656"
      target="_blank"
      class="fix-link fixed flex-c"
    >建案詳情</a>
    <svg
      v-if="showIcon"
      version="1.1"
      preserveAspectRatio="xMinYMax slice"
      id="svg1"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 900 1080"
      style="enable-background:new 0 0 900 1080;"
      xml:space="preserve"
    >
      <path
        class="st0 op op11"
        d="M60.6,922c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
      C64.9,919.5,63.1,921.8,60.6,922C60.7,922,60.6,922,60.6,922z"
      />
      <path
        class="st0 op op3"
        d="M28.1,656.1c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
      C32.3,653.6,30.5,655.8,28.1,656.1C28.1,656.1,28.1,656.1,28.1,656.1z"
      />
      <g class="ops op2">
        <path
          class="st1"
          d="M42,596.6c11.2,8.3,27,5.9,35.2-5.3c1.1-1.5,2-3.1,2.8-4.7c4.6,3,10.2,3.8,15.5,2.2l15.4-2.4l-2.3-15.5
        c-0.1-5.5-2.5-10.6-6.8-14.1c10.5-9.2,11.5-25.1,2.4-35.6c-7.7-8.8-20.4-11.1-30.7-5.7l-9.6-7l-10.3,14c-5.7,7.8-4,18.7,3.7,24.4
        c0,0,0,0,0,0c-7.8-5.7-18.7-4-24.4,3.7c0,0,0,0,0,0l-10.4,14.1l9.7,7.1C30.4,581.3,34.3,590.9,42,596.6z"
        />
        <path
          class="st0"
          d="M51.4,592.5c9.2,6.8,22.2,4.8,28.9-4.4c0.9-1.2,1.6-2.5,2.2-3.8c3.8,2.5,8.4,3.1,12.7,1.8l12.7-2l-1.9-12.7
        c-0.1-4.5-2.1-8.7-5.5-11.6c8.6-7.5,9.5-20.6,1.9-29.2c-6.3-7.2-16.7-9.1-25.2-4.7l-7.9-5.8l-8.5,11.6c-4.7,6.4-3.3,15.4,3.1,20.1
        c-6.4-4.7-15.4-3.3-20.1,3.1l-8.5,11.6l7.9,5.8C41.9,580,45,587.9,51.4,592.5z"
        />
        <path
          class="st0"
          d="M109.6,549.7c-9.2,12.5-26.9,15.2-39.4,6c-12.5-9.2-15.2-26.9-6-39.4c9.2-12.5,26.9-15.2,39.4-6c0,0,0,0,0,0
        C116.1,519.5,118.8,537.1,109.6,549.7 M111.2,550.9c9.9-13.5,7-32.4-6.5-42.3c-13.5-9.9-32.4-7-42.3,6.5s-7,32.4,6.5,42.3
        c0,0,0,0,0,0C82.4,567.3,101.3,564.4,111.2,550.9z"
        />
        <path
          class="st0"
          d="M75.6,595.9c-9.2,12.5-26.9,15.2-39.4,6c-12.5-9.2-15.2-26.9-6-39.4c9.2-12.5,26.9-15.2,39.4-6c0,0,0,0,0,0
        C82.2,565.7,84.8,583.3,75.6,595.9 M77.3,597.1c9.9-13.5,7-32.4-6.5-42.3c-13.5-9.9-32.4-7-42.3,6.5c-9.9,13.5-7,32.4,6.5,42.3
        C48.5,613.5,67.4,610.6,77.3,597.1L77.3,597.1z"
        />
        <path
          class="st0"
          d="M69.8,553.6l-15.3-11.2c-8.4-6.2-10.2-18-4.1-26.5l11.2-15.3l15.3,11.2c8.4,6.2,10.2,18,4.1,26.5L69.8,553.6
        M70.2,556.4l12.4-16.9c6.9-9.3,4.8-22.5-4.5-29.3l-17-12.4l-12.4,16.9c-6.8,9.3-4.8,22.5,4.5,29.3L70.2,556.4L70.2,556.4z"
        />
        <path
          class="st0"
          d="M56.1,572.1c-6.2,8.4-18,10.3-26.5,4.1L14.4,565l11.2-15.3c6.2-8.4,18-10.2,26.5-4l15.3,11.2L56.1,572.1
        M57.8,573.3l12.4-16.9L53.3,544c-9.3-6.9-22.5-4.8-29.3,4.5l-12.4,16.9l16.9,12.4C37.8,584.7,50.9,582.7,57.8,573.3L57.8,573.3z"
        />
        <path
          class="st0"
          d="M115.3,589.5l-16.5,2.6h-0.2h-0.1c-10.6,3.2-21.9-2.8-25.1-13.4c-0.4-1.5-0.7-3-0.8-4.5v0c0-0.7,0-1.3,0-2
        v-0.1L72.3,558l13.6-4l0,0l0,0c0.6-0.2,1.2-0.4,1.8-0.5h0.2c10.8-2.6,21.7,4.1,24.2,14.9c0.4,1.5,0.5,3,0.6,4.5v0.1v0.1
        L115.3,589.5 M117.5,591.3l-2.8-18.5c-0.1-12.2-10.1-22.1-22.3-22c-1.6,0-3.3,0.2-4.9,0.6l0,0h-0.1c-0.7,0.2-1.4,0.4-2.2,0.6
        l-15.1,4.5l0.3,15.7c0,0.7,0,1.5,0,2.2l0,0l0,0c0.9,12.2,11.5,21.4,23.7,20.6c1.7-0.1,3.3-0.4,4.9-0.9l18.5-2.9L117.5,591.3z"
        />
        <polygon
          class="st2"
          points="72.7,558.9 73.3,558.1 92.5,572.2 91.9,573 	"
        />
        <path
          class="st2"
          d="M90.6,574.7c-1.2-0.9-1.4-2.6-0.6-3.7s2.6-1.4,3.7-0.6c1.2,0.9,1.4,2.6,0.6,3.7
        C93.5,575.4,91.8,575.6,90.6,574.7C90.6,574.8,90.6,574.8,90.6,574.7z"
        />

        <rect
          x="54"
          y="567.6"
          transform="matrix(0.5917 -0.8061 0.8061 0.5917 -431.0415 285.0799)"
          class="st2"
          width="23.8"
          height="1"
        />
        <path
          class="st2"
          d="M56.7,576.1c0.9-1.2,2.6-1.5,3.7-0.6c1.2,0.9,1.5,2.6,0.6,3.7c-0.9,1.2-2.5,1.5-3.7,0.6
        C56.1,579,55.8,577.3,56.7,576.1C56.7,576.1,56.7,576.1,56.7,576.1z"
        />
        <polygon
          class="st2"
          points="72.6,558.1 86.7,538.9 87.6,539.5 73.4,558.7 	"
        />
        <path
          class="st2"
          d="M85,537.6c-0.9,1.2-0.6,2.9,0.6,3.7s2.9,0.6,3.7-0.6c0.9-1.2,0.6-2.9-0.6-3.7c0,0,0,0,0,0
        C87.6,536.2,85.9,536.4,85,537.6C85,537.6,85,537.6,85,537.6z"
        />
        <path
          class="st2"
          d="M64.3,570.4c-6.6-4.8-8-14.1-3.1-20.6c4.8-6.6,14.1-8,20.6-3.1c6.6,4.8,8,14.1,3.1,20.6
        C80.1,573.8,70.8,575.2,64.3,570.4C64.3,570.4,64.3,570.4,64.3,570.4z"
        />
        <path
          class="st0"
          d="M70.6,561.7c-1.8-1.3-2.2-3.8-0.9-5.6s3.8-2.2,5.6-0.9c1.8,1.3,2.2,3.8,0.9,5.6c0,0,0,0,0,0
        C74.9,562.6,72.4,562.9,70.6,561.7z"
        />
        <path
          class="st0"
          d="M31.9,528.2c2.8-3.8,8.2-5.2,14.2-3.6l-0.5,2c-5.2-1.3-9.8-0.2-12,2.8s-1.9,7.8,0.9,12.3l-1.7,1.1
        C29.5,537.6,29.1,532,31.9,528.2z"
        />

        <rect
          x="29.4"
          y="525.2"
          transform="matrix(0.9885 -0.1513 0.1513 0.9885 -79.7179 11.2006)"
          class="st0"
          width="8.8"
          height="8.8"
        />
        <path
          class="st0"
          d="M117.1,594.6c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
        C121.3,592.2,119.5,594.4,117.1,594.6C117.1,594.6,117.1,594.6,117.1,594.6z"
        />
      </g>
      <path
        class="st0 op op1"
        d="M6.2,512.8c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
      C10.5,510.3,8.7,512.5,6.2,512.8C6.3,512.8,6.2,512.8,6.2,512.8z"
      />
      <g class="ops op1">
        <path
          class="st0"
          d="M141.7,1071.2l-25.6-7.5c-0.2,0.8-0.5,1.5-0.7,2.3l25.6,7.5c0.6,0.2,1.3-0.2,1.5-0.8c0,0,0,0,0,0
      C142.7,1072.1,142.3,1071.4,141.7,1071.2C141.7,1071.2,141.7,1071.2,141.7,1071.2z"
        />
        <path
          class="st0"
          d="M173.5,1008.6l-52.5,5.2c-0.1-1.4-0.3-2.9-0.5-4.3l35.8-5.4c0.7-0.1,1.1-0.8,1.1-1.4c-0.1-0.7-0.8-1.1-1.4-1
      l-35.8,5.4l0.2,1.5c-0.3-2-0.6-3.9-0.9-5.9l25.3-5.2c0.7-0.1,1.1-0.8,1-1.4c-0.1-0.7-0.8-1.1-1.4-1c0,0,0,0,0,0l-25.3,5.3
      c0.1,0.4,0.2,0.8,0.3,1.3c-0.4-1.9-0.8-3.8-1.2-5.7l10.4-2.7c0.6-0.1,1-0.7,0.9-1.3c-0.1-0.6-0.7-1-1.3-0.9c-0.1,0-0.1,0-0.2,0
      l-10.4,2.7c-0.4-1.4-0.8-2.9-1.2-4.3l3.6-1.1c0.6-0.2,0.9-0.8,0.8-1.4c-0.2-0.6-0.8-0.9-1.4-0.8c-0.2,0-0.4,0-0.6,0l-3.1,1
      c-0.5-1.4-0.9-2.8-1.4-4.2l10.4-3.9c0.6-0.2,0.9-0.9,0.7-1.5c0,0,0,0,0,0c-0.2-0.6-0.9-0.9-1.5-0.7c0,0,0,0,0,0l-10.4,3.9
      c-0.5-1.4-1.1-2.8-1.7-4.2l23.6-10.3c0.6-0.3,0.9-1,0.6-1.6c0,0,0,0,0,0c-0.3-0.6-1-0.9-1.6-0.6l-23.6,10.3c0.1,0.3,0.3,0.7,0.4,1
      c-0.7-1.7-1.5-3.4-2.3-5l32.2-16.1c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1.1-0.9-1.7-0.6l-32.1,16.1c-0.7-1.3-1.4-2.6-2-3.8l68.7-39.3
      c0.7-0.3,0.9-1.1,0.6-1.8s-1.1-0.9-1.8-0.6c-0.1,0-0.1,0.1-0.2,0.1l-68.6,39.2c-0.7-1.3-1.5-2.5-2.2-3.7l30.1-19.3
      c0.6-0.4,0.7-1.2,0.4-1.7c-0.4-0.6-1.2-0.7-1.7-0.4l-30.1,19.3c-0.8-1.3-1.6-2.5-2.5-3.7l20.6-14.8c0.6-0.4,0.7-1.2,0.3-1.7
      c-0.4-0.6-1.2-0.7-1.7-0.3l-20.6,14.8c-0.9-1.2-1.8-2.4-2.7-3.6l8.1-6.5c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2l-8.1,6.5
      c-1-1.2-2-2.4-3-3.6l2.2-1.9c0.4-0.5,0.4-1.2,0-1.6c-0.4-0.4-1-0.4-1.5-0.1l-2.2,1.9c-1.5-1.7-3.1-3.3-4.7-4.9l2.3-2.4
      c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0L82,933c-1.1-1.1-2.2-2.1-3.4-3.2l7.1-8.1c0.5-0.4,0.6-1.1,0.1-1.6
      c-0.4-0.5-1.1-0.6-1.6-0.1c-0.1,0.1-0.2,0.2-0.2,0.3l-7.1,8c0.5,0.4,1,0.8,1.4,1.3c-1.7-1.5-3.4-3-5.2-4.4l0.3,0.2l15.8-19.9
      c0.4-0.5,0.3-1.3-0.2-1.7c-0.5-0.4-1.3-0.3-1.7,0.2l-15.8,20c-1.2-0.9-2.4-1.8-3.6-2.7l20.7-29.1c0.5-0.5,0.5-1.3,0.1-1.8
      s-1.3-0.5-1.8-0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-20.7,29.1c-1.2-0.8-2.4-1.6-3.6-2.4l-2.3-1.4c-1.2-0.8-2.5-1.5-3.8-2.2l17.5-31
      c0.3-0.6,0.1-1.4-0.5-1.7c-0.6-0.3-1.4-0.1-1.7,0.5c0,0,0,0,0,0l-17.5,31c-1.3-0.7-2.6-1.4-4-2.1l11.2-22.6c0.3-0.6,0.1-1.3-0.5-1.6
      c0,0,0,0,0,0c-0.6-0.3-1.3-0.1-1.6,0.5L48,909.3c-1.4-0.7-2.7-1.3-4.1-1.9l4.1-9.5c0.3-0.6,0-1.3-0.6-1.5c-0.6-0.2-1.3,0-1.5,0.6
      l-4.1,9.5c-1.4-0.6-2.9-1.2-4.3-1.7l1-2.8c0.3-0.6,0-1.2-0.5-1.5c-0.6-0.3-1.2,0-1.5,0.5c0,0.1,0,0.1-0.1,0.2l-1,2.8l0.4,0.2
      c-2.3-0.8-4.6-1.6-6.9-2.3l1-3.3c0.2-0.6-0.2-1.2-0.8-1.4c-0.6-0.2-1.2,0.2-1.4,0.8l-1,3.3l1.6,0.5c-2-0.6-4.1-1.2-6.2-1.7h0.1
      l2.5-10.7c0.1-0.6-0.2-1.2-0.9-1.4s-1.2,0.2-1.4,0.9l-2.5,10.7c-1.5-0.3-3-0.6-4.5-0.9l4.5-25.2c0.1-0.7-0.3-1.3-1-1.4c0,0,0,0,0,0
      c-0.7-0.1-1.3,0.3-1.4,1L13,898.2c-1.5-0.2-2.9-0.5-4.4-0.7l4.6-35.7c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.4,1.1l-4.6,35.7
      c-1.4-0.2-2.9-0.3-4.3-0.4l4-52.2c0.1-0.7-0.5-1.4-1.2-1.4s-1.4,0.5-1.4,1.2l-4,52.2c-1.4-0.1-2.9-0.2-4.3-0.2l0.9-36
      c0-0.7-0.5-1.2-1.2-1.3c-0.7,0-1.3,0.5-1.3,1.2l-0.9,36c-1.5,0-3,0-4.4,0l-0.7-25.7c0-0.7-0.6-1.2-1.2-1.2c0,0,0,0,0,0
      c-0.7,0-1.2,0.6-1.2,1.3l0.7,25.7c-1.9,0.1-3.8,0.2-5.7,0.4l1.2-0.1l-0.9-10.8c-0.1-0.6-0.6-1.1-1.3-1.1c-0.6,0.1-1.1,0.6-1.1,1.3
      l0.9,10.8c-1.5,0.2-3.1,0.3-4.6,0.5l-0.5-3.4c-0.1-0.6-0.6-1.1-1.2-1c0,0,0,0,0,0c-0.6,0.1-1.1,0.6-1,1.3c0,0,0,0,0,0l0.5,3.5
      c-2.2,0.3-4.4,0.7-6.7,1.2l-0.8-4c-0.1-0.6-0.7-1-1.3-0.9c-0.6,0.1-1,0.7-0.9,1.3l0.8,4c-1.5,0.3-3,0.7-4.5,1.1l-2.9-11.2
      c-0.2-0.6-0.8-1-1.4-0.8c-0.6,0.2-1,0.8-0.8,1.4c0,0,0,0,0,0l2.9,11.2c-1.5,0.4-2.9,0.8-4.3,1.3l-8-25.1c-0.2-0.6-0.9-1-1.5-0.8
      c0,0,0,0,0,0c-0.6,0.2-1,0.9-0.8,1.5l8,25.1c-1.4,0.5-2.8,1-4.2,1.5l-12.9-34.3c-0.2-0.7-1-1-1.6-0.7c-0.6,0.2-1,1-0.7,1.6
      l12.9,34.4c-1.3,0.5-2.6,1.1-4,1.7l-21.1-48.6c-0.3-0.7-1.1-1-1.8-0.7c-0.7,0.3-1,1.1-0.7,1.8l21.3,48.7c-1.3,0.6-2.6,1.2-3.9,1.9
      l-16.5-32.9c-0.3-0.6-1.1-0.9-1.7-0.6c-0.6,0.3-0.9,1.1-0.6,1.7c0,0,0,0,0,0l16.5,33c-1,0.5-2.1,1.1-3.1,1.7l-0.7,0.4l-13.1-23.1
      c-0.3-0.6-1.1-0.8-1.6-0.5c0,0,0,0,0,0c-0.6,0.3-0.8,1.1-0.5,1.7l13.1,23.1c-1.3,0.8-2.6,1.6-3.8,2.4l-6.3-9.8
      c-0.3-0.5-1.1-0.7-1.6-0.4c-0.5,0.3-0.7,1.1-0.4,1.6c0,0,0,0,0,0l6.3,9.9c-1.3,0.9-2.5,1.7-3.7,2.6l-2.6-3.6
      c-0.4-0.5-1.1-0.6-1.6-0.3c-0.5,0.4-0.6,1.1-0.3,1.6c0,0,0,0,0,0l2.6,3.6l0.6-0.4c-2,1.5-3.9,3-5.8,4.6L-95,923
      c-0.4-0.5-1.1-0.5-1.6-0.2c-0.5,0.4-0.5,1.1-0.2,1.6l3.2,3.9c-1.2,1-2.3,2-3.4,3.1l-8.5-9.2c-0.4-0.5-1.2-0.5-1.6-0.1c0,0,0,0,0,0
      c-0.5,0.4-0.5,1.1-0.1,1.6c0,0,0,0,0,0l8.5,9.2c-1.1,1-2.1,2.1-3.2,3.2l-19.5-19.1c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2,0,1.7
      l19.5,19.1c-1,1.1-2,2.2-2.9,3.3l-28.2-24.9c-0.5-0.5-1.3-0.4-1.8,0.1c0,0,0,0,0,0c-0.5,0.5-0.4,1.3,0.1,1.8c0,0,0,0,0,0l28.3,24.9
      c-0.9,1.1-1.8,2.2-2.7,3.3l-42.4-33.6c-0.6-0.4-1.4-0.4-1.9,0.2c-0.5,0.6-0.4,1.4,0.2,1.9c0,0,0,0,0,0l42.5,33.7
      c-0.8,1.1-1.7,2.3-2.5,3.4l-30.8-21.9c-0.6-0.4-1.3-0.2-1.8,0.3c-0.4,0.6-0.3,1.4,0.3,1.8c0,0,0,0,0,0l30.9,21.9
      c-0.8,1.2-1.6,2.4-2.4,3.6l-23.3-14.8c-0.6-0.4-1.3-0.2-1.7,0.3c0,0,0,0,0,0c-0.4,0.6-0.2,1.3,0.4,1.7l23.3,14.8
      c0.2-0.3,0.3-0.5,0.4-0.8c-1.3,2.1-2.5,4.3-3.7,6.5c0.3-0.7,0.7-1.3,1-1.9l-11.1-6.3c-0.6-0.3-1.3-0.1-1.6,0.5
      c-0.3,0.6-0.1,1.3,0.4,1.6c0,0,0,0,0,0l11.1,6.3c-0.7,1.3-1.4,2.7-2,4l-4.9-2.4c-0.6-0.3-1.2,0-1.5,0.5c-0.3,0.6,0,1.2,0.5,1.5
      l4.9,2.5c-0.9,2-1.8,4-2.6,6.1l-5.6-2.3c-0.6-0.2-1.2,0-1.5,0.6c0,0,0,0,0,0c-0.2,0.6,0,1.2,0.6,1.5c0,0,0,0,0,0l5.6,2.3
      c-0.5,1.4-1,2.9-1.5,4.3V984l-12.9-4.5c-0.6-0.2-1.3,0.1-1.5,0.7c-0.2,0.6,0.1,1.2,0.7,1.5c0,0,0,0,0,0l12.9,4.5
      c-0.5,1.4-0.9,2.8-1.3,4.2l-27.2-8c-0.6-0.2-1.3,0.2-1.5,0.8c-0.2,0.6,0.2,1.3,0.8,1.5l27.3,8c-0.4,1.4-0.7,2.8-1,4.2l-37.7-9
      c-0.7-0.2-1.4,0.3-1.5,0.9c-0.2,0.7,0.3,1.3,0.9,1.5c0,0,0,0,0,0l37.7,9c-0.3,1.4-0.6,2.7-0.8,4.1l-54.3-10
      c-0.7-0.1-1.4,0.3-1.5,1.1c-0.1,0.7,0.3,1.4,1,1.5l54.3,10c-0.3,2.1-0.6,4.2-0.8,6.3c0.1-0.7,0.1-1.4,0.2-2.1l-38.5-5
      c-0.7-0.1-1.3,0.4-1.4,1.1c-0.1,0.7,0.4,1.3,1.1,1.4l38.6,5c-0.2,1.4-0.3,2.8-0.4,4.3l-28.5-2.2c-0.7-0.1-1.3,0.4-1.3,1.1
      c0,0,0,0,0,0c-0.1,0.7,0.4,1.3,1.1,1.3l28.6,2.2c-0.1,1.9-0.2,3.8-0.2,5.7c0-0.4,0-0.9,0-1.4l-13.7-0.3c-0.6,0-1.2,0.5-1.2,1.1
      c0,0.6,0.5,1.2,1.1,1.2c0,0,0,0,0,0l13.8,0.3c0,4.4,0.2,8.9,0.7,13.3l-7,0.7c-0.6,0.1-1.1,0.6-1,1.2c0.1,0.6,0.6,1.1,1.2,1l7-0.7
      c0.2,1.5,0.4,2.9,0.6,4.4l-14.3,2.2c-0.6,0.1-1.1,0.7-1,1.3c0,0,0,0,0,0c0.1,0.6,0.7,1.1,1.3,1c0,0,0,0,0,0l14.3-2.2
      c0.3,1.4,0.5,2.9,0.8,4.3l-28.6,5.9c-0.7,0.1-1.1,0.7-1,1.4c0,0,0,0,0,0.1c0.1,0.7,0.8,1.1,1.4,1l28.7-5.9c0.3,1.4,0.7,2.8,1,4.1
      l-38.3,10c-0.7,0.2-1.1,0.8-0.9,1.5c0,0,0,0,0,0c0.2,0.7,0.9,1.1,1.5,0.9c0,0,0,0,0,0l38.3-10c0.4,1.3,0.8,2.7,1.2,4l-53.4,16.9
      c-0.7,0.2-1.1,1-0.9,1.7c0.2,0.7,1,1.1,1.7,0.9c0,0,0,0,0,0l53.4-17c0.4,1.3,0.9,2.6,1.4,3.9l-37.1,13.9c-0.6,0.3-0.9,1-0.7,1.6
      c0.2,0.6,1,1,1.6,0.7l37.1-14c0.5,1.3,1,2.6,1.6,3.9l-26.9,11.7c-0.6,0.3-0.9,1-0.6,1.6c0,0,0,0,0,0c0.3,0.6,1,0.9,1.6,0.6
      c0,0,0,0,0,0l26.9-11.8c0.6,1.3,1.2,2.6,1.9,3.9l-12.9,6.5c-0.6,0.3-0.8,1-0.5,1.6c0.3,0.6,1,0.8,1.6,0.5c0,0,0,0,0,0l12.9-6.5
      c0.7,1.3,1.4,2.6,2.1,3.9l-6.1,3.5c-0.5,0.3-0.7,1-0.4,1.5c0.3,0.5,1,0.7,1.5,0.4l6.2-3.5c1.3,2.2,2.6,4.3,4.1,6.4
      c-0.2-0.3-0.4-0.6-0.6-0.9l-6.3,4.2c-0.5,0.3-0.7,1-0.3,1.6c0.3,0.5,1,0.7,1.6,0.3l6.4-4.2c0.9,1.3,1.8,2.6,2.8,3.9
      c-0.1-0.1-0.1-0.2-0.2-0.3l-12.1,9c-0.5,0.4-0.6,1.1-0.3,1.6c0,0,0,0,0,0c0.4,0.5,1.1,0.6,1.6,0.3c0,0,0,0,0,0l12.1-9
      c-0.1-0.1-0.1-0.2-0.2-0.3c0.9,1.2,1.9,2.5,2.9,3.7l-22.9,19c-0.5,0.4-0.6,1.2-0.2,1.7c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.7,0.2l22.9-19
      l-0.6-0.7c1.1,1.3,2.2,2.6,3.4,3.9l-29.5,27.1c-0.5,0.5-0.6,1.3-0.1,1.8c0,0,0,0,0,0c0.5,0.5,1.3,0.5,1.8,0.1l29.5-27.1
      c-0.3-0.4-0.7-0.7-1-1.1c1.3,1.4,2.6,2.7,3.9,4.1l-39.4,40.3c-0.5,0.5-0.5,1.3,0,1.9c0,0,0,0,0,0c0.5,0.5,1.3,0.5,1.9,0c0,0,0,0,0,0
      l39.5-40.3c-0.3-0.3-0.6-0.6-0.8-0.8c1.6,1.6,3.3,3.1,5,4.6c-0.4-0.3-0.8-0.7-1.1-1l-26.4,30c-0.4,0.5-0.4,1.3,0.1,1.8
      c0.5,0.5,1.3,0.4,1.8-0.1c0,0,0,0,0,0l26.4-30c-0.1-0.1-0.3-0.2-0.4-0.4c1.2,1,2.4,2.1,3.7,3.1l-18.4,23.2c-0.4,0.5-0.3,1.3,0.2,1.7
      c0,0,0,0,0,0c0.5,0.4,1.3,0.3,1.7-0.2l18.4-23.2c1.1,0.9,2.3,1.7,3.5,2.6l-8.5,12c-0.4,0.5-0.2,1.2,0.3,1.6c0.5,0.4,1.2,0.2,1.6-0.3
      l8.5-11.9c1.3,0.9,2.6,1.8,3.9,2.6l-0.2-0.1l-3.9,6.1c-0.3,0.5-0.2,1.2,0.3,1.6c0,0,0,0,0,0c0.5,0.3,1.2,0.2,1.6-0.3c0,0,0,0,0,0
      l3.9-6.1c2.4,1.5,4.8,2.8,7.3,4.2c-0.6-0.3-1.1-0.6-1.7-0.9l-3.7,6.8c-0.3,0.5-0.1,1.2,0.4,1.5c0.6,0.3,1.2,0.1,1.5-0.5l3.7-6.8
      c1.3,0.7,2.6,1.3,3.9,2l-6.5,13.6c-0.3,0.6,0,1.3,0.5,1.5c0,0,0,0,0,0c0.6,0.3,1.3,0,1.5-0.5c0,0,0,0,0,0l6.5-13.6
      c1.3,0.6,2.6,1.2,4,1.8l-11.4,27.5c-0.2,0.6,0,1.3,0.7,1.6c0.6,0.2,1.3,0,1.6-0.7l11.3-27.6l-0.4-0.2c1.4,0.6,2.9,1.2,4.4,1.7
      l-13.3,37.7c-0.2,0.7,0.1,1.4,0.8,1.6c0,0,0,0,0,0c0.7,0.2,1.4-0.1,1.6-0.8c0,0,0,0,0,0l13.3-37.7c1.3,0.4,2.6,0.9,3.9,1.3l-15.9,54
      c-0.2,0.7,0.2,1.4,0.9,1.6c0.7,0.2,1.4-0.2,1.6-0.9c0,0,0,0,0,0l15.9-54l-0.3-0.1c1.4,0.4,2.8,0.8,4.2,1.2l-9.2,38.8
      c-0.1,0.7,0.3,1.3,0.9,1.5c0.7,0.2,1.4-0.3,1.5-0.9c0,0,0,0,0,0l9.2-38.7l-0.2,0c1.4,0.3,2.9,0.7,4.3,0.9l-5.3,29
      c-0.1,0.7,0.3,1.3,1,1.4c0,0,0,0,0,0c0.7,0.1,1.3-0.3,1.4-1c0,0,0,0,0,0l5.3-28.9c-0.4-0.1-0.8-0.1-1.2-0.2c2.3,0.4,4.5,0.8,6.8,1.1
      l-1.3-0.2l-1.9,14.3c-0.1,0.6,0.4,1.2,1,1.3c0.6,0.1,1.2-0.4,1.3-1c0,0,0,0,0,0l1.8-14.3c1.5,0.2,2.9,0.3,4.4,0.5l-0.5,6.9
      c-0.1,0.6,0.4,1.1,1,1.2c0.6,0.1,1.2-0.4,1.2-1l0.5-6.9c2.2,0.2,4.3,0.3,6.5,0.3l0,7.3c0,0.6,0.5,1.1,1.1,1.1c0,0,0,0,0,0
      c0.6,0,1.1-0.5,1.1-1.1v-7.3c1.5,0,3,0,4.4,0l0.7,14.7c0,0.6,0.6,1.1,1.2,1.1c0,0,0,0,0,0c0.6,0,1.1-0.6,1.1-1.2c0,0,0,0,0,0
      l-0.7-14.7c1.8-0.1,3.6-0.2,5.3-0.3l-1,0.1l2.9,29.1c0.1,0.7,0.7,1.2,1.3,1.1c0.7-0.1,1.1-0.7,1.1-1.3l-2.9-29.1
      c1.4-0.1,2.9-0.3,4.3-0.5l5.9,38.9c0.1,0.7,0.7,1.2,1.4,1.1c0.7-0.1,1.1-0.7,1-1.4l-5.9-38.9c2-0.3,4-0.6,6-1l-1.9,0.4l11.2,54.6
      c0.1,0.7,0.8,1.2,1.5,1.1c0.7-0.1,1.2-0.8,1.1-1.5c0-0.1,0-0.1,0-0.2l-11.2-54.6c1.4-0.3,2.8-0.6,4.1-0.9l9.9,37.9
      c0.2,0.7,0.9,1.1,1.5,0.9c0.7-0.2,1.1-0.9,0.9-1.5l-9.9-37.8c1.4-0.4,2.7-0.7,4-1.1l8.7,27.4c0.2,0.7,0.8,1.1,1.5,0.9
      c0.7-0.2,1.1-0.8,0.9-1.5c0,0,0-0.1,0-0.1l-8.6-27.4c1.4-0.4,2.8-0.9,4.2-1.4l4.8,12.8c0.3,0.6,0.9,0.9,1.5,0.6
      c0.5-0.2,0.8-0.8,0.6-1.4l-4.8-12.8c1.4-0.5,2.8-1.1,4.2-1.6l2.5,5.7c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5
      c0,0,0-0.1,0-0.1l-2.5-5.6c-0.5,0.2-0.9,0.4-1.4,0.6c2.5-1,4.9-2.1,7.4-3.3l3,5.8c0.2,0.6,0.8,0.9,1.4,0.7c0.6-0.2,0.9-0.8,0.7-1.4
      c0-0.1-0.1-0.2-0.1-0.3l-3-5.8c1.3-0.7,2.6-1.4,4-2.1l7.1,11.9c0.3,0.6,1,0.7,1.6,0.4c0.6-0.3,0.7-1,0.4-1.6l-7.1-11.9
      c-0.4,0.2-0.8,0.5-1.2,0.7c1.7-1,3.3-2,4.9-3l15.7,23.7c0.4,0.6,1.1,0.7,1.7,0.4c0.6-0.4,0.7-1.1,0.3-1.7l-15.7-23.7
      c1.2-0.8,2.4-1.6,3.6-2.4l23,31c0.5,0.5,1.3,0.5,1.8,0c0.4-0.4,0.5-1,0.2-1.5l-23-30.9c1.1-0.8,2.3-1.7,3.4-2.5l34.9,42.2
      c0.4,0.6,1.2,0.8,1.8,0.4c0.6-0.4,0.8-1.2,0.4-1.8c-0.1-0.1-0.2-0.2-0.2-0.3l0-0.1l-34.9-42.2c1.1-0.9,2.2-1.8,3.3-2.7l25.8,28.1
      c0.5,0.5,1.3,0.5,1.8,0.1c0.5-0.5,0.5-1.3,0.1-1.8L79,1122.4c1.1-1,2.1-1.9,3.2-2.9l19.8,19.4c0.5,0.4,1.3,0.4,1.7-0.2
      c0.4-0.5,0.4-1.1,0-1.6l-19.8-19.4c1-1,2.1-2.1,3.1-3.2l9.5,8.4c0.5,0.4,1.2,0.3,1.6-0.2c0.4-0.5,0.3-1.1-0.1-1.6l-9.5-8.4
      c1-1.1,2-2.3,3-3.4l4,3.2c0.5,0.4,1.2,0.3,1.6-0.2s0.3-1.2-0.2-1.6l-4-3.2c1.4-1.7,2.7-3.5,4-5.3l4.5,3.1c0.5,0.3,1.2,0.2,1.6-0.4
      c0.3-0.5,0.2-1.1-0.3-1.5l-4.5-3.1c-0.2,0.3-0.4,0.6-0.6,0.8c1.1-1.5,2.1-3.1,3.1-4.6l10.9,6.7c0.6,0.3,1.3,0.2,1.6-0.4
      c0.3-0.5,0.2-1.2-0.4-1.6c0,0,0,0,0,0l-10.9-6.6c0.8-1.3,1.5-2.6,2.3-3.9l24.1,13c0.6,0.3,1.3,0.1,1.6-0.5c0,0,0,0,0,0
      c0.3-0.6,0.1-1.3-0.5-1.7l-24-13c0.7-1.3,1.4-2.6,2-3.9l33.9,16.1c0.6,0.3,1.4,0,1.7-0.7c0.3-0.6,0-1.3-0.6-1.6l-33.8-16.1
      c0.6-1.3,1.2-2.6,1.8-3.9l49.7,20.5c0.7,0.3,1.4,0,1.7-0.7c0.3-0.7,0-1.4-0.7-1.7l-49.7-20.5c0.5-1.3,1.1-2.6,1.6-4l35.1,12.3
      c0.7,0.2,1.4-0.1,1.6-0.8c0.2-0.7-0.1-1.4-0.8-1.6c0,0,0,0,0,0l-35.1-12.3c-0.1,0.3-0.2,0.6-0.3,0.9c1.6-4.4,3-8.9,4.1-13.4
      c-0.2,0.6-0.3,1.2-0.5,1.8l11.4,2.7c0.6,0.2,1.3-0.1,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5c-0.1,0-0.1,0-0.2,0l-11.3-2.7
      c0.4-1.8,0.8-3.5,1.2-5.3c0,0.3-0.1,0.6-0.2,0.8l4.1,0.8c0.6,0.1,1.2-0.3,1.3-1c0.1-0.6-0.3-1.1-0.9-1.3l-4-0.7
      c0.4-2.2,0.8-4.4,1.1-6.6l4.5,0.5c0.6,0.1,1.2-0.4,1.3-1c0.1-0.6-0.4-1.2-1-1.3l-4.4-0.5c0.2-1.5,0.3-3,0.5-4.6l11.8,0.7
      c0.6,0,1.2-0.5,1.2-1.1c0-0.6-0.4-1.2-1.1-1.2c0,0,0,0,0,0l-11.8-0.7c0.1-1.5,0.2-3,0.2-4.5l26.4,0.2c0.7,0,1.2-0.5,1.2-1.2
      c0-0.7-0.5-1.2-1.2-1.2l-26.3-0.2c0-2,0-4.1-0.1-6.2c0,0.6,0.1,1.2,0.1,1.8l36.5-1.7c0.7,0,1.3-0.5,1.3-1.2s-0.5-1.3-1.2-1.3
      c-0.1,0-0.1,0-0.2,0l-36.4,1.7c-0.1-1.4-0.1-2.9-0.2-4.3l52.5-5.2c0.7-0.1,1.2-0.8,1.1-1.5C174.7,1009.1,174.2,1008.6,173.5,1008.6z
      M54.3,1136.5c-60.8,34.5-138.1,13.2-172.7-47.6c-34.5-60.8-13.2-138,47.5-172.7c60.7-34.5,138.2-13.2,172.7,47.5
      C136.3,1024.6,115.1,1101.9,54.3,1136.5z"
        />
      </g>
      <g class="ops op3">
        <path
          class="st1"
          d="M231.6,941.2c10.8-6.1,14.6-19.8,8.4-30.6c0,0,0,0,0,0c-0.8-1.4-1.7-2.7-2.8-3.9c4.1-2.6,6.8-7,7.4-11.8
        l3.6-13.4l-13.4-3.7c-4.5-2-9.6-1.8-13.9,0.4c-0.5-1.6-1.2-3.1-2-4.5c-6.1-10.8-19.8-14.6-30.6-8.6c-7.5,4.2-12,12.5-11.4,21.1
        l-9.2,5.3l7.7,13.6c4.3,7.5,13.8,10.1,21.2,5.8c0,0,0,0,0,0c-7.5,4.3-10.1,13.8-5.9,21.3l7.7,13.5l9.3-5.3
        C214.9,945.1,224.1,945.5,231.6,941.2z"
        />
        <path
          class="st0"
          d="M231.7,932.1c8.9-5,12-16.3,6.9-25.1c0,0,0,0,0,0c-0.7-1.2-1.4-2.2-2.3-3.2c3.4-2.2,5.6-5.7,6.1-9.7l3-11
        l-11-3.1c-3.7-1.6-7.9-1.5-11.4,0.3c-0.4-1.3-0.9-2.5-1.6-3.7c-5-8.9-16.3-12-25.1-6.9c-6.1,3.5-9.8,10.2-9.3,17.2l-7.6,4.3
        l6.3,11.1c3.5,6.1,11.3,8.3,17.4,4.8c0,0,0,0,0,0c-6.1,3.5-8.3,11.3-4.8,17.5l6.3,11.1l7.6-4.3C218,935.3,225.6,935.6,231.7,932.1z
        "
        />
        <path
          class="st0"
          d="M218,869.2c6.9,12.1,2.6,27.4-9.4,34.3c-12.1,6.9-27.4,2.6-34.3-9.4c-6.9-12.1-2.6-27.4,9.4-34.3c0,0,0,0,0,0
        C195.8,853,211.1,857.2,218,869.2 M219.6,868.3c-7.4-13-23.8-17.5-36.8-10.2c-13,7.4-17.5,23.8-10.2,36.8s23.8,17.5,36.8,10.2
        c0,0,0,0,0.1,0C222.4,897.7,226.9,881.3,219.6,868.3z"
        />
        <path
          class="st0"
          d="M243.3,913.6c6.9,12.1,2.7,27.4-9.4,34.3c-12.1,6.9-27.4,2.7-34.3-9.4c-6.9-12.1-2.7-27.4,9.4-34.3
        c0,0,0,0,0,0C221.1,897.4,236.4,901.6,243.3,913.6 M244.9,912.8c-7.4-13-23.8-17.5-36.8-10.2c-13,7.4-17.5,23.8-10.2,36.8
        s23.8,17.5,36.8,10.2c0,0,0,0,0.1,0C247.7,942.2,252.2,925.7,244.9,912.8z"
        />
        <path
          class="st0"
          d="M206.7,903l-14.7,8.3c-8.1,4.6-18.4,1.8-23-6.3l-8.3-14.7l14.7-8.3c8.1-4.6,18.4-1.7,23,6.3L206.7,903
        M209.2,903.7l-9.3-16.3c-5.1-9-16.5-12.1-25.5-7l-16.3,9.3l9.2,16.3c5.1,9,16.5,12.1,25.5,7L209.2,903.7z"
        />
        <path
          class="st0"
          d="M216.8,920.8c4.6,8.1,1.8,18.4-6.3,23l-14.7,8.4l-8.3-14.7c-4.6-8.1-1.8-18.4,6.3-23l14.7-8.3L216.8,920.8
        M218.4,920l-9.2-16.3l-16.3,9.2c-9,5.1-12.1,16.5-7,25.5l9.2,16.3l16.3-9.2C220.4,940.4,223.5,929,218.4,920
        C218.4,920,218.4,920,218.4,920z"
        />
        <path
          class="st0"
          d="M252.5,879l-3.9,14.3v0.1v0.1c-1.3,9.9-10.3,16.8-20.2,15.5c-1.3-0.2-2.6-0.5-3.8-0.9h-0.1
        c-0.6-0.2-1.1-0.4-1.6-0.7l0,0h-0.1l-11.6-5l1.7-12.5V890c0-0.6,0.1-1.1,0.2-1.7v-0.1c1.8-9.7,11.2-16.1,21-14.3
        c1.3,0.2,2.6,0.6,3.8,1.2h0.1h0.1L252.5,879 M254.8,877.7l-16.1-4.5c-10-4.4-21.7,0.2-26,10.3c-0.6,1.4-1,2.8-1.3,4.2l0,0l0,0
        c-0.1,0.7-0.2,1.3-0.3,2l-1.9,13.9l12.9,5.5c0.6,0.3,1.2,0.5,1.8,0.8l0,0c10.2,3.8,21.6-1.5,25.4-11.7c0.5-1.4,0.9-2.9,1.1-4.4
        L254.8,877.7L254.8,877.7z"
        />
        <path
          class="st2"
          d="M218.4,913.6c-6.3,3.6-14.4,1.4-18-5s-1.4-14.4,5-18c6.3-3.6,14.3-1.4,17.9,4.9
        C226.9,901.9,224.7,910,218.4,913.6C218.4,913.6,218.4,913.6,218.4,913.6z"
        />

        <rect
          x="210.5"
          y="896.4"
          transform="matrix(0.8703 -0.4926 0.4926 0.8703 -413.0874 225.2769)"
          class="st2"
          width="21.3"
          height="0.9"
        />
        <path
          class="st2"
          d="M231.5,893.7c-1.1,0.7-2.6,0.2-3.3-0.9c-0.7-1.1-0.2-2.6,0.9-3.3c1.1-0.7,2.6-0.2,3.3,0.9
        C233.1,891.6,232.7,893.1,231.5,893.7C231.5,893.7,231.5,893.7,231.5,893.7z"
        />
        <polygon
          class="st2"
          points="222,920.9 211.5,902.4 212.3,901.9 222.8,920.4 	"
        />
        <path
          class="st2"
          d="M220.3,921.8c-0.7-1.1-0.3-2.6,0.9-3.3c1.1-0.7,2.6-0.3,3.3,0.9c0.7,1.1,0.3,2.6-0.9,3.3c0,0,0,0,0,0
        C222.4,923.4,220.9,923,220.3,921.8z"
        />

        <rect
          x="206.1"
          y="882.2"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -414.1788 218.6701)"
          class="st2"
          width="0.9"
          height="21.3"
        />
        <path
          class="st2"
          d="M199.2,884.8c0.7,1.1,2.1,1.5,3.3,0.9c1.1-0.7,1.5-2.1,0.9-3.3c-0.7-1.1-2.1-1.5-3.3-0.9
        C199,882.2,198.6,883.7,199.2,884.8C199.2,884.8,199.2,884.8,199.2,884.8z"
        />
        <path
          class="st0"
          d="M213.6,905.2c-1.7,1-3.8,0.4-4.8-1.3s-0.4-3.8,1.3-4.8c1.7-1,3.8-0.4,4.8,1.3
        C215.9,902.1,215.3,904.3,213.6,905.2C213.6,905.2,213.6,905.2,213.6,905.2z"
        />
        <path
          class="st0"
          d="M172.3,924.6c-2.1-3.6-1.2-8.5,2.2-12.8l1.4,1.1c-3,3.7-3.7,7.8-2.1,10.8s5.7,4.4,10.4,3.8l0.3,1.8
        C179.1,930,174.4,928.2,172.3,924.6z"
        />

        <rect
          x="170.1"
          y="919.7"
          transform="matrix(0.2654 -0.9641 0.9641 0.2654 -762.6086 846.3065)"
          class="st0"
          width="7.9"
          height="7.9"
        />
        <path
          class="st0"
          d="M276.8,866.8c-2.2,2.1-4.7,2.9-5.6,1.9s0-3.5,2.1-5.6s4.7-2.9,5.6-1.9S278.9,864.8,276.8,866.8z"
        />
        <path
          class="st0"
          d="M274.2,875.8c2.2,1.6,3.4,3.7,2.6,4.7s-3.1,0.6-5.3-1s-3.4-3.7-2.6-4.7S272,874.2,274.2,875.8z"
        />
        <path
          class="st0"
          d="M264.4,864.3c0.8,2.2,0.6,4.3-0.5,4.6s-2.5-1.1-3.3-3.3s-0.6-4.3,0.5-4.6S263.6,862.1,264.4,864.3z"
        />
        <polygon
          class="st0"
          points="252.9,877.8 271.8,867.1 272.6,868.5 253.7,879.2 	"
        />
        <path
          class="st0"
          d="M254.7,876.8c12-6.8,15-2,15.1-1.8l-1.3,0.8c0,0-2.5-3.6-13,2.3L254.7,876.8z"
        />
        <path
          class="st0"
          d="M258.4,874.9c5.6-3.2,4.5-7.5,4.5-7.7l1.5-0.4c0,0,1.5,5.6-5.2,9.5L258.4,874.9z"
        />
        <path
          class="st0"
          d="M244.6,967c-0.8-2.8-0.3-5.4,1-5.8s3,1.6,3.8,4.5s0.3,5.4-1,5.8S245.4,969.9,244.6,967z"
        />
        <path
          class="st0"
          d="M236.1,963.2c-2.3,1.5-4.7,1.9-5.4,0.8s0.6-3.2,2.8-4.7s4.7-1.8,5.4-0.8S238.4,961.8,236.1,963.2z"
        />
        <path
          class="st0"
          d="M250.4,953.8c-2.1,1-4.2,1-4.7,0s0.9-2.6,3-3.6s4.2-1,4.7,0S252.6,952.8,250.4,953.8z"
        />

        <rect
          x="240.8"
          y="946.5"
          transform="matrix(0.8688 -0.4952 0.4952 0.8688 -441.1308 244.9069)"
          class="st0"
          width="1.6"
          height="16.8"
        />
        <path
          class="st0"
          d="M236.7,948l1.4-0.8c3,5.2,3.9,9,2.6,11.1c-0.6,1-1.6,1.6-2.7,1.6v-1.6c0.6,0,1.1-0.4,1.4-0.9
        C239.9,956.6,240.2,954.2,236.7,948z"
        />
        <path
          class="st0"
          d="M237.4,949.2l1.4-0.8c3.2,5.7,7.4,4.6,7.5,4.6l0.4,1.5C246.4,954.6,241.2,955.9,237.4,949.2z"
        />
        <path
          class="st0"
          d="M172.6,841.3c2.4,2.5,5.3,3.6,6.5,2.5s0.2-4.1-2.2-6.5s-5.3-3.6-6.5-2.5S170.2,838.8,172.6,841.3z"
        />
        <path
          class="st0"
          d="M172.5,850.5c-2,1.3-3.1,3.1-2.5,4s2.7,0.7,4.7-0.6s3.1-3.1,2.5-4S174.5,849.2,172.5,850.5z"
        />
        <path
          class="st0"
          d="M188.3,842.2c-2.6,1.3-4.2,3.1-3.7,4.1s3.1,0.8,5.7-0.5s4.2-3.1,3.7-4.1S190.9,840.9,188.3,842.2z"
        />

        <rect
          x="181"
          y="841.4"
          transform="matrix(0.8689 -0.495 0.495 0.8689 -396.819 201.3951)"
          class="st0"
          width="1.6"
          height="16.8"
        />
        <path
          class="st0"
          d="M175.7,850.2c0.6-0.9,1.7-1.5,2.8-1.5c2.5,0,5.2,2.7,8.2,8l-1.4,0.8c-3.5-6.2-5.8-7.1-6.9-7.2
        c-0.6,0-1.1,0.3-1.4,0.7L175.7,850.2z"
        />
        <path
          class="st0"
          d="M182.9,850.6c0-1.8,0.6-3.6,1.8-5l1.1,1.1c-0.1,0.1-3.1,3.2,0.1,8.8l-1.4,0.8
        C183.6,854.6,183,852.6,182.9,850.6z"
        />
      </g>
      <path
        class="st0 op op10"
        d="M115.4,868.9c-3.8-4.5-7.1-0.8-7.1-0.8c-0.9,1.1-2.4,1.5-3.7,1.2c-1.6-0.7-3-1.6-4.3-2.8
      c-3.2-3.6-5.8-7.7-7.7-12.1l-0.2-0.5l-0.3,0.4c-1.3,1.5-2.8,2.9-4.4,4.1l-0.2,0.1l0,0c-0.2,0.1-0.3,0.3-0.5,0.4
      c-1.7,1.3-3.4,2.4-5.3,3.3c-1.6,0.6-3.2,0.9-4.9,0.8c-1.5-0.3-2.7-1.3-3.2-2.7c0,0-2.1-4.9-6.9-2.4c-1.5,0.7-3.3,0-4-1.5
      c0,0,0,0,0-0.1c0,0,3.2,11.9,11.3,9.8c5.1-1.2,9.9-3.6,13.9-6.8l2.4,4.4l4.7-2.6c2.8,4.5,6.3,8.5,10.5,11.9
      c7.3,6.4,13.8-3.8,13.8-3.8C117.5,871.1,115.4,868.9,115.4,868.9z"
      />
      <path
        class="st0 op op9"
        d="M134.9,845.9c-5.3-4.8-9.2,0.2-9.2,0.2c-1.5,2-3.9,3.1-6.4,3c-3.6-0.3-6.9-1.6-9.9-3.7c-5-4-9-9.1-11.9-14.8
      l-0.2-0.5l-0.3,0.4c-3.4,4.8-8,8.7-13.4,11.3c-3.3,1.3-6.9,1.7-10.3,1.1c-2.6-0.6-4.8-2.3-5.9-4.7c0,0-3.3-6-9.1-2.7
      c-1.9,0.9-4.2,0.1-5.3-1.7c0,0,4.3,11.4,12.3,12l0.5,0.1c10.1,1.3,20-2.2,27.4-9.6l3,3.9l4-3.1c6.4,9.4,15.8,15.5,25.9,16.9l0.5,0.1
      c7.9,1.5,13.6-8.6,13.6-8.6C138.8,847.1,136.5,847.2,134.9,845.9z"
      />
      <path
        class="st0 op op8"
        d="M151.4,815.4c-6.9-4.7-11.2,1.2-11.2,1.2c-3.6,4.7-7.9,4.9-7.9,4.9c-6.5,0.6-13.1-0.5-19.1-3.1
      c-5.1-3.3-9.6-7.6-13-12.7l-0.3-0.4l-0.3,0.4c-3.2,4.8-7.5,8.9-12.5,11.8c-6.1,2.3-12.6,2.9-19,1.8c0,0-4.4-0.5-8.1-5.4
      c0,0-4.6-6.2-11.3-2c-2.2,1.1-5,0.6-6.6-1.4c0,0,4.7,8.8,12.2,11.3c3.9,1.3,7.9,2.1,12,2.2c11.5,0.4,22.5-4.3,30.2-12.7l3.2,3.6
      l3.9-3.5c8.1,9,19.2,14.4,30.8,14.8c4.1,0.1,8.1-0.4,12-1.5c7.4-2,11.7-10.4,11.7-10.4C155.1,817.6,151.4,815.4,151.4,815.4z"
      />
      <path
        class="st0 op op7"
        d="M136.4,791.5C136.4,791.5,136.4,791.4,136.4,791.5c-5.7-3.8-8.6,2-8.6,2c-0.9,2.3-3,3.9-5.4,4.3
      c-3.5,0.4-7-0.3-10.1-1.9c-0.4-0.2-0.7-0.4-1-0.6l-0.9-0.6l0.1,0.1c-4.4-2.9-8.2-6.6-11.3-11l-0.3-0.4l-0.2,0.4
      c-2,4.7-5,8.8-8.7,12.3l0.1-0.1c-0.2,0.2-0.5,0.5-0.7,0.7s-0.6,0.5-0.9,0.7c-2.8,2-6.1,3.2-9.6,3.3c-2.5-0.1-4.8-1.4-6.2-3.5
      c0,0-4.1-5.3-8.8-0.8c-1.6,1.2-3.9,0.9-5.2-0.7c0,0,5.5,9.7,12.7,9.5h1.3c9.5-0.7,18-6.1,23.7-14.9l3.7,3.2l3.2-3.7
      c7.5,7.9,17.1,12.1,26.7,11.4c0.4,0,0.8-0.1,1.2-0.1l0.6-0.1c6.6-1.3,9.7-11,9.7-11C140.3,791.8,138.2,792.4,136.4,791.5z"
      />
      <rect
        x="90.7"
        y="749.4"
        transform="matrix(0.5223 -0.8527 0.8527 0.5223 -596.8165 439.0725)"
        class="st0 op op6"
        width="5.7"
        height="5.7"
      />
      <rect
        x="66.5"
        y="695.3"
        transform="matrix(0.2103 -0.9776 0.9776 0.2103 -627.8402 620.0771)"
        class="st0 op op4"
        width="6.8"
        height="6.8"
      />
      <rect
        x="98.3"
        y="645.4"
        transform="matrix(4.710640e-02 -0.9989 0.9989 4.710640e-02 -551.2132 719.7966)"
        class="st0 op op4"
        width="6.8"
        height="6.8"
      />
      <path
        class="st0 op op5"
        d="M285.8,1005.6C285.8,1005.6,285.8,1005.6,285.8,1005.6c-2.2-1.2-4.9-0.3-6.1,1.9s-0.3,4.9,1.9,6.1
      s4.9,0.3,6.1-1.9C288.8,1009.5,288,1006.8,285.8,1005.6z"
      />
      <path
        class="st0 op op11"
        d="M423.7,1014.5C423.7,1014.5,423.7,1014.5,423.7,1014.5c-2.2-1.2-4.9-0.3-6.1,1.9s-0.3,4.9,1.9,6.1
      s4.9,0.3,6.1-1.9C426.7,1018.4,425.9,1015.7,423.7,1014.5z"
      />
      <path
        class="st0 op op9"
        d="M375.5,971.5c2.4,2,0,4.4,0,4.4c-4.8,4.4-0.4,7.6-0.4,7.6c1.3,0.8,1.9,2.3,1.6,3.8c-0.6,1.7-1.6,3.4-2.9,4.7
      c-3.9,3.7-8.4,6.8-13.2,9.1l-0.5,0.3l0.4,0.3c2.1,1.5,4,3.2,5.7,5c1.6,1.6,2.9,3.4,4.2,5.3c0.9,1.6,1.3,3.3,1.4,5.1
      c0,0,0,2.2-2.8,3.6c0,0-5.4,2.6-2.1,7.5c0.8,1.5,0.3,3.4-1.2,4.2c-0.1,0-0.1,0.1-0.2,0.1c0,0,13.2-4.4,10.2-12.8
      c-1.9-5.4-4.9-10.2-8.9-14.2l4-3.1l-3.2-4.1c5.1-3.4,9.6-7.6,13.3-12.6C387.6,977.5,375.5,971.5,375.5,971.5z"
      />
      <path
        class="st0 op op4"
        d="M276,1026.9c-3.3-2.4-6.9-4.1-10.8-5.2l1.8-5.7l-5.5-1.8c1.9-4,3.2-8.3,3.7-12.7c1.5-8.5-10.3-7.2-10.3-7.2
      c2.7,0.4,1.9,3,1.9,3c-1.7,5.1,3,5.3,3,5.3c2.5,0.1,2.9,1.9,2.9,1.9c0.3,1.5,0.2,3-0.1,4.5c-0.6,1.7-1.2,3.4-2,5l0,0l-0.1,0.2
      c-1.1,2.4-2.4,4.6-3.9,6.8l-0.3,0.4h0.5c2.2,0.1,4.3,0.4,6.5,0.8c1.9,0.4,3.7,1,5.5,1.7c1.3,0.7,2.4,1.7,3.3,2.9
      c0,0,0.9,1.5-0.6,3.7c0,0-3,4.2,1.6,6c0,0,2.3,1.1,0.8,3.6C273.8,1040,281.9,1031.2,276,1026.9z"
      />
      <path
        class="st0 op op3"
        d="M266.2,1050.8c-4.5-8.5-12.5-14.4-22.2-16.5l1.1-6.3l-5.8-1c3.5-10.4,3-21.2-1.6-30l-0.2-0.4
      c-3.4-7.3-14.9-6.1-14.9-6.1c2.1,0.3,3.5,2.1,3.4,4.2c-0.9,7.1,5.5,7.4,5.5,7.4c2.5,0,4.8,1.3,6.2,3.5c1.8,3.1,2.7,6.6,2.8,10.2
      c-0.4,6.4-2.1,12.6-5.1,18.2l-0.2,0.5h0.5c5.9,0,11.8,1.5,17,4.3c3,1.9,5.4,4.6,6.9,7.8c1,2.4,0.9,5.2-0.4,7.5c0,0-3,6.2,3.1,8.9
      c1.8,1.1,2.5,3.4,1.6,5.3c0,0,6.7-10.1,2.6-17C266.4,1051.1,266.3,1051,266.2,1050.8z"
      />
      <path
        class="st0 op op2"
        d="M246.4,1073.8c-6.8-9.4-17.1-15.6-28.4-17.4l0.3-6.1l-5.8-0.3c2.4-11.4-0.1-23.4-6.9-33l-0.3-0.5
      c-5-7.7-17.3-6.2-17.3-6.2c2.4,0.3,4.2,2.1,4.5,4.5c0,4.2,3.2,7.6,7.4,7.8c2.9,0.1,5.6,1.4,7.4,3.6c2.5,3.1,4.1,6.8,4.8,10.8
      c0.8,6.6,0,13.3-2.4,19.5l-0.2,0.5h0.5c6.7-0.1,13.3,1.4,19.3,4.3c3.6,2,6.7,4.8,8.9,8.2c1.5,2.4,1.8,5.3,1,8c0,0-2.1,6.6,5,9.4
      c2.1,1,3.2,3.3,2.7,5.6c0,0,5.5-10.9-0.2-18.1L246.4,1073.8z"
      />
      <rect
        x="401.2"
        y="1008.3"
        transform="matrix(0.91 -0.4147 0.4147 0.91 -382.9422 258.5958)"
        class="st0 op op8"
        width="5.7"
        height="5.7"
      />
      <rect
        x="343"
        y="997.5"
        transform="matrix(0.7212 -0.6928 0.6928 0.7212 -596.7914 519.0358)"
        class="st0 op op6"
        width="6.7"
        height="6.7"
      />
      <path
        class="st0 op op1"
        d="M219.9,1090.7l-0.3-0.3c-5.7-5.5-13.5-8.1-21.6-7.5l-0.9-6.2l-5.9,0.8c-0.1-9.1-3.4-17.5-9.4-23.3l-0.3-0.3
      c-4.7-5-13.8-1-13.8-1c1.7-0.3,3.4,0.8,3.9,2.5c1.2,6,6.5,4.5,6.5,4.5c2.1-0.7,4.3-0.2,6,1.2c2.3,2,4,4.6,5,7.5
      c1.4,5.3,1.7,10.9,0.7,16.3l-0.1,0.4l0.4-0.1c4.8-1.6,10-2,15-1.1c2.9,0.7,5.6,2.3,7.7,4.5c1.5,1.7,2.1,4,1.7,6.3
      c0,0-0.8,5.8,4.9,6.4c1.8,0.4,3,2.1,2.8,3.9C222.3,1105.2,225.1,1095.2,219.9,1090.7z"
      />
      <path
        class="st0 op op5"
        d="M106.8,708.9c-6.3-1.5-6.5,3.9-6.5,3.9c0.1,1.5-0.9,2.9-2.3,3.5c-1.8,0.4-3.7,0.5-5.5,0.3
      c-5.3-1.2-10.3-3.1-15-5.8l-0.5-0.3v0.5c-0.1,2.5-0.4,5-0.9,7.5c-0.5,2.2-1.1,4.3-2,6.4c-0.8,1.6-2,3-3.5,4c0,0-1.8,1.2-4.5-0.3
      c0,0-5.2-3-7.4,2.4c-0.8,1.5-2.7,2.1-4.2,1.4c-0.1,0-0.1-0.1-0.2-0.1c0,0,11,8.5,16.3,1.3c3.4-4.5,5.7-9.8,6.8-15.3l4.8,1.6l1.6-4.9
      c5.7,2.4,11.7,3.7,17.9,4c10.6,1,8.8-12.4,8.8-12.4C110,709.5,106.8,708.9,106.8,708.9z"
      />
      <path
        class="st0 op op2"
        d="M22.7,622.5c-5.2,1.4-2.7,5.5-2.7,5.5c1.3,2.1,0.1,3.4,0.1,3.4c-1.1,1.1-2.4,1.9-3.8,2.4
      c-1.7,0.5-3.5,0.9-5.3,1.2l0,0l-0.3,0.1c-2.6,0.4-5.1,0.6-7.7,0.5H2.4l0.3,0.4c1.1,1.9,2.1,3.8,2.9,5.8c0.7,1.8,1.3,3.6,1.6,5.5
      c0.2,1.5,0,2.9-0.5,4.3c0,0-0.7,1.6-3.4,1.6c0,0-5.1-0.1-4.1,4.7c0,0,0.4,2.5-2.5,2.7c0,0,11.9,1.8,12.2-5.5
      c0.2-4.1-0.4-8.1-1.8-11.9l5.7-1.7l-1.6-5.5c4.4-0.7,8.7-2,12.7-4c7.9-3.5,0.2-12.6,0.2-12.6C25.3,621.6,22.7,622.5,22.7,622.5z"
      />
      <path
        class="st0 op op1"
        d="M-11.6,624.6c10.6-2.9,19.3-9.3,24-18.1c0.1-0.2,0.1-0.3,0.2-0.4c4.1-6.9-3.3-15.7-3.3-15.7
      c0.9,1.9,0.2,4.1-1.6,5.2c-6.3,3.2-3,8.7-3,8.7c1.4,2.1,1.6,4.7,0.6,7c-1.6,3.2-3.9,6-6.9,8c-5.5,3.3-11.6,5.3-18,5.9l-0.5,0.1
      l0.3,0.4c3.3,4.9,5.4,10.6,5.9,16.5c0.1,3.5-0.8,7-2.6,10.1c-1.4,2.2-3.8,3.6-6.4,3.9c0,0-6.8,1-5.7,7.5c0.1,2.1-1.4,4-3.5,4.3
      c0,0,12.2-0.1,15.6-7.3l0.3-0.4c4.5-8.5,4.9-18.4,1.2-27.6l5.9-2.6L-11.6,624.6z"
      />
      <path
        class="st0 op op6"
        d="M17.9,304.5c2.2,0.4,4.4-1,4.8-3.2s-1-4.4-3.2-4.8c0,0,0,0,0,0c-2.2-0.4-4.4,1-4.8,3.2
      C14.2,301.9,15.6,304,17.9,304.5z"
      />
      <path
        class="st0 op op5"
        d="M19.4,344.2c0-0.2,0-0.3,0.1-0.5c0.3-1.2,1.4-1.9,2.6-1.6c4.3,1.4,4.6-3.3,4.6-3.3c0.2-2.4,1.7-3,1.7-3
      c1.3-0.4,2.7-0.4,4-0.2c1.7,0.5,3.3,1.1,4.9,1.9c1.8,0.9,3.5,2,5.1,3.2l0.3,0.3v-0.5c0.1-2.4,0.5-4.8,1.1-7.1l0.1-0.2l0,0
      c0.4-1.6,0.9-3.2,1.5-4.8c0.6-1.3,1.4-2.4,2.5-3.3c1-0.6,2.3-0.5,3.1,0.3c0,0,3.5,2.7,5.2-2c0.1-0.2,0.1-0.3,0.2-0.4
      c0.6-1,1.9-1.2,2.9-0.6c0,0-7.7-7.8-11.5-0.8c-2.2,3.5-3.7,7.3-4.7,11.3l-5-1.9l-2,5.1c-3.4-1.5-7-2.4-10.7-2.5
      C18.6,333.2,19.4,344.2,19.4,344.2z"
      />
      <path
        class="st0 op op4"
        d="M14.5,367.2c5.9,1.5,7.3-4.6,7.3-4.6c0.4-2.4,1.9-4.4,4.1-5.6c2.9-1.4,6.2-2,9.4-1.6c5.4,1,10.4,3.3,14.6,6.7
      l0.3,0.3l0.1-0.5c1.1-5.8,3.4-11.2,6.8-16c2.1-2.5,4.8-4.5,7.8-5.7c2.2-0.7,4.6-0.3,6.4,1.1c0,0,4.8,3.4,8.2-2.1
      c1.1-1.6,3.2-2,4.9-1c0,0-7.5-7.5-14.1-4.2l-0.4,0.2c-8.4,3.6-15,11-18.4,20.5l-4.8-2.6l-2.9,5.2c-8.1-4.1-17.2-4.5-25.3-1l-0.4,0.2
      c-6.9,2.5-7.9,13.7-7.9,13.7C10.7,368.2,12.5,366.9,14.5,367.2z"
      />
      <path
        class="st0 op op3"
        d="M13.7,394.7c3.6,1.4,7.6-0.4,9-3.9c0.9-2.4,2.8-4.3,5.3-5.2c3.6-1.1,7.4-1.4,11.1-0.7c6,1.4,11.5,4.1,16.3,8
      l0.4,0.3l0.2-0.5c1.9-5.8,5.2-11,9.5-15.3c2.8-2.4,6.1-4.1,9.6-4.9c2.6-0.5,5.2,0.1,7.3,1.7c3.1,2.2,7.4,1.6,9.7-1.4
      c1.6-1.5,4-1.7,5.8-0.5c0,0-8-8.1-16-5.5l-0.5,0.1c-10.4,2.9-19,10.1-23.9,19.5l-4.3-3.1l-3.3,4.6c-9.1-5.2-20-6.7-30.3-3.9
      l-0.5,0.2c-8.2,1.9-10.5,12.9-10.5,12.9C9.4,395.2,11.6,394.2,13.7,394.7z"
      />
      <path
        class="st0 op op2"
        d="M57.3,419L57.3,419c1.8-2.1,3.8-4,6.1-5.7c2.4-1.5,5.1-2.4,7.9-2.5c2,0,3.8,1,4.9,2.6c0,0,3,4.1,7.3,0.5
      c1.3-0.9,3.2-0.7,4.2,0.5c0,0-4.1-8.1-10.4-7.2h-0.4c-7.6,0.7-14.8,4.8-20.1,11.2l-3.7-4l-4.2,3.9c-5.4-5.1-12.5-7.7-19.8-7h-0.4
      c-6.3,0.2-10.1,9-10.1,9c0.9-1.4,2.7-1.9,4.2-1.2c4.4,2.8,7.2-1.8,7.2-1.8c1-1.8,2.8-3.1,4.8-3.5c2.8-0.4,5.6,0,8.1,1.2
      c4.1,2.2,7.6,5.5,10.2,9.4l0.2,0.3l0.2-0.4C54.6,422.6,55.9,420.7,57.3,419L57.3,419z"
      />
      <g>
        <line
          class="st3 an1"
          x1="-70.5"
          y1="452"
          x2="34.3"
          y2="529.5"
        />
        <path
          class="st3 an2"
          d="M-75.2,548.4c62.1-11.1,111.5-55.5,126-113.2c11.7-46.8-0.1-96-32.5-134.8"
        />
        <path
          class="st3 an3"
          d="M-242,799.7c0-100.5,76.3-182,170.4-182s171.4,81.5,171.4,182S29.4,979.7-64.7,979.7S-242,900.2-242,799.7"
        />
        <line
          class="st3 an4"
          x1="175"
          y1="923.1"
          x2="103.6"
          y2="963.8"
        />
        <path
          class="st3 an5"
          d="M173.5,1124.7c22.6-81.3,114-158.6,248.6-107.5"
        />
        <path
          class="st3 an6"
          d="M115.4,590.6c3.9,3.9,10.7,33.6-14.5,58.8c-17.7,17.7-54.2,20.4-72.2,2.4"
        />
      </g>
    </svg>
    <svg
      v-if="showIcon"
      version="1.1"
      preserveAspectRatio="xMaxYMax slice"
      id="svg1-1"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="1020 -20 900 1080"
      style="enable-background:new 0 0  900 1080;"
      xml:space="preserve"
    >
      <g class="tr">

        <g class="ops op7">
          <path
            class="st0"
            d="M1690.4,815.6c2.1-0.9,4.6,0,5.5,2.1s0,4.6-2.1,5.5c-2.1,0.9-4.6,0-5.5-2.1c0,0,0,0,0,0 C1687.3,819,1688.3,816.5,1690.4,815.6z"
          />
          <path
            class="st1"
            d="M1761.4,831.1c-8.4-9.9-23.2-11.1-33.1-2.7c-1.3,1.1-2.4,2.3-3.5,3.7c-3.5-3.7-8.5-5.6-13.5-5.3l-14.5-1.2 l-1.2,14.5c-1.1,4.9,0,10.1,3.1,14.2c-1.5,0.8-3,1.8-4.3,2.9c-9.9,8.4-11.1,23.2-2.7,33.1c5.8,6.9,15,9.8,23.7,7.6l7.2,8.5 l12.4-10.5c6.9-5.8,7.8-16.1,2-22.9c0,0,0,0-0.1-0.1c5.8,6.9,16.1,7.7,22.9,2c0,0,0,0,0.1-0.1l12.4-10.5l-7.2-8.5 C1768.6,847.4,1767.2,837.9,1761.4,831.1z"
          />
          <path
            class="st0"
            d="M1752,832.7c-6.9-8.1-19-9.1-27.2-2.3c-1.1,0.9-2,1.9-2.8,3c-2.9-3-6.9-4.6-11.1-4.4l-11.9-0.9l-1,11.9 c-0.9,4.1,0,8.4,2.5,11.7c-1.2,0.6-2.4,1.4-3.5,2.3c-8.1,6.9-9.1,19.1-2.2,27.2c4.8,5.6,12.3,8,19.5,6.2l5.9,7l10.2-8.6 c5.6-4.8,6.3-13.2,1.6-18.8c0,0,0,0,0,0c4.8,5.6,13.2,6.3,18.8,1.6c0,0,0,0,0,0l10.2-8.7l-5.9-7 C1757.9,846.2,1756.8,838.3,1752,832.7z"
          />
          <path
            class="st0"
            d="M1690,858.9c11.1-9.4,27.6-8,37,3c9.4,11.1,8,27.6-3,37c-11.1,9.4-27.6,8-37-3c0,0,0,0,0,0 C1677.6,884.9,1679,868.3,1690,858.9 M1688.8,857.5c-11.9,10.1-13.4,27.9-3.3,39.7c10.1,11.9,27.9,13.4,39.7,3.3 c11.9-10.1,13.4-27.9,3.3-39.7c0,0,0,0,0,0C1718.4,848.9,1700.6,847.4,1688.8,857.5z"
          />
          <path
            class="st0"
            d="M1730.8,824.4c11.1-9.4,27.6-8,37,3c9.4,11.1,8,27.6-3,37c-11.1,9.4-27.6,8-37-3c0,0,0,0,0,0 C1718.4,850.3,1719.7,833.8,1730.8,824.4 M1729.5,823c-11.9,10.1-13.4,27.9-3.3,39.7c10.1,11.9,27.9,13.4,39.7,3.3 c11.9-10.1,13.4-27.9,3.3-39.7c0,0,0,0,0,0C1759.2,814.4,1741.4,812.9,1729.5,823z"
          />
          <path
            class="st0"
            d="M1726.9,864.1l11.4,13.5c6.3,7.4,5.4,18.5-2,24.9l-13.5,11.4l-11.4-13.5c-6.3-7.4-5.4-18.5,2-24.9 L1726.9,864.1 M1727.2,861.4l-14.9,12.6c-8.2,7-9.3,19.3-2.3,27.6l12.6,14.9l14.9-12.6c8.3-7,9.3-19.3,2.3-27.6c0,0,0,0,0,0 L1727.2,861.4L1727.2,861.4z"
          />
          <path
            class="st0"
            d="M1743.3,850.2c7.4-6.3,18.5-5.4,24.9,2l11.4,13.5l-13.5,11.4c-7.4,6.3-18.6,5.4-24.9-2l-11.4-13.5 L1743.3,850.2 M1742.1,848.7l-14.9,12.6l12.6,14.9c7,8.2,19.3,9.3,27.5,2.3c0,0,0,0,0,0l14.9-12.6l-12.6-14.9 C1762.6,842.7,1750.3,841.7,1742.1,848.7C1742.1,848.7,1742.1,848.7,1742.1,848.7L1742.1,848.7z"
          />
          <path
            class="st0"
            d="M1693.5,821.6l15.5,1.2h0.3c10.3-0.6,19.2,7.2,19.9,17.6c0.1,1.4,0,2.8-0.2,4.2v0.1c-0.1,0.6-0.2,1.2-0.4,1.8 v0.1l-2.8,12.9l-13.2,0.7h-0.1c-0.6,0.1-1.2,0.1-1.8,0.1h-0.2c-10.4,0-18.8-8.4-18.7-18.8c0-1.4,0.2-2.8,0.5-4.2v-0.1v-0.1 L1693.5,821.6 M1691.7,819.6l-1.5,17.4c-2.5,11.1,4.5,22.2,15.6,24.7c1.5,0.3,3,0.5,4.6,0.5h0.1c0.7,0,1.4,0,2.1-0.1l14.6-0.8 l3.2-14.3c0.2-0.7,0.3-1.4,0.4-2l0,0l0,0c1.9-11.3-5.7-21.9-17-23.8c-1.5-0.3-3.1-0.3-4.7-0.2L1691.7,819.6L1691.7,819.6z"
          />

          <rect
            x="1717.4"
            y="839.3"
            transform="matrix(0.7627 -0.6468 0.6468 0.7627 -142.303 1312.9254)"
            class="st2"
            width="1"
            height="22.2"
          />
          <path
            class="st2"
            d="M1712.6,840.3c0.9,1.1,0.8,2.6-0.3,3.5c-1.1,0.9-2.6,0.8-3.5-0.3s-0.8-2.6,0.3-3.5c0,0,0,0,0,0 C1710.1,839.2,1711.7,839.3,1712.6,840.3z"
          />
          <polygon
            class="st2"
            points="1742.3,844.9 1725.4,859.3 1724.8,858.5 1741.7,844.2 	"
          />
          <path
            class="st2"
            d="M1743.6,846.4c-1.1,0.9-2.6,0.8-3.5-0.3c-0.9-1.1-0.8-2.6,0.3-3.5c1.1-0.9,2.6-0.8,3.5,0.3c0,0,0,0,0,0 C1744.8,844,1744.7,845.5,1743.6,846.4C1743.6,846.4,1743.6,846.4,1743.6,846.4z"
          />
          <polygon
            class="st2"
            points="1725.3,859.3 1708.4,873.7 1707.8,872.9 1724.7,858.6 	"
          />
          <path
            class="st2"
            d="M1709.7,875.2c1.1-0.9,1.2-2.5,0.3-3.5c-0.9-1.1-2.5-1.2-3.5-0.3s-1.2,2.5-0.3,3.5c0,0,0,0,0,0 C1707,876,1708.6,876.1,1709.7,875.2C1709.7,875.3,1709.7,875.2,1709.7,875.2z"
          />
          <path
            class="st2"
            d="M1735.6,850c4.9,5.8,4.2,14.5-1.6,19.4c-5.8,4.9-14.5,4.2-19.4-1.6c-4.9-5.8-4.2-14.5,1.6-19.4 C1722,843.5,1730.6,844.2,1735.6,850C1735.6,850,1735.6,850,1735.6,850z"
          />
          <path
            class="st0"
            d="M1727.9,856.5c1.3,1.6,1.1,3.9-0.4,5.2c-1.6,1.3-3.9,1.1-5.2-0.4c-1.3-1.6-1.1-3.9,0.4-5.2 C1724.2,854.8,1726.6,855,1727.9,856.5C1727.9,856.5,1727.9,856.5,1727.9,856.5z"
          />
          <path
            class="st0"
            d="M1755.7,895.2c-3.3,2.8-8.5,2.9-13.6,0.2l0.9-1.7c4.4,2.3,8.8,2.3,11.5,0s3.4-6.7,1.8-11.4l1.8-0.6 C1760,887.2,1759.1,892.4,1755.7,895.2z"
          />

          <rect
            x="1750.2"
            y="889.4"
            transform="matrix(8.315601e-02 -0.9965 0.9965 8.315601e-02 718.0031 2567.5305)"
            class="st0"
            width="8.2"
            height="8.2"
          />
        </g>
        <rect
          x="1775.9"
          y="919.4"
          transform="matrix(8.211244e-02 -0.9966 0.9966 8.211244e-02 713.4471 2620.7095)"
          class="st0 op op6"
          width="7.2"
          height="7.2"
        />
        <rect
          x="1793.3"
          y="940.3"
          transform="matrix(8.280820e-02 -0.9966 0.9966 8.280820e-02 707.3826 2656.4475)"
          class="st0 op op5"
          width="7.2"
          height="7.2"
        />

        <path
          class="st0 op op11"
          d="M1690.7,1099.8c-1.6-4.8-6.2-2.8-6.2-2.8c-1.2,0.6-2.7,0.6-3.9-0.2c-1.2-1-2.2-2.3-2.8-3.7 c-0.7-1.8-1.2-3.7-1.5-5.6c-0.4-2.1-0.5-4.3-0.5-6.4v-0.4l-0.4,0.2c-4.1,1.8-8.5,3-13,3.4c-1.6,0-3.2-0.2-4.7-0.8 c-1.2-0.6-1.9-1.8-1.8-3.1c0,0,0.1-4.7-5.4-4c0,0-2.8,0.2-3-2.4c0,0-2,11.1,7,11.3c4.9,0.4,9.9-0.1,14.7-1.4l1.6,4.8l4.5-1.5 c0.7,4.8,2.5,9.4,5.2,13.5c4.2,6.6,13.9,0.5,13.9,0.5C1692.9,1101.8,1691.3,1101.2,1690.7,1099.8z"
        />
        <path
          class="st0 op op10"
          d="M1710.5,1090c-1.8-5.9-7.9-3.9-7.9-3.9c-2.3,0.9-4.9,0.7-7-0.6c-2.8-1.8-5-4.3-6.4-7.3 c-2-5.2-2.6-10.7-1.9-16.2l0.1-0.4l-0.5,0.2c-5.6,2.1-11.5,3-17.5,2.6c-3.3-0.5-6.4-1.8-9.1-3.8c-1.8-1.5-2.7-3.8-2.4-6.1 c0,0,0.5-5.9-6.2-6c-1.9-0.1-3.5-1.7-3.5-3.7c0,0-2.5,10.5,3.8,14.5c0.1,0.1,0.3,0.2,0.4,0.3c7.8,5.5,18.1,7.2,28.4,5l0.8,4.7 l4.5-0.7c0.4,9.8,4.9,18.4,12.6,23.9l0.4,0.3c5.8,4.6,16-0.4,16-0.4C1713.3,1092.8,1711.3,1091.8,1710.5,1090z"
        />
        <path
          class="st0 op op9"
          d="M1732.1,1080c-1.9-7.2-8.9-5.9-8.9-5.9c-5.7,1-9-1.5-9-1.5c-5-3.4-9.1-8-11.8-13.3c-1.5-5.2-1.9-10.7-1-16 l0.1-0.5l-0.5,0.1c-5.5,1.3-11.3,1.4-16.9,0.4c-5.8-2-10.9-5.5-15-10c0,0-2.8-2.9-2.3-8.4c0,0,0.8-6.8-6.9-8c0,0-3.9-0.8-3.9-4.8 c0,0-2.4,8.5,1.3,14.5c2,3.2,4.4,6.1,7.2,8.6c8.2,7.1,19.2,10.5,30.4,9.5l0.5,4.9l4.4-0.4c-0.1,10.6,4.4,20.7,12.5,27.8 c2.9,2.5,6.1,4.6,9.6,6.1c6.7,3.1,15.5,0.1,15.5,0.1C1735.1,1083.6,1732.9,1082.2,1732.1,1080z"
        />
        <path
          class="st0 op op8"
          d="M1683,1020.2c0.2,0.3,0.5,0.6,0.8,0.9c6.1,6.5,15.3,9.8,25.4,9.2l-0.2,4.5l4.5,0.2c-1.7,9.6,0.6,18.7,6.7,25.2 c0.3,0.3,0.5,0.6,0.8,0.8c4.8,4.6,14.6,1.6,14.6,1.6c0,0,0,0-0.1,0c-1.9,0.3-3.6-1-3.9-2.8c-0.4-6-6.6-5-6.6-5 c-2.3,0.5-4.7-0.1-6.5-1.6c-2.3-2.2-3.9-5.1-4.5-8.3c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3-0.1-0.7-0.1-1v0.1c-0.3-4.7,0.4-9.5,2-13.9 l0.2-0.4l-0.5,0.1c-4.8,1-9.8,1.1-14.6,0.3h0.2l-1-0.2c-0.4-0.1-0.7-0.2-1.1-0.3c-3.1-0.9-6-2.7-8.1-5.2c-1.4-1.8-1.8-4.2-1-6.3 c0,0,1.7-5.7-4.5-6.8c0,0,0,0,0,0c-1.8-0.5-2.9-2.3-2.4-4.1c0,0-4,8.6-0.4,13.7C1682.7,1019.9,1682.9,1020.1,1683,1020.2z"
        />
        <path
          class="st0 op op4"
          d="M1829,980.4C1829,980.4,1829,980.4,1829,980.4c-1.7,1.5-1.9,4.1-0.4,5.9c1.5,1.7,4.1,1.9,5.9,0.4 c1.7-1.5,1.9-4.1,0.4-5.9S1830.8,978.9,1829,980.4z"
        />
        <path
          class="st0 op op6"
          d="M1776.8,1018.5c2-5.1-3.5-6.3-3.5-6.3c-2.8-0.7-3.3-2.7-3.3-2.7c-0.3-1.6-0.3-3.3,0.2-4.9 c0.7-2,1.6-3.9,2.7-5.7c1.2-2,2.6-3.9,4.1-5.7l0.3-0.4l-0.5-0.1c-4.9-1.1-9.6-3-13.9-5.5c-1.4-1-2.7-2.2-3.6-3.7 c-0.6-1.3-0.4-2.8,0.7-3.8c0,0,3.4-3.8-1.9-6.8c0,0-2.6-1.6-1-4c0,0-9.7,7.9-1.9,14c4.4,3.8,9.4,6.7,14.8,8.7l-2,4.4l4.3,2 c-2.8,4.4-4.5,9.5-5.1,14.7c-0.9,8.3,12,9.5,12,9.5c-0.1,0-0.1,0-0.2-0.1C1777.3,1021.6,1776.4,1020,1776.8,1018.5z"
        />
        <path
          class="st0 op op3"
          d="M1865,994c1.4-1,3-1.9,4.6-2.7c1.8-0.9,3.8-1.6,5.7-2.1l0.4-0.1l-0.3-0.3c-1.8-1.6-3.5-3.4-5-5.3l-0.1-0.1l0,0 c-1-1.3-2-2.7-2.9-4.2c-0.6-1.2-0.9-2.6-1-4c0,0,0-1.7,2.2-2.3c0,0,4.3-1.2,1.7-5.4c0,0-1.2-2.2,1-3.2c0,0-11,1.3-7.8,8.7 c1.4,3.9,3.5,7.6,6.1,10.8l-4.6,2.7l2.8,4.7c-3.3,1.7-6.3,4.1-8.8,7c-4.5,5.2,4.8,11.5,4.8,11.5c-1.8-2,0-3.4,0-3.4 c3.8-2.6,0.2-5.8,0.2-5.8c-1.8-1.7-1.3-3.2-1.3-3.2C1863.1,996,1863.9,994.9,1865,994z"
        />
        <path
          class="st0 op op2"
          d="M1882.8,1004c4.1-3.7,9.1-6.2,14.5-7.5l0.4-0.1l-0.3-0.4c-3.9-4.5-6.9-9.8-8.5-15.5c-0.7-3.2-0.6-6.6,0.4-9.8 c0.8-2.2,2.6-3.9,4.9-4.5c0,0,5.8-1.6,3.5-7.9c-0.6-1.9,0.4-3.9,2.2-4.6c0,0-10.7,1.3-12.2,8.7c-0.1,0.1-0.1,0.3-0.1,0.4 c-2.4,9-0.6,18.8,4.8,27.5l-5,2.1l2.4,5.6c-8.4,4-14.4,10.9-16.8,19.6c0,0.2-0.1,0.3-0.1,0.5c-2.2,7.1,6,14.9,6,14.9 c-1.2-1.6-1.1-3.8,0.4-5.2c4.9-3.8,0.9-8.8,0.9-8.8c-1.6-1.9-2.3-4.3-1.9-6.8C1878.8,1009.3,1880.4,1006.4,1882.8,1004z"
        />
        <path
          class="st0 op op1"
          d="M1925.6,1011.5l-0.3-0.4c-3.4-5.2-5.5-11.1-6.2-17.2c-0.2-3.7,0.5-7.4,2.1-10.8c1.2-2.4,3.4-4.1,6-4.8 c3.7-1,6-4.8,5.1-8.6c-0.2-2.2,1.1-4.2,3.1-5c0,0-11.4,1.3-14.4,9.3c-0.1,0.2-0.1,0.3-0.2,0.5c-4.2,10.2-3.9,21.5,0.6,31.4l-5.1,1.5 l1.6,5.6c-9.9,4-17.9,11.8-22.2,21.8c-0.1,0.2-0.1,0.3-0.2,0.5c-3.7,7.8,3.7,16.5,3.7,16.5c-0.9-2-0.4-4.3,1.3-5.7 c3.3-2,4.5-6.3,2.5-9.6c-1.4-2.3-1.7-5-0.8-7.5c1.3-3.6,3.5-6.8,6.4-9.3c4.9-3.9,10.5-6.6,16.6-8L1925.6,1011.5z"
        />
        <rect
          x="1736.7"
          y="1008.1"
          transform="matrix(0.1989 -0.98 0.98 0.1989 402.9319 2514.3726)"
          class="st0 op op7"
          width="5.3"
          height="5.3"
        />
        <rect
          x="1786.5"
          y="986.3"
          transform="matrix(0.5125 -0.8587 0.8587 0.5125 22.8545 2019.146)"
          class="st0 op op5"
          width="6.3"
          height="6.3"
        />
        <path
          class="st3 an7"
          d="M1929.5,1013.9c-117.1-76.3-231.1,6.8-254.9,69.7"
        />
        <line
          class="st3 an8"
          x1="1828.8"
          y1="982.2"
          x2="1753.4"
          y2="893"
        />
      </g>
    </svg>
    <svg
      v-if="showIcon"
      version="1.1"
      preserveAspectRatio="xMinYMax slice"
      id="svg2"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 900 1080"
      style="enable-background:new 0 0 900 1080;"
      xml:space="preserve"
    >
      <path
        class="st0 op op11"
        d="M60.6,922c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
      C64.9,919.5,63.1,921.8,60.6,922C60.7,922,60.6,922,60.6,922z"
      />
      <path
        class="st0 op op3"
        d="M28.1,656.1c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
      C32.3,653.6,30.5,655.8,28.1,656.1C28.1,656.1,28.1,656.1,28.1,656.1z"
      />
      <g class="ops op2">
        <path
          class="st1"
          d="M42,596.6c11.2,8.3,27,5.9,35.2-5.3c1.1-1.5,2-3.1,2.8-4.7c4.6,3,10.2,3.8,15.5,2.2l15.4-2.4l-2.3-15.5
        c-0.1-5.5-2.5-10.6-6.8-14.1c10.5-9.2,11.5-25.1,2.4-35.6c-7.7-8.8-20.4-11.1-30.7-5.7l-9.6-7l-10.3,14c-5.7,7.8-4,18.7,3.7,24.4
        c0,0,0,0,0,0c-7.8-5.7-18.7-4-24.4,3.7c0,0,0,0,0,0l-10.4,14.1l9.7,7.1C30.4,581.3,34.3,590.9,42,596.6z"
        />
        <path
          class="st0"
          d="M51.4,592.5c9.2,6.8,22.2,4.8,28.9-4.4c0.9-1.2,1.6-2.5,2.2-3.8c3.8,2.5,8.4,3.1,12.7,1.8l12.7-2l-1.9-12.7
        c-0.1-4.5-2.1-8.7-5.5-11.6c8.6-7.5,9.5-20.6,1.9-29.2c-6.3-7.2-16.7-9.1-25.2-4.7l-7.9-5.8l-8.5,11.6c-4.7,6.4-3.3,15.4,3.1,20.1
        c-6.4-4.7-15.4-3.3-20.1,3.1l-8.5,11.6l7.9,5.8C41.9,580,45,587.9,51.4,592.5z"
        />
        <path
          class="st0"
          d="M109.6,549.7c-9.2,12.5-26.9,15.2-39.4,6c-12.5-9.2-15.2-26.9-6-39.4c9.2-12.5,26.9-15.2,39.4-6c0,0,0,0,0,0
        C116.1,519.5,118.8,537.1,109.6,549.7 M111.2,550.9c9.9-13.5,7-32.4-6.5-42.3c-13.5-9.9-32.4-7-42.3,6.5s-7,32.4,6.5,42.3
        c0,0,0,0,0,0C82.4,567.3,101.3,564.4,111.2,550.9z"
        />
        <path
          class="st0"
          d="M75.6,595.9c-9.2,12.5-26.9,15.2-39.4,6c-12.5-9.2-15.2-26.9-6-39.4c9.2-12.5,26.9-15.2,39.4-6c0,0,0,0,0,0
        C82.2,565.7,84.8,583.3,75.6,595.9 M77.3,597.1c9.9-13.5,7-32.4-6.5-42.3c-13.5-9.9-32.4-7-42.3,6.5c-9.9,13.5-7,32.4,6.5,42.3
        C48.5,613.5,67.4,610.6,77.3,597.1L77.3,597.1z"
        />
        <path
          class="st0"
          d="M69.8,553.6l-15.3-11.2c-8.4-6.2-10.2-18-4.1-26.5l11.2-15.3l15.3,11.2c8.4,6.2,10.2,18,4.1,26.5L69.8,553.6
        M70.2,556.4l12.4-16.9c6.9-9.3,4.8-22.5-4.5-29.3l-17-12.4l-12.4,16.9c-6.8,9.3-4.8,22.5,4.5,29.3L70.2,556.4L70.2,556.4z"
        />
        <path
          class="st0"
          d="M56.1,572.1c-6.2,8.4-18,10.3-26.5,4.1L14.4,565l11.2-15.3c6.2-8.4,18-10.2,26.5-4l15.3,11.2L56.1,572.1
        M57.8,573.3l12.4-16.9L53.3,544c-9.3-6.9-22.5-4.8-29.3,4.5l-12.4,16.9l16.9,12.4C37.8,584.7,50.9,582.7,57.8,573.3L57.8,573.3z"
        />
        <path
          class="st0"
          d="M115.3,589.5l-16.5,2.6h-0.2h-0.1c-10.6,3.2-21.9-2.8-25.1-13.4c-0.4-1.5-0.7-3-0.8-4.5v0c0-0.7,0-1.3,0-2
        v-0.1L72.3,558l13.6-4l0,0l0,0c0.6-0.2,1.2-0.4,1.8-0.5h0.2c10.8-2.6,21.7,4.1,24.2,14.9c0.4,1.5,0.5,3,0.6,4.5v0.1v0.1
        L115.3,589.5 M117.5,591.3l-2.8-18.5c-0.1-12.2-10.1-22.1-22.3-22c-1.6,0-3.3,0.2-4.9,0.6l0,0h-0.1c-0.7,0.2-1.4,0.4-2.2,0.6
        l-15.1,4.5l0.3,15.7c0,0.7,0,1.5,0,2.2l0,0l0,0c0.9,12.2,11.5,21.4,23.7,20.6c1.7-0.1,3.3-0.4,4.9-0.9l18.5-2.9L117.5,591.3z"
        />
        <polygon
          class="st2"
          points="72.7,558.9 73.3,558.1 92.5,572.2 91.9,573 	"
        />
        <path
          class="st2"
          d="M90.6,574.7c-1.2-0.9-1.4-2.6-0.6-3.7s2.6-1.4,3.7-0.6c1.2,0.9,1.4,2.6,0.6,3.7
        C93.5,575.4,91.8,575.6,90.6,574.7C90.6,574.8,90.6,574.8,90.6,574.7z"
        />

        <rect
          x="54"
          y="567.6"
          transform="matrix(0.5917 -0.8061 0.8061 0.5917 -431.0415 285.0799)"
          class="st2"
          width="23.8"
          height="1"
        />
        <path
          class="st2"
          d="M56.7,576.1c0.9-1.2,2.6-1.5,3.7-0.6c1.2,0.9,1.5,2.6,0.6,3.7c-0.9,1.2-2.5,1.5-3.7,0.6
        C56.1,579,55.8,577.3,56.7,576.1C56.7,576.1,56.7,576.1,56.7,576.1z"
        />
        <polygon
          class="st2"
          points="72.6,558.1 86.7,538.9 87.6,539.5 73.4,558.7 	"
        />
        <path
          class="st2"
          d="M85,537.6c-0.9,1.2-0.6,2.9,0.6,3.7s2.9,0.6,3.7-0.6c0.9-1.2,0.6-2.9-0.6-3.7c0,0,0,0,0,0
        C87.6,536.2,85.9,536.4,85,537.6C85,537.6,85,537.6,85,537.6z"
        />
        <path
          class="st2"
          d="M64.3,570.4c-6.6-4.8-8-14.1-3.1-20.6c4.8-6.6,14.1-8,20.6-3.1c6.6,4.8,8,14.1,3.1,20.6
        C80.1,573.8,70.8,575.2,64.3,570.4C64.3,570.4,64.3,570.4,64.3,570.4z"
        />
        <path
          class="st0"
          d="M70.6,561.7c-1.8-1.3-2.2-3.8-0.9-5.6s3.8-2.2,5.6-0.9c1.8,1.3,2.2,3.8,0.9,5.6c0,0,0,0,0,0
        C74.9,562.6,72.4,562.9,70.6,561.7z"
        />
        <path
          class="st0"
          d="M31.9,528.2c2.8-3.8,8.2-5.2,14.2-3.6l-0.5,2c-5.2-1.3-9.8-0.2-12,2.8s-1.9,7.8,0.9,12.3l-1.7,1.1
        C29.5,537.6,29.1,532,31.9,528.2z"
        />

        <rect
          x="29.4"
          y="525.2"
          transform="matrix(0.9885 -0.1513 0.1513 0.9885 -79.7179 11.2006)"
          class="st0"
          width="8.8"
          height="8.8"
        />
        <path
          class="st0"
          d="M117.1,594.6c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
        C121.3,592.2,119.5,594.4,117.1,594.6C117.1,594.6,117.1,594.6,117.1,594.6z"
        />
      </g>
      <path
        class="st0 op op1"
        d="M6.2,512.8c-2.5,0.3-4.7-1.5-4.9-4c-0.3-2.5,1.5-4.7,4-4.9c2.5-0.2,4.6,1.5,4.9,4
      C10.5,510.3,8.7,512.5,6.2,512.8C6.3,512.8,6.2,512.8,6.2,512.8z"
      />
      <g class="ops op1">
        <path
          class="st0"
          d="M141.7,1071.2l-25.6-7.5c-0.2,0.8-0.5,1.5-0.7,2.3l25.6,7.5c0.6,0.2,1.3-0.2,1.5-0.8c0,0,0,0,0,0
      C142.7,1072.1,142.3,1071.4,141.7,1071.2C141.7,1071.2,141.7,1071.2,141.7,1071.2z"
        />
        <path
          class="st0"
          d="M173.5,1008.6l-52.5,5.2c-0.1-1.4-0.3-2.9-0.5-4.3l35.8-5.4c0.7-0.1,1.1-0.8,1.1-1.4c-0.1-0.7-0.8-1.1-1.4-1
      l-35.8,5.4l0.2,1.5c-0.3-2-0.6-3.9-0.9-5.9l25.3-5.2c0.7-0.1,1.1-0.8,1-1.4c-0.1-0.7-0.8-1.1-1.4-1c0,0,0,0,0,0l-25.3,5.3
      c0.1,0.4,0.2,0.8,0.3,1.3c-0.4-1.9-0.8-3.8-1.2-5.7l10.4-2.7c0.6-0.1,1-0.7,0.9-1.3c-0.1-0.6-0.7-1-1.3-0.9c-0.1,0-0.1,0-0.2,0
      l-10.4,2.7c-0.4-1.4-0.8-2.9-1.2-4.3l3.6-1.1c0.6-0.2,0.9-0.8,0.8-1.4c-0.2-0.6-0.8-0.9-1.4-0.8c-0.2,0-0.4,0-0.6,0l-3.1,1
      c-0.5-1.4-0.9-2.8-1.4-4.2l10.4-3.9c0.6-0.2,0.9-0.9,0.7-1.5c0,0,0,0,0,0c-0.2-0.6-0.9-0.9-1.5-0.7c0,0,0,0,0,0l-10.4,3.9
      c-0.5-1.4-1.1-2.8-1.7-4.2l23.6-10.3c0.6-0.3,0.9-1,0.6-1.6c0,0,0,0,0,0c-0.3-0.6-1-0.9-1.6-0.6l-23.6,10.3c0.1,0.3,0.3,0.7,0.4,1
      c-0.7-1.7-1.5-3.4-2.3-5l32.2-16.1c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1.1-0.9-1.7-0.6l-32.1,16.1c-0.7-1.3-1.4-2.6-2-3.8l68.7-39.3
      c0.7-0.3,0.9-1.1,0.6-1.8s-1.1-0.9-1.8-0.6c-0.1,0-0.1,0.1-0.2,0.1l-68.6,39.2c-0.7-1.3-1.5-2.5-2.2-3.7l30.1-19.3
      c0.6-0.4,0.7-1.2,0.4-1.7c-0.4-0.6-1.2-0.7-1.7-0.4l-30.1,19.3c-0.8-1.3-1.6-2.5-2.5-3.7l20.6-14.8c0.6-0.4,0.7-1.2,0.3-1.7
      c-0.4-0.6-1.2-0.7-1.7-0.3l-20.6,14.8c-0.9-1.2-1.8-2.4-2.7-3.6l8.1-6.5c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2l-8.1,6.5
      c-1-1.2-2-2.4-3-3.6l2.2-1.9c0.4-0.5,0.4-1.2,0-1.6c-0.4-0.4-1-0.4-1.5-0.1l-2.2,1.9c-1.5-1.7-3.1-3.3-4.7-4.9l2.3-2.4
      c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0L82,933c-1.1-1.1-2.2-2.1-3.4-3.2l7.1-8.1c0.5-0.4,0.6-1.1,0.1-1.6
      c-0.4-0.5-1.1-0.6-1.6-0.1c-0.1,0.1-0.2,0.2-0.2,0.3l-7.1,8c0.5,0.4,1,0.8,1.4,1.3c-1.7-1.5-3.4-3-5.2-4.4l0.3,0.2l15.8-19.9
      c0.4-0.5,0.3-1.3-0.2-1.7c-0.5-0.4-1.3-0.3-1.7,0.2l-15.8,20c-1.2-0.9-2.4-1.8-3.6-2.7l20.7-29.1c0.5-0.5,0.5-1.3,0.1-1.8
      s-1.3-0.5-1.8-0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-20.7,29.1c-1.2-0.8-2.4-1.6-3.6-2.4l-2.3-1.4c-1.2-0.8-2.5-1.5-3.8-2.2l17.5-31
      c0.3-0.6,0.1-1.4-0.5-1.7c-0.6-0.3-1.4-0.1-1.7,0.5c0,0,0,0,0,0l-17.5,31c-1.3-0.7-2.6-1.4-4-2.1l11.2-22.6c0.3-0.6,0.1-1.3-0.5-1.6
      c0,0,0,0,0,0c-0.6-0.3-1.3-0.1-1.6,0.5L48,909.3c-1.4-0.7-2.7-1.3-4.1-1.9l4.1-9.5c0.3-0.6,0-1.3-0.6-1.5c-0.6-0.2-1.3,0-1.5,0.6
      l-4.1,9.5c-1.4-0.6-2.9-1.2-4.3-1.7l1-2.8c0.3-0.6,0-1.2-0.5-1.5c-0.6-0.3-1.2,0-1.5,0.5c0,0.1,0,0.1-0.1,0.2l-1,2.8l0.4,0.2
      c-2.3-0.8-4.6-1.6-6.9-2.3l1-3.3c0.2-0.6-0.2-1.2-0.8-1.4c-0.6-0.2-1.2,0.2-1.4,0.8l-1,3.3l1.6,0.5c-2-0.6-4.1-1.2-6.2-1.7h0.1
      l2.5-10.7c0.1-0.6-0.2-1.2-0.9-1.4s-1.2,0.2-1.4,0.9l-2.5,10.7c-1.5-0.3-3-0.6-4.5-0.9l4.5-25.2c0.1-0.7-0.3-1.3-1-1.4c0,0,0,0,0,0
      c-0.7-0.1-1.3,0.3-1.4,1L13,898.2c-1.5-0.2-2.9-0.5-4.4-0.7l4.6-35.7c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.4,1.1l-4.6,35.7
      c-1.4-0.2-2.9-0.3-4.3-0.4l4-52.2c0.1-0.7-0.5-1.4-1.2-1.4s-1.4,0.5-1.4,1.2l-4,52.2c-1.4-0.1-2.9-0.2-4.3-0.2l0.9-36
      c0-0.7-0.5-1.2-1.2-1.3c-0.7,0-1.3,0.5-1.3,1.2l-0.9,36c-1.5,0-3,0-4.4,0l-0.7-25.7c0-0.7-0.6-1.2-1.2-1.2c0,0,0,0,0,0
      c-0.7,0-1.2,0.6-1.2,1.3l0.7,25.7c-1.9,0.1-3.8,0.2-5.7,0.4l1.2-0.1l-0.9-10.8c-0.1-0.6-0.6-1.1-1.3-1.1c-0.6,0.1-1.1,0.6-1.1,1.3
      l0.9,10.8c-1.5,0.2-3.1,0.3-4.6,0.5l-0.5-3.4c-0.1-0.6-0.6-1.1-1.2-1c0,0,0,0,0,0c-0.6,0.1-1.1,0.6-1,1.3c0,0,0,0,0,0l0.5,3.5
      c-2.2,0.3-4.4,0.7-6.7,1.2l-0.8-4c-0.1-0.6-0.7-1-1.3-0.9c-0.6,0.1-1,0.7-0.9,1.3l0.8,4c-1.5,0.3-3,0.7-4.5,1.1l-2.9-11.2
      c-0.2-0.6-0.8-1-1.4-0.8c-0.6,0.2-1,0.8-0.8,1.4c0,0,0,0,0,0l2.9,11.2c-1.5,0.4-2.9,0.8-4.3,1.3l-8-25.1c-0.2-0.6-0.9-1-1.5-0.8
      c0,0,0,0,0,0c-0.6,0.2-1,0.9-0.8,1.5l8,25.1c-1.4,0.5-2.8,1-4.2,1.5l-12.9-34.3c-0.2-0.7-1-1-1.6-0.7c-0.6,0.2-1,1-0.7,1.6
      l12.9,34.4c-1.3,0.5-2.6,1.1-4,1.7l-21.1-48.6c-0.3-0.7-1.1-1-1.8-0.7c-0.7,0.3-1,1.1-0.7,1.8l21.3,48.7c-1.3,0.6-2.6,1.2-3.9,1.9
      l-16.5-32.9c-0.3-0.6-1.1-0.9-1.7-0.6c-0.6,0.3-0.9,1.1-0.6,1.7c0,0,0,0,0,0l16.5,33c-1,0.5-2.1,1.1-3.1,1.7l-0.7,0.4l-13.1-23.1
      c-0.3-0.6-1.1-0.8-1.6-0.5c0,0,0,0,0,0c-0.6,0.3-0.8,1.1-0.5,1.7l13.1,23.1c-1.3,0.8-2.6,1.6-3.8,2.4l-6.3-9.8
      c-0.3-0.5-1.1-0.7-1.6-0.4c-0.5,0.3-0.7,1.1-0.4,1.6c0,0,0,0,0,0l6.3,9.9c-1.3,0.9-2.5,1.7-3.7,2.6l-2.6-3.6
      c-0.4-0.5-1.1-0.6-1.6-0.3c-0.5,0.4-0.6,1.1-0.3,1.6c0,0,0,0,0,0l2.6,3.6l0.6-0.4c-2,1.5-3.9,3-5.8,4.6L-95,923
      c-0.4-0.5-1.1-0.5-1.6-0.2c-0.5,0.4-0.5,1.1-0.2,1.6l3.2,3.9c-1.2,1-2.3,2-3.4,3.1l-8.5-9.2c-0.4-0.5-1.2-0.5-1.6-0.1c0,0,0,0,0,0
      c-0.5,0.4-0.5,1.1-0.1,1.6c0,0,0,0,0,0l8.5,9.2c-1.1,1-2.1,2.1-3.2,3.2l-19.5-19.1c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2,0,1.7
      l19.5,19.1c-1,1.1-2,2.2-2.9,3.3l-28.2-24.9c-0.5-0.5-1.3-0.4-1.8,0.1c0,0,0,0,0,0c-0.5,0.5-0.4,1.3,0.1,1.8c0,0,0,0,0,0l28.3,24.9
      c-0.9,1.1-1.8,2.2-2.7,3.3l-42.4-33.6c-0.6-0.4-1.4-0.4-1.9,0.2c-0.5,0.6-0.4,1.4,0.2,1.9c0,0,0,0,0,0l42.5,33.7
      c-0.8,1.1-1.7,2.3-2.5,3.4l-30.8-21.9c-0.6-0.4-1.3-0.2-1.8,0.3c-0.4,0.6-0.3,1.4,0.3,1.8c0,0,0,0,0,0l30.9,21.9
      c-0.8,1.2-1.6,2.4-2.4,3.6l-23.3-14.8c-0.6-0.4-1.3-0.2-1.7,0.3c0,0,0,0,0,0c-0.4,0.6-0.2,1.3,0.4,1.7l23.3,14.8
      c0.2-0.3,0.3-0.5,0.4-0.8c-1.3,2.1-2.5,4.3-3.7,6.5c0.3-0.7,0.7-1.3,1-1.9l-11.1-6.3c-0.6-0.3-1.3-0.1-1.6,0.5
      c-0.3,0.6-0.1,1.3,0.4,1.6c0,0,0,0,0,0l11.1,6.3c-0.7,1.3-1.4,2.7-2,4l-4.9-2.4c-0.6-0.3-1.2,0-1.5,0.5c-0.3,0.6,0,1.2,0.5,1.5
      l4.9,2.5c-0.9,2-1.8,4-2.6,6.1l-5.6-2.3c-0.6-0.2-1.2,0-1.5,0.6c0,0,0,0,0,0c-0.2,0.6,0,1.2,0.6,1.5c0,0,0,0,0,0l5.6,2.3
      c-0.5,1.4-1,2.9-1.5,4.3V984l-12.9-4.5c-0.6-0.2-1.3,0.1-1.5,0.7c-0.2,0.6,0.1,1.2,0.7,1.5c0,0,0,0,0,0l12.9,4.5
      c-0.5,1.4-0.9,2.8-1.3,4.2l-27.2-8c-0.6-0.2-1.3,0.2-1.5,0.8c-0.2,0.6,0.2,1.3,0.8,1.5l27.3,8c-0.4,1.4-0.7,2.8-1,4.2l-37.7-9
      c-0.7-0.2-1.4,0.3-1.5,0.9c-0.2,0.7,0.3,1.3,0.9,1.5c0,0,0,0,0,0l37.7,9c-0.3,1.4-0.6,2.7-0.8,4.1l-54.3-10
      c-0.7-0.1-1.4,0.3-1.5,1.1c-0.1,0.7,0.3,1.4,1,1.5l54.3,10c-0.3,2.1-0.6,4.2-0.8,6.3c0.1-0.7,0.1-1.4,0.2-2.1l-38.5-5
      c-0.7-0.1-1.3,0.4-1.4,1.1c-0.1,0.7,0.4,1.3,1.1,1.4l38.6,5c-0.2,1.4-0.3,2.8-0.4,4.3l-28.5-2.2c-0.7-0.1-1.3,0.4-1.3,1.1
      c0,0,0,0,0,0c-0.1,0.7,0.4,1.3,1.1,1.3l28.6,2.2c-0.1,1.9-0.2,3.8-0.2,5.7c0-0.4,0-0.9,0-1.4l-13.7-0.3c-0.6,0-1.2,0.5-1.2,1.1
      c0,0.6,0.5,1.2,1.1,1.2c0,0,0,0,0,0l13.8,0.3c0,4.4,0.2,8.9,0.7,13.3l-7,0.7c-0.6,0.1-1.1,0.6-1,1.2c0.1,0.6,0.6,1.1,1.2,1l7-0.7
      c0.2,1.5,0.4,2.9,0.6,4.4l-14.3,2.2c-0.6,0.1-1.1,0.7-1,1.3c0,0,0,0,0,0c0.1,0.6,0.7,1.1,1.3,1c0,0,0,0,0,0l14.3-2.2
      c0.3,1.4,0.5,2.9,0.8,4.3l-28.6,5.9c-0.7,0.1-1.1,0.7-1,1.4c0,0,0,0,0,0.1c0.1,0.7,0.8,1.1,1.4,1l28.7-5.9c0.3,1.4,0.7,2.8,1,4.1
      l-38.3,10c-0.7,0.2-1.1,0.8-0.9,1.5c0,0,0,0,0,0c0.2,0.7,0.9,1.1,1.5,0.9c0,0,0,0,0,0l38.3-10c0.4,1.3,0.8,2.7,1.2,4l-53.4,16.9
      c-0.7,0.2-1.1,1-0.9,1.7c0.2,0.7,1,1.1,1.7,0.9c0,0,0,0,0,0l53.4-17c0.4,1.3,0.9,2.6,1.4,3.9l-37.1,13.9c-0.6,0.3-0.9,1-0.7,1.6
      c0.2,0.6,1,1,1.6,0.7l37.1-14c0.5,1.3,1,2.6,1.6,3.9l-26.9,11.7c-0.6,0.3-0.9,1-0.6,1.6c0,0,0,0,0,0c0.3,0.6,1,0.9,1.6,0.6
      c0,0,0,0,0,0l26.9-11.8c0.6,1.3,1.2,2.6,1.9,3.9l-12.9,6.5c-0.6,0.3-0.8,1-0.5,1.6c0.3,0.6,1,0.8,1.6,0.5c0,0,0,0,0,0l12.9-6.5
      c0.7,1.3,1.4,2.6,2.1,3.9l-6.1,3.5c-0.5,0.3-0.7,1-0.4,1.5c0.3,0.5,1,0.7,1.5,0.4l6.2-3.5c1.3,2.2,2.6,4.3,4.1,6.4
      c-0.2-0.3-0.4-0.6-0.6-0.9l-6.3,4.2c-0.5,0.3-0.7,1-0.3,1.6c0.3,0.5,1,0.7,1.6,0.3l6.4-4.2c0.9,1.3,1.8,2.6,2.8,3.9
      c-0.1-0.1-0.1-0.2-0.2-0.3l-12.1,9c-0.5,0.4-0.6,1.1-0.3,1.6c0,0,0,0,0,0c0.4,0.5,1.1,0.6,1.6,0.3c0,0,0,0,0,0l12.1-9
      c-0.1-0.1-0.1-0.2-0.2-0.3c0.9,1.2,1.9,2.5,2.9,3.7l-22.9,19c-0.5,0.4-0.6,1.2-0.2,1.7c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.7,0.2l22.9-19
      l-0.6-0.7c1.1,1.3,2.2,2.6,3.4,3.9l-29.5,27.1c-0.5,0.5-0.6,1.3-0.1,1.8c0,0,0,0,0,0c0.5,0.5,1.3,0.5,1.8,0.1l29.5-27.1
      c-0.3-0.4-0.7-0.7-1-1.1c1.3,1.4,2.6,2.7,3.9,4.1l-39.4,40.3c-0.5,0.5-0.5,1.3,0,1.9c0,0,0,0,0,0c0.5,0.5,1.3,0.5,1.9,0c0,0,0,0,0,0
      l39.5-40.3c-0.3-0.3-0.6-0.6-0.8-0.8c1.6,1.6,3.3,3.1,5,4.6c-0.4-0.3-0.8-0.7-1.1-1l-26.4,30c-0.4,0.5-0.4,1.3,0.1,1.8
      c0.5,0.5,1.3,0.4,1.8-0.1c0,0,0,0,0,0l26.4-30c-0.1-0.1-0.3-0.2-0.4-0.4c1.2,1,2.4,2.1,3.7,3.1l-18.4,23.2c-0.4,0.5-0.3,1.3,0.2,1.7
      c0,0,0,0,0,0c0.5,0.4,1.3,0.3,1.7-0.2l18.4-23.2c1.1,0.9,2.3,1.7,3.5,2.6l-8.5,12c-0.4,0.5-0.2,1.2,0.3,1.6c0.5,0.4,1.2,0.2,1.6-0.3
      l8.5-11.9c1.3,0.9,2.6,1.8,3.9,2.6l-0.2-0.1l-3.9,6.1c-0.3,0.5-0.2,1.2,0.3,1.6c0,0,0,0,0,0c0.5,0.3,1.2,0.2,1.6-0.3c0,0,0,0,0,0
      l3.9-6.1c2.4,1.5,4.8,2.8,7.3,4.2c-0.6-0.3-1.1-0.6-1.7-0.9l-3.7,6.8c-0.3,0.5-0.1,1.2,0.4,1.5c0.6,0.3,1.2,0.1,1.5-0.5l3.7-6.8
      c1.3,0.7,2.6,1.3,3.9,2l-6.5,13.6c-0.3,0.6,0,1.3,0.5,1.5c0,0,0,0,0,0c0.6,0.3,1.3,0,1.5-0.5c0,0,0,0,0,0l6.5-13.6
      c1.3,0.6,2.6,1.2,4,1.8l-11.4,27.5c-0.2,0.6,0,1.3,0.7,1.6c0.6,0.2,1.3,0,1.6-0.7l11.3-27.6l-0.4-0.2c1.4,0.6,2.9,1.2,4.4,1.7
      l-13.3,37.7c-0.2,0.7,0.1,1.4,0.8,1.6c0,0,0,0,0,0c0.7,0.2,1.4-0.1,1.6-0.8c0,0,0,0,0,0l13.3-37.7c1.3,0.4,2.6,0.9,3.9,1.3l-15.9,54
      c-0.2,0.7,0.2,1.4,0.9,1.6c0.7,0.2,1.4-0.2,1.6-0.9c0,0,0,0,0,0l15.9-54l-0.3-0.1c1.4,0.4,2.8,0.8,4.2,1.2l-9.2,38.8
      c-0.1,0.7,0.3,1.3,0.9,1.5c0.7,0.2,1.4-0.3,1.5-0.9c0,0,0,0,0,0l9.2-38.7l-0.2,0c1.4,0.3,2.9,0.7,4.3,0.9l-5.3,29
      c-0.1,0.7,0.3,1.3,1,1.4c0,0,0,0,0,0c0.7,0.1,1.3-0.3,1.4-1c0,0,0,0,0,0l5.3-28.9c-0.4-0.1-0.8-0.1-1.2-0.2c2.3,0.4,4.5,0.8,6.8,1.1
      l-1.3-0.2l-1.9,14.3c-0.1,0.6,0.4,1.2,1,1.3c0.6,0.1,1.2-0.4,1.3-1c0,0,0,0,0,0l1.8-14.3c1.5,0.2,2.9,0.3,4.4,0.5l-0.5,6.9
      c-0.1,0.6,0.4,1.1,1,1.2c0.6,0.1,1.2-0.4,1.2-1l0.5-6.9c2.2,0.2,4.3,0.3,6.5,0.3l0,7.3c0,0.6,0.5,1.1,1.1,1.1c0,0,0,0,0,0
      c0.6,0,1.1-0.5,1.1-1.1v-7.3c1.5,0,3,0,4.4,0l0.7,14.7c0,0.6,0.6,1.1,1.2,1.1c0,0,0,0,0,0c0.6,0,1.1-0.6,1.1-1.2c0,0,0,0,0,0
      l-0.7-14.7c1.8-0.1,3.6-0.2,5.3-0.3l-1,0.1l2.9,29.1c0.1,0.7,0.7,1.2,1.3,1.1c0.7-0.1,1.1-0.7,1.1-1.3l-2.9-29.1
      c1.4-0.1,2.9-0.3,4.3-0.5l5.9,38.9c0.1,0.7,0.7,1.2,1.4,1.1c0.7-0.1,1.1-0.7,1-1.4l-5.9-38.9c2-0.3,4-0.6,6-1l-1.9,0.4l11.2,54.6
      c0.1,0.7,0.8,1.2,1.5,1.1c0.7-0.1,1.2-0.8,1.1-1.5c0-0.1,0-0.1,0-0.2l-11.2-54.6c1.4-0.3,2.8-0.6,4.1-0.9l9.9,37.9
      c0.2,0.7,0.9,1.1,1.5,0.9c0.7-0.2,1.1-0.9,0.9-1.5l-9.9-37.8c1.4-0.4,2.7-0.7,4-1.1l8.7,27.4c0.2,0.7,0.8,1.1,1.5,0.9
      c0.7-0.2,1.1-0.8,0.9-1.5c0,0,0-0.1,0-0.1l-8.6-27.4c1.4-0.4,2.8-0.9,4.2-1.4l4.8,12.8c0.3,0.6,0.9,0.9,1.5,0.6
      c0.5-0.2,0.8-0.8,0.6-1.4l-4.8-12.8c1.4-0.5,2.8-1.1,4.2-1.6l2.5,5.7c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5
      c0,0,0-0.1,0-0.1l-2.5-5.6c-0.5,0.2-0.9,0.4-1.4,0.6c2.5-1,4.9-2.1,7.4-3.3l3,5.8c0.2,0.6,0.8,0.9,1.4,0.7c0.6-0.2,0.9-0.8,0.7-1.4
      c0-0.1-0.1-0.2-0.1-0.3l-3-5.8c1.3-0.7,2.6-1.4,4-2.1l7.1,11.9c0.3,0.6,1,0.7,1.6,0.4c0.6-0.3,0.7-1,0.4-1.6l-7.1-11.9
      c-0.4,0.2-0.8,0.5-1.2,0.7c1.7-1,3.3-2,4.9-3l15.7,23.7c0.4,0.6,1.1,0.7,1.7,0.4c0.6-0.4,0.7-1.1,0.3-1.7l-15.7-23.7
      c1.2-0.8,2.4-1.6,3.6-2.4l23,31c0.5,0.5,1.3,0.5,1.8,0c0.4-0.4,0.5-1,0.2-1.5l-23-30.9c1.1-0.8,2.3-1.7,3.4-2.5l34.9,42.2
      c0.4,0.6,1.2,0.8,1.8,0.4c0.6-0.4,0.8-1.2,0.4-1.8c-0.1-0.1-0.2-0.2-0.2-0.3l0-0.1l-34.9-42.2c1.1-0.9,2.2-1.8,3.3-2.7l25.8,28.1
      c0.5,0.5,1.3,0.5,1.8,0.1c0.5-0.5,0.5-1.3,0.1-1.8L79,1122.4c1.1-1,2.1-1.9,3.2-2.9l19.8,19.4c0.5,0.4,1.3,0.4,1.7-0.2
      c0.4-0.5,0.4-1.1,0-1.6l-19.8-19.4c1-1,2.1-2.1,3.1-3.2l9.5,8.4c0.5,0.4,1.2,0.3,1.6-0.2c0.4-0.5,0.3-1.1-0.1-1.6l-9.5-8.4
      c1-1.1,2-2.3,3-3.4l4,3.2c0.5,0.4,1.2,0.3,1.6-0.2s0.3-1.2-0.2-1.6l-4-3.2c1.4-1.7,2.7-3.5,4-5.3l4.5,3.1c0.5,0.3,1.2,0.2,1.6-0.4
      c0.3-0.5,0.2-1.1-0.3-1.5l-4.5-3.1c-0.2,0.3-0.4,0.6-0.6,0.8c1.1-1.5,2.1-3.1,3.1-4.6l10.9,6.7c0.6,0.3,1.3,0.2,1.6-0.4
      c0.3-0.5,0.2-1.2-0.4-1.6c0,0,0,0,0,0l-10.9-6.6c0.8-1.3,1.5-2.6,2.3-3.9l24.1,13c0.6,0.3,1.3,0.1,1.6-0.5c0,0,0,0,0,0
      c0.3-0.6,0.1-1.3-0.5-1.7l-24-13c0.7-1.3,1.4-2.6,2-3.9l33.9,16.1c0.6,0.3,1.4,0,1.7-0.7c0.3-0.6,0-1.3-0.6-1.6l-33.8-16.1
      c0.6-1.3,1.2-2.6,1.8-3.9l49.7,20.5c0.7,0.3,1.4,0,1.7-0.7c0.3-0.7,0-1.4-0.7-1.7l-49.7-20.5c0.5-1.3,1.1-2.6,1.6-4l35.1,12.3
      c0.7,0.2,1.4-0.1,1.6-0.8c0.2-0.7-0.1-1.4-0.8-1.6c0,0,0,0,0,0l-35.1-12.3c-0.1,0.3-0.2,0.6-0.3,0.9c1.6-4.4,3-8.9,4.1-13.4
      c-0.2,0.6-0.3,1.2-0.5,1.8l11.4,2.7c0.6,0.2,1.3-0.1,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5c-0.1,0-0.1,0-0.2,0l-11.3-2.7
      c0.4-1.8,0.8-3.5,1.2-5.3c0,0.3-0.1,0.6-0.2,0.8l4.1,0.8c0.6,0.1,1.2-0.3,1.3-1c0.1-0.6-0.3-1.1-0.9-1.3l-4-0.7
      c0.4-2.2,0.8-4.4,1.1-6.6l4.5,0.5c0.6,0.1,1.2-0.4,1.3-1c0.1-0.6-0.4-1.2-1-1.3l-4.4-0.5c0.2-1.5,0.3-3,0.5-4.6l11.8,0.7
      c0.6,0,1.2-0.5,1.2-1.1c0-0.6-0.4-1.2-1.1-1.2c0,0,0,0,0,0l-11.8-0.7c0.1-1.5,0.2-3,0.2-4.5l26.4,0.2c0.7,0,1.2-0.5,1.2-1.2
      c0-0.7-0.5-1.2-1.2-1.2l-26.3-0.2c0-2,0-4.1-0.1-6.2c0,0.6,0.1,1.2,0.1,1.8l36.5-1.7c0.7,0,1.3-0.5,1.3-1.2s-0.5-1.3-1.2-1.3
      c-0.1,0-0.1,0-0.2,0l-36.4,1.7c-0.1-1.4-0.1-2.9-0.2-4.3l52.5-5.2c0.7-0.1,1.2-0.8,1.1-1.5C174.7,1009.1,174.2,1008.6,173.5,1008.6z
      M54.3,1136.5c-60.8,34.5-138.1,13.2-172.7-47.6c-34.5-60.8-13.2-138,47.5-172.7c60.7-34.5,138.2-13.2,172.7,47.5
      C136.3,1024.6,115.1,1101.9,54.3,1136.5z"
        />
      </g>
      <g class="ops op3">
        <path
          class="st1"
          d="M231.6,941.2c10.8-6.1,14.6-19.8,8.4-30.6c0,0,0,0,0,0c-0.8-1.4-1.7-2.7-2.8-3.9c4.1-2.6,6.8-7,7.4-11.8
        l3.6-13.4l-13.4-3.7c-4.5-2-9.6-1.8-13.9,0.4c-0.5-1.6-1.2-3.1-2-4.5c-6.1-10.8-19.8-14.6-30.6-8.6c-7.5,4.2-12,12.5-11.4,21.1
        l-9.2,5.3l7.7,13.6c4.3,7.5,13.8,10.1,21.2,5.8c0,0,0,0,0,0c-7.5,4.3-10.1,13.8-5.9,21.3l7.7,13.5l9.3-5.3
        C214.9,945.1,224.1,945.5,231.6,941.2z"
        />
        <path
          class="st0"
          d="M231.7,932.1c8.9-5,12-16.3,6.9-25.1c0,0,0,0,0,0c-0.7-1.2-1.4-2.2-2.3-3.2c3.4-2.2,5.6-5.7,6.1-9.7l3-11
        l-11-3.1c-3.7-1.6-7.9-1.5-11.4,0.3c-0.4-1.3-0.9-2.5-1.6-3.7c-5-8.9-16.3-12-25.1-6.9c-6.1,3.5-9.8,10.2-9.3,17.2l-7.6,4.3
        l6.3,11.1c3.5,6.1,11.3,8.3,17.4,4.8c0,0,0,0,0,0c-6.1,3.5-8.3,11.3-4.8,17.5l6.3,11.1l7.6-4.3C218,935.3,225.6,935.6,231.7,932.1z
        "
        />
        <path
          class="st0"
          d="M218,869.2c6.9,12.1,2.6,27.4-9.4,34.3c-12.1,6.9-27.4,2.6-34.3-9.4c-6.9-12.1-2.6-27.4,9.4-34.3c0,0,0,0,0,0
        C195.8,853,211.1,857.2,218,869.2 M219.6,868.3c-7.4-13-23.8-17.5-36.8-10.2c-13,7.4-17.5,23.8-10.2,36.8s23.8,17.5,36.8,10.2
        c0,0,0,0,0.1,0C222.4,897.7,226.9,881.3,219.6,868.3z"
        />
        <path
          class="st0"
          d="M243.3,913.6c6.9,12.1,2.7,27.4-9.4,34.3c-12.1,6.9-27.4,2.7-34.3-9.4c-6.9-12.1-2.7-27.4,9.4-34.3
        c0,0,0,0,0,0C221.1,897.4,236.4,901.6,243.3,913.6 M244.9,912.8c-7.4-13-23.8-17.5-36.8-10.2c-13,7.4-17.5,23.8-10.2,36.8
        s23.8,17.5,36.8,10.2c0,0,0,0,0.1,0C247.7,942.2,252.2,925.7,244.9,912.8z"
        />
        <path
          class="st0"
          d="M206.7,903l-14.7,8.3c-8.1,4.6-18.4,1.8-23-6.3l-8.3-14.7l14.7-8.3c8.1-4.6,18.4-1.7,23,6.3L206.7,903
        M209.2,903.7l-9.3-16.3c-5.1-9-16.5-12.1-25.5-7l-16.3,9.3l9.2,16.3c5.1,9,16.5,12.1,25.5,7L209.2,903.7z"
        />
        <path
          class="st0"
          d="M216.8,920.8c4.6,8.1,1.8,18.4-6.3,23l-14.7,8.4l-8.3-14.7c-4.6-8.1-1.8-18.4,6.3-23l14.7-8.3L216.8,920.8
        M218.4,920l-9.2-16.3l-16.3,9.2c-9,5.1-12.1,16.5-7,25.5l9.2,16.3l16.3-9.2C220.4,940.4,223.5,929,218.4,920
        C218.4,920,218.4,920,218.4,920z"
        />
        <path
          class="st0"
          d="M252.5,879l-3.9,14.3v0.1v0.1c-1.3,9.9-10.3,16.8-20.2,15.5c-1.3-0.2-2.6-0.5-3.8-0.9h-0.1
        c-0.6-0.2-1.1-0.4-1.6-0.7l0,0h-0.1l-11.6-5l1.7-12.5V890c0-0.6,0.1-1.1,0.2-1.7v-0.1c1.8-9.7,11.2-16.1,21-14.3
        c1.3,0.2,2.6,0.6,3.8,1.2h0.1h0.1L252.5,879 M254.8,877.7l-16.1-4.5c-10-4.4-21.7,0.2-26,10.3c-0.6,1.4-1,2.8-1.3,4.2l0,0l0,0
        c-0.1,0.7-0.2,1.3-0.3,2l-1.9,13.9l12.9,5.5c0.6,0.3,1.2,0.5,1.8,0.8l0,0c10.2,3.8,21.6-1.5,25.4-11.7c0.5-1.4,0.9-2.9,1.1-4.4
        L254.8,877.7L254.8,877.7z"
        />
        <path
          class="st2"
          d="M218.4,913.6c-6.3,3.6-14.4,1.4-18-5s-1.4-14.4,5-18c6.3-3.6,14.3-1.4,17.9,4.9
        C226.9,901.9,224.7,910,218.4,913.6C218.4,913.6,218.4,913.6,218.4,913.6z"
        />

        <rect
          x="210.5"
          y="896.4"
          transform="matrix(0.8703 -0.4926 0.4926 0.8703 -413.0874 225.2769)"
          class="st2"
          width="21.3"
          height="0.9"
        />
        <path
          class="st2"
          d="M231.5,893.7c-1.1,0.7-2.6,0.2-3.3-0.9c-0.7-1.1-0.2-2.6,0.9-3.3c1.1-0.7,2.6-0.2,3.3,0.9
        C233.1,891.6,232.7,893.1,231.5,893.7C231.5,893.7,231.5,893.7,231.5,893.7z"
        />
        <polygon
          class="st2"
          points="222,920.9 211.5,902.4 212.3,901.9 222.8,920.4 	"
        />
        <path
          class="st2"
          d="M220.3,921.8c-0.7-1.1-0.3-2.6,0.9-3.3c1.1-0.7,2.6-0.3,3.3,0.9c0.7,1.1,0.3,2.6-0.9,3.3c0,0,0,0,0,0
        C222.4,923.4,220.9,923,220.3,921.8z"
        />

        <rect
          x="206.1"
          y="882.2"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -414.1788 218.6701)"
          class="st2"
          width="0.9"
          height="21.3"
        />
        <path
          class="st2"
          d="M199.2,884.8c0.7,1.1,2.1,1.5,3.3,0.9c1.1-0.7,1.5-2.1,0.9-3.3c-0.7-1.1-2.1-1.5-3.3-0.9
        C199,882.2,198.6,883.7,199.2,884.8C199.2,884.8,199.2,884.8,199.2,884.8z"
        />
        <path
          class="st0"
          d="M213.6,905.2c-1.7,1-3.8,0.4-4.8-1.3s-0.4-3.8,1.3-4.8c1.7-1,3.8-0.4,4.8,1.3
        C215.9,902.1,215.3,904.3,213.6,905.2C213.6,905.2,213.6,905.2,213.6,905.2z"
        />
        <path
          class="st0"
          d="M172.3,924.6c-2.1-3.6-1.2-8.5,2.2-12.8l1.4,1.1c-3,3.7-3.7,7.8-2.1,10.8s5.7,4.4,10.4,3.8l0.3,1.8
        C179.1,930,174.4,928.2,172.3,924.6z"
        />

        <rect
          x="170.1"
          y="919.7"
          transform="matrix(0.2654 -0.9641 0.9641 0.2654 -762.6086 846.3065)"
          class="st0"
          width="7.9"
          height="7.9"
        />
        <path
          class="st0"
          d="M276.8,866.8c-2.2,2.1-4.7,2.9-5.6,1.9s0-3.5,2.1-5.6s4.7-2.9,5.6-1.9S278.9,864.8,276.8,866.8z"
        />
        <path
          class="st0"
          d="M274.2,875.8c2.2,1.6,3.4,3.7,2.6,4.7s-3.1,0.6-5.3-1s-3.4-3.7-2.6-4.7S272,874.2,274.2,875.8z"
        />
        <path
          class="st0"
          d="M264.4,864.3c0.8,2.2,0.6,4.3-0.5,4.6s-2.5-1.1-3.3-3.3s-0.6-4.3,0.5-4.6S263.6,862.1,264.4,864.3z"
        />
        <polygon
          class="st0"
          points="252.9,877.8 271.8,867.1 272.6,868.5 253.7,879.2 	"
        />
        <path
          class="st0"
          d="M254.7,876.8c12-6.8,15-2,15.1-1.8l-1.3,0.8c0,0-2.5-3.6-13,2.3L254.7,876.8z"
        />
        <path
          class="st0"
          d="M258.4,874.9c5.6-3.2,4.5-7.5,4.5-7.7l1.5-0.4c0,0,1.5,5.6-5.2,9.5L258.4,874.9z"
        />
        <path
          class="st0"
          d="M244.6,967c-0.8-2.8-0.3-5.4,1-5.8s3,1.6,3.8,4.5s0.3,5.4-1,5.8S245.4,969.9,244.6,967z"
        />
        <path
          class="st0"
          d="M236.1,963.2c-2.3,1.5-4.7,1.9-5.4,0.8s0.6-3.2,2.8-4.7s4.7-1.8,5.4-0.8S238.4,961.8,236.1,963.2z"
        />
        <path
          class="st0"
          d="M250.4,953.8c-2.1,1-4.2,1-4.7,0s0.9-2.6,3-3.6s4.2-1,4.7,0S252.6,952.8,250.4,953.8z"
        />

        <rect
          x="240.8"
          y="946.5"
          transform="matrix(0.8688 -0.4952 0.4952 0.8688 -441.1308 244.9069)"
          class="st0"
          width="1.6"
          height="16.8"
        />
        <path
          class="st0"
          d="M236.7,948l1.4-0.8c3,5.2,3.9,9,2.6,11.1c-0.6,1-1.6,1.6-2.7,1.6v-1.6c0.6,0,1.1-0.4,1.4-0.9
        C239.9,956.6,240.2,954.2,236.7,948z"
        />
        <path
          class="st0"
          d="M237.4,949.2l1.4-0.8c3.2,5.7,7.4,4.6,7.5,4.6l0.4,1.5C246.4,954.6,241.2,955.9,237.4,949.2z"
        />
        <path
          class="st0"
          d="M172.6,841.3c2.4,2.5,5.3,3.6,6.5,2.5s0.2-4.1-2.2-6.5s-5.3-3.6-6.5-2.5S170.2,838.8,172.6,841.3z"
        />
        <path
          class="st0"
          d="M172.5,850.5c-2,1.3-3.1,3.1-2.5,4s2.7,0.7,4.7-0.6s3.1-3.1,2.5-4S174.5,849.2,172.5,850.5z"
        />
        <path
          class="st0"
          d="M188.3,842.2c-2.6,1.3-4.2,3.1-3.7,4.1s3.1,0.8,5.7-0.5s4.2-3.1,3.7-4.1S190.9,840.9,188.3,842.2z"
        />

        <rect
          x="181"
          y="841.4"
          transform="matrix(0.8689 -0.495 0.495 0.8689 -396.819 201.3951)"
          class="st0"
          width="1.6"
          height="16.8"
        />
        <path
          class="st0"
          d="M175.7,850.2c0.6-0.9,1.7-1.5,2.8-1.5c2.5,0,5.2,2.7,8.2,8l-1.4,0.8c-3.5-6.2-5.8-7.1-6.9-7.2
        c-0.6,0-1.1,0.3-1.4,0.7L175.7,850.2z"
        />
        <path
          class="st0"
          d="M182.9,850.6c0-1.8,0.6-3.6,1.8-5l1.1,1.1c-0.1,0.1-3.1,3.2,0.1,8.8l-1.4,0.8
        C183.6,854.6,183,852.6,182.9,850.6z"
        />
      </g>
      <path
        class="st0 op op10"
        d="M115.4,868.9c-3.8-4.5-7.1-0.8-7.1-0.8c-0.9,1.1-2.4,1.5-3.7,1.2c-1.6-0.7-3-1.6-4.3-2.8
      c-3.2-3.6-5.8-7.7-7.7-12.1l-0.2-0.5l-0.3,0.4c-1.3,1.5-2.8,2.9-4.4,4.1l-0.2,0.1l0,0c-0.2,0.1-0.3,0.3-0.5,0.4
      c-1.7,1.3-3.4,2.4-5.3,3.3c-1.6,0.6-3.2,0.9-4.9,0.8c-1.5-0.3-2.7-1.3-3.2-2.7c0,0-2.1-4.9-6.9-2.4c-1.5,0.7-3.3,0-4-1.5
      c0,0,0,0,0-0.1c0,0,3.2,11.9,11.3,9.8c5.1-1.2,9.9-3.6,13.9-6.8l2.4,4.4l4.7-2.6c2.8,4.5,6.3,8.5,10.5,11.9
      c7.3,6.4,13.8-3.8,13.8-3.8C117.5,871.1,115.4,868.9,115.4,868.9z"
      />
      <path
        class="st0 op op9"
        d="M134.9,845.9c-5.3-4.8-9.2,0.2-9.2,0.2c-1.5,2-3.9,3.1-6.4,3c-3.6-0.3-6.9-1.6-9.9-3.7c-5-4-9-9.1-11.9-14.8
      l-0.2-0.5l-0.3,0.4c-3.4,4.8-8,8.7-13.4,11.3c-3.3,1.3-6.9,1.7-10.3,1.1c-2.6-0.6-4.8-2.3-5.9-4.7c0,0-3.3-6-9.1-2.7
      c-1.9,0.9-4.2,0.1-5.3-1.7c0,0,4.3,11.4,12.3,12l0.5,0.1c10.1,1.3,20-2.2,27.4-9.6l3,3.9l4-3.1c6.4,9.4,15.8,15.5,25.9,16.9l0.5,0.1
      c7.9,1.5,13.6-8.6,13.6-8.6C138.8,847.1,136.5,847.2,134.9,845.9z"
      />
      <path
        class="st0 op op8"
        d="M151.4,815.4c-6.9-4.7-11.2,1.2-11.2,1.2c-3.6,4.7-7.9,4.9-7.9,4.9c-6.5,0.6-13.1-0.5-19.1-3.1
      c-5.1-3.3-9.6-7.6-13-12.7l-0.3-0.4l-0.3,0.4c-3.2,4.8-7.5,8.9-12.5,11.8c-6.1,2.3-12.6,2.9-19,1.8c0,0-4.4-0.5-8.1-5.4
      c0,0-4.6-6.2-11.3-2c-2.2,1.1-5,0.6-6.6-1.4c0,0,4.7,8.8,12.2,11.3c3.9,1.3,7.9,2.1,12,2.2c11.5,0.4,22.5-4.3,30.2-12.7l3.2,3.6
      l3.9-3.5c8.1,9,19.2,14.4,30.8,14.8c4.1,0.1,8.1-0.4,12-1.5c7.4-2,11.7-10.4,11.7-10.4C155.1,817.6,151.4,815.4,151.4,815.4z"
      />
      <path
        class="st0 op op7"
        d="M136.4,791.5C136.4,791.5,136.4,791.4,136.4,791.5c-5.7-3.8-8.6,2-8.6,2c-0.9,2.3-3,3.9-5.4,4.3
      c-3.5,0.4-7-0.3-10.1-1.9c-0.4-0.2-0.7-0.4-1-0.6l-0.9-0.6l0.1,0.1c-4.4-2.9-8.2-6.6-11.3-11l-0.3-0.4l-0.2,0.4
      c-2,4.7-5,8.8-8.7,12.3l0.1-0.1c-0.2,0.2-0.5,0.5-0.7,0.7s-0.6,0.5-0.9,0.7c-2.8,2-6.1,3.2-9.6,3.3c-2.5-0.1-4.8-1.4-6.2-3.5
      c0,0-4.1-5.3-8.8-0.8c-1.6,1.2-3.9,0.9-5.2-0.7c0,0,5.5,9.7,12.7,9.5h1.3c9.5-0.7,18-6.1,23.7-14.9l3.7,3.2l3.2-3.7
      c7.5,7.9,17.1,12.1,26.7,11.4c0.4,0,0.8-0.1,1.2-0.1l0.6-0.1c6.6-1.3,9.7-11,9.7-11C140.3,791.8,138.2,792.4,136.4,791.5z"
      />
      <rect
        x="90.7"
        y="749.4"
        transform="matrix(0.5223 -0.8527 0.8527 0.5223 -596.8165 439.0725)"
        class="st0 op op6"
        width="5.7"
        height="5.7"
      />
      <rect
        x="66.5"
        y="695.3"
        transform="matrix(0.2103 -0.9776 0.9776 0.2103 -627.8402 620.0771)"
        class="st0 op op4"
        width="6.8"
        height="6.8"
      />
      <rect
        x="98.3"
        y="645.4"
        transform="matrix(4.710640e-02 -0.9989 0.9989 4.710640e-02 -551.2132 719.7966)"
        class="st0 op op4"
        width="6.8"
        height="6.8"
      />
      <path
        class="st0 op op5"
        d="M285.8,1005.6C285.8,1005.6,285.8,1005.6,285.8,1005.6c-2.2-1.2-4.9-0.3-6.1,1.9s-0.3,4.9,1.9,6.1
      s4.9,0.3,6.1-1.9C288.8,1009.5,288,1006.8,285.8,1005.6z"
      />
      <path
        class="st0 op op11"
        d="M423.7,1014.5C423.7,1014.5,423.7,1014.5,423.7,1014.5c-2.2-1.2-4.9-0.3-6.1,1.9s-0.3,4.9,1.9,6.1
      s4.9,0.3,6.1-1.9C426.7,1018.4,425.9,1015.7,423.7,1014.5z"
      />
      <path
        class="st0 op op9"
        d="M375.5,971.5c2.4,2,0,4.4,0,4.4c-4.8,4.4-0.4,7.6-0.4,7.6c1.3,0.8,1.9,2.3,1.6,3.8c-0.6,1.7-1.6,3.4-2.9,4.7
      c-3.9,3.7-8.4,6.8-13.2,9.1l-0.5,0.3l0.4,0.3c2.1,1.5,4,3.2,5.7,5c1.6,1.6,2.9,3.4,4.2,5.3c0.9,1.6,1.3,3.3,1.4,5.1
      c0,0,0,2.2-2.8,3.6c0,0-5.4,2.6-2.1,7.5c0.8,1.5,0.3,3.4-1.2,4.2c-0.1,0-0.1,0.1-0.2,0.1c0,0,13.2-4.4,10.2-12.8
      c-1.9-5.4-4.9-10.2-8.9-14.2l4-3.1l-3.2-4.1c5.1-3.4,9.6-7.6,13.3-12.6C387.6,977.5,375.5,971.5,375.5,971.5z"
      />
      <path
        class="st0 op op4"
        d="M276,1026.9c-3.3-2.4-6.9-4.1-10.8-5.2l1.8-5.7l-5.5-1.8c1.9-4,3.2-8.3,3.7-12.7c1.5-8.5-10.3-7.2-10.3-7.2
      c2.7,0.4,1.9,3,1.9,3c-1.7,5.1,3,5.3,3,5.3c2.5,0.1,2.9,1.9,2.9,1.9c0.3,1.5,0.2,3-0.1,4.5c-0.6,1.7-1.2,3.4-2,5l0,0l-0.1,0.2
      c-1.1,2.4-2.4,4.6-3.9,6.8l-0.3,0.4h0.5c2.2,0.1,4.3,0.4,6.5,0.8c1.9,0.4,3.7,1,5.5,1.7c1.3,0.7,2.4,1.7,3.3,2.9
      c0,0,0.9,1.5-0.6,3.7c0,0-3,4.2,1.6,6c0,0,2.3,1.1,0.8,3.6C273.8,1040,281.9,1031.2,276,1026.9z"
      />
      <path
        class="st0 op op3"
        d="M266.2,1050.8c-4.5-8.5-12.5-14.4-22.2-16.5l1.1-6.3l-5.8-1c3.5-10.4,3-21.2-1.6-30l-0.2-0.4
      c-3.4-7.3-14.9-6.1-14.9-6.1c2.1,0.3,3.5,2.1,3.4,4.2c-0.9,7.1,5.5,7.4,5.5,7.4c2.5,0,4.8,1.3,6.2,3.5c1.8,3.1,2.7,6.6,2.8,10.2
      c-0.4,6.4-2.1,12.6-5.1,18.2l-0.2,0.5h0.5c5.9,0,11.8,1.5,17,4.3c3,1.9,5.4,4.6,6.9,7.8c1,2.4,0.9,5.2-0.4,7.5c0,0-3,6.2,3.1,8.9
      c1.8,1.1,2.5,3.4,1.6,5.3c0,0,6.7-10.1,2.6-17C266.4,1051.1,266.3,1051,266.2,1050.8z"
      />
      <path
        class="st0 op op2"
        d="M246.4,1073.8c-6.8-9.4-17.1-15.6-28.4-17.4l0.3-6.1l-5.8-0.3c2.4-11.4-0.1-23.4-6.9-33l-0.3-0.5
      c-5-7.7-17.3-6.2-17.3-6.2c2.4,0.3,4.2,2.1,4.5,4.5c0,4.2,3.2,7.6,7.4,7.8c2.9,0.1,5.6,1.4,7.4,3.6c2.5,3.1,4.1,6.8,4.8,10.8
      c0.8,6.6,0,13.3-2.4,19.5l-0.2,0.5h0.5c6.7-0.1,13.3,1.4,19.3,4.3c3.6,2,6.7,4.8,8.9,8.2c1.5,2.4,1.8,5.3,1,8c0,0-2.1,6.6,5,9.4
      c2.1,1,3.2,3.3,2.7,5.6c0,0,5.5-10.9-0.2-18.1L246.4,1073.8z"
      />
      <rect
        x="401.2"
        y="1008.3"
        transform="matrix(0.91 -0.4147 0.4147 0.91 -382.9422 258.5958)"
        class="st0 op op8"
        width="5.7"
        height="5.7"
      />
      <rect
        x="343"
        y="997.5"
        transform="matrix(0.7212 -0.6928 0.6928 0.7212 -596.7914 519.0358)"
        class="st0 op op6"
        width="6.7"
        height="6.7"
      />
      <path
        class="st0 op op1"
        d="M219.9,1090.7l-0.3-0.3c-5.7-5.5-13.5-8.1-21.6-7.5l-0.9-6.2l-5.9,0.8c-0.1-9.1-3.4-17.5-9.4-23.3l-0.3-0.3
      c-4.7-5-13.8-1-13.8-1c1.7-0.3,3.4,0.8,3.9,2.5c1.2,6,6.5,4.5,6.5,4.5c2.1-0.7,4.3-0.2,6,1.2c2.3,2,4,4.6,5,7.5
      c1.4,5.3,1.7,10.9,0.7,16.3l-0.1,0.4l0.4-0.1c4.8-1.6,10-2,15-1.1c2.9,0.7,5.6,2.3,7.7,4.5c1.5,1.7,2.1,4,1.7,6.3
      c0,0-0.8,5.8,4.9,6.4c1.8,0.4,3,2.1,2.8,3.9C222.3,1105.2,225.1,1095.2,219.9,1090.7z"
      />
      <path
        class="st0 op op5"
        d="M106.8,708.9c-6.3-1.5-6.5,3.9-6.5,3.9c0.1,1.5-0.9,2.9-2.3,3.5c-1.8,0.4-3.7,0.5-5.5,0.3
      c-5.3-1.2-10.3-3.1-15-5.8l-0.5-0.3v0.5c-0.1,2.5-0.4,5-0.9,7.5c-0.5,2.2-1.1,4.3-2,6.4c-0.8,1.6-2,3-3.5,4c0,0-1.8,1.2-4.5-0.3
      c0,0-5.2-3-7.4,2.4c-0.8,1.5-2.7,2.1-4.2,1.4c-0.1,0-0.1-0.1-0.2-0.1c0,0,11,8.5,16.3,1.3c3.4-4.5,5.7-9.8,6.8-15.3l4.8,1.6l1.6-4.9
      c5.7,2.4,11.7,3.7,17.9,4c10.6,1,8.8-12.4,8.8-12.4C110,709.5,106.8,708.9,106.8,708.9z"
      />
      <path
        class="st0 op op2"
        d="M22.7,622.5c-5.2,1.4-2.7,5.5-2.7,5.5c1.3,2.1,0.1,3.4,0.1,3.4c-1.1,1.1-2.4,1.9-3.8,2.4
      c-1.7,0.5-3.5,0.9-5.3,1.2l0,0l-0.3,0.1c-2.6,0.4-5.1,0.6-7.7,0.5H2.4l0.3,0.4c1.1,1.9,2.1,3.8,2.9,5.8c0.7,1.8,1.3,3.6,1.6,5.5
      c0.2,1.5,0,2.9-0.5,4.3c0,0-0.7,1.6-3.4,1.6c0,0-5.1-0.1-4.1,4.7c0,0,0.4,2.5-2.5,2.7c0,0,11.9,1.8,12.2-5.5
      c0.2-4.1-0.4-8.1-1.8-11.9l5.7-1.7l-1.6-5.5c4.4-0.7,8.7-2,12.7-4c7.9-3.5,0.2-12.6,0.2-12.6C25.3,621.6,22.7,622.5,22.7,622.5z"
      />
      <path
        class="st0 op op1"
        d="M-11.6,624.6c10.6-2.9,19.3-9.3,24-18.1c0.1-0.2,0.1-0.3,0.2-0.4c4.1-6.9-3.3-15.7-3.3-15.7
      c0.9,1.9,0.2,4.1-1.6,5.2c-6.3,3.2-3,8.7-3,8.7c1.4,2.1,1.6,4.7,0.6,7c-1.6,3.2-3.9,6-6.9,8c-5.5,3.3-11.6,5.3-18,5.9l-0.5,0.1
      l0.3,0.4c3.3,4.9,5.4,10.6,5.9,16.5c0.1,3.5-0.8,7-2.6,10.1c-1.4,2.2-3.8,3.6-6.4,3.9c0,0-6.8,1-5.7,7.5c0.1,2.1-1.4,4-3.5,4.3
      c0,0,12.2-0.1,15.6-7.3l0.3-0.4c4.5-8.5,4.9-18.4,1.2-27.6l5.9-2.6L-11.6,624.6z"
      />
      <path
        class="st0 op op6"
        d="M17.9,304.5c2.2,0.4,4.4-1,4.8-3.2s-1-4.4-3.2-4.8c0,0,0,0,0,0c-2.2-0.4-4.4,1-4.8,3.2
      C14.2,301.9,15.6,304,17.9,304.5z"
      />
      <path
        class="st0 op op5"
        d="M19.4,344.2c0-0.2,0-0.3,0.1-0.5c0.3-1.2,1.4-1.9,2.6-1.6c4.3,1.4,4.6-3.3,4.6-3.3c0.2-2.4,1.7-3,1.7-3
      c1.3-0.4,2.7-0.4,4-0.2c1.7,0.5,3.3,1.1,4.9,1.9c1.8,0.9,3.5,2,5.1,3.2l0.3,0.3v-0.5c0.1-2.4,0.5-4.8,1.1-7.1l0.1-0.2l0,0
      c0.4-1.6,0.9-3.2,1.5-4.8c0.6-1.3,1.4-2.4,2.5-3.3c1-0.6,2.3-0.5,3.1,0.3c0,0,3.5,2.7,5.2-2c0.1-0.2,0.1-0.3,0.2-0.4
      c0.6-1,1.9-1.2,2.9-0.6c0,0-7.7-7.8-11.5-0.8c-2.2,3.5-3.7,7.3-4.7,11.3l-5-1.9l-2,5.1c-3.4-1.5-7-2.4-10.7-2.5
      C18.6,333.2,19.4,344.2,19.4,344.2z"
      />
      <path
        class="st0 op op4"
        d="M14.5,367.2c5.9,1.5,7.3-4.6,7.3-4.6c0.4-2.4,1.9-4.4,4.1-5.6c2.9-1.4,6.2-2,9.4-1.6c5.4,1,10.4,3.3,14.6,6.7
      l0.3,0.3l0.1-0.5c1.1-5.8,3.4-11.2,6.8-16c2.1-2.5,4.8-4.5,7.8-5.7c2.2-0.7,4.6-0.3,6.4,1.1c0,0,4.8,3.4,8.2-2.1
      c1.1-1.6,3.2-2,4.9-1c0,0-7.5-7.5-14.1-4.2l-0.4,0.2c-8.4,3.6-15,11-18.4,20.5l-4.8-2.6l-2.9,5.2c-8.1-4.1-17.2-4.5-25.3-1l-0.4,0.2
      c-6.9,2.5-7.9,13.7-7.9,13.7C10.7,368.2,12.5,366.9,14.5,367.2z"
      />
      <path
        class="st0 op op3"
        d="M13.7,394.7c3.6,1.4,7.6-0.4,9-3.9c0.9-2.4,2.8-4.3,5.3-5.2c3.6-1.1,7.4-1.4,11.1-0.7c6,1.4,11.5,4.1,16.3,8
      l0.4,0.3l0.2-0.5c1.9-5.8,5.2-11,9.5-15.3c2.8-2.4,6.1-4.1,9.6-4.9c2.6-0.5,5.2,0.1,7.3,1.7c3.1,2.2,7.4,1.6,9.7-1.4
      c1.6-1.5,4-1.7,5.8-0.5c0,0-8-8.1-16-5.5l-0.5,0.1c-10.4,2.9-19,10.1-23.9,19.5l-4.3-3.1l-3.3,4.6c-9.1-5.2-20-6.7-30.3-3.9
      l-0.5,0.2c-8.2,1.9-10.5,12.9-10.5,12.9C9.4,395.2,11.6,394.2,13.7,394.7z"
      />
      <path
        class="st0 op op2"
        d="M57.3,419L57.3,419c1.8-2.1,3.8-4,6.1-5.7c2.4-1.5,5.1-2.4,7.9-2.5c2,0,3.8,1,4.9,2.6c0,0,3,4.1,7.3,0.5
      c1.3-0.9,3.2-0.7,4.2,0.5c0,0-4.1-8.1-10.4-7.2h-0.4c-7.6,0.7-14.8,4.8-20.1,11.2l-3.7-4l-4.2,3.9c-5.4-5.1-12.5-7.7-19.8-7h-0.4
      c-6.3,0.2-10.1,9-10.1,9c0.9-1.4,2.7-1.9,4.2-1.2c4.4,2.8,7.2-1.8,7.2-1.8c1-1.8,2.8-3.1,4.8-3.5c2.8-0.4,5.6,0,8.1,1.2
      c4.1,2.2,7.6,5.5,10.2,9.4l0.2,0.3l0.2-0.4C54.6,422.6,55.9,420.7,57.3,419L57.3,419z"
      />
      <g>
        <line
          class="st3 an1"
          x1="-70.5"
          y1="452"
          x2="34.3"
          y2="529.5"
        />
        <path
          class="st3 an2"
          d="M-75.2,548.4c62.1-11.1,111.5-55.5,126-113.2c11.7-46.8-0.1-96-32.5-134.8"
        />
        <path
          class="st3 an3"
          d="M-242,799.7c0-100.5,76.3-182,170.4-182s171.4,81.5,171.4,182S29.4,979.7-64.7,979.7S-242,900.2-242,799.7"
        />
        <line
          class="st3 an4"
          x1="175"
          y1="923.1"
          x2="103.6"
          y2="963.8"
        />
        <path
          class="st3 an5"
          d="M173.5,1124.7c22.6-81.3,114-158.6,248.6-107.5"
        />
        <path
          class="st3 an6"
          d="M115.4,590.6c3.9,3.9,10.7,33.6-14.5,58.8c-17.7,17.7-54.2,20.4-72.2,2.4"
        />
      </g>
    </svg>
    <svg
      v-if="showIcon"
      version="1.1"
      preserveAspectRatio="xMaxYMax slice"
      id="svg2-1"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="1020 -20 900 1080"
      style="enable-background:new 0 0 900 1080"
      xml:space="preserve"
    >
      <g>

        <g class="ops op7">
          <path
            class="st0"
            d="M1690.4,815.6c2.1-0.9,4.6,0,5.5,2.1s0,4.6-2.1,5.5c-2.1,0.9-4.6,0-5.5-2.1c0,0,0,0,0,0 C1687.3,819,1688.3,816.5,1690.4,815.6z"
          />
          <path
            class="st1"
            d="M1761.4,831.1c-8.4-9.9-23.2-11.1-33.1-2.7c-1.3,1.1-2.4,2.3-3.5,3.7c-3.5-3.7-8.5-5.6-13.5-5.3l-14.5-1.2 l-1.2,14.5c-1.1,4.9,0,10.1,3.1,14.2c-1.5,0.8-3,1.8-4.3,2.9c-9.9,8.4-11.1,23.2-2.7,33.1c5.8,6.9,15,9.8,23.7,7.6l7.2,8.5 l12.4-10.5c6.9-5.8,7.8-16.1,2-22.9c0,0,0,0-0.1-0.1c5.8,6.9,16.1,7.7,22.9,2c0,0,0,0,0.1-0.1l12.4-10.5l-7.2-8.5 C1768.6,847.4,1767.2,837.9,1761.4,831.1z"
          />
          <path
            class="st0"
            d="M1752,832.7c-6.9-8.1-19-9.1-27.2-2.3c-1.1,0.9-2,1.9-2.8,3c-2.9-3-6.9-4.6-11.1-4.4l-11.9-0.9l-1,11.9 c-0.9,4.1,0,8.4,2.5,11.7c-1.2,0.6-2.4,1.4-3.5,2.3c-8.1,6.9-9.1,19.1-2.2,27.2c4.8,5.6,12.3,8,19.5,6.2l5.9,7l10.2-8.6 c5.6-4.8,6.3-13.2,1.6-18.8c0,0,0,0,0,0c4.8,5.6,13.2,6.3,18.8,1.6c0,0,0,0,0,0l10.2-8.7l-5.9-7 C1757.9,846.2,1756.8,838.3,1752,832.7z"
          />
          <path
            class="st0"
            d="M1690,858.9c11.1-9.4,27.6-8,37,3c9.4,11.1,8,27.6-3,37c-11.1,9.4-27.6,8-37-3c0,0,0,0,0,0 C1677.6,884.9,1679,868.3,1690,858.9 M1688.8,857.5c-11.9,10.1-13.4,27.9-3.3,39.7c10.1,11.9,27.9,13.4,39.7,3.3 c11.9-10.1,13.4-27.9,3.3-39.7c0,0,0,0,0,0C1718.4,848.9,1700.6,847.4,1688.8,857.5z"
          />
          <path
            class="st0"
            d="M1730.8,824.4c11.1-9.4,27.6-8,37,3c9.4,11.1,8,27.6-3,37c-11.1,9.4-27.6,8-37-3c0,0,0,0,0,0 C1718.4,850.3,1719.7,833.8,1730.8,824.4 M1729.5,823c-11.9,10.1-13.4,27.9-3.3,39.7c10.1,11.9,27.9,13.4,39.7,3.3 c11.9-10.1,13.4-27.9,3.3-39.7c0,0,0,0,0,0C1759.2,814.4,1741.4,812.9,1729.5,823z"
          />
          <path
            class="st0"
            d="M1726.9,864.1l11.4,13.5c6.3,7.4,5.4,18.5-2,24.9l-13.5,11.4l-11.4-13.5c-6.3-7.4-5.4-18.5,2-24.9 L1726.9,864.1 M1727.2,861.4l-14.9,12.6c-8.2,7-9.3,19.3-2.3,27.6l12.6,14.9l14.9-12.6c8.3-7,9.3-19.3,2.3-27.6c0,0,0,0,0,0 L1727.2,861.4L1727.2,861.4z"
          />
          <path
            class="st0"
            d="M1743.3,850.2c7.4-6.3,18.5-5.4,24.9,2l11.4,13.5l-13.5,11.4c-7.4,6.3-18.6,5.4-24.9-2l-11.4-13.5 L1743.3,850.2 M1742.1,848.7l-14.9,12.6l12.6,14.9c7,8.2,19.3,9.3,27.5,2.3c0,0,0,0,0,0l14.9-12.6l-12.6-14.9 C1762.6,842.7,1750.3,841.7,1742.1,848.7C1742.1,848.7,1742.1,848.7,1742.1,848.7L1742.1,848.7z"
          />
          <path
            class="st0"
            d="M1693.5,821.6l15.5,1.2h0.3c10.3-0.6,19.2,7.2,19.9,17.6c0.1,1.4,0,2.8-0.2,4.2v0.1c-0.1,0.6-0.2,1.2-0.4,1.8 v0.1l-2.8,12.9l-13.2,0.7h-0.1c-0.6,0.1-1.2,0.1-1.8,0.1h-0.2c-10.4,0-18.8-8.4-18.7-18.8c0-1.4,0.2-2.8,0.5-4.2v-0.1v-0.1 L1693.5,821.6 M1691.7,819.6l-1.5,17.4c-2.5,11.1,4.5,22.2,15.6,24.7c1.5,0.3,3,0.5,4.6,0.5h0.1c0.7,0,1.4,0,2.1-0.1l14.6-0.8 l3.2-14.3c0.2-0.7,0.3-1.4,0.4-2l0,0l0,0c1.9-11.3-5.7-21.9-17-23.8c-1.5-0.3-3.1-0.3-4.7-0.2L1691.7,819.6L1691.7,819.6z"
          />

          <rect
            x="1717.4"
            y="839.3"
            transform="matrix(0.7627 -0.6468 0.6468 0.7627 -142.303 1312.9254)"
            class="st2"
            width="1"
            height="22.2"
          />
          <path
            class="st2"
            d="M1712.6,840.3c0.9,1.1,0.8,2.6-0.3,3.5c-1.1,0.9-2.6,0.8-3.5-0.3s-0.8-2.6,0.3-3.5c0,0,0,0,0,0 C1710.1,839.2,1711.7,839.3,1712.6,840.3z"
          />
          <polygon
            class="st2"
            points="1742.3,844.9 1725.4,859.3 1724.8,858.5 1741.7,844.2 	"
          />
          <path
            class="st2"
            d="M1743.6,846.4c-1.1,0.9-2.6,0.8-3.5-0.3c-0.9-1.1-0.8-2.6,0.3-3.5c1.1-0.9,2.6-0.8,3.5,0.3c0,0,0,0,0,0 C1744.8,844,1744.7,845.5,1743.6,846.4C1743.6,846.4,1743.6,846.4,1743.6,846.4z"
          />
          <polygon
            class="st2"
            points="1725.3,859.3 1708.4,873.7 1707.8,872.9 1724.7,858.6 	"
          />
          <path
            class="st2"
            d="M1709.7,875.2c1.1-0.9,1.2-2.5,0.3-3.5c-0.9-1.1-2.5-1.2-3.5-0.3s-1.2,2.5-0.3,3.5c0,0,0,0,0,0 C1707,876,1708.6,876.1,1709.7,875.2C1709.7,875.3,1709.7,875.2,1709.7,875.2z"
          />
          <path
            class="st2"
            d="M1735.6,850c4.9,5.8,4.2,14.5-1.6,19.4c-5.8,4.9-14.5,4.2-19.4-1.6c-4.9-5.8-4.2-14.5,1.6-19.4 C1722,843.5,1730.6,844.2,1735.6,850C1735.6,850,1735.6,850,1735.6,850z"
          />
          <path
            class="st0"
            d="M1727.9,856.5c1.3,1.6,1.1,3.9-0.4,5.2c-1.6,1.3-3.9,1.1-5.2-0.4c-1.3-1.6-1.1-3.9,0.4-5.2 C1724.2,854.8,1726.6,855,1727.9,856.5C1727.9,856.5,1727.9,856.5,1727.9,856.5z"
          />
          <path
            class="st0"
            d="M1755.7,895.2c-3.3,2.8-8.5,2.9-13.6,0.2l0.9-1.7c4.4,2.3,8.8,2.3,11.5,0s3.4-6.7,1.8-11.4l1.8-0.6 C1760,887.2,1759.1,892.4,1755.7,895.2z"
          />

          <rect
            x="1750.2"
            y="889.4"
            transform="matrix(8.315601e-02 -0.9965 0.9965 8.315601e-02 718.0031 2567.5305)"
            class="st0"
            width="8.2"
            height="8.2"
          />
        </g>
        <rect
          x="1775.9"
          y="919.4"
          transform="matrix(8.211244e-02 -0.9966 0.9966 8.211244e-02 713.4471 2620.7095)"
          class="st0 op op6"
          width="7.2"
          height="7.2"
        />
        <rect
          x="1793.3"
          y="940.3"
          transform="matrix(8.280820e-02 -0.9966 0.9966 8.280820e-02 707.3826 2656.4475)"
          class="st0 op op5"
          width="7.2"
          height="7.2"
        />

        <path
          class="st0 op op11"
          d="M1690.7,1099.8c-1.6-4.8-6.2-2.8-6.2-2.8c-1.2,0.6-2.7,0.6-3.9-0.2c-1.2-1-2.2-2.3-2.8-3.7 c-0.7-1.8-1.2-3.7-1.5-5.6c-0.4-2.1-0.5-4.3-0.5-6.4v-0.4l-0.4,0.2c-4.1,1.8-8.5,3-13,3.4c-1.6,0-3.2-0.2-4.7-0.8 c-1.2-0.6-1.9-1.8-1.8-3.1c0,0,0.1-4.7-5.4-4c0,0-2.8,0.2-3-2.4c0,0-2,11.1,7,11.3c4.9,0.4,9.9-0.1,14.7-1.4l1.6,4.8l4.5-1.5 c0.7,4.8,2.5,9.4,5.2,13.5c4.2,6.6,13.9,0.5,13.9,0.5C1692.9,1101.8,1691.3,1101.2,1690.7,1099.8z"
        />
        <path
          class="st0 op op10"
          d="M1710.5,1090c-1.8-5.9-7.9-3.9-7.9-3.9c-2.3,0.9-4.9,0.7-7-0.6c-2.8-1.8-5-4.3-6.4-7.3 c-2-5.2-2.6-10.7-1.9-16.2l0.1-0.4l-0.5,0.2c-5.6,2.1-11.5,3-17.5,2.6c-3.3-0.5-6.4-1.8-9.1-3.8c-1.8-1.5-2.7-3.8-2.4-6.1 c0,0,0.5-5.9-6.2-6c-1.9-0.1-3.5-1.7-3.5-3.7c0,0-2.5,10.5,3.8,14.5c0.1,0.1,0.3,0.2,0.4,0.3c7.8,5.5,18.1,7.2,28.4,5l0.8,4.7 l4.5-0.7c0.4,9.8,4.9,18.4,12.6,23.9l0.4,0.3c5.8,4.6,16-0.4,16-0.4C1713.3,1092.8,1711.3,1091.8,1710.5,1090z"
        />
        <path
          class="st0 op op9"
          d="M1732.1,1080c-1.9-7.2-8.9-5.9-8.9-5.9c-5.7,1-9-1.5-9-1.5c-5-3.4-9.1-8-11.8-13.3c-1.5-5.2-1.9-10.7-1-16 l0.1-0.5l-0.5,0.1c-5.5,1.3-11.3,1.4-16.9,0.4c-5.8-2-10.9-5.5-15-10c0,0-2.8-2.9-2.3-8.4c0,0,0.8-6.8-6.9-8c0,0-3.9-0.8-3.9-4.8 c0,0-2.4,8.5,1.3,14.5c2,3.2,4.4,6.1,7.2,8.6c8.2,7.1,19.2,10.5,30.4,9.5l0.5,4.9l4.4-0.4c-0.1,10.6,4.4,20.7,12.5,27.8 c2.9,2.5,6.1,4.6,9.6,6.1c6.7,3.1,15.5,0.1,15.5,0.1C1735.1,1083.6,1732.9,1082.2,1732.1,1080z"
        />
        <path
          class="st0 op op8"
          d="M1683,1020.2c0.2,0.3,0.5,0.6,0.8,0.9c6.1,6.5,15.3,9.8,25.4,9.2l-0.2,4.5l4.5,0.2c-1.7,9.6,0.6,18.7,6.7,25.2 c0.3,0.3,0.5,0.6,0.8,0.8c4.8,4.6,14.6,1.6,14.6,1.6c0,0,0,0-0.1,0c-1.9,0.3-3.6-1-3.9-2.8c-0.4-6-6.6-5-6.6-5 c-2.3,0.5-4.7-0.1-6.5-1.6c-2.3-2.2-3.9-5.1-4.5-8.3c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3-0.1-0.7-0.1-1v0.1c-0.3-4.7,0.4-9.5,2-13.9 l0.2-0.4l-0.5,0.1c-4.8,1-9.8,1.1-14.6,0.3h0.2l-1-0.2c-0.4-0.1-0.7-0.2-1.1-0.3c-3.1-0.9-6-2.7-8.1-5.2c-1.4-1.8-1.8-4.2-1-6.3 c0,0,1.7-5.7-4.5-6.8c0,0,0,0,0,0c-1.8-0.5-2.9-2.3-2.4-4.1c0,0-4,8.6-0.4,13.7C1682.7,1019.9,1682.9,1020.1,1683,1020.2z"
        />
        <path
          class="st0 op op4"
          d="M1829,980.4C1829,980.4,1829,980.4,1829,980.4c-1.7,1.5-1.9,4.1-0.4,5.9c1.5,1.7,4.1,1.9,5.9,0.4 c1.7-1.5,1.9-4.1,0.4-5.9S1830.8,978.9,1829,980.4z"
        />
        <path
          class="st0 op op6"
          d="M1776.8,1018.5c2-5.1-3.5-6.3-3.5-6.3c-2.8-0.7-3.3-2.7-3.3-2.7c-0.3-1.6-0.3-3.3,0.2-4.9 c0.7-2,1.6-3.9,2.7-5.7c1.2-2,2.6-3.9,4.1-5.7l0.3-0.4l-0.5-0.1c-4.9-1.1-9.6-3-13.9-5.5c-1.4-1-2.7-2.2-3.6-3.7 c-0.6-1.3-0.4-2.8,0.7-3.8c0,0,3.4-3.8-1.9-6.8c0,0-2.6-1.6-1-4c0,0-9.7,7.9-1.9,14c4.4,3.8,9.4,6.7,14.8,8.7l-2,4.4l4.3,2 c-2.8,4.4-4.5,9.5-5.1,14.7c-0.9,8.3,12,9.5,12,9.5c-0.1,0-0.1,0-0.2-0.1C1777.3,1021.6,1776.4,1020,1776.8,1018.5z"
        />
        <path
          class="st0 op op3"
          d="M1865,994c1.4-1,3-1.9,4.6-2.7c1.8-0.9,3.8-1.6,5.7-2.1l0.4-0.1l-0.3-0.3c-1.8-1.6-3.5-3.4-5-5.3l-0.1-0.1l0,0 c-1-1.3-2-2.7-2.9-4.2c-0.6-1.2-0.9-2.6-1-4c0,0,0-1.7,2.2-2.3c0,0,4.3-1.2,1.7-5.4c0,0-1.2-2.2,1-3.2c0,0-11,1.3-7.8,8.7 c1.4,3.9,3.5,7.6,6.1,10.8l-4.6,2.7l2.8,4.7c-3.3,1.7-6.3,4.1-8.8,7c-4.5,5.2,4.8,11.5,4.8,11.5c-1.8-2,0-3.4,0-3.4 c3.8-2.6,0.2-5.8,0.2-5.8c-1.8-1.7-1.3-3.2-1.3-3.2C1863.1,996,1863.9,994.9,1865,994z"
        />
        <path
          class="st0 op op2"
          d="M1882.8,1004c4.1-3.7,9.1-6.2,14.5-7.5l0.4-0.1l-0.3-0.4c-3.9-4.5-6.9-9.8-8.5-15.5c-0.7-3.2-0.6-6.6,0.4-9.8 c0.8-2.2,2.6-3.9,4.9-4.5c0,0,5.8-1.6,3.5-7.9c-0.6-1.9,0.4-3.9,2.2-4.6c0,0-10.7,1.3-12.2,8.7c-0.1,0.1-0.1,0.3-0.1,0.4 c-2.4,9-0.6,18.8,4.8,27.5l-5,2.1l2.4,5.6c-8.4,4-14.4,10.9-16.8,19.6c0,0.2-0.1,0.3-0.1,0.5c-2.2,7.1,6,14.9,6,14.9 c-1.2-1.6-1.1-3.8,0.4-5.2c4.9-3.8,0.9-8.8,0.9-8.8c-1.6-1.9-2.3-4.3-1.9-6.8C1878.8,1009.3,1880.4,1006.4,1882.8,1004z"
        />
        <path
          class="st0 op op1"
          d="M1925.6,1011.5l-0.3-0.4c-3.4-5.2-5.5-11.1-6.2-17.2c-0.2-3.7,0.5-7.4,2.1-10.8c1.2-2.4,3.4-4.1,6-4.8 c3.7-1,6-4.8,5.1-8.6c-0.2-2.2,1.1-4.2,3.1-5c0,0-11.4,1.3-14.4,9.3c-0.1,0.2-0.1,0.3-0.2,0.5c-4.2,10.2-3.9,21.5,0.6,31.4l-5.1,1.5 l1.6,5.6c-9.9,4-17.9,11.8-22.2,21.8c-0.1,0.2-0.1,0.3-0.2,0.5c-3.7,7.8,3.7,16.5,3.7,16.5c-0.9-2-0.4-4.3,1.3-5.7 c3.3-2,4.5-6.3,2.5-9.6c-1.4-2.3-1.7-5-0.8-7.5c1.3-3.6,3.5-6.8,6.4-9.3c4.9-3.9,10.5-6.6,16.6-8L1925.6,1011.5z"
        />
        <rect
          x="1736.7"
          y="1008.1"
          transform="matrix(0.1989 -0.98 0.98 0.1989 402.9319 2514.3726)"
          class="st0 op op7"
          width="5.3"
          height="5.3"
        />
        <rect
          x="1786.5"
          y="986.3"
          transform="matrix(0.5125 -0.8587 0.8587 0.5125 22.8545 2019.146)"
          class="st0 op op5"
          width="6.3"
          height="6.3"
        />
        <path
          class="st3 an7"
          d="M1929.5,1013.9c-117.1-76.3-231.1,6.8-254.9,69.7"
        />
        <line
          class="st3 an8"
          x1="1828.8"
          y1="982.2"
          x2="1753.4"
          y2="893"
        />
      </g>
    </svg>
    <div v-if="!isMobile">
      <a
        href=" https://m.591.com.tw/show/housing/124656"
        target="_blank"
        class="fix-link fixed flex-c"
      >建案詳情</a>
      <img
        src="./s1/logo.png"
        alt=""
        class="logo absolute"
      >
    </div>
    <div
      v-else
      class="fullscreen"
    >
      <!--
       <img
        src="./mo/1/bg.png"
        alt=""
        class="bg-img"
      >
      -->
      <img
        src="./mo/1/logo.png"
        alt=""
        class="logo absolute"
      >
    </div>
    <img
      src="./s1/re.png"
      alt=""
      class="img absolute"
      v-scroll-to="{ element: `#contact` }"
    >
  </div>
</template>
<style lang="scss">
.svg {
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  .st0 {
    fill: #ffffff;
  }
  .st1 {
    opacity: 0.5;
    fill: #ffffff;
    enable-background: new;
  }
  .st2 {
    fill: #3b5662;
  }
  .st3 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4;
    stroke-miterlimit: 10;
    animation: letterDraw 2s linear 0s forwards;
  }
  .an1 {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation-duration: 1s;
    animation-delay: 1s;
  }
  .an2 {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation-duration: 2s;
    animation-delay: 1s;
  }
  .an3 {
    stroke-dasharray: 1500;
    stroke-dashoffset: 1500;
    animation-duration: 4s;
    animation-delay: 1s;
  }
  .an3-1 {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation-duration: 4s;
    animation-delay: 1s;
  }
  .an4 {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation-duration: 1s;
    animation-delay: 1s;
  }
  .an5 {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation-duration: 4s;
    animation-delay: 1s;
  }
  .an6 {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation-duration: 1s;
    animation-delay: 4s;
  }
  .an7 {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-duration: 5s;
    animation-delay: 1s;
  }
  .an8 {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation-duration: 2s;
    animation-delay: 2s;
  }
  .op {
    opacity: 0;
    animation: op 0.5s 1s forwards;
  }
  .ops {
    opacity: 0;
    animation: ops 0.5s 1s forwards;
  }
  .op1 {
    opacity: 0;
    animation-delay: 1s;
  }
  .op2 {
    opacity: 0;
    animation-delay: 1.5s;
  }
  .op3 {
    opacity: 0;
    animation-delay: 2s;
  }
  .op4 {
    opacity: 0;
    animation-delay: 2.5s;
  }
  .op5 {
    opacity: 0;
    animation-delay: 3s;
  }
  .op6 {
    opacity: 0;
    animation-delay: 3.5s;
  }
  .op7 {
    opacity: 0;
    animation-delay: 4s;
  }
  .op8 {
    opacity: 0;
    animation-delay: 4.5s;
  }
  .op9 {
    opacity: 0;
    animation-delay: 5s;
  }
  .op10 {
    opacity: 0;
    animation-delay: 5.5s;
  }
  .op11 {
    opacity: 0;
    animation-delay: 6s;
  }
  .tr {
    transform: translate(-5%, 0%);
  }
}
@keyframes letterDraw {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes op {
  to {
    opacity: 1;
  }
}
@keyframes ops {
  to {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
#svg1 {
  top: 60px;
  right: 0;
  width: size(900);
  transform: translate(0, -7%) rotate(180deg);
}
#svg1-1 {
  top: 60px;
  left: 0;
  width: size(900);
  transform: translate(0, -7%) rotate(180deg);
}
#svg2 {
  bottom: 0;
  left: 0;
  width: size(900);
  //transform: translate(0, -7%) rotate(180deg);
}
#svg2-1 {
  bottom: 0;
  right: 0;
  width: size(900);
  // transform: translate(0, -7%) rotate(180deg);
}
.relative {
  height: 100vh;
  overflow: hidden;
  background: #3b5662;
}

.fix-link {
  font-size: 24px;
  color: #fff;
  background-color: #a59a7e;
  writing-mode: vertical-lr;
  text-orientation: upright;
  right: 0;
  bottom: 150px;
  z-index: 2;
  position: fixed;
  text-decoration: none;
  padding: 20px 15px;
  letter-spacing: 5px;
}

.bg-img {
  width: 100vw;
  height: auto;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  object-fit: cover;

  &:nth-child(1) {
    position: relative;
  }
}

.logo {
  width: size(1100);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: size(-80);
  opacity: 0;
  animation: logo 0.5s 3s forwards;
}

.img {
  width: size(120);
  margin: 0 auto;
  bottom: size(50);
  left: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  animation: logo 0.5s 3.5s forwards;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-10%);
  }
}

@keyframes logo {
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 1440px) {
  .bg-img {
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  #svg1 {
    transform: translate(120%, 53%) rotate(90deg) scale(1.6);
    transform-origin: 50% 11%;
    width: 80vw;
    height: 100vw;
    top: 0;
    left: 0;
  }
  #svg1-1 {
    transform: translate(129%, 90%) rotate(104deg) scale(1.6);
    transform-origin: 50% 11%;
    width: 80vw;
    height: 100vw;
    top: auto;
    bottom: 63px;
    left: 0;
  }
  #svg2 {
    transform: translate(-27%, -13%) rotate(0) scale(-1.55, 1.55);
    transform-origin: 50% 11%;
    width: 80vw;
    height: 100vw;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
  }
  #svg2-1 {
    display: none;
  }

  .fix-link {
    font-size: 18px;
    color: #fff;
    right: 0;
    bottom: 100px;
    z-index: 5;
    position: fixed;
    text-decoration: none;
    padding: 10px 5px;
    letter-spacing: 5px;
  }

  .relative {
    height: calc(100vh - 63px);
  }
  .bg-img {
    height: auto;
    top: 0;
    bottom: 0;
    &:nth-child(1) {
      position: absolute;
    }
  }

  .logo {
    //top: size-m(197);
    margin-top: size-m(-120);
  }
  .img {
    width: size-m(120);
    bottom: size-m(50);
  }
}
</style>
<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section1',
  props: ['showIcon'],

  components: {},
  data() {
    return {
      isMobile,
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},
}
</script>
