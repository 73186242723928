<template>
  <div class="relative">
    <div v-if="!isMobile">
      <img
        src="./s3/bg.jpg"
        alt=""
        class="bg-img"
      >

      
<svg v-if="showIcon" version="1.1" id="svg6" class="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 421.8 564.3" style="enable-background:new 0 0 421.8 564.3;" xml:space="preserve">

        <g class="op op2">
          <path
            class="st0"
            d="M134.5,536.5c-2-0.9-3.9-1-5-0.6l-5.5-4.3c0.9,0.1,1.7,0.1,2.4-0.2c0.7-0.3,1.3-0.8,1.7-1.5
      c1.1-0.1,2.6-1,3.9-2.4c1.8-2,2.5-4.4,1.5-5.2c-1-0.9-3.2,0.1-5,2.1c-1.5,1.8-2.3,3.7-1.9,4.8c-0.2,0.3-0.5,0.6-0.8,0.8
      c-0.9,0.3-3.1,0.3-7.9-3.2c0,0,0,0-0.1-0.1l0,0c-0.3-0.2-0.6-0.4-0.9-0.6l0,0c0.6-0.6,1.1-1.2,1.6-1.9c6.5-8.4,7.3-19.5,3-28.4
      l7.8-10l-14.8-11.5c-2.6-2-5.6-3.2-8.6-3.7c0.5-2.4,0.5-4.7,0-6.7l0,0l-1-7.8l-7.8,1l0,0c-2,0-4.3,0.5-6.4,1.7
      c-1.2-2.8-3.1-5.4-5.7-7.4c0,0,0,0,0,0l-14.8-11.5l-7.8,10c-9.8-2-20.3,1.4-26.8,9.8c0,0,0,0,0,0.1c-0.5,0.6-1,1.3-1.4,2
      c-5.3-4.2-5.9-6.5-5.7-7.5c0-0.4,0.2-0.7,0.5-1c1,0,2.4-0.8,3.5-2.2c1.6-1.8,2.1-3.8,1.3-4.6c-0.8-0.7-2.8,0.1-4.3,1.9
      c-1.2,1.4-1.8,2.9-1.7,3.8c-0.5,0.5-0.8,1.1-0.9,1.8c-0.1,0.7,0.1,1.5,0.4,2.3l-4.8-3.7c0.1-1.4-0.6-3.5-2.1-5.4
      c-2.1-2.7-4.9-4.1-6.2-3.1c-1.3,1-0.7,4,1.5,6.8c2.1,2.7,4.7,4,6,3.2l6,4.7c-1.6-0.2-3.2,0.2-4.6,1c-1-0.1-2.7,1.2-4.1,3.2
      c-1.6,2.4-2.2,4.8-1.3,5.5c0.9,0.7,3-0.6,4.6-3c1.1-1.7,1.7-3.3,1.7-4.5c0.8-0.5,4-2,8.5,1.5l0,0l0.7,0.5c0.1,0.1,0.1,0.1,0.2,0.2
      c-6.4,11.4-3.7,26.2,7,34.5c1.6,1.2,3.3,2.3,5,3.1c-2.4,3.4-3.7,7.6-3.5,12.1c0.1,1.5,0.3,3,0.7,4.4l2,15.3l-0.8,1l0,0c0,0,0,0,0,0
      l-4.1,5.2c-1.9,1.7-3.8,2-5,1.9c-0.2-0.9-1.3-2.1-2.9-2.9c-2-1.1-4.1-1.2-4.6-0.2s0.8,2.6,2.8,3.7c1.4,0.7,2.8,1,3.7,0.8
      c0.9,0.1,2.1,0.2,3.5-0.2l-4.9,6.3c-1.2-0.2-3.1,0.5-4.8,1.8c-2.3,1.8-3.5,4.2-2.7,5.3s3.5,0.5,5.8-1.3c2-1.6,3.2-3.6,2.9-4.9
      l5.3-6.8c-0.8,2.9-0.1,4.6,0.5,5.4c-0.6,0.9-0.4,2.9,0.6,5c1.2,2.4,3.2,3.8,4.3,3.3s1-3-0.2-5.4c-0.9-1.9-2.3-3.1-3.4-3.4l0.1-0.2
      c0,0-2.3-2.2,1.6-8.8c0.1-0.1,0.1-0.2,0.2-0.3l0,0c0.4-0.7,0.9-1.4,1.5-2.2l1.7-2.2l15.5-1.9c6.1,0.1,11.6-2.5,15.3-6.8
      c1.2,1.5,2.6,2.8,4.2,4.1c10.7,8.3,25.6,7.4,35.1-1.7c0.2,0.1,0.4,0.3,0.5,0.4l0.7,0.5c3.8,3.1,3.7,6.1,3.4,7.3
      c-1,0.1-2.3,1.1-3.2,2.6c-1.3,2-1.6,4-0.7,4.7c0.9,0.7,2.7-0.5,4-2.4c0.9-1.4,1.3-2.7,1.2-3.7l0.1,0c0.1-0.1,0.9-2.2,0-4.8l6.8,5.3
      c0,1.2,1.6,2.9,4,3.9c2.7,1.2,5.3,1.1,5.9-0.2S137.2,537.6,134.5,536.5z M126.8,486l-6.2,8c-1.7-2.9-3.9-5.5-6.7-7.7
      c-7.8-6-17.8-7.2-26.3-3.9c-0.3,0.1-0.6,0.2-1,0.3l3.2-4.1c5.7-7.4,16.3-8.7,23.7-3L126.8,486z M105.6,505.7
      c-0.2-2.2-0.8-4.4-1.8-6.4l5.4-6.9l-10.1-7.8c-1.2-0.9-2.5-1.6-3.8-2c6-0.5,12.2,1.2,17.4,5.2c2.8,2.2,5.1,4.9,6.7,7.9l-2.9,3.7
      C113.7,503,109.8,505.1,105.6,505.7z M60.2,455.7l2.9-3.8c3.2,0.8,6.4,2.3,9.2,4.5c5.1,3.9,8.2,9.4,9.3,15.3
      c-0.7-1.1-1.6-2.1-2.7-2.9L68.8,461l-5.3,6.9c-2.2-0.5-4.5-0.5-6.6-0.2C56.4,463.6,57.5,459.3,60.2,455.7z M97.4,458.9l0.7,6l6-0.8
      c0.5,1.8,0.5,4,0,6.3c-5.9-0.3-11.9,2.2-15.8,7.2l-5.8,7.4c0,0,0,0,0,0l5.8-7.5c3.9-5,4.9-11.5,3.1-17.1
      C93.5,459.3,95.6,458.8,97.4,458.9z M70.6,442.4l13.3,10.4c7.4,5.7,8.7,16.3,3,23.7l-3.2,4.1c0.1-0.6,0.1-1.2,0.1-1.8
      c0.8-8.8-2.8-17.9-10.3-23.7c-2.8-2.2-5.9-3.7-9.1-4.7L70.6,442.4z M41.5,496.1c-11-8.5-12.9-24.3-4.4-35.3
      c5.9-7.6,15.3-10.9,24.1-9.3l-2.4,3.1c-3.1,4-4.3,8.8-3.8,13.5c-5.2,1.3-9.7,4.9-12.1,10c-3.4,7.3-1.6,15.7,3.8,21
      c0,0-0.1,0.1-0.1,0.1C44.8,498.4,43.1,497.4,41.5,496.1z M65.3,529.5c-1.3,0.3-2.7,0.4-4,0.4H61l-14.8,1.8l-1.9-14.8v-0.1v-0.1
      c-1.5-5.4-0.3-11,2.7-15.2c1.5,0.6,3,1,4.5,1.3c-2.2,3.3-2.9,7.3-1.8,11.1l1.5,11.3l11.4-1.4c3.9,0.1,7.7-1.6,10.3-4.5
      c0.7,1.4,1.5,2.8,2.4,4.1C72.9,526.4,69.4,528.6,65.3,529.5z M81.8,527.5c-1.6-1.2-2.9-2.6-4.1-4.1c0-0.1,0.1-0.1,0.1-0.2
      c7.9,4.7,18.2,2.9,24-4.5c2.5-3.2,3.8-7.2,3.9-11.1c4.7-0.6,9.1-3,12.2-7c0,0,0,0,0,0l2.4-3c3.6,8.2,2.7,18-3.2,25.6
      C108.6,534,92.8,536,81.8,527.5z"
          />
          <g class="st1">
            <path
              class="st0"
              d="M111,519.8c-7.7,9.9-22,11.6-31.8,3.9c-1.2-1-2.4-2.1-3.4-3.3c-3.2,3.7-7.9,5.7-12.8,5.7l-13.9,1.7L47.3,514
        c-1.3-4.7-0.4-9.7,2.4-13.7c-1.5-0.7-2.9-1.6-4.2-2.6c-9.8-7.5-11.6-21.6-4.1-31.4c0-0.1,0.1-0.1,0.1-0.2
        c5.3-6.8,14.1-10,22.5-8.2l6.6-8.4l12.4,9.6c6.8,5.2,8.1,15,2.8,21.7c0,0,0,0-0.1,0.1c5.3-6.8,15.2-8,22-2.7l12.4,9.5l-6.6,8.4
        C117.2,503.9,116.3,513.1,111,519.8z"
            />
          </g>
          <path
            class="st2"
            d="M85.4,502.8c4.5-5.7,3.4-14-2.3-18.5c-5.7-4.5-14-3.4-18.5,2.3s-3.4,14,2.3,18.5c0,0,0,0,0,0
      C72.6,509.6,80.9,508.6,85.4,502.8z"
          />

          <rect
            x="57.8"
            y="502.7"
            transform="matrix(0.6122 -0.7907 0.7907 0.6122 -371.2825 249.2368)"
            class="st2"
            width="21.3"
            height="0.9"
          />
          <path
            class="st2"
            d="M63.8,513c0.8-1,0.6-2.5-0.4-3.4c-1-0.8-2.5-0.6-3.4,0.4c-0.8,1-0.6,2.5,0.4,3.4c0,0,0,0,0,0
      C61.5,514.2,63,514,63.8,513z"
          />
          <polygon
            class="st2"
            points="92,507.5 75.2,494.4 74.7,495.1 91.5,508.2 	"
          />
          <path
            class="st2"
            d="M93.2,505.9c-1-0.8-2.5-0.6-3.4,0.4c-0.8,1-0.6,2.5,0.4,3.4c1,0.8,2.5,0.6,3.3-0.4
      C94.5,508.2,94.3,506.7,93.2,505.9z"
          />

          <rect
            x="66.1"
            y="477.6"
            transform="matrix(0.6136 -0.7896 0.7896 0.6136 -359.7793 241.1746)"
            class="st2"
            width="0.9"
            height="21.3"
          />
          <path
            class="st2"
            d="M59.6,479.8c1,0.8,1.2,2.3,0.4,3.4c-0.8,1-2.3,1.2-3.4,0.4c-1-0.8-1.2-2.3-0.4-3.3
      C57.1,479.2,58.6,479,59.6,479.8z"
          />
          <path
            class="st0"
            d="M77.8,496.9c1.2-1.5,0.9-3.8-0.7-4.9c-1.5-1.2-3.8-0.9-4.9,0.7c-1.2,1.5-0.9,3.7,0.6,4.9
      C74.3,498.8,76.5,498.5,77.8,496.9C77.7,497,77.7,496.9,77.8,496.9z"
          />
        </g>
        <g class="op op2">
          <path
            class="st0"
            d="M59.2,151.1c0.9-2.3-0.2-4.9-2.5-5.8c0,0,0,0,0,0c-2.3-0.9-4.9,0.2-5.8,2.5c-0.9,2.3,0.2,4.9,2.5,5.8
      C55.7,154.6,58.3,153.4,59.2,151.1z"
          />
          <path
            class="st0"
            d="M47.2,15.7c2.3,0.9,4.9-0.2,5.8-2.5s-0.2-4.9-2.5-5.8c-2.3-0.9-4.9,0.2-5.8,2.5c0,0,0,0,0,0
      C43.8,12.2,44.9,14.8,47.2,15.7z"
          />
          <path
            class="st0"
            d="M38.7,74.4l0.3-0.4c1.2-2.2,2.7-4.3,4.3-6.2c1.5-1.7,3.1-3.3,4.8-4.8c1.5-1,3.2-1.7,5-1.9c0,0,2.1-0.2,3.8,2.4
      c0,0,3.2,5.1,7.7,1.3c1.4-1,3.4-0.7,4.3,0.7c0,0.1,0.1,0.1,0.1,0.2c0,0-5.9-12.6-13.9-8.8C50,59.4,45.5,63,42,67.4l-3.5-3.6
      l-3.7,3.6c-3.9-4.7-8.7-8.8-14-11.8c-8.9-5.8-13.5,6.9-13.5,6.9c1.8-2.5,4.4-0.5,4.4-0.5c4.9,4.3,7.6-0.5,7.6-0.5
      c0.7-1.4,2.1-2.2,3.6-2c1.8,0.5,3.5,1.2,5,2.4c4.1,3.5,7.7,7.6,10.5,12.1L38.7,74.4z"
          />
          <path
            class="st0"
            d="M84.6,194.9l0.5,0.2v-0.5c-0.6-5.9,0.2-11.9,2.4-17.4c1.6-3.2,4-5.9,7-7.7c2.3-1.3,5.1-1.4,7.5-0.5
      c0,0,6.5,2.3,8.5-4c0.9-1.9,3.1-2.9,5.1-2.2c0,0-10.8-5.6-17.2-0.8l-0.4,0.3c-8,5.4-12.9,14-13.9,23.8l-6.5-0.4l-0.4,5.9
      c-10.7-2.4-21.4-0.7-29.6,4.9l-0.4,0.3c-6.9,4.2-4.5,15.5-4.5,15.5c0-2.1,1.7-3.8,3.8-3.9c7.1,0.1,6.7-6.3,6.7-6.3
      c-0.2-2.5,0.8-5,2.8-6.5c2.9-2.1,6.3-3.4,9.8-3.8C72.2,191.5,78.6,192.5,84.6,194.9z"
          />
          <path
            class="st0"
            d="M110.2,218.1l-0.1-0.5c-0.8-6.6-0.1-13.3,2.1-19.6c1.6-3.8,4-7.1,7.2-9.7c2.2-1.8,5.1-2.4,7.9-1.8
      c0,0,6.8,1.4,8.8-6c0.8-2.2,3-3.6,5.3-3.3c0,0-11.4-4.3-18,2.1l-0.4,0.4c-8.6,7.8-13.6,18.7-14.2,30.1l-6.2,0.3l0.3,5.7
      c-11.6-1.1-23.2,2.6-32,10.5l-0.4,0.4c-7.1,5.8-4.3,17.9-4.3,17.9c0-2.3,1.6-4.4,3.9-4.9c4.1-0.4,7.2-4,6.9-8.2
      c-0.3-2.9,0.8-5.7,2.8-7.7c2.9-2.8,6.3-4.9,10.2-6c6.5-1.5,13.2-1.4,19.7,0.2L110.2,218.1z"
          />
          <rect
            x="40.7"
            y="26.9"
            transform="matrix(0.8593 -0.5114 0.5114 0.8593 -9.0827 26.46)"
            class="st0"
            width="5.7"
            height="5.7"
          />

          <rect
            x="36.1"
            y="84.8"
            transform="matrix(0.6413 -0.7673 0.7673 0.6413 -53.4604 61.9404)"
            class="st0"
            width="6.7"
            height="6.7"
          />
          <path
            class="st0"
            d="M63.3,165.4l-1.1,5.7c-4.2-1.4-8.6-2.2-13-2.3c-8.7-0.6-6,11-6,11c0-2.7,2.7-2.2,2.7-2.2
      c5.3,1.2,4.9-3.6,4.9-3.6c-0.2-2.5,1.5-3.1,1.5-3.1c1.4-0.4,3-0.6,4.5-0.4c1.8,0.4,3.5,0.8,5.2,1.4l0,0l0.2,0
      c2.5,0.9,4.9,1.9,7.2,3.2l0.4,0.3v-0.5c-0.2-2.2-0.1-4.4,0.1-6.5c0.2-1.9,0.6-3.8,1.1-5.6c0.5-1.4,1.4-2.6,2.5-3.6
      c0,0,1.4-1.1,3.7,0.2c0,0,4.5,2.5,5.8-2.3c0,0,0.8-2.4,3.5-1.2c0,0-9.7-7.1-13.3-0.7c-2,3.5-3.3,7.3-3.9,11.3L63.3,165.4z"
          />
          <path
            class="st0"
            d="M142.7,204.1c-0.1,0.1-0.2,0.2-0.3,0.3c-4.9,6.3-6.6,14.3-5.1,22.3l-6.1,1.6l1.5,5.7
      c-9.1,1.1-17.1,5.3-22.2,11.9l-0.2,0.3c-4.5,5.3,0.5,13.8,0.5,13.8c-0.5-1.7,0.4-3.5,2.1-4.1c5.8-1.8,3.8-7,3.8-7
      c-0.9-2-0.7-4.3,0.5-6.1c1.8-2.5,4.2-4.5,7-5.8c2.8-1.1,5.8-1.8,8.7-2.2l0,0l0.2,0c2.4-0.3,4.8-0.4,7.2-0.2h0.4l-0.2-0.4
      c-2.1-4.6-3-9.7-2.7-14.8c0.4-3,1.7-5.8,3.6-8.1c1.5-1.7,3.7-2.5,6-2.4c0,0,5.9,0.1,5.9-5.6c0.2-1.8,1.7-3.2,3.6-3.2
      C156.9,200.1,146.6,198.4,142.7,204.1z"
          />
        </g>
        <path
          class="st3 an2"
          d="M179.9,246.2C62.4,224.5,16.8,103.8,48.2,11.9"
        />
        <g class="op op1">
          <path
            class="st0"
            d="M349.2,344.8c0.3-0.8,0.6-1.6,0.9-2.3l36.1,15.2c0.6,0.3,0.9,1,0.7,1.6c-0.3,0.6-1,0.9-1.6,0.7L349.2,344.8z"
          />
          <path
            class="st0"
            d="M391.5,343.5l-37.7-11.4c0.2-0.8,0.5-1.6,0.7-2.4l37.8,11.4c0.7,0.2,1,0.9,0.8,1.6S392.2,343.7,391.5,343.5
    L391.5,343.5z"
          />
          <path
            class="st0"
            d="M160.8,405l-13.6,21.8c-0.3,0.6-0.2,1.3,0.4,1.7c0,0,0,0,0,0c0.6,0.4,1.3,0.2,1.7-0.4l13.6-21.8
      C162.2,405.8,161.5,405.4,160.8,405z"
          />
          <path
            class="st0"
            d="M248.6,423.1l3.8,26.4c0.1,0.7,0.7,1.1,1.4,1c0.7-0.1,1.1-0.7,1-1.4l-3.8-26.5
      C250.2,422.9,249.4,423,248.6,423.1z"
          />
          <path
            class="st0"
            d="M313.3,186.5l-4.3,5.6l0.1,0.1c0.6,0.4,1.1,0.9,1.6,1.3l4.4-5.6c0,0,0,0,0.1-0.1c0.4-0.5,0.3-1.2-0.3-1.6
      S313.7,186,313.3,186.5z"
          />
          <path
            class="st0"
            d="M322.2,203.9l10.7-10.7c0.5-0.5,0.5-1.2,0-1.6c-0.5-0.4-1.2-0.4-1.7,0l-10.7,10.7L322.2,203.9z"
          />
          <path
            class="st0"
            d="M335.2,219.5l45.5-33.3c0.5-0.4,0.6-1.2,0.3-1.7c-0.4-0.6-1.2-0.8-1.8-0.4l-45.6,33.3
      C334.2,218,334.7,218.8,335.2,219.5z"
          />
          <path
            class="st0"
            d="M255.6,161c0.1-0.6-0.3-1.1-0.8-1.2c-0.6-0.2-1.2,0.2-1.4,0.8l-1.1,6.3l2.2,0.4L255.6,161z"
          />
          <path
            class="st0"
            d="M413.5,266.3c0,0-0.1,0-0.1,0l-55.8,8c-0.2-1.4-0.5-2.7-0.7-4.1l39.2-7.7c0.7-0.1,1.2-0.7,1.1-1.4
      c-0.1-0.7-0.7-1.2-1.4-1.1c0,0-0.1,0-0.1,0l-39.3,7.7c-0.3-1.4-0.6-2.8-1-4.2c0,0,0,0,0,0.1l28.9-7.3c0.6-0.2,1-0.8,0.9-1.5
      c-0.2-0.6-0.8-1-1.5-0.9c0,0,0,0,0,0l-28.9,7.3c-0.9-3.6-2-7.1-3.3-10.5l7.3-2.7c0.6-0.2,0.9-0.9,0.6-1.5c-0.2-0.6-0.8-0.8-1.4-0.6
      l-7.2,2.7c0.2,0.6,0.4,1.2,0.6,1.8c-1-2.6-2-5.2-3.1-7.8l6.6-3c0.6-0.3,0.8-1,0.5-1.5c-0.3-0.5-0.9-0.7-1.4-0.5l-6.6,3
      c0,0.1,0.1,0.2,0.1,0.2c-0.6-1.4-1.3-2.8-2-4.2l13-6.7c0.6-0.3,0.8-0.9,0.6-1.5c-0.3-0.6-0.9-0.8-1.5-0.6c0,0-0.1,0-0.1,0.1
      l-13,6.7c-0.7-1.3-1.3-2.5-2-3.8l25.6-14.9c0.6-0.4,0.7-1.1,0.4-1.7c-0.3-0.5-1-0.7-1.6-0.4l-25.6,14.9c-1-1.7-2.1-3.4-3.1-5.1
      c0.3,0.5,0.7,1,1,1.5l33.5-21.9c0,0,0,0,0,0c0.6-0.4,0.7-1.2,0.3-1.7c-0.4-0.6-1.1-0.7-1.7-0.4l-33.5,21.9c-2.3-3.4-4.7-6.8-7.3-10
      c0.3,0.4,0.6,0.8,1,1.2l31-25.3c0.5-0.4,0.6-1.2,0.2-1.8c-0.4-0.5-1.2-0.6-1.8-0.2l-31.1,25.3c-1.1-1.3-2.2-2.6-3.3-3.9l0.6,0.6
      l22.1-20c0.5-0.4,0.6-1.2,0.2-1.7c-0.4-0.5-1.2-0.6-1.7-0.2c0,0-0.1,0.1-0.1,0.1l-22.1,20c-2.5-2.7-5.1-5.3-7.8-7.8l5.1-5.7
      c0.4-0.5,0.4-1.2-0.1-1.6c-0.5-0.4-1.2-0.4-1.6,0.1l-5.1,5.7l0.8,0.7c-2.4-2.2-4.8-4.3-7.4-6.3c-1.2-0.9-2.4-1.8-3.7-2.7l8.3-11.9
      c0.4-0.5,0.3-1.2-0.2-1.6s-1.2-0.3-1.6,0.2c0,0-0.1,0.1-0.1,0.1l-8.2,11.8c-1.2-0.8-2.4-1.6-3.6-2.4l15.5-25
      c0.4-0.6,0.2-1.3-0.4-1.7c-0.6-0.4-1.3-0.2-1.7,0.4c0,0,0,0,0,0l-15.5,24.9c-1.2-0.8-2.4-1.5-3.7-2.2l19.1-34.7
      c0.3-0.6,0.1-1.4-0.5-1.7c-0.6-0.3-1.4-0.1-1.7,0.5L292,181c-1.2-0.7-2.5-1.3-3.7-1.9l24.4-50.4c0.4-0.6,0.1-1.4-0.5-1.8
      c-0.6-0.4-1.4-0.1-1.8,0.5c0,0.1-0.1,0.1-0.1,0.2L286,177.9c-1.3-0.6-2.5-1.2-3.8-1.8l15.4-36.5c0.3-0.6,0-1.4-0.7-1.7
      c-0.6-0.3-1.4,0-1.6,0.7c0,0,0,0,0,0l-15.4,36.4c-1.3-0.6-2.6-1.1-4-1.6l10-27.5c0.2-0.6-0.1-1.3-0.7-1.6c-0.6-0.2-1.3,0.1-1.6,0.7
      c0,0,0,0,0,0l-9.9,27.5c-1.4-0.5-2.8-1-4.1-1.4l4.2-13.9c0.2-0.6-0.2-1.3-0.8-1.4c0,0,0,0,0,0c-0.6-0.2-1.3,0.2-1.5,0.8l-4.2,13.9
      c-1.4-0.4-2.8-0.9-4.3-1.3l1.7-6.8c0.1-0.6-0.2-1.2-0.8-1.4c-0.6-0.2-1.2,0.2-1.4,0.8c0,0,0,0,0,0l-1.7,6.8
      c-4.3-1.1-8.7-1.9-13.1-2.6l1.7-13.7c0.1-0.6-0.3-1.2-0.9-1.3s-1.2,0.3-1.3,0.9c0,0,0,0.1,0,0.1l-1.6,13.6l1.3,0.2
      c-1.9-0.3-3.8-0.5-5.7-0.6l1.9-28.6c0-0.7-0.5-1.2-1.2-1.2c-0.6,0-1.1,0.5-1.2,1.1l-1.9,28.5c-1.4-0.1-2.8-0.2-4.3-0.2l0.6-38.9
      c0-0.7-0.5-1.3-1.2-1.3c-0.7,0-1.2,0.6-1.3,1.2l-0.6,38.8l2.1,0.1c-2.1-0.1-4.2-0.1-6.3-0.1l-2.1-55.2c0-0.7-0.6-1.3-1.4-1.3
      c-0.7,0-1.3,0.6-1.3,1.4l2.1,55.1c-1.4,0-2.8,0.1-4.2,0.2l-3.5-38.6c-0.1-0.7-0.7-1.2-1.4-1.1c-0.7,0.1-1.2,0.7-1.1,1.4l3.5,38.6
      c-1.4,0.1-2.9,0.3-4.3,0.4l-4-28.1c-0.1-0.6-0.7-1.1-1.4-1c-0.7,0.1-1.1,0.7-1,1.4l4,28.1c-1.4,0.2-2.9,0.4-4.4,0.6l-2.6-13.4
      c-0.1-0.6-0.7-1-1.4-0.9s-1,0.7-0.9,1.4l2.6,13.3h0.1c-1.5,0.3-3,0.6-4.5,0.9l-1.5-5.9c-0.2-0.6-0.8-0.9-1.4-0.8
      c-0.6,0.2-0.9,0.7-0.8,1.3l1.5,5.9c-2.1,0.5-4.3,1.1-6.4,1.7l-1.7-5.2c-0.1-0.6-0.7-1-1.4-0.8c-0.6,0.1-1,0.8-0.8,1.4
      c0,0.1,0,0.1,0.1,0.2l1.6,5.2c-1.4,0.5-2.8,0.9-4.3,1.4l-4.6-11.9c-0.2-0.6-0.8-1-1.4-0.8c-0.6,0.2-1,0.8-0.8,1.4
      c0,0.1,0,0.1,0.1,0.2l4.6,11.9l2.1-0.7c-2.3,0.8-4.7,1.7-7,2.7l0.8-0.3l-11.3-25.2c-0.3-0.6-1-0.9-1.6-0.6c-0.6,0.3-0.9,1-0.6,1.6
      c0,0,0,0,0,0l11.3,25.1c-1.3,0.6-2.6,1.2-3.9,1.8L156,143.9c-0.3-0.6-1.1-0.9-1.7-0.5c0,0,0,0,0,0c-0.6,0.3-0.8,1.1-0.5,1.7
      l17.2,33.6c-1.3,0.6-2.5,1.3-3.8,2l-27.2-46.9c-0.4-0.6-1.2-0.8-1.8-0.4c-0.6,0.4-0.7,1.1-0.4,1.7L165,182
      c-1.2,0.7-2.5,1.4-3.7,2.2l-20.6-31.6c-0.4-0.6-1.2-0.7-1.8-0.3c-0.5,0.4-0.7,1.1-0.3,1.6l20.6,31.5c-1.2,0.8-2.4,1.6-3.6,2.4
      l-16.1-22.1c-0.4-0.5-1.2-0.7-1.7-0.3c-0.5,0.4-0.7,1.2-0.3,1.7l16.1,22.1c-1.2,0.9-2.4,1.7-3.6,2.7l-7.9-9.7
      c-0.4-0.5-1.1-0.6-1.6-0.2c-0.5,0.4-0.6,1.1-0.2,1.6l7.9,9.7c-1.2,1-2.4,1.9-3.5,2.9l-3.4-3.7c-0.4-0.5-1.1-0.5-1.6-0.1
      c-0.5,0.4-0.5,1.1-0.1,1.6c0,0,0,0,0,0l3.4,3.7c-1.8,1.6-3.6,3.4-5.4,5.1l0.5-0.5l-3.2-3.1c-0.5-0.4-1.2-0.4-1.6,0
      c-0.4,0.5-0.4,1.2,0,1.6l3.2,3c-1.4,1.4-2.8,2.9-4.1,4.5l1-1.1l-8.9-7.7c-0.5-0.4-1.2-0.3-1.6,0.3c-0.3,0.5-0.3,1.1,0.1,1.5
      l8.8,7.6c-1.3,1.5-2.6,3.1-3.9,4.7l1-1.3l-21-16.3c-0.5-0.4-1.3-0.3-1.7,0.2c-0.4,0.5-0.3,1.3,0.2,1.7c0,0,0,0,0,0l21,16.3
      c-0.2,0.2-0.4,0.4-0.5,0.7c-0.7,0.9-1.4,1.9-2.1,2.8l-30.2-21.1c-0.6-0.4-1.3-0.3-1.7,0.3c0,0,0,0,0,0c-0.4,0.6-0.2,1.3,0.3,1.8
      l30.2,21c-0.8,1.2-1.6,2.4-2.4,3.6l-45.2-28.1c-0.6-0.4-1.4-0.2-1.8,0.4c0,0,0,0,0,0c-0.4,0.6-0.2,1.4,0.4,1.8l45.1,28
      c-0.8,1.2-1.5,2.5-2.2,3.7l-32.2-17.7c-0.6-0.4-1.4-0.2-1.7,0.4s-0.2,1.4,0.4,1.7c0,0,0.1,0.1,0.1,0.1l32.1,17.7
      c-0.7,1.3-1.4,2.6-2.1,3.9l-23.7-11.5c-0.6-0.3-1.3,0-1.6,0.6c-0.3,0.6,0,1.3,0.6,1.6c0,0,0,0,0,0l23.7,11.5
      c-0.7,1.4-1.3,2.7-1.9,4.1l-10.6-4.5c-0.6-0.3-1.3,0-1.5,0.6c-0.3,0.6,0,1.3,0.6,1.5l10.6,4.5c-0.6,1.4-1.2,2.8-1.7,4.3l-3.8-1.4
      c-0.6-0.2-1.2,0.1-1.5,0.7c-0.2,0.6,0.1,1.2,0.7,1.4l3.8,1.4c-0.8,2.1-1.5,4.3-2.1,6.4l-3.4-0.9c-0.6-0.1-1.2,0.2-1.4,0.8
      c-0.1,0.6,0.2,1.1,0.7,1.3l3.3,1c-0.4,1.5-0.8,3-1.2,4.5l-10.5-2.4c-0.6-0.1-1.3,0.2-1.4,0.9c-0.1,0.6,0.2,1.3,0.9,1.4l10.5,2.4
      c0.1-0.4,0.2-0.8,0.3-1.2c-0.5,1.9-0.9,3.8-1.2,5.6l-25.3-4.4c-0.7-0.2-1.3,0.2-1.5,0.9c-0.2,0.7,0.2,1.3,0.9,1.5
      c0.1,0,0.1,0,0.2,0l25.3,4.4c-0.3,1.5-0.5,2.9-0.7,4.4l-35.8-4.3c-0.7-0.1-1.3,0.4-1.4,1.1c0,0,0,0,0,0c-0.1,0.7,0.4,1.3,1.1,1.4
      l35.7,4.3c-0.2,1.4-0.3,2.9-0.4,4.3L47.8,280c-0.7,0-1.4,0.5-1.4,1.2s0.5,1.4,1.2,1.4l52.2,3.5c-0.1,1.5-0.1,2.9-0.2,4.3l-36-0.5
      c-0.7,0-1.3,0.5-1.3,1.2c0,0.7,0.5,1.3,1.2,1.3c0,0,0,0,0,0l35.9,0.5c0,1.5,0,3,0,4.5l-25.6,1c-0.7,0-1.2,0.5-1.3,1.2
      c0,0.7,0.5,1.2,1.2,1.3c0.1,0,0.1,0,0.2,0l25.7-1c0.1,1.5,0.1,3,0.2,4.5l-10.9,1c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1
      c0,0,0,0,0,0l10.9-1c0,0,0-0.1,0-0.1c0.2,2.1,0.4,4.2,0.7,6.3c-0.1-0.5-0.2-1.1-0.3-1.6l-3.4,0.5c-0.6,0.1-1,0.7-1,1.3
      c0.1,0.6,0.7,1,1.3,1l3.5-0.5c0.3,2.4,0.8,4.8,1.3,7.1l-0.1-0.5l-2.9,0.6c-0.6,0.1-1,0.7-0.9,1.3c0.1,0.6,0.7,1,1.3,0.9l2.9-0.6
      c0.3,1.5,0.7,3,1.1,4.5l-10,2.7c-0.6,0.2-1,0.8-0.8,1.4c0.2,0.6,0.8,1,1.4,0.8c0,0,0,0,0,0l10-2.7c0.4,1.5,0.9,2.9,1.3,4.4l-24,7.9
      c-0.6,0.2-1,0.9-0.8,1.5c0,0,0,0,0,0c0.2,0.6,0.9,1,1.5,0.8c0,0,0,0,0,0l24-7.9c0.5,1.4,1,2.8,1.5,4.2l-33.2,12.8
      c-0.6,0.3-1,1-0.7,1.6c0.3,0.6,1,1,1.6,0.7l33.2-12.8c0.5,1.3,1.1,2.7,1.6,3.9c0,0,0,0,0,0l-50.7,23.9c-0.6,0.3-0.9,1.1-0.6,1.7
      c0.2,0.5,0.7,0.7,1.2,0.7c0.2,0,0.4,0,0.6-0.1l50.7-23.9l0,0.1c0.6,1.3,1.2,2.6,1.9,4l-31.7,16.3c-0.6,0.3-0.9,1-0.6,1.7
      c0.3,0.6,1,0.9,1.7,0.6c0,0,0.1,0,0.1,0l31.7-16.3c0.7,1.3,1.4,2.6,2.1,3.9l-22,12.8c-0.6,0.3-0.8,1.1-0.4,1.7
      c0.3,0.6,1.1,0.8,1.7,0.4c0,0,0,0,0,0l22-12.8l-0.2-0.3c1.2,1.9,2.4,3.9,3.6,5.8c-0.4-0.5-0.7-1.1-1.1-1.6l-9,5.9
      c-0.5,0.4-0.7,1.1-0.3,1.6c0.4,0.5,1.1,0.7,1.6,0.3l9-5.9c0.9,1.3,1.7,2.5,2.6,3.8l-2.7,2c-0.5,0.4-0.6,1.1-0.3,1.6
      c0.4,0.5,1.1,0.6,1.6,0.3l2.7-2c1.4,1.8,2.8,3.6,4.2,5.3l-2.2,1.9c-0.5,0.4-0.5,1.1-0.1,1.6c0.4,0.5,1.1,0.5,1.6,0.1l2.2-1.9
      c1,1.2,2.1,2.3,3.1,3.5l-7.6,7.1c-0.5,0.4-0.5,1.2,0,1.6c0.4,0.5,1.2,0.5,1.6,0.1l7.5-7.1c1,1.1,2.1,2.2,3.2,3.2l-17.5,18.3
      c-0.5,0.5-0.5,1.3,0,1.7s1.3,0.5,1.7,0l17.6-18.3c1.1,1,2.2,2,3.3,3l-23.4,27c-0.5,0.5-0.4,1.3,0.1,1.8c0,0,0,0,0,0
      c0.5,0.4,1.3,0.4,1.8-0.1l23.4-27c1.2,1,2.4,2,3.7,3l-0.3-0.3l-48.7,62.3c-0.5,0.6-0.4,1.4,0.2,1.9c0.6,0.5,1.4,0.4,1.9-0.2
      c0,0,0-0.1,0.1-0.1l48.7-62.3c1.2,0.9,2.3,1.8,3.5,2.6l-20.5,29.5c-0.4,0.6-0.3,1.4,0.3,1.8c0.6,0.4,1.4,0.3,1.8-0.3l20.5-29.5
      c3.2,2.1,6.4,4.1,9.7,6l-5.4,9.7c-0.2,0.6,0.1,1.3,0.7,1.5c0.5,0.2,1,0,1.3-0.4l5.4-9.8c1.3,0.7,2.6,1.4,4,2l-1.4,2.9
      c-0.1,0.2-0.1,0.4-0.1,0.6c-0.2,0.6,0.1,1.2,0.7,1.4c0.5,0.2,1.1,0,1.4-0.5l1.6-3.4c1.4,0.6,2.7,1.2,4.1,1.8l-4.2,9.9
      c-0.3,0.6-0.1,1.3,0.5,1.6s1.3,0.1,1.6-0.5c0-0.1,0.1-0.1,0.1-0.2l4.1-9.9c1.8,0.7,3.6,1.4,5.4,2l-1.2-0.4l-8.8,24.3
      c-0.2,0.6,0.1,1.3,0.7,1.6s1.3-0.1,1.6-0.7l8.8-24.3c1.9,0.7,3.8,1.3,5.7,1.8L192,419l-10.5,34.7c-0.2,0.7,0.2,1.4,0.8,1.6
      c0.7,0.2,1.4-0.2,1.6-0.8l10.5-34.7c1.4,0.4,2.8,0.8,4.2,1.1L185.9,472c-0.2,0.7,0.3,1.4,1,1.6c0,0,0,0,0,0c0.7,0.2,1.4-0.3,1.6-1
      c0,0,0,0,0,0l12.6-51.2c1.4,0.3,2.8,0.6,4.2,0.8l-6.9,35.8c-0.2,0.7,0.2,1.4,0.9,1.5c0.7,0.2,1.4-0.2,1.5-0.9c0,0,0-0.1,0-0.1
      l6.9-35.8c-0.6-0.1-1.2-0.2-1.7-0.3c2,0.4,4,0.7,6.1,1l-3.6,26.1c-0.1,0.7,0.4,1.3,1,1.4c0.7,0.1,1.3-0.4,1.4-1l3.6-26.1
      c1.5,0.2,3,0.3,4.5,0.4l-1,11.7c-0.1,0.6,0.4,1.2,1,1.3c0.6,0.1,1.2-0.4,1.3-1c0,0,0-0.1,0-0.1l1-11.8c1.5,0.1,3.1,0.2,4.6,0.2
      l-0.1,4.4c0,0.6,0.5,1.1,1.1,1.2s1.1-0.5,1.2-1.1l0.1-4.5c2.2,0,4.5,0,6.7-0.1l0.2,4.1c0,0.6,0.5,1.1,1.2,1.1
      c0.6,0,1.1-0.5,1.1-1.2c0,0,0,0,0,0l-0.2-4.1h-0.8c1.8-0.1,3.6-0.2,5.4-0.4l1,11.6c0.1,0.6,0.6,1.1,1.3,1s1.1-0.6,1-1.3l-1-11.6
      l-1.8,0.2c4.7-0.5,9.3-1.2,13.9-2.1l-0.9,0.2l7.2,36.5c0.1,0.7,0.8,1.1,1.5,1c0.7-0.1,1.1-0.8,1-1.5l-7.2-36.5
      c1.4-0.3,2.8-0.7,4.1-1l13.1,52.1c0.1,0.7,0.8,1.2,1.5,1.1c0.7-0.1,1.2-0.8,1.1-1.5c0-0.1,0-0.1-0.1-0.2l-13.1-52.1
      c1.4-0.4,2.7-0.8,4.1-1.2l11,35.8c0.1,0.7,0.8,1.1,1.5,1c0.7-0.1,1.1-0.8,1-1.5c0-0.1,0-0.1-0.1-0.2l-11-35.8
      c1.4-0.5,2.8-1,4.1-1.5l9.4,25.6c0.2,0.6,0.9,1,1.6,0.7c0.6-0.2,1-0.9,0.7-1.6c0,0,0,0,0,0l-9.4-25.7c1.4-0.6,2.8-1.1,4.2-1.7
      l5,11.7c0.3,0.6,0.9,0.9,1.5,0.6c0,0,0,0,0,0c0.6-0.3,0.9-0.9,0.6-1.5l-5-11.8c1.7-0.8,3.4-1.6,5-2.4l-0.9,0.5l2.4,4.9
      c0.3,0.6,0.9,0.8,1.5,0.5c0,0,0,0,0,0c0.6-0.3,0.8-0.9,0.5-1.5l-2.4-4.9c2-1,3.9-2.1,5.8-3.2l2.6,4.4c0.3,0.6,1,0.7,1.6,0.4
      c0.6-0.3,0.7-1,0.4-1.6l-2.6-4.5c1.3-0.8,2.6-1.6,3.8-2.4l6.9,10.6c0.3,0.5,1.1,0.7,1.6,0.3c0.5-0.3,0.7-1.1,0.3-1.6l-6.9-10.6
      c1.2-0.8,2.4-1.7,3.6-2.6l16.4,22.4c0.4,0.5,1.2,0.7,1.7,0.3c0.5-0.4,0.7-1.1,0.3-1.7c0,0,0,0,0,0l-16.4-22.4
      c1.1-0.9,2.2-1.8,3.4-2.7l24,29.7c0.4,0.5,1.2,0.6,1.8,0.2c0.5-0.4,0.6-1.2,0.2-1.8L314,393.3c1.1-0.9,2.1-1.9,3.1-2.8l36.8,40.6
      c0.5,0.5,1.3,0.6,1.9,0.1c0.5-0.5,0.6-1.3,0.1-1.9L319,388.7c1-1,2-2,3-3l27.3,27.2c0.5,0.5,1.3,0.5,1.8,0c0.5-0.5,0.5-1.3,0-1.8
      c0,0,0,0,0,0l-27.3-27.2c1-1,1.9-2.1,2.9-3.2l21.2,19c0.5,0.4,1.3,0.4,1.7-0.1c0.5-0.5,0.4-1.3-0.1-1.7c0,0,0,0,0,0l-21.2-19
      c0.9-1.1,1.9-2.2,2.8-3.4l10.8,8.7c0.5,0.4,1.2,0.3,1.6-0.2c0.4-0.5,0.3-1.2-0.2-1.6l-10.8-8.7c0.9-1.2,1.8-2.4,2.7-3.6l5.3,3.8
      c0.4,0.4,1.1,0.5,1.6,0c0.4-0.4,0.5-1.1,0-1.6c-0.1-0.1-0.2-0.2-0.3-0.2l-5.3-3.8c1.5-2.2,3-4.5,4.4-6.8c-0.3,0.4-0.5,0.9-0.8,1.3
      l5.2,3.3c0.5,0.4,1.2,0.3,1.6-0.2c0.4-0.5,0.3-1.2-0.2-1.6c-0.1,0-0.1-0.1-0.2-0.1l-5.3-3.3c0.8-1.3,1.5-2.6,2.2-3.9l12,6.6
      c0.5,0.4,1.2,0.3,1.6-0.2c0.4-0.5,0.3-1.2-0.2-1.6c-0.1-0.1-0.2-0.1-0.3-0.2l-12-6.6c0.7-1.3,1.3-2.6,2-3.9l25.8,12.5
      c0.6,0.3,1.3,0,1.6-0.6c0.3-0.6,0-1.3-0.6-1.6l-25.9-12.5c1.5-3.3,2.9-6.7,4.1-10.1l52.4,18.9c0.7,0.3,1.4,0,1.7-0.7
      c0.3-0.7,0-1.4-0.7-1.7c-0.1,0-0.1,0-0.2-0.1l-52.4-18.9c-0.2,0.6-0.4,1.3-0.6,1.9c1.4-4.1,2.7-8.2,3.7-12.4l28.4,7
      c0.7,0.2,1.3-0.2,1.5-0.9c0.2-0.7-0.2-1.3-0.9-1.5l-28.4-7c-0.1,0.3-0.2,0.7-0.2,1c0.4-1.7,0.8-3.5,1.1-5.2l14.4,2.8
      c0.6,0.1,1.2-0.3,1.4-0.9c0.1-0.6-0.3-1.2-0.9-1.4l-14.4-2.8c0.2-1.5,0.5-2.9,0.7-4.4l7.2,1c0.6,0.1,1.2-0.4,1.3-1c0,0,0,0,0,0
      c0.1-0.6-0.4-1.2-1-1.3c0,0,0,0,0,0l-7.2-1c0.3-2.2,0.5-4.3,0.6-6.5l6.9,0.5c0.6,0.1,1.2-0.4,1.3-1c0.1-0.6-0.4-1.2-1-1.3
      c0,0-0.1,0-0.1,0l-6.9-0.5c0.1-1.5,0.1-3,0.2-4.4l14.4,0.2c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2l-14.4-0.3
      c0,0.5,0,0.9,0,1.4c0-2.3,0-4.6-0.1-6.9c0,0.4,0,0.8,0.1,1.2l29.4-1.1c0.7,0,1.2-0.6,1.2-1.3c0-0.7-0.6-1.2-1.3-1.2l-29.4,1.1
      c-0.1-1.4-0.2-2.9-0.3-4.3c0,0,0,0,0,0.1l39.7-3.6c0,0,0,0,0,0c0.7-0.1,1.2-0.7,1.1-1.4c-0.1-0.7-0.7-1.2-1.4-1.1l-39.7,3.6
      c-0.1-1.4-0.3-2.7-0.5-4.1l55.7-8c0.7-0.1,1.3-0.7,1.2-1.4C414.8,266.8,414.2,266.2,413.5,266.3z M329.4,372.5L329.4,372.5
      c-42.9,55.2-122.5,65.2-177.7,22.3C96.4,351.8,86.4,272.2,129.4,217s122.5-65.2,177.7-22.3c0,0,0,0,0,0
      C362.3,237.7,372.2,317.2,329.4,372.5z"
          />
          <path
            class="st0"
            d="M367.4,250.4l-14.5,4.5c0.2,0.7,0.4,1.5,0.7,2.2l14.5-4.5c0.6-0.2,1-0.8,0.8-1.5S368,250.3,367.4,250.4z"
          />
        </g>
</svg>
      <img
        src="./s3/bg2.png"
        alt=""
        class="bg-img rel"
      >

      <div class="title-block flex-ac flex-jb absolute">
        <img
          src="./s2/l.png"
          alt=""
          class="title-l"
        >
        <h3 class="title">重劃區<br />3大優勢</h3>
        <img
          src="./s2/l.png"
          alt=""
          class="title-r"
        >
      </div>
      <div class="block-list flex-c">
        <div class="block">
          <h3 class="title">捷運未來式，大臺北山手線世代</h3>
          <img
            src="./s3/1.jpg"
            alt=""
            class="img"
            data-aos="fade"
            data-aos-delay="600"
          >
        </div>
        <div class="block">
          <h3 class="title">棋盤式街廓，行車行人共好規劃</h3>
          <img
            src="./s3/2.jpg"
            alt=""
            class="img"
            data-aos="fade"
            data-aos-delay="700"
          >
        </div>
        <div class="block">
          <h3 class="title">氧生綠覆率，樹影濃蔭悠然伴居</h3>
          <img
            src="./s3/3.jpg"
            alt=""
            class="img"
            data-aos="fade"
            data-aos-delay="800"
          >
        </div>
      </div>
    </div>
    <div
      v-else
    >

      <div class="title-block flex-ac flex-jb">
        <img
          src="./s2/l.png"
          alt=""
          class="title-l"
        >
        <h3 class="title">重劃區<br />3大優勢</h3>
        <img
          src="./s2/l.png"
          alt=""
          class="title-r"
        >
      </div>
      <img
        src="./mo/3/map.jpg"
        alt=""
        class="img"
        data-aos="fade"
        data-aos-delay="400"
        @click="isShowDialog = true"
      >
      <img
        src="./s3/bg2.png"
        alt=""
        class="bg-img rel"
      >

      <img
        src="./mo/3/btn.png"
        alt=""
        class="btn absolute"
        @click="isShowDialog = true"
      >

      <div :class="`dialog ${isShowDialog ? 'show' : ''}`">
        <img
          src="./mo/3/map.jpg"
          alt=""
          class="dialog-content"
        >
        <img
          src="@/projects/jh/s4/close.png"
          class="close"
          alt
          @click="isShowDialog = false"
        />
      </div>
      <div  class="slide-box">

      <h3 class="m-title">
        捷運未來式，大臺北山手線世代
      </h3>
      <div
        class="slide relative"
        data-aos="fade"
        data-aos-delay="400"
      >
        <img
          :src="slide.src"
          v-for="(slide, index) in slideList"
          :class="`slide-img ${slideIndex === index ? 'active' : ''}`"
          :key="`s3-1-slide-${index}`"
        />
        <div class="arrows absolute">
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-l"
            @click="addIndex"
          >
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-r"
            @click="decIndex"
          >
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#section3 .svg {
  .st0 {
    fill: #3b5662;
  }
  .st1 {
    opacity: 0.45;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    stroke: #3b5662;
    stroke-width: 3;
  }
}

@media screen and (max-width: 767px) {
/*  .svg {
    .pc {
      display: none;
    }
    .r30 {
      transform: rotate(-30deg);
      transform-origin: 68% 54%;
    }
  }
  */
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.relative {
  // height: 100vh;
  overflow: hidden;
  position: relative;
  margin: size(-280) 0 0 0;
  background: linear-gradient(to bottom, rgba(217,215,210,0) 0%,rgba(217,215,210,1) 5%,rgba(217,215,210,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00d9d7d2', endColorstr='#d9d7d2',GradientType=0 );
}
#svg6 {
  top: size(380);
  left: size(-250);
  width: size(425);
  transform:rotate(-30deg) scaleX(-1);
  z-index: 1;
}

.bg-img {
  width: 100vw;
  height: auto;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  object-fit: cover;

  &:nth-child(1) {
  top:size(220);
  }
  &.rel {
    position: relative;
  }
}

.title-block {
  width: size(480);
  top: size(132);
  left: size(142);
  .title {
    font-size: size(59);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #3b5662;
  }
  .title-l {
    width: size(77);
  }

  .title-r {
    width: size(77);
    transform: rotate(180deg);
  }
}

.block-list {
  padding-bottom: size(84);background: #3b5662;
  .block {
    width: size(560);
    margin: 0 size(16);
    .title {
      font-size: size(28);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-bottom: size(42);
    }
    .img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .bg-img {
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
.relative {
  // height: 100vh;
  overflow: hidden;
  position: relative;
  margin: size-m(-220) 0 0 0;
}
.bg-img {
  &:nth-child(1) {
  top:0;
  }
  &.rel {
    position: absolute;top: size-m(160);
  }
}
  .title-block {
    width: 60vw;
    margin: 0 auto;
    margin-top: size-m(80);
    margin-bottom: size-m(22);
    top: 0;
    left: 0;
    right: 0;
    .title {
      font-size: size-m(30);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: center;
      color: #3b5662;
    }
    .title-l {
      width: size-m(33);
    }

    .title-r {
      width: size-m(33);
      transform: rotate(180deg);
    }
  }

  .img {
    width: 100vw;
  }

  .btn {
    width: size-m(155);
    top: size-m(252);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
.slide-box{background: #3b5662;padding: 0 0 size-m(95) 0;}
  .m-title {
    font-size: size-m(15);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-top: size-m(30);
    padding-bottom: size-m(5);
  }

  .slide {
    width: size-m(316) !important;
    height: size-m(175) !important;
    margin: 0 auto 0;
    overflow: visible;

    & .slide-img {
      width: 100%;
    }
  }

  .arrows {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow-l {
      width: size-m(15);
      position: absolute;
      left: size-m(-25);
      cursor: pointer;
    }

    .arrow-r {
      width: size-m(15);
      transform: rotate(180deg);
      position: absolute;
      right: size-m(-25);
      cursor: pointer;
    }
  }

  .dialog {
    display: none;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    overflow-x: scroll;

    .dialog-content {
      height: 100vh;
      margin: 0;
    border-radius: 0;position: relative;
        left: 50vh;
    }

    &.show {
      display: flex;
    }

    .close {
      position: fixed;
      width: 40px;
      right: 10px;
      top: 20px;
      cursor: pointer;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import slider from '@/mixins/slider.js'

export default {
  name: 'section3',

  mixins: [slider],
  props: ['showIcon'],

  components: {},
  data() {
    return {
      isMobile,
      isShowDialog: false,
      slideList: [
        { text: '', src: require('./s3/1.jpg') },
        { text: '', src: require('./s3/2.jpg') },
        { text: '', src: require('./s3/3.jpg') },
      ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},
}
</script>
