export default [
  { name: '首頁', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '區域環境', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: 0 },
  { name: '3大優勢', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: 0 },
  { name: '外觀', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', offset: 0 },
  { name: '規劃', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: 0 },
  { name: '品牌', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: 0 },
  // { name: '團隊', imgSrc: '', subTitle: '', section: 'section', svgSrc: '', offset: 0 },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 0 },
  // { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0 },
]

// 黃金地段：location
// 交通機能：traffic
// 生活版圖：life、job、vacation
// 優質團隊：architecture、brand、五個絕美承諾
// 預約鑑賞：（總價988萬起、後面的預約參觀）
// 基本資料
