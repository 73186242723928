<template>
  <div class="relative">
    <svg v-if="showIcon" preserveAspectRatio="xMinYMid meet" version="1.1" id="svg3" class="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 799.5 523.3" style="enable-background:new 0 0 799.5 523.3;" xml:space="preserve">
      <path
        class="st0 ops op10"
        d="M248.6,351.2c4.4-3.7,0.8-7,0.8-7c-1.1-0.9-1.5-2.3-1.1-3.6c0.7-1.6,1.6-3,2.8-4.2c3.6-3.1,7.6-5.6,11.9-7.5
      l0.5-0.2l-0.4-0.3c-1.7-1.5-3.2-3.2-4.6-5l0,0c-1.2-1.6-2.3-3.4-3.2-5.2c-0.6-1.5-0.9-3.2-0.8-4.8c0.3-1.4,1.3-2.6,2.7-3.1
      c0,0,4.8-2,2.3-6.7c-0.6-1.5,0-3.2,1.5-3.9c0,0-11.6,3.1-9.6,11.1l0,0c1.6,6.8,5.3,13,10.5,17.7c-6.7,2.9-12.7,7.5-17.2,13.2
      c-6.3,7.1,3.7,13.5,3.7,13.5C246.5,353.2,248.6,351.2,248.6,351.2z"
      />
      <path
        class="st0 ops op9"
        d="M271.1,370.2c4.7-5.1-0.2-9-0.2-9c-2-1.4-3.1-3.8-3-6.3c0.3-3.5,1.6-6.8,3.6-9.7c3.9-4.9,8.9-8.8,14.5-11.6
      l0.5-0.2l-0.4-0.3c-4.7-3.4-8.5-7.9-11.1-13.1c-1.3-3.2-1.6-6.7-1-10.1c0.6-2.5,2.3-4.7,4.6-5.8c0,0,5.9-3.2,2.6-8.9
      c-0.9-1.9-0.1-4.1,1.7-5.1c0,0-11.1,4.2-11.8,12c0,0.1,0,0.3-0.1,0.5c-1.6,11.7,3.6,23.1,13.8,30.7c-12,6.1-20.1,16.7-21.6,28.4
      c0,0.2,0,0.3,0,0.5c-1.4,7.7,8.4,13.3,8.4,13.3C270,374.1,269.8,371.8,271.1,370.2z"
      />
      <path
        class="st0 ops op8"
        d="M294.6,291.4L294.6,291.4c-1.3,3.8-2,7.7-2.2,11.8c-0.4,13.1,5.9,25.5,16.7,32.9c-11.4,7.9-18.5,20.5-18.9,33.6
      c-0.1,4,0.4,7.9,1.4,11.7l0,0c1.9,7.2,10.2,11.4,10.2,11.4c-3.1-2.8-0.9-6.4-0.9-6.4c4.6-6.7-1.2-11-1.2-11
      c-4.6-3.5-4.8-7.8-4.8-7.8c-0.6-6.4,0.5-12.8,3-18.7c3.2-5,7.5-9.3,12.4-12.7l0.4-0.3l-0.4-0.3c-4.7-3.1-8.7-7.3-11.6-12.2
      c-2.2-5.9-2.8-12.3-1.8-18.6c0,0,0.5-4.3,5.3-8c0,0,6.1-4.5,1.9-11.1c-1.1-2.2-0.6-4.9,1.3-6.5C305.6,279.5,297,284.1,294.6,291.4z"
      />
      <path
        class="st0 ops op7"
        d="M310.9,308L310.9,308c0,0.4,0,0.8,0,1.2c0.8,11.1,8.1,20.8,19.7,26c-10.7,7.7-16.5,18.9-15.8,30
      c0,0.4,0.1,0.8,0.1,1.2l0,0c0,0.2,0,0.4,0.1,0.6l0,0c1.3,6.5,10.8,9.5,10.8,9.5c-1.7-0.9-2.3-3-1.4-4.7c0,0,0-0.1,0-0.1
      c3.6-5.6-2-8.3-2-8.3c-2.2-0.9-3.8-2.9-4.2-5.3c-0.4-3.4,0.3-6.9,1.8-9.9c0.2-0.3,0.4-0.7,0.6-1c0.2-0.3,0.4-0.6,0.6-0.9l-0.1,0.1
      c2.8-4.3,6.5-8,10.7-11l0.4-0.3l-0.4-0.2c-4.6-2-8.7-4.8-12.1-8.5l0.1,0.1l-0.7-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-2-2.7-3.1-6-3.2-9.4
      c0.1-2.5,1.3-4.8,3.4-6.1c0,0,5.2-4,0.8-8.6c-1.2-1.6-0.9-3.8,0.7-5c0,0,0,0,0,0C320.1,295.7,310.8,301.1,310.9,308z"
      />
      <g class="ops op3">
        <path
          class="st0 st1"
          d="M515,279.4c-8.1,11-5.7,26.4,5.3,34.5c1.4,1,3,1.9,4.6,2.7c-2.9,4.5-3.7,10-2.1,15.1l2.4,15.1l15.1-2.3
        c5.3-0.1,10.4-2.5,13.8-6.6c1.2,1.4,2.5,2.6,4,3.6c11,8.1,26.4,5.7,34.5-5.3c5.6-7.6,6.3-17.7,1.9-26.1l6.9-9.4l-13.8-10.1
        c-7.6-5.6-18.3-4-23.9,3.6c0,0,0,0,0,0.1c5.6-7.6,4-18.3-3.7-23.9l-13.8-10.1l-6.9,9.5C530,268,520.6,271.8,515,279.4z"
        />
        <path
          class="st0"
          d="M519,288.6c-6.6,9-4.7,21.7,4.3,28.3c0,0,0,0,0,0c1.2,0.9,2.4,1.6,3.8,2.2c-2.4,3.7-3,8.2-1.8,12.4l1.9,12.4
      l12.4-1.9c4.4,0,8.5-2,11.3-5.4c7.4,8.4,20.1,9.3,28.6,1.9c7-6.2,8.9-16.4,4.6-24.6l5.7-7.7l-11.3-8.3c-6.2-4.6-15-3.3-19.6,2.9
      c0,0,0,0,0,0.1c4.6-6.2,3.3-15-2.9-19.6c0,0,0,0-0.1,0l-11.3-8.3l-5.7,7.8C531.2,279.2,523.5,282.3,519,288.6z"
        />
        <path
          class="st0"
          d="M560.9,345.5c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.6c0,0,0,0,0,0
      C590.4,351.8,573.1,354.5,560.9,345.5 M559.7,347.1c13.2,9.7,31.7,6.8,41.4-6.3c9.7-13.2,6.8-31.7-6.3-41.4
      c-13.2-9.7-31.7-6.8-41.4,6.3c0,0,0,0,0,0C543.7,318.9,546.5,337.4,559.7,347.1C559.7,347.1,559.7,347.1,559.7,347.1z"
        />
        <path
          class="st0"
          d="M515.7,312.2c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.5
      C545.2,318.6,527.9,321.2,515.7,312.2 M514.5,313.9c13.2,9.7,31.7,6.8,41.4-6.3s6.8-31.7-6.3-41.4c-13.2-9.7-31.7-6.8-41.4,6.3
      c0,0,0,0,0,0C498.5,285.7,501.3,304.2,514.5,313.9z"
        />
        <path
          class="st0"
          d="M557.1,306.5l11-14.9c6.1-8.2,17.6-10,25.9-4l14.9,11l-11,14.9c-6,8.2-17.6,10-25.9,4L557.1,306.5 M554.2,306.9
      l16.5,12.1c9.1,6.7,22,4.7,28.7-4.4l12.1-16.5L595.1,286c-9.1-6.7-22-4.7-28.7,4.4c0,0,0,0,0,0L554.2,306.9L554.2,306.9z"
        />
        <path
          class="st0"
          d="M538.9,293.2c-8.3-6-10-17.6-4-25.9l11-14.9l14.9,11c8.2,6,10,17.6,4,25.9l-11,14.9L538.9,293.2 M537.8,294.9
      l16.5,12.1l12.1-16.5c6.7-9.1,4.7-22-4.4-28.7c0,0,0,0,0,0l-16.5-12.1l-12.1,16.5C526.6,275.2,528.5,288.1,537.8,294.9
      C537.7,294.8,537.7,294.8,537.8,294.9L537.8,294.9z"
        />
        <path
          class="st0"
          d="M521.9,351.1l-2.5-16.1v-0.1v-0.1c-3.2-10.4,2.6-21.4,13-24.6c1.4-0.4,2.9-0.7,4.4-0.8h0.1c0.6,0,1.3,0,1.9,0
      h0.1l13.9-0.3l3.9,13.3v0l0,0c0.2,0.6,0.4,1.2,0.5,1.8v0.1c2.5,10.6-4,21.2-14.5,23.7c-1.4,0.3-2.9,0.5-4.4,0.5h0h-0.1L521.9,351.1
      M520.2,353.3l18.2-2.7c12-0.1,21.6-9.9,21.4-21.9c0-1.6-0.2-3.2-0.6-4.8l0,0v-0.1c-0.2-0.7-0.4-1.4-0.6-2.1l-4.4-14.8l-15.4,0.3
      c-0.7,0-1.5,0-2.2,0l0,0c-12,0.8-21,11.2-20.2,23.1c0.1,1.7,0.4,3.3,0.9,4.9L520.2,353.3z"
        />
        <rect
          x="533.7"
          y="318.6"
          transform="matrix(0.591 -0.8067 0.8067 0.591 -34.3336 570.4008)"
          class="st2"
          width="23.3"
          height="1"
        />
        <path
          class="st2"
          d="M536.3,326.9c0.9-1.2,2.5-1.4,3.7-0.6s1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6
      C535.7,329.8,535.5,328.1,536.3,326.9C536.3,327,536.3,327,536.3,326.9z"
        />
        <rect
          x="542.4"
          y="291.1"
          transform="matrix(0.5917 -0.8061 0.8061 0.5917 -22.4438 561.2402)"
          class="st2"
          width="1"
          height="23.3"
        />
        <path
          class="st2"
          d="M535,293.8c1.2,0.9,1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6c-1.2-0.9-1.4-2.5-0.6-3.7c0,0,0,0,0,0
      C532.2,293.2,533.8,292.9,535,293.8C535,293.8,535,293.8,535,293.8z"
        />
        <polygon
          class="st2"
          points="552.6,309.3 571.4,323.1 570.8,323.9 552,310.1 "
        />
        <path
          class="st2"
          d="M572.7,321.4c-1.2-0.9-2.8-0.6-3.7,0.6c-0.9,1.2-0.6,2.8,0.6,3.7s2.8,0.6,3.7-0.6c0,0,0,0,0,0
      C574.1,323.9,573.9,322.3,572.7,321.4C572.7,321.4,572.7,321.4,572.7,321.4z"
        />
        <path
          class="st2"
          d="M540.6,301.1c4.7-6.4,13.8-7.8,20.2-3.1c6.4,4.7,7.8,13.8,3.1,20.2c-4.7,6.4-13.8,7.8-20.2,3.1
      C537.3,316.6,535.9,307.6,540.6,301.1C540.6,301.1,540.6,301.1,540.6,301.1z"
        />
        <path
          class="st0"
          d="M549.2,307.4c1.3-1.7,3.7-2.1,5.4-0.8c1.7,1.3,2.1,3.7,0.8,5.4c-1.3,1.7-3.7,2.1-5.4,0.8
      C548.3,311.5,547.9,309.1,549.2,307.4z"
        />
        <path
          class="st0"
          d="M581.8,269.5c3.7,2.7,5,8,3.5,13.9l-1.9-0.5c1.3-5,0.2-9.5-2.8-11.8s-7.6-1.9-12.1,0.9l-1.1-1.7
      C572.6,267.1,578.1,266.8,581.8,269.5z"
        />
        <rect
          x="576.2"
          y="267"
          transform="matrix(0.9885 -0.1511 0.1511 0.9885 -34.3258 90.823)"
          class="st0"
          width="8.6"
          height="8.6"
        />
      </g>
      <rect
        x="612.2"
        y="220.5"
        transform="matrix(0.9885 -0.1511 0.1511 0.9885 -26.8084 95.6392)"
        class="st0 ops op1"
        width="7.5"
        height="7.5"
      />
      <path
        class="st0 ops op3"
        d="M456.8,265.7c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C459.2,269.9,457,268.1,456.8,265.7C456.8,265.7,456.8,265.7,456.8,265.7z"
      />
      <path
        class="st0 op op11"
        d="M196.6,297.6c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C199.1,301.7,196.9,300,196.6,297.6z"
      />
      <path
        class="st0 op op3"
        d="M516.9,352.8c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C519.3,357,517.2,355.2,516.9,352.8C516.9,352.8,516.9,352.8,516.9,352.8z"
      />
      <path
        class="st0 op op2"
        d="M596.9,244.4c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C599.4,248.5,597.2,246.8,596.9,244.4C596.9,244.4,596.9,244.4,596.9,244.4z"
      />
      <path
        class="st0 op op5"
        d="M405.1,342.7c1.5-6.2-3.9-6.3-3.9-6.3c-1.5,0.1-2.8-0.8-3.4-2.2c-0.4-1.8-0.5-3.6-0.3-5.4
      c1.2-5.1,3.1-10.1,5.7-14.6l0.3-0.5h-0.5c-2.5-0.1-4.9-0.4-7.3-0.9l0,0c-2.1-0.5-4.2-1.1-6.3-2c-1.6-0.8-2.9-2-3.9-3.4
      c0,0-1.2-1.8,0.3-4.4c0,0,3-5.1-2.3-7.2c-1.5-0.7-2.1-2.5-1.4-4c0-0.1,0.1-0.2,0.2-0.3c0,0-8.3,10.8-1.3,16l0,0
      c6.1,4.5,13.4,7.1,20.9,7.3c-4,7.1-6.3,15-6.6,23.1c-0.9,10.3,12.2,8.6,12.2,8.6C404.5,345.9,405.1,342.7,405.1,342.7z"
      />
      <path
        class="st0 op op2"
        d="M489.7,260.5c-1.4-5.1-5.3-2.6-5.3-2.6c-2.1,1.2-3.3,0.1-3.3,0.1c-1-1.1-1.8-2.3-2.4-3.7
      c-1.2-4.2-1.8-8.7-1.7-13.1v-0.5l-0.4,0.2c-1.8,1.1-3.7,2.1-5.7,2.9l0,0c-1.7,0.7-3.5,1.2-5.4,1.6c-1.4,0.2-2.9,0-4.2-0.5
      c0,0-1.5-0.7-1.5-3.3c0,0,0.1-5-4.6-4c0,0-2.4,0.4-2.6-2.5c0,0-1.8,11.6,5.4,11.9l0,0c6.4,0.3,12.8-1.5,18.2-4.9
      c-0.2,6.8,1.4,13.5,4.4,19.5c3.5,7.7,12.3,0.2,12.3,0.2C490.5,263,489.7,260.5,489.7,260.5z"
      />
      <path
        class="st0 op op1"
        d="M516.1,245.8c-3.2-6.2-8.5-3-8.5-3c-2.1,1.4-4.7,1.6-6.9,0.6c-3.1-1.5-5.8-3.9-7.8-6.7
      c-3.2-5.4-5.2-11.4-5.8-17.6l-0.1-0.5l-0.4,0.3c-4.8,3.3-10.4,5.2-16.1,5.8c-3.5,0.1-6.9-0.8-9.8-2.6c-2.2-1.4-3.6-3.7-3.8-6.3
      c0,0-1-6.6-7.4-5.6c-2.1,0.1-3.9-1.3-4.2-3.4c0,0,0.1,11.9,7.2,15.2l0.4,0.2c10.4,5.6,22.9,4.7,33.5-2.1c1.5,13.4,8.7,24.7,19,30.2
      l0.4,0.2c6.8,4,15.4-3.2,15.4-3.2C519.3,248.2,517.1,247.5,516.1,245.8z"
      />
      <path
        class="st0 op op1"
        d="M157.8,340.4c1.2-0.7,2.4-1.5,3.6-2.2l18.9,29.5c0.4,0.6,1.1,0.7,1.7,0.4c0.6-0.4,0.7-1.1,0.4-1.7c0,0,0,0,0,0
      l-18.8-29.4c1.2-0.8,2.4-1.6,3.6-2.4l14.4,20.2c0.4,0.5,1.1,0.7,1.6,0.3c0,0,0,0,0,0c0.5-0.4,0.7-1.1,0.3-1.7l-14.5-20.1
      c1.2-0.9,2.4-1.8,3.6-2.7l6.3,7.9c0.4,0.5,1.2,0.5,1.6,0c0.4-0.4,0.5-1,0.2-1.5l-6.3-7.9c1.2-1,2.4-1.9,3.5-2.9l1.9,2.1
      c0.4,0.5,1.1,0.5,1.6,0.1c0.5-0.4,0.5-1.1,0.1-1.6l-1.9-2.1c1.7-1.5,3.3-3,4.8-4.6l2.3,2.3c0.4,0.4,1.1,0.4,1.6,0c0,0,0,0,0,0
      c0.4-0.4,0.4-1.1,0-1.6l-2.3-2.3c1.1-1.1,2.1-2.2,3.1-3.3l7.9,7c0.5,0.4,1.2,0.3,1.6-0.2c0.3-0.5,0.3-1.1-0.1-1.5l-7.9-7l-1.3,1.4
      c1.5-1.7,2.9-3.4,4.3-5.1c-0.1,0.1-0.2,0.2-0.2,0.3l19.5,15.5c0.6,0.4,1.3,0.2,1.7-0.3c0.3-0.5,0.2-1.1-0.2-1.5l-19.5-15.4
      c0.9-1.1,1.8-2.3,2.6-3.5l28.5,20.2c0.6,0.4,1.3,0.2,1.7-0.3c0.4-0.5,0.2-1.3-0.3-1.7l-28.4-20.2c0.8-1.2,1.6-2.4,2.3-3.6l1.4-2.2
      c0.8-1.2,1.5-2.4,2.2-3.7l30.3,17.1c0.6,0.3,1.3,0.1,1.7-0.5c0.3-0.6,0.1-1.3-0.5-1.7l-30.3-17c0.7-1.3,1.4-2.6,2-3.9l22.1,11
      c0.6,0.3,1.3,0.1,1.6-0.5c0,0,0,0,0,0c0.3-0.6,0-1.3-0.5-1.6l-22.1-11c0.6-1.3,1.3-2.7,1.9-4l9.3,4c0.6,0.2,1.2-0.1,1.5-0.7
      c0.2-0.5,0-1.1-0.6-1.4l-9.3-4c0.6-1.4,1.2-2.8,1.7-4.2l2.7,1c0.6,0.2,1.2-0.1,1.4-0.6c0.2-0.6-0.1-1.2-0.7-1.4l-2.7-1l-0.1,0.4
      c0.8-2.2,1.5-4.5,2.2-6.7l3.3,1c0.6,0.2,1.3-0.2,1.5-0.8c0.2-0.6-0.2-1.3-0.8-1.5l-3.3-1c-0.1,0.5-0.3,1-0.5,1.6c0.6-2,1.1-4,1.6-6
      c0.7-3,0,0.1,0,0.1l10.4,2.5c0.6,0,1.1-0.6,1.1-1.2c0-0.4-0.2-0.8-0.6-0.9l-10.4-2.5c0.3-1.4,0.6-2.9,0.9-4.4l24.7,4.5
      c0.6,0.1,1.3-0.3,1.4-1c0,0,0,0,0,0c0.1-0.7-0.3-1.3-1-1.4c0,0,0,0,0,0l-24.7-4.5c0.2-1.4,0.4-2.9,0.6-4.3l34.9,4.5
      c0.7,0.1,1.3-0.4,1.4-1c0.1-0.7-0.4-1.3-1-1.4c0,0,0,0,0,0l-34.9-4.6c0.2-1.4,0.3-2.8,0.4-4.2l51.1,3.9c0.7,0.1,1.3-0.5,1.4-1.2
      c0.1-0.7-0.5-1.3-1.2-1.4l-51.1-3.9c0.1-1.4,0.2-2.8,0.2-4.2l35.2,0.9c0.7,0,1.2-0.5,1.3-1.2c0-0.7-0.5-1.2-1.2-1.3c0,0,0,0,0,0
      l-35.2-0.8c0-1.5,0-2.9,0-4.3l25.1-0.7c0.7-0.1,1.1-0.7,1.1-1.3c-0.1-0.6-0.5-1-1.1-1.1l-25.2,0.7c-0.1-1.6-0.2-3.2-0.3-4.8
      c0,0.1,0,0.3,0,0.4l10.5-0.8c0.6,0,1.1-0.6,1-1.2c0-0.6-0.6-1.1-1.2-1l-10.5,0.9c-0.2-1.5-0.3-3-0.5-4.5l3.4-0.4
      c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-0.9l-3.4,0.5c-0.3-2.2-0.7-4.4-1.2-6.5l3.9-0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c-0.1-0.5-0.7-0.9-1.2-0.8l-3.9,0.8c-0.3-1.5-0.7-2.9-1.1-4.4l10.9-2.8c0.6-0.2,1-0.8,0.8-1.4c0,0,0,0,0,0c-0.1-0.6-0.8-1-1.4-0.8
      c0,0,0,0,0,0l-11,2.9c-0.4-1.4-0.8-2.8-1.3-4.3l24.5-7.8c0.6-0.2,1-0.9,0.8-1.5c0,0,0,0,0,0c-0.2-0.6-0.9-1-1.5-0.8l-24.6,7.8
      c-0.5-1.4-0.9-2.7-1.4-4.1l33.6-12.6c0.6-0.2,1-0.9,0.8-1.6c-0.2-0.6-0.9-1-1.6-0.8c0,0,0,0-0.1,0l-33.6,12.7
      c-0.5-1.3-1-2.6-1.6-3.9l47.6-20.8c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1-0.8-1.6-0.6l-47.7,20.9c-0.6-1.3-1.2-2.5-1.8-3.8l32.2-16.1
      c0.6-0.3,0.8-1,0.5-1.6c-0.3-0.6-1-0.9-1.7-0.5l-32.2,16.1c-0.5-1-1.1-2-1.6-3c-0.1-0.2-0.3-0.5-0.4-0.7l22.6-12.8
      c0.6-0.3,0.8-1.1,0.5-1.6c-0.3-0.6-1.1-0.8-1.6-0.5l-22.7,12.9c0.3,0.5,0.6,0.9,0.8,1.4c-1-1.7-2.1-3.4-3.1-5.1l9.6-6.2
      c0.5-0.3,0.7-1,0.4-1.6c-0.3-0.5-1-0.7-1.6-0.3c0,0,0,0,0,0l-9.7,6.2c-0.8-1.2-1.7-2.5-2.6-3.7l3.5-2.5c0.5-0.4,0.6-1,0.3-1.5
      c-0.4-0.5-1-0.6-1.5-0.3l-3.5,2.5l0.4,0.6c-1.5-1.9-3-3.9-4.5-5.7l3.8-3.1c0.5-0.4,0.5-1.1,0.2-1.5c0,0,0,0,0,0
      c-0.4-0.5-1.1-0.5-1.6-0.1l-3.8,3.1c-1-1.1-2-2.2-3-3.3l9-8.3c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2
      c-0.1,0-0.1,0.1-0.1,0.1l-9,8.3c-1-1.1-2.1-2.1-3.1-3.1l18.7-19.1c0.5-0.5,0.5-1.2,0-1.7c0,0,0,0,0,0c-0.5-0.5-1.2-0.5-1.7,0
      l-18.7,19.2c-1-1-2.1-1.9-3.2-2.8l24.3-27.7c0.5-0.5,0.4-1.3-0.1-1.7c-0.5-0.5-1.3-0.4-1.7,0.1l-24.3,27.7c-1.1-0.9-2.2-1.8-3.3-2.6
      l32.9-41.5c0.4-0.6,0.1-1.4-0.5-1.8c-0.5-0.3-1.1-0.2-1.5,0.2l-32.9,41.5c-1.1-0.8-2.2-1.7-3.4-2.4l21.4-30.1
      c0.4-0.6,0.3-1.3-0.3-1.7c-0.6-0.4-1.3-0.3-1.7,0.3c0,0,0,0,0,0l-21.4,30.2c-1.2-0.8-2.4-1.6-3.6-2.3l14.4-22.7
      c0.3-0.6,0-1.3-0.6-1.6c-0.5-0.2-1.1-0.1-1.4,0.3l-14.5,22.8l0.7,0.4c-2.1-1.3-4.2-2.5-6.4-3.6c0.6,0.3,1.3,0.6,1.9,1l6.1-10.8
      c0.3-0.6,0.1-1.2-0.4-1.5c-0.5-0.3-1.2-0.1-1.5,0.4c0,0,0,0,0,0l-6.1,10.9c-1.3-0.7-2.6-1.4-3.9-2l2.4-4.8c0.3-0.5,0-1.2-0.5-1.5
      c-0.5-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0l-2.4,4.8c-2-0.9-4-1.8-5.9-2.6l2.3-5.5c0.1-0.6-0.2-1.2-0.8-1.3c-0.5-0.1-0.9,0.1-1.2,0.5
      l-2.3,5.5c-1.4-0.5-2.8-1-4.2-1.5h0.1l4.4-12.6c0.2-0.6-0.1-1.2-0.7-1.4c-0.6-0.2-1.2,0.1-1.4,0.7l-4.5,12.6
      c-1.4-0.4-2.8-0.9-4.1-1.3l7.8-26.7c0.2-0.6-0.2-1.3-0.8-1.5c-0.6-0.2-1.3,0.2-1.5,0.8l-7.8,26.7c-1.4-0.4-2.7-0.7-4.1-1l8.8-36.9
      c0.2-0.7-0.2-1.3-0.8-1.5c-0.7-0.2-1.3,0.2-1.5,0.8c0,0,0,0.1,0,0.1l-8.8,36.9c-1.3-0.3-2.7-0.6-4-0.8l9.7-53.1
      c0.1-0.7-0.3-1.3-1-1.5c-0.7-0.1-1.4,0.3-1.5,1l-9.7,53.2l-4-0.6l4.9-37.7c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.4,1
      c0,0,0,0,0,0l-4.9,37.7c-1.4-0.1-2.8-0.3-4.2-0.4l2.1-27.9c0.1-0.6-0.4-1.2-1.1-1.3c0,0,0,0,0,0c-0.7-0.1-1.2,0.4-1.3,1.1
      c0,0,0,0,0,0l-2.2,28l-4.3-0.2l0.3-13.4c0-0.6-0.5-1.1-1.1-1.2c-0.6,0-1.1,0.5-1.2,1.1c0,0,0,0,0,0l-0.3,13.5c-1.5,0-2.9,0-4.4,0.1
      L90.2,97c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0l0.2,6.3c-2.1,0.1-4.3,0.3-6.4,0.5l-0.7-6.8
      c-0.1-0.6-0.6-1.1-1.2-1c-0.6,0.1-1.1,0.6-1,1.2l0.7,6.8c-1.4,0.2-2.9,0.4-4.3,0.6l-2.1-14c-0.1-0.6-0.6-1.1-1.3-1c0,0,0,0,0,0
      c-0.6,0.1-1.1,0.6-1,1.2c0,0,0,0,0,0.1l2.1,14c-1.4,0.2-2.8,0.5-4.2,0.8l-5.8-28c-0.1-0.6-0.8-1.1-1.4-0.9c-0.6,0.2-0.9,0.8-0.8,1.3
      l5.8,28c-1.3,0.3-2.7,0.6-4,1l-9.8-37.4c-0.2-0.7-0.8-1.1-1.5-0.9c0,0,0,0,0,0c-0.7,0.2-1,0.9-0.9,1.5l9.8,37.5
      c-1.3,0.4-2.6,0.8-3.9,1.2L39.6,56.9c-0.2-0.7-0.9-1-1.6-0.8c-0.7,0.2-1,0.9-0.8,1.6l16.6,52.2c-1.3,0.4-2.6,0.9-3.8,1.4L36.2,75
      c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.2-1,0.9-0.7,1.6l13.7,36.3c-1.3,0.5-2.6,1-3.8,1.6L32.3,87.5c-0.3-0.6-1-0.9-1.6-0.6
      c-0.6,0.3-0.9,1-0.6,1.6c0,0,0,0,0,0l11.5,26.3c-1.3,0.6-2.6,1.2-3.8,1.8L31.5,104c-0.3-0.6-1-0.8-1.5-0.5c-0.6,0.3-0.8,1-0.5,1.5
      l6.3,12.7c-1.3,0.7-2.5,1.3-3.8,2l0,0l-3.4-6c-0.3-0.5-1-0.7-1.5-0.4c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0l3.4,6
      c0,0,0,0,0,0c-2.2,1.3-4.3,2.6-6.3,4c0.3-0.2,0.6-0.4,0.9-0.6l-4.1-6.2c-0.3-0.5-1-0.7-1.5-0.3c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.3,1.5
      l4.1,6.2c-1.2,0.8-2.4,1.6-3.5,2.5l-8.8-11.8c-0.4-0.5-1.1-0.6-1.6-0.2c-0.5,0.4-0.6,1.1-0.2,1.6c0,0,0,0,0,0l8.8,11.8
      c-1.1,0.9-2.2,1.7-3.3,2.6l-18.6-22.4c-0.4-0.5-1.2-0.6-1.7-0.2c0,0,0,0,0,0c-0.5,0.4-0.6,1.2-0.2,1.7l18.6,22.4l0.7-0.6
      c-1.3,1.1-2.5,2.2-3.8,3.3l-26.5-28.8c-0.4-0.5-1.2-0.5-1.7-0.1c0,0,0,0,0,0c-0.5,0.5-0.5,1.2-0.1,1.8l26.5,28.8l1.1-1
      c-1.4,1.2-2.7,2.5-4,3.8l-39.4-38.6c-0.5-0.5-1.3-0.5-1.8,0c0,0,0,0,0,0c-0.5,0.5-0.5,1.3,0,1.8l39.5,38.6c0.3-0.4,0.7-0.7,1.1-1.1
      c-1.3,1.3-2.6,2.7-3.8,4l-29.3-25.8c-0.5-0.4-1.3-0.4-1.7,0.1c-0.5,0.5-0.4,1.3,0.1,1.7c0,0,0,0,0,0l29.3,25.8
      c0.2-0.2,0.4-0.5,0.6-0.7c-1.1,1.3-2.2,2.6-3.3,3.9l-22.7-18c-0.5-0.4-1.3-0.3-1.7,0.2c0,0,0,0,0,0c-0.4,0.5-0.3,1.3,0.2,1.7
      l22.7,18c-0.9,1.1-1.7,2.3-2.5,3.4l-11.7-8.3c-0.5-0.4-1.2-0.2-1.6,0.3c-0.4,0.5-0.2,1.2,0.3,1.6l11.7,8.3c-0.9,1.3-1.7,2.5-2.5,3.8
      l0.1-0.2l-6-3.8c-0.5-0.3-1.2-0.2-1.5,0.3c0,0,0,0,0,0c-0.3,0.5-0.2,1.2,0.3,1.5l6,3.8c-1.5,2.3-2.8,4.7-4.1,7.1
      c0.3-0.5,0.6-1.1,0.9-1.6l-6.6-3.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.5,1.5l6.6,3.6c-1,1.9-2,3.9-2.9,5.9
      c0.3-0.7,0.6-1.4,0.9-2l-13.3-6.4c-0.6-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0c-0.3,0.6,0,1.2,0.5,1.5c0,0,0,0,0,0l13.3,6.3
      c-0.6,1.3-1.2,2.6-1.7,3.9l-26.9-11.1c-0.6-0.2-1.3,0-1.6,0.7c-0.2,0.6,0,1.3,0.7,1.6l26.9,11.1c-0.5,1.3-1,2.6-1.5,3.9l-36.9-13
      c-0.6-0.2-1.3,0.1-1.6,0.7c0,0,0,0,0,0c-0.2,0.6,0.1,1.3,0.8,1.6l36.9,13c-0.4,1.3-0.9,2.6-1.3,3.8l-52.9-15.5
      c-0.7-0.2-1.4,0.2-1.6,0.9c0,0,0,0,0,0c-0.2,0.7,0.2,1.4,0.9,1.6l52.9,15.5c0-0.1,0-0.2,0.1-0.3c-0.4,1.4-0.8,2.9-1.2,4.3l-37.9-9.2
      c-0.7-0.1-1.3,0.3-1.5,0.9c-0.2,0.7,0.3,1.3,0.9,1.5c0,0,0,0,0,0l37.9,9.2c-0.3,1.3-0.6,2.6-0.8,3.9l-28.3-5.2
      c-0.7-0.1-1.3,0.3-1.4,1c0,0,0,0,0,0c-0.1,0.6,0.3,1.3,1,1.4c0,0,0,0,0,0l28.3,5.2c0.1-0.4,0.2-0.8,0.2-1.1
      c-0.4,2.2-0.8,4.4-1.1,6.6c0.1-0.4,0.1-0.9,0.2-1.3l-14-1.8c-0.6-0.1-1.2,0.4-1.3,1c-0.1,0.6,0.4,1.2,1,1.3c0,0,0,0,0,0l14,1.8
      c-0.2,1.4-0.3,2.9-0.5,4.3l-6.8-0.5c-0.6,0-1.1,0.4-1.2,1c0,0.6,0.4,1.1,1,1.2l6.7,0.5c-0.2,2.1-0.3,4.2-0.3,6.4l-7.2,0
      c-0.6,0-1.1,0.5-1.1,1.1v0c0,0.6,0.5,1.1,1.1,1.1l7.2,0c0,1.4,0,2.9,0,4.3l-14.3,0.7c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1
      c0,0,0,0,0,0l14.3-0.7c0.1,1.8,0.2,3.5,0.3,5.2l-0.1-1l-28.5,2.8c-0.6,0.1-1.1,0.6-1.1,1.3c0,0,0,0,0,0c0.1,0.7,0.6,1.1,1.3,1.1
      l28.5-2.8c0.1,1.5,0.3,2.9,0.5,4.4l-38.2,5.5c-0.7,0.1-1.1,0.7-1,1.4c0.1,0.7,0.7,1.2,1.4,1.1l38.2-5.5c0.3,1.9,0.6,3.8,1,5.7
      c-0.1-0.6-0.3-1.3-0.4-1.9l-53.4,11c-0.7,0.2-1.1,0.8-1,1.5c0.2,0.7,0.8,1.1,1.5,1l53.4-11c0.3,1.4,0.6,2.8,1,4.2l-37.2,9.4
      c-0.6,0.2-1,0.9-0.9,1.5c0.2,0.7,0.8,1.1,1.5,0.9l37.2-9.4c0.3,1.3,0.7,2.5,1,3.8l-26.8,8.5c-0.6,0.2-1,0.9-0.8,1.5c0,0,0,0,0,0
      c0.2,0.6,0.9,1,1.5,0.8l26.8-8.5c0.4,1.4,0.9,2.7,1.4,4.1l-12.6,4.7c-0.6,0.2-0.9,0.9-0.7,1.5c0,0,0,0,0,0c0.2,0.6,0.9,0.9,1.5,0.7
      c0,0,0,0,0,0l12.5-4.7c0.5,1.4,1,2.7,1.6,4.1l-5.6,2.4c-0.6,0.2-0.8,0.9-0.6,1.5c0.3,0.6,0.9,0.8,1.5,0.6l5.5-2.4
      c-0.2-0.5-0.4-0.9-0.6-1.4c1,2.4,2.1,4.8,3.3,7.2l-5.7,3c-0.5,0.3-0.7,0.9-0.5,1.5c0.3,0.5,1,0.7,1.5,0.5l5.7-3
      c0.7,1.3,1.3,2.6,2.1,3.9l-11.7,6.9c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0c0.3,0.5,1,0.7,1.5,0.4c0,0,0,0,0,0l11.6-6.9
      c0.7,1.2,1.5,2.5,2.2,3.7l-23.2,15.4c-0.5,0.4-0.7,1.1-0.4,1.6c0,0,0,0,0,0c0.4,0.5,1.1,0.7,1.7,0.3l23.1-15.4
      c0.8,1.2,1.6,2.3,2.4,3.5l-30.3,22.5c-0.5,0.4-0.7,1.2-0.3,1.7c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.7,0.3l30.3-22.6
      c0.8,1.1,1.6,2.2,2.5,3.3l-41.3,34.2c-0.5,0.5-0.6,1.3-0.2,1.8c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.8,0.2l41.3-34.2
      c0.9,1.1,1.7,2.2,2.6,3.2l-27.5,25.3c-0.5,0.5-0.5,1.2-0.1,1.7c0.5,0.5,1.2,0.5,1.7,0.1c0,0,0,0,0,0l27.5-25.3c1,1,1.9,2.1,2.9,3.1
      l-19,19.4c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0,0c0.5,0.5,1.2,0.5,1.7,0c0,0,0,0,0,0l19-19.4c1,1,2,2,3.1,3l-8.2,9.3
      c-0.4,0.5-0.4,1.2,0.1,1.6c0.5,0.4,1.2,0.4,1.6-0.1c0,0,0,0,0,0l8.2-9.3c1.1,1,2.2,2,3.4,2.9l-3.1,4c-0.4,0.5-0.3,1.2,0.2,1.5
      c0.5,0.4,1.2,0.3,1.6-0.2l3.1-3.9c1.7,1.3,3.4,2.7,5.2,4l-3,4.4c-0.3,0.5-0.2,1.2,0.3,1.5c0.5,0.3,1.2,0.2,1.5-0.3l3-4.4l-0.8-0.6
      c1.5,1,3,2.1,4.5,3l-6.5,10.7c-0.3,0.5-0.1,1.2,0.4,1.6c0.5,0.3,1.2,0.2,1.6-0.4c0,0,0,0,0,0l6.5-10.6c1.3,0.8,2.5,1.5,3.8,2.2
      l-12.7,23.5c-0.3,0.6-0.1,1.3,0.5,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0.1,1.6-0.5l12.7-23.5c1.3,0.7,2.5,1.4,3.8,2l-15.7,33.1
      c-0.3,0.6,0,1.3,0.6,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0,1.6-0.6l15.7-33.1c1.3,0.6,2.5,1.2,3.8,1.8l-20,48.6c-0.3,0.7,0,1.4,0.7,1.7
      c0.7,0.3,1.4,0,1.7-0.7c0,0,0,0,0,0l20-48.6c1.3,0.5,2.6,1.1,3.9,1.6l-12.2,34.3c-0.2,0.6,0.1,1.3,0.8,1.6c0.6,0.2,1.3-0.1,1.6-0.7
      c0,0,0,0,0,0l12.1-34.3l-0.8-0.3c1.8,0.7,3.6,1.3,5.5,1.8c-0.2-0.1-0.4-0.1-0.6-0.2l-7.4,25.1c-0.2,0.6,0.2,1.3,0.8,1.5c0,0,0,0,0,0
      c0.6,0.2,1.3-0.2,1.5-0.8c0,0,0,0,0,0l7.4-25.1c-0.2-0.1-0.5-0.2-0.7-0.2c2.2,0.7,4.5,1.3,6.7,1.9l-1.8-0.4l-2.6,11.1
      c-0.1,0.6,0.2,1.2,0.8,1.4c0,0,0,0,0,0c0.6,0.1,1.2-0.2,1.4-0.8c0,0,0,0,0,0l2.6-11.1c1.7,0.4,3.4,0.8,5.2,1.2l-0.8-0.2l-0.7,4
      c-0.1,0.6,0.3,1.2,0.9,1.3c0.6,0.1,1.2-0.3,1.3-0.9l0.7-4c2.2,0.4,4.3,0.8,6.5,1.1l-0.5,4.4c-0.1,0.6,0.4,1.2,1,1.2c0,0,0,0,0,0
      c0.6,0.1,1.2-0.4,1.2-1c0,0,0,0,0,0l0.5-4.3c1.5,0.2,3,0.3,4.5,0.5l-0.7,11.5c0,0.6,0.4,1.2,1.1,1.2c0.6,0,1.2-0.4,1.2-1.1
      c0,0,0,0,0,0l0.7-11.5c1.5,0.1,2.9,0.2,4.4,0.2l-0.2,25.8c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2l0.2-25.7c2,0,4,0,6-0.1
      c-0.6,0-1.1,0.1-1.7,0.1l1.6,35.7c0,0.7,0.6,1.2,1.3,1.2c0,0,0,0,0,0c0.7,0,1.2-0.6,1.2-1.3c0,0,0,0,0,0l-1.6-35.7
      c1.4-0.1,2.8-0.1,4.2-0.2l5,51.3c0.1,0.7,0.7,1.2,1.4,1.2c0.7-0.1,1.2-0.7,1.2-1.4c0,0,0,0,0,0l-5-51.3c1.4-0.1,2.8-0.3,4.2-0.5
      l5.2,35.1c0.1,0.6,0.7,1.1,1.4,1c0.7-0.1,1.1-0.7,1-1.4l-5.3-35l-1.5,0.2c1.9-0.3,3.8-0.6,5.7-0.9l5.1,24.7c0.1,0.6,0.7,1.1,1.4,0.9
      c0,0,0,0,0,0c0.6-0.1,1.1-0.8,0.9-1.4l-5.1-24.7c-0.4,0.1-0.8,0.2-1.2,0.2c1.9-0.4,3.7-0.8,5.6-1.2l2.7,10.2c0.2,0.6,0.8,1,1.4,0.8
      c0,0,0,0,0,0c0.6-0.2,1-0.8,0.8-1.4l-2.7-10.2c1.4-0.4,2.8-0.8,4.2-1.2l1.1,3.5c0.2,0.6,0.8,0.9,1.4,0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c0-0.2,0-0.4,0-0.6l-1-3c1.4-0.4,2.8-0.9,4.1-1.4l3.8,10.2c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5l-3.8-10.2
      c1.4-0.5,2.8-1.1,4.1-1.7l10.1,23.1c0.3,0.6,1,0.9,1.6,0.6c0.6-0.3,0.9-1,0.6-1.6l-10.1-23c-0.7,0.3-1.4,0.6-2.1,0.9
      c2-0.9,4-1.8,6-2.7l15.7,31.5c0.3,0.6,1,0.9,1.7,0.6s0.9-1,0.6-1.7l-15.7-31.4c1.3-0.6,2.5-1.3,3.8-2 M155.9,338
      c-59.5,33.8-135.1,13-169-46.5c-33.8-59.5-13-135.1,46.5-169c59.5-33.8,135.1-13,169,46.5C236.1,228.5,215.3,304.1,155.9,338z"
      />
      <g class="op op2 pc">
        <path
          class="st0 st1"
          d="M177.9,464.8c6,10.6,19.4,14.3,30,8.3c0,0,0,0,0,0c1.4-0.8,2.7-1.7,3.8-2.8c2.6,4,6.8,6.7,11.5,7.3l13.1,3.6
        L240,468c1.9-4.4,1.8-9.3-0.3-13.6c1.5-0.5,3-1.1,4.4-1.9c10.5-6,14.2-19.5,8.2-30c-4.2-7.3-12.1-11.5-20.4-11l-5.1-9l-13.3,7.5
        c-7.3,4.2-9.9,13.5-5.7,20.8c-4.2-7.3-13.5-9.9-20.8-5.7l-13.3,7.5l5.2,9.1C174.1,448.5,173.7,457.5,177.9,464.8z"
        />
        <path
          class="st0"
          d="M186.8,464.9c4.9,8.7,15.9,11.7,24.5,6.8c0,0,0,0,0.1,0c1.1-0.6,2.2-1.4,3.1-2.3c2.1,3.3,5.6,5.5,9.5,6
      l10.8,2.9l3-10.8c1.6-3.6,1.5-7.7-0.3-11.2c1.3-0.4,2.5-0.9,3.6-1.6c8.7-4.8,11.8-15.8,7-24.5c-3.4-6.1-10-9.7-17-9.2l-4.2-7.4
      l-10.9,6.2c-6,3.4-8.1,11-4.7,17c-3.4-6-11-8.1-17-4.8c0,0,0,0-0.1,0l-10.9,6.2l4.2,7.5C183.6,451.6,183.3,459,186.8,464.9z"
        />
        <path
          class="st0"
          d="M248.3,451.6c-11.8,6.7-26.8,2.6-33.5-9.2c-6.7-11.8-2.6-26.8,9.2-33.5s26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C264.2,429.8,260.1,444.8,248.3,451.6 M249.2,453.1c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.7,7.2-17.1,23.3-9.9,36c0,0,0,0,0,0C220.4,455.9,236.5,460.3,249.2,453.1C249.2,453.2,249.2,453.1,249.2,453.1L249.2,453.1z"
        />
        <path
          class="st0"
          d="M204.9,476.2c-11.8,6.7-26.8,2.6-33.5-9.2s-2.6-26.8,9.2-33.5c11.8-6.7,26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C220.8,454.5,216.7,469.5,204.9,476.2 M205.7,477.8c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.6,7.2-17.1,23.3-9.9,35.9C176.9,480.6,193,485,205.7,477.8C205.7,477.8,205.7,477.8,205.7,477.8z"
        />
        <path
          class="st0"
          d="M215.2,440.5l-8.2-14.4c-4.5-7.9-1.7-18,6.2-22.5l14.4-8.2l8.1,14.4c4.5,7.9,1.7,18-6.2,22.5L215.2,440.5
      M214.6,442.9l15.9-9c8.8-5,11.9-16.2,6.9-25l-9-15.9l-15.9,9c-8.8,5-11.9,16.2-6.9,24.9c0,0,0,0,0,0L214.6,442.9z"
        />
        <path
          class="st0"
          d="M197.8,450.4c-7.9,4.5-18,1.8-22.5-6.1c0,0,0,0,0,0l-8.2-14.4l14.4-8.2c7.9-4.5,18-1.7,22.5,6.2l8.2,14.3
      L197.8,450.4 M198.7,451.9l15.9-9l-9-15.9c-5-8.8-16.2-11.9-25-6.9c0,0,0,0,0,0l-15.9,9l9,15.9C178.7,453.9,189.8,457,198.7,451.9
      C198.6,452,198.7,452,198.7,451.9L198.7,451.9z"
        />
        <path
          class="st0"
          d="M238.7,485.3l-14-3.8h-0.1h-0.1c-9.6-1.2-16.4-10-15.2-19.6c0.2-1.3,0.5-2.6,0.9-3.9v-0.1
      c0.2-0.5,0.4-1.1,0.7-1.6l0,0l0,0l4.8-11.4l12.2,1.7h0.1c0.5,0,1.1,0.1,1.7,0.2h0.1c9.5,1.8,15.8,11,14,20.5
      c-0.2,1.3-0.6,2.6-1.2,3.8v0.1v0.1L238.7,485.3 M240,487.5l4.4-15.8c4.3-9.8-0.2-21.2-10-25.4c-1.3-0.6-2.7-1-4.2-1.3l0,0
      c-0.6-0.1-1.3-0.2-1.9-0.3l-13.6-1.8l-5.5,12.6c-0.3,0.6-0.5,1.2-0.8,1.8l0,0l0,0c-3.7,10,1.4,21.2,11.4,24.9c1.4,0.5,2.8,0.9,4.3,1
      L240,487.5L240,487.5z"
        />
        <path
          class="st2"
          d="M204.9,451.9c-3.5-6.2-1.4-14,4.8-17.6c6.2-3.5,14-1.4,17.6,4.8s1.4,14-4.8,17.6c0,0,0,0,0,0
      C216.2,460.2,208.4,458.1,204.9,451.9z"
        />
        <polygon
          class="st2"
          points="215.7,445.7 216.5,445.3 226.7,463.4 226,463.8 "
        />
        <path
          class="st2"
          d="M224.3,464.8c-0.6-1.1-0.2-2.6,0.9-3.2s2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c0,0,0,0,0,0
      C226.4,466.3,225,465.9,224.3,464.8z"
        />
        <polygon
          class="st2"
          points="197.8,455.4 215.9,445.1 216.3,445.9 198.2,456.2 "
        />
        <path
          class="st2"
          d="M196.8,453.8c1.1-0.6,2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c-1.1,0.6-2.6,0.2-3.2-0.9c0,0,0,0,0,0
      C195.3,455.9,195.7,454.4,196.8,453.8C196.8,453.8,196.8,453.8,196.8,453.8z"
        />
        <rect
          x="214.8"
          y="439.9"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -188.1656 168.7628)"
          class="st2"
          width="20.8"
          height="0.9"
        />
        <path
          class="st2"
          d="M233,433.2c-1.1,0.6-1.5,2.1-0.9,3.2c0.6,1.1,2.1,1.5,3.2,0.9s1.5-2.1,0.9-3.2c0,0,0,0,0,0
      C235.6,433,234.2,432.6,233,433.2z"
        />
        <path
          class="st0"
          d="M213.1,447.2c-0.9-1.7-0.4-3.8,1.3-4.7c1.7-0.9,3.8-0.4,4.7,1.3c0.9,1.7,0.4,3.8-1.3,4.7c0,0,0,0,0,0
      C216.1,449.5,214,448.9,213.1,447.2C213.1,447.3,213.1,447.2,213.1,447.2z"
        />
        <path
          class="st0"
          d="M194.1,406.8c3.5-2,8.3-1.2,12.6,2.2l-1.1,1.4c-3.6-2.9-7.7-3.6-10.6-2c-2.9,1.6-4.3,5.5-3.7,10.2l-1.8,0.2
      C188.8,413.5,190.6,408.9,194.1,406.8z"
        />
        <rect
          x="191.3"
          y="404.7"
          transform="matrix(0.2654 -0.9641 0.9641 0.2654 -250.5467 488.2404)"
          class="st0"
          width="7.7"
          height="7.7"
        />
        <path
          class="st0"
          d="M250.6,509c-2-2.1-2.8-4.6-1.8-5.5s3.4,0,5.4,2.1s2.8,4.6,1.9,5.5C255.1,512.1,252.7,511.2,250.6,509z"
        />
        <path
          class="st0"
          d="M241.8,506.5c-1.5,2.1-3.6,3.3-4.6,2.6c-1-0.7-0.6-3,1-5.2c1.6-2.1,3.6-3.3,4.6-2.6S243.4,504.4,241.8,506.5z"
        />
        <path
          class="st0"
          d="M253.1,496.9c-2.1,0.8-4.2,0.5-4.5-0.5s1.1-2.4,3.2-3.2c2.1-0.8,4.2-0.5,4.5,0.5
      C256.7,494.7,255.2,496.1,253.1,496.9z"
        />
        <rect
          x="243.7"
          y="484.7"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -212.8196 185.46)"
          class="st0"
          width="1.5"
          height="21.2"
        />
        <path
          class="st0"
          d="M240.8,487.4c6.7,11.8,2,14.7,1.8,14.8l-0.8-1.3c0,0,3.5-2.5-2.3-12.7L240.8,487.4z"
        />
        <path
          class="st0"
          d="M242.7,491.1c3.1,5.5,7.3,4.4,7.5,4.4l0.4,1.5c-0.1,0-5.5,1.4-9.2-5.1L242.7,491.1z"
        />
        <path
          class="st0"
          d="M152.6,477.5c2.8-0.8,5.3-0.3,5.7,1s-1.6,3-4.4,3.7c-2.8,0.7-5.3,0.3-5.7-1C147.9,480,149.8,478.3,152.6,477.5z
      "
        />
        <path
          class="st0"
          d="M156.2,469.2c-1.5-2.2-1.8-4.6-0.8-5.3s3.1,0.5,4.6,2.8s1.8,4.6,0.8,5.3S157.8,471.5,156.2,469.2z"
        />
        <path
          class="st0"
          d="M165.6,483.3c-1-2.1-1-4.1,0-4.6s2.6,0.8,3.6,2.9s1,4.1,0,4.6S166.6,485.4,165.6,483.3z"
        />
        <rect
          x="156.2"
          y="473.8"
          transform="matrix(0.8687 -0.4953 0.4953 0.8687 -213.4897 143.7556)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M171.2,469.9l0.8,1.3c-5.1,2.9-8.8,3.8-10.9,2.6c-0.9-0.5-1.6-1.5-1.6-2.6h1.5c0,0.6,0.4,1.1,0.9,1.3
      C162.8,473,165.2,473.3,171.2,469.9z"
        />
        <path
          class="st0"
          d="M170.1,470.5l0.8,1.3c-5.5,3.1-4.5,7.3-4.5,7.3l-1.5,0.4C164.8,479.4,163.5,474.2,170.1,470.5z"
        />
        <path
          class="st0"
          d="M275.6,407.1c-2.4,2.4-3.5,5.2-2.4,6.3c1.1,1.1,4,0.2,6.4-2.2c2.4-2.4,3.5-5.2,2.4-6.3S278,404.8,275.6,407.1z"
        />
        <path
          class="st0"
          d="M266.7,407c-1.3-2-3-3.1-3.9-2.5s-0.7,2.6,0.6,4.6s3,3.1,3.9,2.5S267.9,408.9,266.7,407z"
        />
        <path
          class="st0"
          d="M274.7,422.5c-1.2-2.5-3-4.1-4-3.6s-0.8,3,0.4,5.5s3.1,4.1,4,3.6C276.1,427.5,276,425,274.7,422.5z"
        />
        <rect
          x="259.1"
          y="415.4"
          transform="matrix(0.8691 -0.4945 0.4945 0.8691 -170.8213 186.6468)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M266.9,410.1c0.9,0.6,1.4,1.6,1.4,2.7c0,2.4-2.7,5.1-7.8,8l-0.8-1.3c6-3.4,7-5.7,7-6.7c0-0.5-0.2-1.1-0.7-1.4
      L266.9,410.1z"
        />
        <path
          class="st0"
          d="M266.5,417.2c1.8,0,3.5,0.6,4.9,1.8l-1.1,1.1c-0.1-0.1-3.1-3-8.6,0.1l-0.8-1.3
      C262.6,417.9,264.5,417.3,266.5,417.2z"
        />
      </g>
      <rect
        x="254.7"
        y="325"
        transform="matrix(0.8777 -0.4792 0.4792 0.8777 -125.6418 163.5741)"
        class="st0 op op10"
        width="6"
        height="6"
      />
      <rect
        x="277.7"
        y="330.1"
        transform="matrix(0.789 -0.6144 0.6144 0.789 -145.3814 242.6519)"
        class="st0 op op9"
        width="5.7"
        height="5.7"
      />
      <rect
        x="302.5"
        y="333.2"
        transform="matrix(0.7508 -0.6605 0.6605 0.7508 -145.8386 285.39)"
        class="st0 op op8"
        width="5.6"
        height="5.6"
      />
      <rect
        x="323.5"
        y="332.9"
        transform="matrix(0.6543 -0.7562 0.7562 0.6543 -141.0644 362.7884)"
        class="st0 op op7"
        width="5.6"
        height="5.6"
      />
      <rect
        x="395.2"
        y="313.8"
        transform="matrix(0.316 -0.9488 0.9488 0.316 -28.186 594.4554)"
        class="st0 op op5"
        width="5.9"
        height="5.9"
      />
      <rect
        x="358.4"
        y="326.7"
        transform="matrix(0.5219 -0.853 0.853 0.5219 -108.4008 465.6359)"
        class="st0 op op6"
        width="5.6"
        height="5.6"
      />
      <rect
        x="411.8"
        y="303.3"
        transform="matrix(0.2103 -0.9776 0.9776 0.2103 27.9992 647.9564)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="460.6"
        y="334.4"
        transform="matrix(4.710640e-02 -0.9989 0.9989 4.710640e-02 104.6518 785.1971)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="470.1"
        y="243.4"
        transform="matrix(0.9596 -0.2813 0.2813 0.9596 -50.2987 143.1508)"
        class="st0 op op2"
        width="6.6"
        height="6.6"
      />
      <rect
        x="480.3"
        y="219.2"
        transform="matrix(0.9117 -0.4108 0.4108 0.9117 -48.7281 218.2997)"
        class="st0 op op1"
        width="6.6"
        height="6.6"
      />
      <g class="r30">
        <path
          class="st0 op op6"
          d="M770.7,358.9c-1.6-1.5-4.1-1.4-5.7,0.2c-1.5,1.6-1.4,4.1,0.2,5.7c1.6,1.5,4.1,1.4,5.6-0.2
      C772.4,363,772.3,360.5,770.7,358.9C770.7,359,770.7,358.9,770.7,358.9z"
        />
        <path
          class="st0 op op5"
          d="M729.6,381c-3-3.8,1-5.4,1-5.4c1.1-0.3,1.9-1.3,1.9-2.4c-0.2-1.3-0.7-2.6-1.4-3.8c-2.4-3.2-5.3-6.1-8.6-8.5
      l-0.4-0.3l0.4-0.2c1.8-0.7,3.6-1.6,5.3-2.6l0,0c1.5-0.9,2.9-1.9,4.2-3c0.9-0.9,1.6-2.1,2-3.4c0,0,0.3-1.5-1.6-2.9
      c0,0-3.7-2.7-0.4-5.6c0.8-0.8,0.8-2.1,0-3c-0.1-0.1-0.2-0.2-0.4-0.3c0,0,9.5,5.1,5.7,10.5l0,0c-3.4,4.8-8.1,8.5-13.6,10.6
      c5,3.6,9.1,8.4,11.8,14c3.8,6.8-6.6,9.1-6.6,9.1c1-0.4,1.5-1.6,1.1-2.6C729.8,381.3,729.7,381.1,729.6,381z"
        />
        <path
          class="st0 op op4"
          d="M704.2,392.2c-2.8-5.8,2.5-7.9,2.5-7.9c2.1-0.8,3.7-2.5,4.3-4.7c0.6-3.1,0.4-6.4-0.6-9.4
      c-2.2-5.3-5.5-10.1-9.7-14l-0.3-0.3l0.4-0.1c5-1.7,9.6-4.7,13.2-8.6c2-2.5,3.2-5.5,3.6-8.6c0.2-2.4-0.8-4.7-2.5-6.3
      c0,0-4.3-4.4,0-8.5c1.3-1.5,1.2-3.6-0.2-5c0,0,8.7,6.6,7.2,13.7l-0.1,0.4c-1.7,10.7-9.2,19.4-20.1,23.4c9,8.5,13.3,20,11.6,30.6
      c0,0.1,0,0.3-0.1,0.4c-0.8,7.2-10.8,9.5-10.8,9.5C704.2,395.9,704.9,394,704.2,392.2z"
        />
        <path
          class="st0 op op3"
          d="M669.9,384.9c-1.2-3.5,0.6-7.4,4-8.8c2.4-0.9,4.3-2.8,5.2-5.2c1.2-3.4,1.5-7,1-10.5c-1.3-5.8-3.9-11.3-7.7-15.9
      l-0.3-0.4l0.5-0.1c5.7-1.9,10.9-5.1,15.1-9.3c2.5-2.7,4.3-6,5.2-9.5c0.6-2.5,0-5.1-1.5-7.1c-2.2-3-1.5-7.2,1.5-9.5
      c1.5-1.5,1.8-3.8,0.7-5.6c0,0,7.9,7.6,5.1,15.4l-0.1,0.5c-3.7,12-13,21.5-24.9,25.6c7.8,9.7,10.4,22.6,6.8,34.5
      c0,0.2-0.1,0.3-0.2,0.5c-2,8-12.9,10.3-12.9,10.3C669.3,389.1,670.4,387,669.9,384.9z"
        />
        <rect
          x="725.5"
          y="358.5"
          transform="matrix(0.8026 -0.5965 0.5965 0.8026 -71.8132 505.9555)"
          class="st0 op op5"
          width="6.1"
          height="6.1"
        />
        <rect
          x="703.7"
          y="354.2"
          transform="matrix(0.875 -0.4842 0.4842 0.875 -84.5816 386.8404)"
          class="st0 op op4"
          width="6.1"
          height="6.1"
        />
        <path
          class="st0 op op2"
          d="M640.4,356.5c-0.7-5.4,4.2-5.8,4.2-5.8c1.9,0,3.7-1.1,4.7-2.7c1.3-2.4,2-5.1,2-7.9c-0.3-4.9-1.8-9.7-4.2-14
      l-0.2-0.4h0.4c4.6-0.1,9-1.2,13-3.5c2.3-1.5,4.1-3.6,5.2-6.1c0.8-1.9,0.6-4-0.3-5.8c0,0-2.3-4.7,2.3-6.9c1.4-0.8,1.9-2.6,1.2-4.1
      c0,0,5.3,7.7,2.2,13.1l-0.2,0.4c-4.2,8.2-12.6,13.4-22.6,13.6c5,9.4,5.5,19.9,1.2,28.1c0,0.1-0.1,0.2-0.2,0.3
      c-2.6,5.6-11.4,4.8-11.4,4.8C639.5,359.5,640.6,358.1,640.4,356.5z"
        />
        <rect
          x="649.2"
          y="325.6"
          transform="matrix(0.9825 -0.1865 0.1865 0.9825 -49.8557 127.4308)"
          class="st0 op op2"
          width="6.1"
          height="6.1"
        />
        <rect
          x="674.6"
          y="342.5"
          transform="matrix(0.9275 -0.3738 0.3738 0.9275 -80.0531 278.3417)"
          class="st0 op op3"
          width="6.1"
          height="6.1"
        />
        <path
          class="st3 an2"
          d="M613,155.1c-41.5,80,10.9,217,155.5,207.4"
        />
      </g>
      <g>
        <line
          class="st3 an1"
          x1="656.5"
          y1="168.9"
          x2="580.6"
          y2="271.1"
        />

        <path
          class="st3 an3-1"
          d="M493.6,141.7C480.7,62.1,408.6,4.3,322.3,4.3c-10.3,0-20.6,0.8-30.7,2.5c-46.9,7.6-88.2,31.8-116.3,68 s-39.7,80.2-32.6,123.9c12.9,79.6,84.9,137.4,171.2,137.4v0c10.3,0,20.6-0.9,30.7-2.5c46.9-7.6,88.2-31.8,116.3-68 S500.7,185.4,493.6,141.7"
        />
        <path
          class="st3 an6"
          d="M461,265.6c-35.1,42.9,11.4,107,59.9,85.6"
        />
      </g>
      <line
        class="st3 an6"
        x1="155.8"
        y1="340"
        x2="196.1"
        y2="410.1"
      />
    </svg>
    <svg  v-if="showIcon" version="1.1" id="svg4" class="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 799.5 523.3" preserveAspectRatio="xMinYMid meet" style="enable-background:new 0 0 799.5 523.3;" xml:space="preserve"    >
      <path
        class="st0 ops op10"
        d="M248.6,351.2c4.4-3.7,0.8-7,0.8-7c-1.1-0.9-1.5-2.3-1.1-3.6c0.7-1.6,1.6-3,2.8-4.2c3.6-3.1,7.6-5.6,11.9-7.5
      l0.5-0.2l-0.4-0.3c-1.7-1.5-3.2-3.2-4.6-5l0,0c-1.2-1.6-2.3-3.4-3.2-5.2c-0.6-1.5-0.9-3.2-0.8-4.8c0.3-1.4,1.3-2.6,2.7-3.1
      c0,0,4.8-2,2.3-6.7c-0.6-1.5,0-3.2,1.5-3.9c0,0-11.6,3.1-9.6,11.1l0,0c1.6,6.8,5.3,13,10.5,17.7c-6.7,2.9-12.7,7.5-17.2,13.2
      c-6.3,7.1,3.7,13.5,3.7,13.5C246.5,353.2,248.6,351.2,248.6,351.2z"
      />
      <path
        class="st0 ops op9"
        d="M271.1,370.2c4.7-5.1-0.2-9-0.2-9c-2-1.4-3.1-3.8-3-6.3c0.3-3.5,1.6-6.8,3.6-9.7c3.9-4.9,8.9-8.8,14.5-11.6
      l0.5-0.2l-0.4-0.3c-4.7-3.4-8.5-7.9-11.1-13.1c-1.3-3.2-1.6-6.7-1-10.1c0.6-2.5,2.3-4.7,4.6-5.8c0,0,5.9-3.2,2.6-8.9
      c-0.9-1.9-0.1-4.1,1.7-5.1c0,0-11.1,4.2-11.8,12c0,0.1,0,0.3-0.1,0.5c-1.6,11.7,3.6,23.1,13.8,30.7c-12,6.1-20.1,16.7-21.6,28.4
      c0,0.2,0,0.3,0,0.5c-1.4,7.7,8.4,13.3,8.4,13.3C270,374.1,269.8,371.8,271.1,370.2z"
      />
      <path
        class="st0 ops op8"
        d="M294.6,291.4L294.6,291.4c-1.3,3.8-2,7.7-2.2,11.8c-0.4,13.1,5.9,25.5,16.7,32.9c-11.4,7.9-18.5,20.5-18.9,33.6
      c-0.1,4,0.4,7.9,1.4,11.7l0,0c1.9,7.2,10.2,11.4,10.2,11.4c-3.1-2.8-0.9-6.4-0.9-6.4c4.6-6.7-1.2-11-1.2-11
      c-4.6-3.5-4.8-7.8-4.8-7.8c-0.6-6.4,0.5-12.8,3-18.7c3.2-5,7.5-9.3,12.4-12.7l0.4-0.3l-0.4-0.3c-4.7-3.1-8.7-7.3-11.6-12.2
      c-2.2-5.9-2.8-12.3-1.8-18.6c0,0,0.5-4.3,5.3-8c0,0,6.1-4.5,1.9-11.1c-1.1-2.2-0.6-4.9,1.3-6.5C305.6,279.5,297,284.1,294.6,291.4z"
      />
      <path
        class="st0 ops op7"
        d="M310.9,308L310.9,308c0,0.4,0,0.8,0,1.2c0.8,11.1,8.1,20.8,19.7,26c-10.7,7.7-16.5,18.9-15.8,30
      c0,0.4,0.1,0.8,0.1,1.2l0,0c0,0.2,0,0.4,0.1,0.6l0,0c1.3,6.5,10.8,9.5,10.8,9.5c-1.7-0.9-2.3-3-1.4-4.7c0,0,0-0.1,0-0.1
      c3.6-5.6-2-8.3-2-8.3c-2.2-0.9-3.8-2.9-4.2-5.3c-0.4-3.4,0.3-6.9,1.8-9.9c0.2-0.3,0.4-0.7,0.6-1c0.2-0.3,0.4-0.6,0.6-0.9l-0.1,0.1
      c2.8-4.3,6.5-8,10.7-11l0.4-0.3l-0.4-0.2c-4.6-2-8.7-4.8-12.1-8.5l0.1,0.1l-0.7-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-2-2.7-3.1-6-3.2-9.4
      c0.1-2.5,1.3-4.8,3.4-6.1c0,0,5.2-4,0.8-8.6c-1.2-1.6-0.9-3.8,0.7-5c0,0,0,0,0,0C320.1,295.7,310.8,301.1,310.9,308z"
      />
      <g class="ops op3">
        <path
          class="st0 st1"
          d="M515,279.4c-8.1,11-5.7,26.4,5.3,34.5c1.4,1,3,1.9,4.6,2.7c-2.9,4.5-3.7,10-2.1,15.1l2.4,15.1l15.1-2.3
        c5.3-0.1,10.4-2.5,13.8-6.6c1.2,1.4,2.5,2.6,4,3.6c11,8.1,26.4,5.7,34.5-5.3c5.6-7.6,6.3-17.7,1.9-26.1l6.9-9.4l-13.8-10.1
        c-7.6-5.6-18.3-4-23.9,3.6c0,0,0,0,0,0.1c5.6-7.6,4-18.3-3.7-23.9l-13.8-10.1l-6.9,9.5C530,268,520.6,271.8,515,279.4z"
        />
        <path
          class="st0"
          d="M519,288.6c-6.6,9-4.7,21.7,4.3,28.3c0,0,0,0,0,0c1.2,0.9,2.4,1.6,3.8,2.2c-2.4,3.7-3,8.2-1.8,12.4l1.9,12.4
      l12.4-1.9c4.4,0,8.5-2,11.3-5.4c7.4,8.4,20.1,9.3,28.6,1.9c7-6.2,8.9-16.4,4.6-24.6l5.7-7.7l-11.3-8.3c-6.2-4.6-15-3.3-19.6,2.9
      c0,0,0,0,0,0.1c4.6-6.2,3.3-15-2.9-19.6c0,0,0,0-0.1,0l-11.3-8.3l-5.7,7.8C531.2,279.2,523.5,282.3,519,288.6z"
        />
        <path
          class="st0"
          d="M560.9,345.5c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.6c0,0,0,0,0,0
      C590.4,351.8,573.1,354.5,560.9,345.5 M559.7,347.1c13.2,9.7,31.7,6.8,41.4-6.3c9.7-13.2,6.8-31.7-6.3-41.4
      c-13.2-9.7-31.7-6.8-41.4,6.3c0,0,0,0,0,0C543.7,318.9,546.5,337.4,559.7,347.1C559.7,347.1,559.7,347.1,559.7,347.1z"
        />
        <path
          class="st0"
          d="M515.7,312.2c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.5
      C545.2,318.6,527.9,321.2,515.7,312.2 M514.5,313.9c13.2,9.7,31.7,6.8,41.4-6.3s6.8-31.7-6.3-41.4c-13.2-9.7-31.7-6.8-41.4,6.3
      c0,0,0,0,0,0C498.5,285.7,501.3,304.2,514.5,313.9z"
        />
        <path
          class="st0"
          d="M557.1,306.5l11-14.9c6.1-8.2,17.6-10,25.9-4l14.9,11l-11,14.9c-6,8.2-17.6,10-25.9,4L557.1,306.5 M554.2,306.9
      l16.5,12.1c9.1,6.7,22,4.7,28.7-4.4l12.1-16.5L595.1,286c-9.1-6.7-22-4.7-28.7,4.4c0,0,0,0,0,0L554.2,306.9L554.2,306.9z"
        />
        <path
          class="st0"
          d="M538.9,293.2c-8.3-6-10-17.6-4-25.9l11-14.9l14.9,11c8.2,6,10,17.6,4,25.9l-11,14.9L538.9,293.2 M537.8,294.9
      l16.5,12.1l12.1-16.5c6.7-9.1,4.7-22-4.4-28.7c0,0,0,0,0,0l-16.5-12.1l-12.1,16.5C526.6,275.2,528.5,288.1,537.8,294.9
      C537.7,294.8,537.7,294.8,537.8,294.9L537.8,294.9z"
        />
        <path
          class="st0"
          d="M521.9,351.1l-2.5-16.1v-0.1v-0.1c-3.2-10.4,2.6-21.4,13-24.6c1.4-0.4,2.9-0.7,4.4-0.8h0.1c0.6,0,1.3,0,1.9,0
      h0.1l13.9-0.3l3.9,13.3v0l0,0c0.2,0.6,0.4,1.2,0.5,1.8v0.1c2.5,10.6-4,21.2-14.5,23.7c-1.4,0.3-2.9,0.5-4.4,0.5h0h-0.1L521.9,351.1
      M520.2,353.3l18.2-2.7c12-0.1,21.6-9.9,21.4-21.9c0-1.6-0.2-3.2-0.6-4.8l0,0v-0.1c-0.2-0.7-0.4-1.4-0.6-2.1l-4.4-14.8l-15.4,0.3
      c-0.7,0-1.5,0-2.2,0l0,0c-12,0.8-21,11.2-20.2,23.1c0.1,1.7,0.4,3.3,0.9,4.9L520.2,353.3z"
        />
        <rect
          x="533.7"
          y="318.6"
          transform="matrix(0.591 -0.8067 0.8067 0.591 -34.3336 570.4008)"
          class="st2"
          width="23.3"
          height="1"
        />
        <path
          class="st2"
          d="M536.3,326.9c0.9-1.2,2.5-1.4,3.7-0.6s1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6
      C535.7,329.8,535.5,328.1,536.3,326.9C536.3,327,536.3,327,536.3,326.9z"
        />
        <rect
          x="542.4"
          y="291.1"
          transform="matrix(0.5917 -0.8061 0.8061 0.5917 -22.4438 561.2402)"
          class="st2"
          width="1"
          height="23.3"
        />
        <path
          class="st2"
          d="M535,293.8c1.2,0.9,1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6c-1.2-0.9-1.4-2.5-0.6-3.7c0,0,0,0,0,0
      C532.2,293.2,533.8,292.9,535,293.8C535,293.8,535,293.8,535,293.8z"
        />
        <polygon
          class="st2"
          points="552.6,309.3 571.4,323.1 570.8,323.9 552,310.1 "
        />
        <path
          class="st2"
          d="M572.7,321.4c-1.2-0.9-2.8-0.6-3.7,0.6c-0.9,1.2-0.6,2.8,0.6,3.7s2.8,0.6,3.7-0.6c0,0,0,0,0,0
      C574.1,323.9,573.9,322.3,572.7,321.4C572.7,321.4,572.7,321.4,572.7,321.4z"
        />
        <path
          class="st2"
          d="M540.6,301.1c4.7-6.4,13.8-7.8,20.2-3.1c6.4,4.7,7.8,13.8,3.1,20.2c-4.7,6.4-13.8,7.8-20.2,3.1
      C537.3,316.6,535.9,307.6,540.6,301.1C540.6,301.1,540.6,301.1,540.6,301.1z"
        />
        <path
          class="st0"
          d="M549.2,307.4c1.3-1.7,3.7-2.1,5.4-0.8c1.7,1.3,2.1,3.7,0.8,5.4c-1.3,1.7-3.7,2.1-5.4,0.8
      C548.3,311.5,547.9,309.1,549.2,307.4z"
        />
        <path
          class="st0"
          d="M581.8,269.5c3.7,2.7,5,8,3.5,13.9l-1.9-0.5c1.3-5,0.2-9.5-2.8-11.8s-7.6-1.9-12.1,0.9l-1.1-1.7
      C572.6,267.1,578.1,266.8,581.8,269.5z"
        />
        <rect
          x="576.2"
          y="267"
          transform="matrix(0.9885 -0.1511 0.1511 0.9885 -34.3258 90.823)"
          class="st0"
          width="8.6"
          height="8.6"
        />
      </g>
      <rect
        x="612.2"
        y="220.5"
        transform="matrix(0.9885 -0.1511 0.1511 0.9885 -26.8084 95.6392)"
        class="st0 ops op1"
        width="7.5"
        height="7.5"
      />
      <path
        class="st0 ops op3"
        d="M456.8,265.7c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C459.2,269.9,457,268.1,456.8,265.7C456.8,265.7,456.8,265.7,456.8,265.7z"
      />
      <path
        class="st0 op op11"
        d="M196.6,297.6c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C199.1,301.7,196.9,300,196.6,297.6z"
      />
      <path
        class="st0 op op3"
        d="M516.9,352.8c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C519.3,357,517.2,355.2,516.9,352.8C516.9,352.8,516.9,352.8,516.9,352.8z"
      />
      <path
        class="st0 op op2"
        d="M596.9,244.4c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C599.4,248.5,597.2,246.8,596.9,244.4C596.9,244.4,596.9,244.4,596.9,244.4z"
      />
      <path
        class="st0 op op5"
        d="M405.1,342.7c1.5-6.2-3.9-6.3-3.9-6.3c-1.5,0.1-2.8-0.8-3.4-2.2c-0.4-1.8-0.5-3.6-0.3-5.4
      c1.2-5.1,3.1-10.1,5.7-14.6l0.3-0.5h-0.5c-2.5-0.1-4.9-0.4-7.3-0.9l0,0c-2.1-0.5-4.2-1.1-6.3-2c-1.6-0.8-2.9-2-3.9-3.4
      c0,0-1.2-1.8,0.3-4.4c0,0,3-5.1-2.3-7.2c-1.5-0.7-2.1-2.5-1.4-4c0-0.1,0.1-0.2,0.2-0.3c0,0-8.3,10.8-1.3,16l0,0
      c6.1,4.5,13.4,7.1,20.9,7.3c-4,7.1-6.3,15-6.6,23.1c-0.9,10.3,12.2,8.6,12.2,8.6C404.5,345.9,405.1,342.7,405.1,342.7z"
      />
      <path
        class="st0 op op2"
        d="M489.7,260.5c-1.4-5.1-5.3-2.6-5.3-2.6c-2.1,1.2-3.3,0.1-3.3,0.1c-1-1.1-1.8-2.3-2.4-3.7
      c-1.2-4.2-1.8-8.7-1.7-13.1v-0.5l-0.4,0.2c-1.8,1.1-3.7,2.1-5.7,2.9l0,0c-1.7,0.7-3.5,1.2-5.4,1.6c-1.4,0.2-2.9,0-4.2-0.5
      c0,0-1.5-0.7-1.5-3.3c0,0,0.1-5-4.6-4c0,0-2.4,0.4-2.6-2.5c0,0-1.8,11.6,5.4,11.9l0,0c6.4,0.3,12.8-1.5,18.2-4.9
      c-0.2,6.8,1.4,13.5,4.4,19.5c3.5,7.7,12.3,0.2,12.3,0.2C490.5,263,489.7,260.5,489.7,260.5z"
      />
      <path
        class="st0 op op1"
        d="M516.1,245.8c-3.2-6.2-8.5-3-8.5-3c-2.1,1.4-4.7,1.6-6.9,0.6c-3.1-1.5-5.8-3.9-7.8-6.7
      c-3.2-5.4-5.2-11.4-5.8-17.6l-0.1-0.5l-0.4,0.3c-4.8,3.3-10.4,5.2-16.1,5.8c-3.5,0.1-6.9-0.8-9.8-2.6c-2.2-1.4-3.6-3.7-3.8-6.3
      c0,0-1-6.6-7.4-5.6c-2.1,0.1-3.9-1.3-4.2-3.4c0,0,0.1,11.9,7.2,15.2l0.4,0.2c10.4,5.6,22.9,4.7,33.5-2.1c1.5,13.4,8.7,24.7,19,30.2
      l0.4,0.2c6.8,4,15.4-3.2,15.4-3.2C519.3,248.2,517.1,247.5,516.1,245.8z"
      />
      <path
        class="st0 op op1"
        d="M157.8,340.4c1.2-0.7,2.4-1.5,3.6-2.2l18.9,29.5c0.4,0.6,1.1,0.7,1.7,0.4c0.6-0.4,0.7-1.1,0.4-1.7c0,0,0,0,0,0
      l-18.8-29.4c1.2-0.8,2.4-1.6,3.6-2.4l14.4,20.2c0.4,0.5,1.1,0.7,1.6,0.3c0,0,0,0,0,0c0.5-0.4,0.7-1.1,0.3-1.7l-14.5-20.1
      c1.2-0.9,2.4-1.8,3.6-2.7l6.3,7.9c0.4,0.5,1.2,0.5,1.6,0c0.4-0.4,0.5-1,0.2-1.5l-6.3-7.9c1.2-1,2.4-1.9,3.5-2.9l1.9,2.1
      c0.4,0.5,1.1,0.5,1.6,0.1c0.5-0.4,0.5-1.1,0.1-1.6l-1.9-2.1c1.7-1.5,3.3-3,4.8-4.6l2.3,2.3c0.4,0.4,1.1,0.4,1.6,0c0,0,0,0,0,0
      c0.4-0.4,0.4-1.1,0-1.6l-2.3-2.3c1.1-1.1,2.1-2.2,3.1-3.3l7.9,7c0.5,0.4,1.2,0.3,1.6-0.2c0.3-0.5,0.3-1.1-0.1-1.5l-7.9-7l-1.3,1.4
      c1.5-1.7,2.9-3.4,4.3-5.1c-0.1,0.1-0.2,0.2-0.2,0.3l19.5,15.5c0.6,0.4,1.3,0.2,1.7-0.3c0.3-0.5,0.2-1.1-0.2-1.5l-19.5-15.4
      c0.9-1.1,1.8-2.3,2.6-3.5l28.5,20.2c0.6,0.4,1.3,0.2,1.7-0.3c0.4-0.5,0.2-1.3-0.3-1.7l-28.4-20.2c0.8-1.2,1.6-2.4,2.3-3.6l1.4-2.2
      c0.8-1.2,1.5-2.4,2.2-3.7l30.3,17.1c0.6,0.3,1.3,0.1,1.7-0.5c0.3-0.6,0.1-1.3-0.5-1.7l-30.3-17c0.7-1.3,1.4-2.6,2-3.9l22.1,11
      c0.6,0.3,1.3,0.1,1.6-0.5c0,0,0,0,0,0c0.3-0.6,0-1.3-0.5-1.6l-22.1-11c0.6-1.3,1.3-2.7,1.9-4l9.3,4c0.6,0.2,1.2-0.1,1.5-0.7
      c0.2-0.5,0-1.1-0.6-1.4l-9.3-4c0.6-1.4,1.2-2.8,1.7-4.2l2.7,1c0.6,0.2,1.2-0.1,1.4-0.6c0.2-0.6-0.1-1.2-0.7-1.4l-2.7-1l-0.1,0.4
      c0.8-2.2,1.5-4.5,2.2-6.7l3.3,1c0.6,0.2,1.3-0.2,1.5-0.8c0.2-0.6-0.2-1.3-0.8-1.5l-3.3-1c-0.1,0.5-0.3,1-0.5,1.6c0.6-2,1.1-4,1.6-6
      c0.7-3,0,0.1,0,0.1l10.4,2.5c0.6,0,1.1-0.6,1.1-1.2c0-0.4-0.2-0.8-0.6-0.9l-10.4-2.5c0.3-1.4,0.6-2.9,0.9-4.4l24.7,4.5
      c0.6,0.1,1.3-0.3,1.4-1c0,0,0,0,0,0c0.1-0.7-0.3-1.3-1-1.4c0,0,0,0,0,0l-24.7-4.5c0.2-1.4,0.4-2.9,0.6-4.3l34.9,4.5
      c0.7,0.1,1.3-0.4,1.4-1c0.1-0.7-0.4-1.3-1-1.4c0,0,0,0,0,0l-34.9-4.6c0.2-1.4,0.3-2.8,0.4-4.2l51.1,3.9c0.7,0.1,1.3-0.5,1.4-1.2
      c0.1-0.7-0.5-1.3-1.2-1.4l-51.1-3.9c0.1-1.4,0.2-2.8,0.2-4.2l35.2,0.9c0.7,0,1.2-0.5,1.3-1.2c0-0.7-0.5-1.2-1.2-1.3c0,0,0,0,0,0
      l-35.2-0.8c0-1.5,0-2.9,0-4.3l25.1-0.7c0.7-0.1,1.1-0.7,1.1-1.3c-0.1-0.6-0.5-1-1.1-1.1l-25.2,0.7c-0.1-1.6-0.2-3.2-0.3-4.8
      c0,0.1,0,0.3,0,0.4l10.5-0.8c0.6,0,1.1-0.6,1-1.2c0-0.6-0.6-1.1-1.2-1l-10.5,0.9c-0.2-1.5-0.3-3-0.5-4.5l3.4-0.4
      c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-0.9l-3.4,0.5c-0.3-2.2-0.7-4.4-1.2-6.5l3.9-0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c-0.1-0.5-0.7-0.9-1.2-0.8l-3.9,0.8c-0.3-1.5-0.7-2.9-1.1-4.4l10.9-2.8c0.6-0.2,1-0.8,0.8-1.4c0,0,0,0,0,0c-0.1-0.6-0.8-1-1.4-0.8
      c0,0,0,0,0,0l-11,2.9c-0.4-1.4-0.8-2.8-1.3-4.3l24.5-7.8c0.6-0.2,1-0.9,0.8-1.5c0,0,0,0,0,0c-0.2-0.6-0.9-1-1.5-0.8l-24.6,7.8
      c-0.5-1.4-0.9-2.7-1.4-4.1l33.6-12.6c0.6-0.2,1-0.9,0.8-1.6c-0.2-0.6-0.9-1-1.6-0.8c0,0,0,0-0.1,0l-33.6,12.7
      c-0.5-1.3-1-2.6-1.6-3.9l47.6-20.8c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1-0.8-1.6-0.6l-47.7,20.9c-0.6-1.3-1.2-2.5-1.8-3.8l32.2-16.1
      c0.6-0.3,0.8-1,0.5-1.6c-0.3-0.6-1-0.9-1.7-0.5l-32.2,16.1c-0.5-1-1.1-2-1.6-3c-0.1-0.2-0.3-0.5-0.4-0.7l22.6-12.8
      c0.6-0.3,0.8-1.1,0.5-1.6c-0.3-0.6-1.1-0.8-1.6-0.5l-22.7,12.9c0.3,0.5,0.6,0.9,0.8,1.4c-1-1.7-2.1-3.4-3.1-5.1l9.6-6.2
      c0.5-0.3,0.7-1,0.4-1.6c-0.3-0.5-1-0.7-1.6-0.3c0,0,0,0,0,0l-9.7,6.2c-0.8-1.2-1.7-2.5-2.6-3.7l3.5-2.5c0.5-0.4,0.6-1,0.3-1.5
      c-0.4-0.5-1-0.6-1.5-0.3l-3.5,2.5l0.4,0.6c-1.5-1.9-3-3.9-4.5-5.7l3.8-3.1c0.5-0.4,0.5-1.1,0.2-1.5c0,0,0,0,0,0
      c-0.4-0.5-1.1-0.5-1.6-0.1l-3.8,3.1c-1-1.1-2-2.2-3-3.3l9-8.3c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2
      c-0.1,0-0.1,0.1-0.1,0.1l-9,8.3c-1-1.1-2.1-2.1-3.1-3.1l18.7-19.1c0.5-0.5,0.5-1.2,0-1.7c0,0,0,0,0,0c-0.5-0.5-1.2-0.5-1.7,0
      l-18.7,19.2c-1-1-2.1-1.9-3.2-2.8l24.3-27.7c0.5-0.5,0.4-1.3-0.1-1.7c-0.5-0.5-1.3-0.4-1.7,0.1l-24.3,27.7c-1.1-0.9-2.2-1.8-3.3-2.6
      l32.9-41.5c0.4-0.6,0.1-1.4-0.5-1.8c-0.5-0.3-1.1-0.2-1.5,0.2l-32.9,41.5c-1.1-0.8-2.2-1.7-3.4-2.4l21.4-30.1
      c0.4-0.6,0.3-1.3-0.3-1.7c-0.6-0.4-1.3-0.3-1.7,0.3c0,0,0,0,0,0l-21.4,30.2c-1.2-0.8-2.4-1.6-3.6-2.3l14.4-22.7
      c0.3-0.6,0-1.3-0.6-1.6c-0.5-0.2-1.1-0.1-1.4,0.3l-14.5,22.8l0.7,0.4c-2.1-1.3-4.2-2.5-6.4-3.6c0.6,0.3,1.3,0.6,1.9,1l6.1-10.8
      c0.3-0.6,0.1-1.2-0.4-1.5c-0.5-0.3-1.2-0.1-1.5,0.4c0,0,0,0,0,0l-6.1,10.9c-1.3-0.7-2.6-1.4-3.9-2l2.4-4.8c0.3-0.5,0-1.2-0.5-1.5
      c-0.5-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0l-2.4,4.8c-2-0.9-4-1.8-5.9-2.6l2.3-5.5c0.1-0.6-0.2-1.2-0.8-1.3c-0.5-0.1-0.9,0.1-1.2,0.5
      l-2.3,5.5c-1.4-0.5-2.8-1-4.2-1.5h0.1l4.4-12.6c0.2-0.6-0.1-1.2-0.7-1.4c-0.6-0.2-1.2,0.1-1.4,0.7l-4.5,12.6
      c-1.4-0.4-2.8-0.9-4.1-1.3l7.8-26.7c0.2-0.6-0.2-1.3-0.8-1.5c-0.6-0.2-1.3,0.2-1.5,0.8l-7.8,26.7c-1.4-0.4-2.7-0.7-4.1-1l8.8-36.9
      c0.2-0.7-0.2-1.3-0.8-1.5c-0.7-0.2-1.3,0.2-1.5,0.8c0,0,0,0.1,0,0.1l-8.8,36.9c-1.3-0.3-2.7-0.6-4-0.8l9.7-53.1
      c0.1-0.7-0.3-1.3-1-1.5c-0.7-0.1-1.4,0.3-1.5,1l-9.7,53.2l-4-0.6l4.9-37.7c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.4,1
      c0,0,0,0,0,0l-4.9,37.7c-1.4-0.1-2.8-0.3-4.2-0.4l2.1-27.9c0.1-0.6-0.4-1.2-1.1-1.3c0,0,0,0,0,0c-0.7-0.1-1.2,0.4-1.3,1.1
      c0,0,0,0,0,0l-2.2,28l-4.3-0.2l0.3-13.4c0-0.6-0.5-1.1-1.1-1.2c-0.6,0-1.1,0.5-1.2,1.1c0,0,0,0,0,0l-0.3,13.5c-1.5,0-2.9,0-4.4,0.1
      L90.2,97c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0l0.2,6.3c-2.1,0.1-4.3,0.3-6.4,0.5l-0.7-6.8
      c-0.1-0.6-0.6-1.1-1.2-1c-0.6,0.1-1.1,0.6-1,1.2l0.7,6.8c-1.4,0.2-2.9,0.4-4.3,0.6l-2.1-14c-0.1-0.6-0.6-1.1-1.3-1c0,0,0,0,0,0
      c-0.6,0.1-1.1,0.6-1,1.2c0,0,0,0,0,0.1l2.1,14c-1.4,0.2-2.8,0.5-4.2,0.8l-5.8-28c-0.1-0.6-0.8-1.1-1.4-0.9c-0.6,0.2-0.9,0.8-0.8,1.3
      l5.8,28c-1.3,0.3-2.7,0.6-4,1l-9.8-37.4c-0.2-0.7-0.8-1.1-1.5-0.9c0,0,0,0,0,0c-0.7,0.2-1,0.9-0.9,1.5l9.8,37.5
      c-1.3,0.4-2.6,0.8-3.9,1.2L39.6,56.9c-0.2-0.7-0.9-1-1.6-0.8c-0.7,0.2-1,0.9-0.8,1.6l16.6,52.2c-1.3,0.4-2.6,0.9-3.8,1.4L36.2,75
      c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.2-1,0.9-0.7,1.6l13.7,36.3c-1.3,0.5-2.6,1-3.8,1.6L32.3,87.5c-0.3-0.6-1-0.9-1.6-0.6
      c-0.6,0.3-0.9,1-0.6,1.6c0,0,0,0,0,0l11.5,26.3c-1.3,0.6-2.6,1.2-3.8,1.8L31.5,104c-0.3-0.6-1-0.8-1.5-0.5c-0.6,0.3-0.8,1-0.5,1.5
      l6.3,12.7c-1.3,0.7-2.5,1.3-3.8,2l0,0l-3.4-6c-0.3-0.5-1-0.7-1.5-0.4c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0l3.4,6
      c0,0,0,0,0,0c-2.2,1.3-4.3,2.6-6.3,4c0.3-0.2,0.6-0.4,0.9-0.6l-4.1-6.2c-0.3-0.5-1-0.7-1.5-0.3c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.3,1.5
      l4.1,6.2c-1.2,0.8-2.4,1.6-3.5,2.5l-8.8-11.8c-0.4-0.5-1.1-0.6-1.6-0.2c-0.5,0.4-0.6,1.1-0.2,1.6c0,0,0,0,0,0l8.8,11.8
      c-1.1,0.9-2.2,1.7-3.3,2.6l-18.6-22.4c-0.4-0.5-1.2-0.6-1.7-0.2c0,0,0,0,0,0c-0.5,0.4-0.6,1.2-0.2,1.7l18.6,22.4l0.7-0.6
      c-1.3,1.1-2.5,2.2-3.8,3.3l-26.5-28.8c-0.4-0.5-1.2-0.5-1.7-0.1c0,0,0,0,0,0c-0.5,0.5-0.5,1.2-0.1,1.8l26.5,28.8l1.1-1
      c-1.4,1.2-2.7,2.5-4,3.8l-39.4-38.6c-0.5-0.5-1.3-0.5-1.8,0c0,0,0,0,0,0c-0.5,0.5-0.5,1.3,0,1.8l39.5,38.6c0.3-0.4,0.7-0.7,1.1-1.1
      c-1.3,1.3-2.6,2.7-3.8,4l-29.3-25.8c-0.5-0.4-1.3-0.4-1.7,0.1c-0.5,0.5-0.4,1.3,0.1,1.7c0,0,0,0,0,0l29.3,25.8
      c0.2-0.2,0.4-0.5,0.6-0.7c-1.1,1.3-2.2,2.6-3.3,3.9l-22.7-18c-0.5-0.4-1.3-0.3-1.7,0.2c0,0,0,0,0,0c-0.4,0.5-0.3,1.3,0.2,1.7
      l22.7,18c-0.9,1.1-1.7,2.3-2.5,3.4l-11.7-8.3c-0.5-0.4-1.2-0.2-1.6,0.3c-0.4,0.5-0.2,1.2,0.3,1.6l11.7,8.3c-0.9,1.3-1.7,2.5-2.5,3.8
      l0.1-0.2l-6-3.8c-0.5-0.3-1.2-0.2-1.5,0.3c0,0,0,0,0,0c-0.3,0.5-0.2,1.2,0.3,1.5l6,3.8c-1.5,2.3-2.8,4.7-4.1,7.1
      c0.3-0.5,0.6-1.1,0.9-1.6l-6.6-3.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.5,1.5l6.6,3.6c-1,1.9-2,3.9-2.9,5.9
      c0.3-0.7,0.6-1.4,0.9-2l-13.3-6.4c-0.6-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0c-0.3,0.6,0,1.2,0.5,1.5c0,0,0,0,0,0l13.3,6.3
      c-0.6,1.3-1.2,2.6-1.7,3.9l-26.9-11.1c-0.6-0.2-1.3,0-1.6,0.7c-0.2,0.6,0,1.3,0.7,1.6l26.9,11.1c-0.5,1.3-1,2.6-1.5,3.9l-36.9-13
      c-0.6-0.2-1.3,0.1-1.6,0.7c0,0,0,0,0,0c-0.2,0.6,0.1,1.3,0.8,1.6l36.9,13c-0.4,1.3-0.9,2.6-1.3,3.8l-52.9-15.5
      c-0.7-0.2-1.4,0.2-1.6,0.9c0,0,0,0,0,0c-0.2,0.7,0.2,1.4,0.9,1.6l52.9,15.5c0-0.1,0-0.2,0.1-0.3c-0.4,1.4-0.8,2.9-1.2,4.3l-37.9-9.2
      c-0.7-0.1-1.3,0.3-1.5,0.9c-0.2,0.7,0.3,1.3,0.9,1.5c0,0,0,0,0,0l37.9,9.2c-0.3,1.3-0.6,2.6-0.8,3.9l-28.3-5.2
      c-0.7-0.1-1.3,0.3-1.4,1c0,0,0,0,0,0c-0.1,0.6,0.3,1.3,1,1.4c0,0,0,0,0,0l28.3,5.2c0.1-0.4,0.2-0.8,0.2-1.1
      c-0.4,2.2-0.8,4.4-1.1,6.6c0.1-0.4,0.1-0.9,0.2-1.3l-14-1.8c-0.6-0.1-1.2,0.4-1.3,1c-0.1,0.6,0.4,1.2,1,1.3c0,0,0,0,0,0l14,1.8
      c-0.2,1.4-0.3,2.9-0.5,4.3l-6.8-0.5c-0.6,0-1.1,0.4-1.2,1c0,0.6,0.4,1.1,1,1.2l6.7,0.5c-0.2,2.1-0.3,4.2-0.3,6.4l-7.2,0
      c-0.6,0-1.1,0.5-1.1,1.1v0c0,0.6,0.5,1.1,1.1,1.1l7.2,0c0,1.4,0,2.9,0,4.3l-14.3,0.7c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1
      c0,0,0,0,0,0l14.3-0.7c0.1,1.8,0.2,3.5,0.3,5.2l-0.1-1l-28.5,2.8c-0.6,0.1-1.1,0.6-1.1,1.3c0,0,0,0,0,0c0.1,0.7,0.6,1.1,1.3,1.1
      l28.5-2.8c0.1,1.5,0.3,2.9,0.5,4.4l-38.2,5.5c-0.7,0.1-1.1,0.7-1,1.4c0.1,0.7,0.7,1.2,1.4,1.1l38.2-5.5c0.3,1.9,0.6,3.8,1,5.7
      c-0.1-0.6-0.3-1.3-0.4-1.9l-53.4,11c-0.7,0.2-1.1,0.8-1,1.5c0.2,0.7,0.8,1.1,1.5,1l53.4-11c0.3,1.4,0.6,2.8,1,4.2l-37.2,9.4
      c-0.6,0.2-1,0.9-0.9,1.5c0.2,0.7,0.8,1.1,1.5,0.9l37.2-9.4c0.3,1.3,0.7,2.5,1,3.8l-26.8,8.5c-0.6,0.2-1,0.9-0.8,1.5c0,0,0,0,0,0
      c0.2,0.6,0.9,1,1.5,0.8l26.8-8.5c0.4,1.4,0.9,2.7,1.4,4.1l-12.6,4.7c-0.6,0.2-0.9,0.9-0.7,1.5c0,0,0,0,0,0c0.2,0.6,0.9,0.9,1.5,0.7
      c0,0,0,0,0,0l12.5-4.7c0.5,1.4,1,2.7,1.6,4.1l-5.6,2.4c-0.6,0.2-0.8,0.9-0.6,1.5c0.3,0.6,0.9,0.8,1.5,0.6l5.5-2.4
      c-0.2-0.5-0.4-0.9-0.6-1.4c1,2.4,2.1,4.8,3.3,7.2l-5.7,3c-0.5,0.3-0.7,0.9-0.5,1.5c0.3,0.5,1,0.7,1.5,0.5l5.7-3
      c0.7,1.3,1.3,2.6,2.1,3.9l-11.7,6.9c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0c0.3,0.5,1,0.7,1.5,0.4c0,0,0,0,0,0l11.6-6.9
      c0.7,1.2,1.5,2.5,2.2,3.7l-23.2,15.4c-0.5,0.4-0.7,1.1-0.4,1.6c0,0,0,0,0,0c0.4,0.5,1.1,0.7,1.7,0.3l23.1-15.4
      c0.8,1.2,1.6,2.3,2.4,3.5l-30.3,22.5c-0.5,0.4-0.7,1.2-0.3,1.7c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.7,0.3l30.3-22.6
      c0.8,1.1,1.6,2.2,2.5,3.3l-41.3,34.2c-0.5,0.5-0.6,1.3-0.2,1.8c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.8,0.2l41.3-34.2
      c0.9,1.1,1.7,2.2,2.6,3.2l-27.5,25.3c-0.5,0.5-0.5,1.2-0.1,1.7c0.5,0.5,1.2,0.5,1.7,0.1c0,0,0,0,0,0l27.5-25.3c1,1,1.9,2.1,2.9,3.1
      l-19,19.4c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0,0c0.5,0.5,1.2,0.5,1.7,0c0,0,0,0,0,0l19-19.4c1,1,2,2,3.1,3l-8.2,9.3
      c-0.4,0.5-0.4,1.2,0.1,1.6c0.5,0.4,1.2,0.4,1.6-0.1c0,0,0,0,0,0l8.2-9.3c1.1,1,2.2,2,3.4,2.9l-3.1,4c-0.4,0.5-0.3,1.2,0.2,1.5
      c0.5,0.4,1.2,0.3,1.6-0.2l3.1-3.9c1.7,1.3,3.4,2.7,5.2,4l-3,4.4c-0.3,0.5-0.2,1.2,0.3,1.5c0.5,0.3,1.2,0.2,1.5-0.3l3-4.4l-0.8-0.6
      c1.5,1,3,2.1,4.5,3l-6.5,10.7c-0.3,0.5-0.1,1.2,0.4,1.6c0.5,0.3,1.2,0.2,1.6-0.4c0,0,0,0,0,0l6.5-10.6c1.3,0.8,2.5,1.5,3.8,2.2
      l-12.7,23.5c-0.3,0.6-0.1,1.3,0.5,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0.1,1.6-0.5l12.7-23.5c1.3,0.7,2.5,1.4,3.8,2l-15.7,33.1
      c-0.3,0.6,0,1.3,0.6,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0,1.6-0.6l15.7-33.1c1.3,0.6,2.5,1.2,3.8,1.8l-20,48.6c-0.3,0.7,0,1.4,0.7,1.7
      c0.7,0.3,1.4,0,1.7-0.7c0,0,0,0,0,0l20-48.6c1.3,0.5,2.6,1.1,3.9,1.6l-12.2,34.3c-0.2,0.6,0.1,1.3,0.8,1.6c0.6,0.2,1.3-0.1,1.6-0.7
      c0,0,0,0,0,0l12.1-34.3l-0.8-0.3c1.8,0.7,3.6,1.3,5.5,1.8c-0.2-0.1-0.4-0.1-0.6-0.2l-7.4,25.1c-0.2,0.6,0.2,1.3,0.8,1.5c0,0,0,0,0,0
      c0.6,0.2,1.3-0.2,1.5-0.8c0,0,0,0,0,0l7.4-25.1c-0.2-0.1-0.5-0.2-0.7-0.2c2.2,0.7,4.5,1.3,6.7,1.9l-1.8-0.4l-2.6,11.1
      c-0.1,0.6,0.2,1.2,0.8,1.4c0,0,0,0,0,0c0.6,0.1,1.2-0.2,1.4-0.8c0,0,0,0,0,0l2.6-11.1c1.7,0.4,3.4,0.8,5.2,1.2l-0.8-0.2l-0.7,4
      c-0.1,0.6,0.3,1.2,0.9,1.3c0.6,0.1,1.2-0.3,1.3-0.9l0.7-4c2.2,0.4,4.3,0.8,6.5,1.1l-0.5,4.4c-0.1,0.6,0.4,1.2,1,1.2c0,0,0,0,0,0
      c0.6,0.1,1.2-0.4,1.2-1c0,0,0,0,0,0l0.5-4.3c1.5,0.2,3,0.3,4.5,0.5l-0.7,11.5c0,0.6,0.4,1.2,1.1,1.2c0.6,0,1.2-0.4,1.2-1.1
      c0,0,0,0,0,0l0.7-11.5c1.5,0.1,2.9,0.2,4.4,0.2l-0.2,25.8c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2l0.2-25.7c2,0,4,0,6-0.1
      c-0.6,0-1.1,0.1-1.7,0.1l1.6,35.7c0,0.7,0.6,1.2,1.3,1.2c0,0,0,0,0,0c0.7,0,1.2-0.6,1.2-1.3c0,0,0,0,0,0l-1.6-35.7
      c1.4-0.1,2.8-0.1,4.2-0.2l5,51.3c0.1,0.7,0.7,1.2,1.4,1.2c0.7-0.1,1.2-0.7,1.2-1.4c0,0,0,0,0,0l-5-51.3c1.4-0.1,2.8-0.3,4.2-0.5
      l5.2,35.1c0.1,0.6,0.7,1.1,1.4,1c0.7-0.1,1.1-0.7,1-1.4l-5.3-35l-1.5,0.2c1.9-0.3,3.8-0.6,5.7-0.9l5.1,24.7c0.1,0.6,0.7,1.1,1.4,0.9
      c0,0,0,0,0,0c0.6-0.1,1.1-0.8,0.9-1.4l-5.1-24.7c-0.4,0.1-0.8,0.2-1.2,0.2c1.9-0.4,3.7-0.8,5.6-1.2l2.7,10.2c0.2,0.6,0.8,1,1.4,0.8
      c0,0,0,0,0,0c0.6-0.2,1-0.8,0.8-1.4l-2.7-10.2c1.4-0.4,2.8-0.8,4.2-1.2l1.1,3.5c0.2,0.6,0.8,0.9,1.4,0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c0-0.2,0-0.4,0-0.6l-1-3c1.4-0.4,2.8-0.9,4.1-1.4l3.8,10.2c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5l-3.8-10.2
      c1.4-0.5,2.8-1.1,4.1-1.7l10.1,23.1c0.3,0.6,1,0.9,1.6,0.6c0.6-0.3,0.9-1,0.6-1.6l-10.1-23c-0.7,0.3-1.4,0.6-2.1,0.9
      c2-0.9,4-1.8,6-2.7l15.7,31.5c0.3,0.6,1,0.9,1.7,0.6s0.9-1,0.6-1.7l-15.7-31.4c1.3-0.6,2.5-1.3,3.8-2 M155.9,338
      c-59.5,33.8-135.1,13-169-46.5c-33.8-59.5-13-135.1,46.5-169c59.5-33.8,135.1-13,169,46.5C236.1,228.5,215.3,304.1,155.9,338z"
      />
      <g class="op op2 pc">
        <path
          class="st0 st1"
          d="M177.9,464.8c6,10.6,19.4,14.3,30,8.3c0,0,0,0,0,0c1.4-0.8,2.7-1.7,3.8-2.8c2.6,4,6.8,6.7,11.5,7.3l13.1,3.6
        L240,468c1.9-4.4,1.8-9.3-0.3-13.6c1.5-0.5,3-1.1,4.4-1.9c10.5-6,14.2-19.5,8.2-30c-4.2-7.3-12.1-11.5-20.4-11l-5.1-9l-13.3,7.5
        c-7.3,4.2-9.9,13.5-5.7,20.8c-4.2-7.3-13.5-9.9-20.8-5.7l-13.3,7.5l5.2,9.1C174.1,448.5,173.7,457.5,177.9,464.8z"
        />
        <path
          class="st0"
          d="M186.8,464.9c4.9,8.7,15.9,11.7,24.5,6.8c0,0,0,0,0.1,0c1.1-0.6,2.2-1.4,3.1-2.3c2.1,3.3,5.6,5.5,9.5,6
      l10.8,2.9l3-10.8c1.6-3.6,1.5-7.7-0.3-11.2c1.3-0.4,2.5-0.9,3.6-1.6c8.7-4.8,11.8-15.8,7-24.5c-3.4-6.1-10-9.7-17-9.2l-4.2-7.4
      l-10.9,6.2c-6,3.4-8.1,11-4.7,17c-3.4-6-11-8.1-17-4.8c0,0,0,0-0.1,0l-10.9,6.2l4.2,7.5C183.6,451.6,183.3,459,186.8,464.9z"
        />
        <path
          class="st0"
          d="M248.3,451.6c-11.8,6.7-26.8,2.6-33.5-9.2c-6.7-11.8-2.6-26.8,9.2-33.5s26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C264.2,429.8,260.1,444.8,248.3,451.6 M249.2,453.1c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.7,7.2-17.1,23.3-9.9,36c0,0,0,0,0,0C220.4,455.9,236.5,460.3,249.2,453.1C249.2,453.2,249.2,453.1,249.2,453.1L249.2,453.1z"
        />
        <path
          class="st0"
          d="M204.9,476.2c-11.8,6.7-26.8,2.6-33.5-9.2s-2.6-26.8,9.2-33.5c11.8-6.7,26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C220.8,454.5,216.7,469.5,204.9,476.2 M205.7,477.8c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.6,7.2-17.1,23.3-9.9,35.9C176.9,480.6,193,485,205.7,477.8C205.7,477.8,205.7,477.8,205.7,477.8z"
        />
        <path
          class="st0"
          d="M215.2,440.5l-8.2-14.4c-4.5-7.9-1.7-18,6.2-22.5l14.4-8.2l8.1,14.4c4.5,7.9,1.7,18-6.2,22.5L215.2,440.5
      M214.6,442.9l15.9-9c8.8-5,11.9-16.2,6.9-25l-9-15.9l-15.9,9c-8.8,5-11.9,16.2-6.9,24.9c0,0,0,0,0,0L214.6,442.9z"
        />
        <path
          class="st0"
          d="M197.8,450.4c-7.9,4.5-18,1.8-22.5-6.1c0,0,0,0,0,0l-8.2-14.4l14.4-8.2c7.9-4.5,18-1.7,22.5,6.2l8.2,14.3
      L197.8,450.4 M198.7,451.9l15.9-9l-9-15.9c-5-8.8-16.2-11.9-25-6.9c0,0,0,0,0,0l-15.9,9l9,15.9C178.7,453.9,189.8,457,198.7,451.9
      C198.6,452,198.7,452,198.7,451.9L198.7,451.9z"
        />
        <path
          class="st0"
          d="M238.7,485.3l-14-3.8h-0.1h-0.1c-9.6-1.2-16.4-10-15.2-19.6c0.2-1.3,0.5-2.6,0.9-3.9v-0.1
      c0.2-0.5,0.4-1.1,0.7-1.6l0,0l0,0l4.8-11.4l12.2,1.7h0.1c0.5,0,1.1,0.1,1.7,0.2h0.1c9.5,1.8,15.8,11,14,20.5
      c-0.2,1.3-0.6,2.6-1.2,3.8v0.1v0.1L238.7,485.3 M240,487.5l4.4-15.8c4.3-9.8-0.2-21.2-10-25.4c-1.3-0.6-2.7-1-4.2-1.3l0,0
      c-0.6-0.1-1.3-0.2-1.9-0.3l-13.6-1.8l-5.5,12.6c-0.3,0.6-0.5,1.2-0.8,1.8l0,0l0,0c-3.7,10,1.4,21.2,11.4,24.9c1.4,0.5,2.8,0.9,4.3,1
      L240,487.5L240,487.5z"
        />
        <path
          class="st2"
          d="M204.9,451.9c-3.5-6.2-1.4-14,4.8-17.6c6.2-3.5,14-1.4,17.6,4.8s1.4,14-4.8,17.6c0,0,0,0,0,0
      C216.2,460.2,208.4,458.1,204.9,451.9z"
        />
        <polygon
          class="st2"
          points="215.7,445.7 216.5,445.3 226.7,463.4 226,463.8 "
        />
        <path
          class="st2"
          d="M224.3,464.8c-0.6-1.1-0.2-2.6,0.9-3.2s2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c0,0,0,0,0,0
      C226.4,466.3,225,465.9,224.3,464.8z"
        />
        <polygon
          class="st2"
          points="197.8,455.4 215.9,445.1 216.3,445.9 198.2,456.2 "
        />
        <path
          class="st2"
          d="M196.8,453.8c1.1-0.6,2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c-1.1,0.6-2.6,0.2-3.2-0.9c0,0,0,0,0,0
      C195.3,455.9,195.7,454.4,196.8,453.8C196.8,453.8,196.8,453.8,196.8,453.8z"
        />
        <rect
          x="214.8"
          y="439.9"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -188.1656 168.7628)"
          class="st2"
          width="20.8"
          height="0.9"
        />
        <path
          class="st2"
          d="M233,433.2c-1.1,0.6-1.5,2.1-0.9,3.2c0.6,1.1,2.1,1.5,3.2,0.9s1.5-2.1,0.9-3.2c0,0,0,0,0,0
      C235.6,433,234.2,432.6,233,433.2z"
        />
        <path
          class="st0"
          d="M213.1,447.2c-0.9-1.7-0.4-3.8,1.3-4.7c1.7-0.9,3.8-0.4,4.7,1.3c0.9,1.7,0.4,3.8-1.3,4.7c0,0,0,0,0,0
      C216.1,449.5,214,448.9,213.1,447.2C213.1,447.3,213.1,447.2,213.1,447.2z"
        />
        <path
          class="st0"
          d="M194.1,406.8c3.5-2,8.3-1.2,12.6,2.2l-1.1,1.4c-3.6-2.9-7.7-3.6-10.6-2c-2.9,1.6-4.3,5.5-3.7,10.2l-1.8,0.2
      C188.8,413.5,190.6,408.9,194.1,406.8z"
        />
        <rect
          x="191.3"
          y="404.7"
          transform="matrix(0.2654 -0.9641 0.9641 0.2654 -250.5467 488.2404)"
          class="st0"
          width="7.7"
          height="7.7"
        />
        <path
          class="st0"
          d="M250.6,509c-2-2.1-2.8-4.6-1.8-5.5s3.4,0,5.4,2.1s2.8,4.6,1.9,5.5C255.1,512.1,252.7,511.2,250.6,509z"
        />
        <path
          class="st0"
          d="M241.8,506.5c-1.5,2.1-3.6,3.3-4.6,2.6c-1-0.7-0.6-3,1-5.2c1.6-2.1,3.6-3.3,4.6-2.6S243.4,504.4,241.8,506.5z"
        />
        <path
          class="st0"
          d="M253.1,496.9c-2.1,0.8-4.2,0.5-4.5-0.5s1.1-2.4,3.2-3.2c2.1-0.8,4.2-0.5,4.5,0.5
      C256.7,494.7,255.2,496.1,253.1,496.9z"
        />
        <rect
          x="243.7"
          y="484.7"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -212.8196 185.46)"
          class="st0"
          width="1.5"
          height="21.2"
        />
        <path
          class="st0"
          d="M240.8,487.4c6.7,11.8,2,14.7,1.8,14.8l-0.8-1.3c0,0,3.5-2.5-2.3-12.7L240.8,487.4z"
        />
        <path
          class="st0"
          d="M242.7,491.1c3.1,5.5,7.3,4.4,7.5,4.4l0.4,1.5c-0.1,0-5.5,1.4-9.2-5.1L242.7,491.1z"
        />
        <path
          class="st0"
          d="M152.6,477.5c2.8-0.8,5.3-0.3,5.7,1s-1.6,3-4.4,3.7c-2.8,0.7-5.3,0.3-5.7-1C147.9,480,149.8,478.3,152.6,477.5z
      "
        />
        <path
          class="st0"
          d="M156.2,469.2c-1.5-2.2-1.8-4.6-0.8-5.3s3.1,0.5,4.6,2.8s1.8,4.6,0.8,5.3S157.8,471.5,156.2,469.2z"
        />
        <path
          class="st0"
          d="M165.6,483.3c-1-2.1-1-4.1,0-4.6s2.6,0.8,3.6,2.9s1,4.1,0,4.6S166.6,485.4,165.6,483.3z"
        />
        <rect
          x="156.2"
          y="473.8"
          transform="matrix(0.8687 -0.4953 0.4953 0.8687 -213.4897 143.7556)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M171.2,469.9l0.8,1.3c-5.1,2.9-8.8,3.8-10.9,2.6c-0.9-0.5-1.6-1.5-1.6-2.6h1.5c0,0.6,0.4,1.1,0.9,1.3
      C162.8,473,165.2,473.3,171.2,469.9z"
        />
        <path
          class="st0"
          d="M170.1,470.5l0.8,1.3c-5.5,3.1-4.5,7.3-4.5,7.3l-1.5,0.4C164.8,479.4,163.5,474.2,170.1,470.5z"
        />
        <path
          class="st0"
          d="M275.6,407.1c-2.4,2.4-3.5,5.2-2.4,6.3c1.1,1.1,4,0.2,6.4-2.2c2.4-2.4,3.5-5.2,2.4-6.3S278,404.8,275.6,407.1z"
        />
        <path
          class="st0"
          d="M266.7,407c-1.3-2-3-3.1-3.9-2.5s-0.7,2.6,0.6,4.6s3,3.1,3.9,2.5S267.9,408.9,266.7,407z"
        />
        <path
          class="st0"
          d="M274.7,422.5c-1.2-2.5-3-4.1-4-3.6s-0.8,3,0.4,5.5s3.1,4.1,4,3.6C276.1,427.5,276,425,274.7,422.5z"
        />
        <rect
          x="259.1"
          y="415.4"
          transform="matrix(0.8691 -0.4945 0.4945 0.8691 -170.8213 186.6468)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M266.9,410.1c0.9,0.6,1.4,1.6,1.4,2.7c0,2.4-2.7,5.1-7.8,8l-0.8-1.3c6-3.4,7-5.7,7-6.7c0-0.5-0.2-1.1-0.7-1.4
      L266.9,410.1z"
        />
        <path
          class="st0"
          d="M266.5,417.2c1.8,0,3.5,0.6,4.9,1.8l-1.1,1.1c-0.1-0.1-3.1-3-8.6,0.1l-0.8-1.3
      C262.6,417.9,264.5,417.3,266.5,417.2z"
        />
      </g>
      <rect
        x="254.7"
        y="325"
        transform="matrix(0.8777 -0.4792 0.4792 0.8777 -125.6418 163.5741)"
        class="st0 op op10"
        width="6"
        height="6"
      />
      <rect
        x="277.7"
        y="330.1"
        transform="matrix(0.789 -0.6144 0.6144 0.789 -145.3814 242.6519)"
        class="st0 op op9"
        width="5.7"
        height="5.7"
      />
      <rect
        x="302.5"
        y="333.2"
        transform="matrix(0.7508 -0.6605 0.6605 0.7508 -145.8386 285.39)"
        class="st0 op op8"
        width="5.6"
        height="5.6"
      />
      <rect
        x="323.5"
        y="332.9"
        transform="matrix(0.6543 -0.7562 0.7562 0.6543 -141.0644 362.7884)"
        class="st0 op op7"
        width="5.6"
        height="5.6"
      />
      <rect
        x="395.2"
        y="313.8"
        transform="matrix(0.316 -0.9488 0.9488 0.316 -28.186 594.4554)"
        class="st0 op op5"
        width="5.9"
        height="5.9"
      />
      <rect
        x="358.4"
        y="326.7"
        transform="matrix(0.5219 -0.853 0.853 0.5219 -108.4008 465.6359)"
        class="st0 op op6"
        width="5.6"
        height="5.6"
      />
      <rect
        x="411.8"
        y="303.3"
        transform="matrix(0.2103 -0.9776 0.9776 0.2103 27.9992 647.9564)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="460.6"
        y="334.4"
        transform="matrix(4.710640e-02 -0.9989 0.9989 4.710640e-02 104.6518 785.1971)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="470.1"
        y="243.4"
        transform="matrix(0.9596 -0.2813 0.2813 0.9596 -50.2987 143.1508)"
        class="st0 op op2"
        width="6.6"
        height="6.6"
      />
      <rect
        x="480.3"
        y="219.2"
        transform="matrix(0.9117 -0.4108 0.4108 0.9117 -48.7281 218.2997)"
        class="st0 op op1"
        width="6.6"
        height="6.6"
      />
      <g class="r30">
        <path
          class="st0 op op6"
          d="M770.7,358.9c-1.6-1.5-4.1-1.4-5.7,0.2c-1.5,1.6-1.4,4.1,0.2,5.7c1.6,1.5,4.1,1.4,5.6-0.2
      C772.4,363,772.3,360.5,770.7,358.9C770.7,359,770.7,358.9,770.7,358.9z"
        />
        <path
          class="st0 op op5"
          d="M729.6,381c-3-3.8,1-5.4,1-5.4c1.1-0.3,1.9-1.3,1.9-2.4c-0.2-1.3-0.7-2.6-1.4-3.8c-2.4-3.2-5.3-6.1-8.6-8.5
      l-0.4-0.3l0.4-0.2c1.8-0.7,3.6-1.6,5.3-2.6l0,0c1.5-0.9,2.9-1.9,4.2-3c0.9-0.9,1.6-2.1,2-3.4c0,0,0.3-1.5-1.6-2.9
      c0,0-3.7-2.7-0.4-5.6c0.8-0.8,0.8-2.1,0-3c-0.1-0.1-0.2-0.2-0.4-0.3c0,0,9.5,5.1,5.7,10.5l0,0c-3.4,4.8-8.1,8.5-13.6,10.6
      c5,3.6,9.1,8.4,11.8,14c3.8,6.8-6.6,9.1-6.6,9.1c1-0.4,1.5-1.6,1.1-2.6C729.8,381.3,729.7,381.1,729.6,381z"
        />
        <path
          class="st0 op op4"
          d="M704.2,392.2c-2.8-5.8,2.5-7.9,2.5-7.9c2.1-0.8,3.7-2.5,4.3-4.7c0.6-3.1,0.4-6.4-0.6-9.4
      c-2.2-5.3-5.5-10.1-9.7-14l-0.3-0.3l0.4-0.1c5-1.7,9.6-4.7,13.2-8.6c2-2.5,3.2-5.5,3.6-8.6c0.2-2.4-0.8-4.7-2.5-6.3
      c0,0-4.3-4.4,0-8.5c1.3-1.5,1.2-3.6-0.2-5c0,0,8.7,6.6,7.2,13.7l-0.1,0.4c-1.7,10.7-9.2,19.4-20.1,23.4c9,8.5,13.3,20,11.6,30.6
      c0,0.1,0,0.3-0.1,0.4c-0.8,7.2-10.8,9.5-10.8,9.5C704.2,395.9,704.9,394,704.2,392.2z"
        />
        <path
          class="st0 op op3"
          d="M669.9,384.9c-1.2-3.5,0.6-7.4,4-8.8c2.4-0.9,4.3-2.8,5.2-5.2c1.2-3.4,1.5-7,1-10.5c-1.3-5.8-3.9-11.3-7.7-15.9
      l-0.3-0.4l0.5-0.1c5.7-1.9,10.9-5.1,15.1-9.3c2.5-2.7,4.3-6,5.2-9.5c0.6-2.5,0-5.1-1.5-7.1c-2.2-3-1.5-7.2,1.5-9.5
      c1.5-1.5,1.8-3.8,0.7-5.6c0,0,7.9,7.6,5.1,15.4l-0.1,0.5c-3.7,12-13,21.5-24.9,25.6c7.8,9.7,10.4,22.6,6.8,34.5
      c0,0.2-0.1,0.3-0.2,0.5c-2,8-12.9,10.3-12.9,10.3C669.3,389.1,670.4,387,669.9,384.9z"
        />
        <rect
          x="725.5"
          y="358.5"
          transform="matrix(0.8026 -0.5965 0.5965 0.8026 -71.8132 505.9555)"
          class="st0 op op5"
          width="6.1"
          height="6.1"
        />
        <rect
          x="703.7"
          y="354.2"
          transform="matrix(0.875 -0.4842 0.4842 0.875 -84.5816 386.8404)"
          class="st0 op op4"
          width="6.1"
          height="6.1"
        />
        <path
          class="st0 op op2"
          d="M640.4,356.5c-0.7-5.4,4.2-5.8,4.2-5.8c1.9,0,3.7-1.1,4.7-2.7c1.3-2.4,2-5.1,2-7.9c-0.3-4.9-1.8-9.7-4.2-14
      l-0.2-0.4h0.4c4.6-0.1,9-1.2,13-3.5c2.3-1.5,4.1-3.6,5.2-6.1c0.8-1.9,0.6-4-0.3-5.8c0,0-2.3-4.7,2.3-6.9c1.4-0.8,1.9-2.6,1.2-4.1
      c0,0,5.3,7.7,2.2,13.1l-0.2,0.4c-4.2,8.2-12.6,13.4-22.6,13.6c5,9.4,5.5,19.9,1.2,28.1c0,0.1-0.1,0.2-0.2,0.3
      c-2.6,5.6-11.4,4.8-11.4,4.8C639.5,359.5,640.6,358.1,640.4,356.5z"
        />
        <rect
          x="649.2"
          y="325.6"
          transform="matrix(0.9825 -0.1865 0.1865 0.9825 -49.8557 127.4308)"
          class="st0 op op2"
          width="6.1"
          height="6.1"
        />
        <rect
          x="674.6"
          y="342.5"
          transform="matrix(0.9275 -0.3738 0.3738 0.9275 -80.0531 278.3417)"
          class="st0 op op3"
          width="6.1"
          height="6.1"
        />
        <path
          class="st3 an2"
          d="M613,155.1c-41.5,80,10.9,217,155.5,207.4"
        />
      </g>
      <g>
        <line
          class="st3 an1"
          x1="656.5"
          y1="168.9"
          x2="580.6"
          y2="271.1"
        />

        <path
          class="st3 an3-1"
          d="M493.6,141.7C480.7,62.1,408.6,4.3,322.3,4.3c-10.3,0-20.6,0.8-30.7,2.5c-46.9,7.6-88.2,31.8-116.3,68 s-39.7,80.2-32.6,123.9c12.9,79.6,84.9,137.4,171.2,137.4v0c10.3,0,20.6-0.9,30.7-2.5c46.9-7.6,88.2-31.8,116.3-68 S500.7,185.4,493.6,141.7"
        />
        <path
          class="st3 an6"
          d="M461,265.6c-35.1,42.9,11.4,107,59.9,85.6"
        />
      </g>
      <line
        class="st3 an6"
        x1="155.8"
        y1="340"
        x2="196.1"
        y2="410.1"
      />
    </svg>
    <svg version="1.1"
      id="svg5"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 799.5 523.3"
      style="enable-background:new 0 0 799.5 523.3;"
      xml:space="preserve"
      preserveAspectRatio="xMinYMid meet"
    >
      <path
        class="st0 ops op10"
        d="M248.6,351.2c4.4-3.7,0.8-7,0.8-7c-1.1-0.9-1.5-2.3-1.1-3.6c0.7-1.6,1.6-3,2.8-4.2c3.6-3.1,7.6-5.6,11.9-7.5
      l0.5-0.2l-0.4-0.3c-1.7-1.5-3.2-3.2-4.6-5l0,0c-1.2-1.6-2.3-3.4-3.2-5.2c-0.6-1.5-0.9-3.2-0.8-4.8c0.3-1.4,1.3-2.6,2.7-3.1
      c0,0,4.8-2,2.3-6.7c-0.6-1.5,0-3.2,1.5-3.9c0,0-11.6,3.1-9.6,11.1l0,0c1.6,6.8,5.3,13,10.5,17.7c-6.7,2.9-12.7,7.5-17.2,13.2
      c-6.3,7.1,3.7,13.5,3.7,13.5C246.5,353.2,248.6,351.2,248.6,351.2z"
      />
      <path
        class="st0 ops op9"
        d="M271.1,370.2c4.7-5.1-0.2-9-0.2-9c-2-1.4-3.1-3.8-3-6.3c0.3-3.5,1.6-6.8,3.6-9.7c3.9-4.9,8.9-8.8,14.5-11.6
      l0.5-0.2l-0.4-0.3c-4.7-3.4-8.5-7.9-11.1-13.1c-1.3-3.2-1.6-6.7-1-10.1c0.6-2.5,2.3-4.7,4.6-5.8c0,0,5.9-3.2,2.6-8.9
      c-0.9-1.9-0.1-4.1,1.7-5.1c0,0-11.1,4.2-11.8,12c0,0.1,0,0.3-0.1,0.5c-1.6,11.7,3.6,23.1,13.8,30.7c-12,6.1-20.1,16.7-21.6,28.4
      c0,0.2,0,0.3,0,0.5c-1.4,7.7,8.4,13.3,8.4,13.3C270,374.1,269.8,371.8,271.1,370.2z"
      />
      <path
        class="st0 ops op8"
        d="M294.6,291.4L294.6,291.4c-1.3,3.8-2,7.7-2.2,11.8c-0.4,13.1,5.9,25.5,16.7,32.9c-11.4,7.9-18.5,20.5-18.9,33.6
      c-0.1,4,0.4,7.9,1.4,11.7l0,0c1.9,7.2,10.2,11.4,10.2,11.4c-3.1-2.8-0.9-6.4-0.9-6.4c4.6-6.7-1.2-11-1.2-11
      c-4.6-3.5-4.8-7.8-4.8-7.8c-0.6-6.4,0.5-12.8,3-18.7c3.2-5,7.5-9.3,12.4-12.7l0.4-0.3l-0.4-0.3c-4.7-3.1-8.7-7.3-11.6-12.2
      c-2.2-5.9-2.8-12.3-1.8-18.6c0,0,0.5-4.3,5.3-8c0,0,6.1-4.5,1.9-11.1c-1.1-2.2-0.6-4.9,1.3-6.5C305.6,279.5,297,284.1,294.6,291.4z"
      />
      <path
        class="st0 ops op7"
        d="M310.9,308L310.9,308c0,0.4,0,0.8,0,1.2c0.8,11.1,8.1,20.8,19.7,26c-10.7,7.7-16.5,18.9-15.8,30
      c0,0.4,0.1,0.8,0.1,1.2l0,0c0,0.2,0,0.4,0.1,0.6l0,0c1.3,6.5,10.8,9.5,10.8,9.5c-1.7-0.9-2.3-3-1.4-4.7c0,0,0-0.1,0-0.1
      c3.6-5.6-2-8.3-2-8.3c-2.2-0.9-3.8-2.9-4.2-5.3c-0.4-3.4,0.3-6.9,1.8-9.9c0.2-0.3,0.4-0.7,0.6-1c0.2-0.3,0.4-0.6,0.6-0.9l-0.1,0.1
      c2.8-4.3,6.5-8,10.7-11l0.4-0.3l-0.4-0.2c-4.6-2-8.7-4.8-12.1-8.5l0.1,0.1l-0.7-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-2-2.7-3.1-6-3.2-9.4
      c0.1-2.5,1.3-4.8,3.4-6.1c0,0,5.2-4,0.8-8.6c-1.2-1.6-0.9-3.8,0.7-5c0,0,0,0,0,0C320.1,295.7,310.8,301.1,310.9,308z"
      />
      <g class="ops op3">
        <path
          class="st0 st1"
          d="M515,279.4c-8.1,11-5.7,26.4,5.3,34.5c1.4,1,3,1.9,4.6,2.7c-2.9,4.5-3.7,10-2.1,15.1l2.4,15.1l15.1-2.3
        c5.3-0.1,10.4-2.5,13.8-6.6c1.2,1.4,2.5,2.6,4,3.6c11,8.1,26.4,5.7,34.5-5.3c5.6-7.6,6.3-17.7,1.9-26.1l6.9-9.4l-13.8-10.1
        c-7.6-5.6-18.3-4-23.9,3.6c0,0,0,0,0,0.1c5.6-7.6,4-18.3-3.7-23.9l-13.8-10.1l-6.9,9.5C530,268,520.6,271.8,515,279.4z"
        />
        <path
          class="st0"
          d="M519,288.6c-6.6,9-4.7,21.7,4.3,28.3c0,0,0,0,0,0c1.2,0.9,2.4,1.6,3.8,2.2c-2.4,3.7-3,8.2-1.8,12.4l1.9,12.4
      l12.4-1.9c4.4,0,8.5-2,11.3-5.4c7.4,8.4,20.1,9.3,28.6,1.9c7-6.2,8.9-16.4,4.6-24.6l5.7-7.7l-11.3-8.3c-6.2-4.6-15-3.3-19.6,2.9
      c0,0,0,0,0,0.1c4.6-6.2,3.3-15-2.9-19.6c0,0,0,0-0.1,0l-11.3-8.3l-5.7,7.8C531.2,279.2,523.5,282.3,519,288.6z"
        />
        <path
          class="st0"
          d="M560.9,345.5c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.6c0,0,0,0,0,0
      C590.4,351.8,573.1,354.5,560.9,345.5 M559.7,347.1c13.2,9.7,31.7,6.8,41.4-6.3c9.7-13.2,6.8-31.7-6.3-41.4
      c-13.2-9.7-31.7-6.8-41.4,6.3c0,0,0,0,0,0C543.7,318.9,546.5,337.4,559.7,347.1C559.7,347.1,559.7,347.1,559.7,347.1z"
        />
        <path
          class="st0"
          d="M515.7,312.2c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.5
      C545.2,318.6,527.9,321.2,515.7,312.2 M514.5,313.9c13.2,9.7,31.7,6.8,41.4-6.3s6.8-31.7-6.3-41.4c-13.2-9.7-31.7-6.8-41.4,6.3
      c0,0,0,0,0,0C498.5,285.7,501.3,304.2,514.5,313.9z"
        />
        <path
          class="st0"
          d="M557.1,306.5l11-14.9c6.1-8.2,17.6-10,25.9-4l14.9,11l-11,14.9c-6,8.2-17.6,10-25.9,4L557.1,306.5 M554.2,306.9
      l16.5,12.1c9.1,6.7,22,4.7,28.7-4.4l12.1-16.5L595.1,286c-9.1-6.7-22-4.7-28.7,4.4c0,0,0,0,0,0L554.2,306.9L554.2,306.9z"
        />
        <path
          class="st0"
          d="M538.9,293.2c-8.3-6-10-17.6-4-25.9l11-14.9l14.9,11c8.2,6,10,17.6,4,25.9l-11,14.9L538.9,293.2 M537.8,294.9
      l16.5,12.1l12.1-16.5c6.7-9.1,4.7-22-4.4-28.7c0,0,0,0,0,0l-16.5-12.1l-12.1,16.5C526.6,275.2,528.5,288.1,537.8,294.9
      C537.7,294.8,537.7,294.8,537.8,294.9L537.8,294.9z"
        />
        <path
          class="st0"
          d="M521.9,351.1l-2.5-16.1v-0.1v-0.1c-3.2-10.4,2.6-21.4,13-24.6c1.4-0.4,2.9-0.7,4.4-0.8h0.1c0.6,0,1.3,0,1.9,0
      h0.1l13.9-0.3l3.9,13.3v0l0,0c0.2,0.6,0.4,1.2,0.5,1.8v0.1c2.5,10.6-4,21.2-14.5,23.7c-1.4,0.3-2.9,0.5-4.4,0.5h0h-0.1L521.9,351.1
      M520.2,353.3l18.2-2.7c12-0.1,21.6-9.9,21.4-21.9c0-1.6-0.2-3.2-0.6-4.8l0,0v-0.1c-0.2-0.7-0.4-1.4-0.6-2.1l-4.4-14.8l-15.4,0.3
      c-0.7,0-1.5,0-2.2,0l0,0c-12,0.8-21,11.2-20.2,23.1c0.1,1.7,0.4,3.3,0.9,4.9L520.2,353.3z"
        />
        <rect
          x="533.7"
          y="318.6"
          transform="matrix(0.591 -0.8067 0.8067 0.591 -34.3336 570.4008)"
          class="st2"
          width="23.3"
          height="1"
        />
        <path
          class="st2"
          d="M536.3,326.9c0.9-1.2,2.5-1.4,3.7-0.6s1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6
      C535.7,329.8,535.5,328.1,536.3,326.9C536.3,327,536.3,327,536.3,326.9z"
        />
        <rect
          x="542.4"
          y="291.1"
          transform="matrix(0.5917 -0.8061 0.8061 0.5917 -22.4438 561.2402)"
          class="st2"
          width="1"
          height="23.3"
        />
        <path
          class="st2"
          d="M535,293.8c1.2,0.9,1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6c-1.2-0.9-1.4-2.5-0.6-3.7c0,0,0,0,0,0
      C532.2,293.2,533.8,292.9,535,293.8C535,293.8,535,293.8,535,293.8z"
        />
        <polygon
          class="st2"
          points="552.6,309.3 571.4,323.1 570.8,323.9 552,310.1 "
        />
        <path
          class="st2"
          d="M572.7,321.4c-1.2-0.9-2.8-0.6-3.7,0.6c-0.9,1.2-0.6,2.8,0.6,3.7s2.8,0.6,3.7-0.6c0,0,0,0,0,0
      C574.1,323.9,573.9,322.3,572.7,321.4C572.7,321.4,572.7,321.4,572.7,321.4z"
        />
        <path
          class="st2"
          d="M540.6,301.1c4.7-6.4,13.8-7.8,20.2-3.1c6.4,4.7,7.8,13.8,3.1,20.2c-4.7,6.4-13.8,7.8-20.2,3.1
      C537.3,316.6,535.9,307.6,540.6,301.1C540.6,301.1,540.6,301.1,540.6,301.1z"
        />
        <path
          class="st0"
          d="M549.2,307.4c1.3-1.7,3.7-2.1,5.4-0.8c1.7,1.3,2.1,3.7,0.8,5.4c-1.3,1.7-3.7,2.1-5.4,0.8
      C548.3,311.5,547.9,309.1,549.2,307.4z"
        />
        <path
          class="st0"
          d="M581.8,269.5c3.7,2.7,5,8,3.5,13.9l-1.9-0.5c1.3-5,0.2-9.5-2.8-11.8s-7.6-1.9-12.1,0.9l-1.1-1.7
      C572.6,267.1,578.1,266.8,581.8,269.5z"
        />
        <rect
          x="576.2"
          y="267"
          transform="matrix(0.9885 -0.1511 0.1511 0.9885 -34.3258 90.823)"
          class="st0"
          width="8.6"
          height="8.6"
        />
      </g>
      <rect
        x="612.2"
        y="220.5"
        transform="matrix(0.9885 -0.1511 0.1511 0.9885 -26.8084 95.6392)"
        class="st0 ops op1"
        width="7.5"
        height="7.5"
      />
      <path
        class="st0 ops op3"
        d="M456.8,265.7c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C459.2,269.9,457,268.1,456.8,265.7C456.8,265.7,456.8,265.7,456.8,265.7z"
      />
      <path
        class="st0 op op11"
        d="M196.6,297.6c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C199.1,301.7,196.9,300,196.6,297.6z"
      />
      <path
        class="st0 op op3"
        d="M516.9,352.8c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C519.3,357,517.2,355.2,516.9,352.8C516.9,352.8,516.9,352.8,516.9,352.8z"
      />
      <path
        class="st0 op op2"
        d="M596.9,244.4c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C599.4,248.5,597.2,246.8,596.9,244.4C596.9,244.4,596.9,244.4,596.9,244.4z"
      />
      <path
        class="st0 op op5"
        d="M405.1,342.7c1.5-6.2-3.9-6.3-3.9-6.3c-1.5,0.1-2.8-0.8-3.4-2.2c-0.4-1.8-0.5-3.6-0.3-5.4
      c1.2-5.1,3.1-10.1,5.7-14.6l0.3-0.5h-0.5c-2.5-0.1-4.9-0.4-7.3-0.9l0,0c-2.1-0.5-4.2-1.1-6.3-2c-1.6-0.8-2.9-2-3.9-3.4
      c0,0-1.2-1.8,0.3-4.4c0,0,3-5.1-2.3-7.2c-1.5-0.7-2.1-2.5-1.4-4c0-0.1,0.1-0.2,0.2-0.3c0,0-8.3,10.8-1.3,16l0,0
      c6.1,4.5,13.4,7.1,20.9,7.3c-4,7.1-6.3,15-6.6,23.1c-0.9,10.3,12.2,8.6,12.2,8.6C404.5,345.9,405.1,342.7,405.1,342.7z"
      />
      <path
        class="st0 op op2"
        d="M489.7,260.5c-1.4-5.1-5.3-2.6-5.3-2.6c-2.1,1.2-3.3,0.1-3.3,0.1c-1-1.1-1.8-2.3-2.4-3.7
      c-1.2-4.2-1.8-8.7-1.7-13.1v-0.5l-0.4,0.2c-1.8,1.1-3.7,2.1-5.7,2.9l0,0c-1.7,0.7-3.5,1.2-5.4,1.6c-1.4,0.2-2.9,0-4.2-0.5
      c0,0-1.5-0.7-1.5-3.3c0,0,0.1-5-4.6-4c0,0-2.4,0.4-2.6-2.5c0,0-1.8,11.6,5.4,11.9l0,0c6.4,0.3,12.8-1.5,18.2-4.9
      c-0.2,6.8,1.4,13.5,4.4,19.5c3.5,7.7,12.3,0.2,12.3,0.2C490.5,263,489.7,260.5,489.7,260.5z"
      />
      <path
        class="st0 op op1"
        d="M516.1,245.8c-3.2-6.2-8.5-3-8.5-3c-2.1,1.4-4.7,1.6-6.9,0.6c-3.1-1.5-5.8-3.9-7.8-6.7
      c-3.2-5.4-5.2-11.4-5.8-17.6l-0.1-0.5l-0.4,0.3c-4.8,3.3-10.4,5.2-16.1,5.8c-3.5,0.1-6.9-0.8-9.8-2.6c-2.2-1.4-3.6-3.7-3.8-6.3
      c0,0-1-6.6-7.4-5.6c-2.1,0.1-3.9-1.3-4.2-3.4c0,0,0.1,11.9,7.2,15.2l0.4,0.2c10.4,5.6,22.9,4.7,33.5-2.1c1.5,13.4,8.7,24.7,19,30.2
      l0.4,0.2c6.8,4,15.4-3.2,15.4-3.2C519.3,248.2,517.1,247.5,516.1,245.8z"
      />
      <path
        class="st0 op op1"
        d="M157.8,340.4c1.2-0.7,2.4-1.5,3.6-2.2l18.9,29.5c0.4,0.6,1.1,0.7,1.7,0.4c0.6-0.4,0.7-1.1,0.4-1.7c0,0,0,0,0,0
      l-18.8-29.4c1.2-0.8,2.4-1.6,3.6-2.4l14.4,20.2c0.4,0.5,1.1,0.7,1.6,0.3c0,0,0,0,0,0c0.5-0.4,0.7-1.1,0.3-1.7l-14.5-20.1
      c1.2-0.9,2.4-1.8,3.6-2.7l6.3,7.9c0.4,0.5,1.2,0.5,1.6,0c0.4-0.4,0.5-1,0.2-1.5l-6.3-7.9c1.2-1,2.4-1.9,3.5-2.9l1.9,2.1
      c0.4,0.5,1.1,0.5,1.6,0.1c0.5-0.4,0.5-1.1,0.1-1.6l-1.9-2.1c1.7-1.5,3.3-3,4.8-4.6l2.3,2.3c0.4,0.4,1.1,0.4,1.6,0c0,0,0,0,0,0
      c0.4-0.4,0.4-1.1,0-1.6l-2.3-2.3c1.1-1.1,2.1-2.2,3.1-3.3l7.9,7c0.5,0.4,1.2,0.3,1.6-0.2c0.3-0.5,0.3-1.1-0.1-1.5l-7.9-7l-1.3,1.4
      c1.5-1.7,2.9-3.4,4.3-5.1c-0.1,0.1-0.2,0.2-0.2,0.3l19.5,15.5c0.6,0.4,1.3,0.2,1.7-0.3c0.3-0.5,0.2-1.1-0.2-1.5l-19.5-15.4
      c0.9-1.1,1.8-2.3,2.6-3.5l28.5,20.2c0.6,0.4,1.3,0.2,1.7-0.3c0.4-0.5,0.2-1.3-0.3-1.7l-28.4-20.2c0.8-1.2,1.6-2.4,2.3-3.6l1.4-2.2
      c0.8-1.2,1.5-2.4,2.2-3.7l30.3,17.1c0.6,0.3,1.3,0.1,1.7-0.5c0.3-0.6,0.1-1.3-0.5-1.7l-30.3-17c0.7-1.3,1.4-2.6,2-3.9l22.1,11
      c0.6,0.3,1.3,0.1,1.6-0.5c0,0,0,0,0,0c0.3-0.6,0-1.3-0.5-1.6l-22.1-11c0.6-1.3,1.3-2.7,1.9-4l9.3,4c0.6,0.2,1.2-0.1,1.5-0.7
      c0.2-0.5,0-1.1-0.6-1.4l-9.3-4c0.6-1.4,1.2-2.8,1.7-4.2l2.7,1c0.6,0.2,1.2-0.1,1.4-0.6c0.2-0.6-0.1-1.2-0.7-1.4l-2.7-1l-0.1,0.4
      c0.8-2.2,1.5-4.5,2.2-6.7l3.3,1c0.6,0.2,1.3-0.2,1.5-0.8c0.2-0.6-0.2-1.3-0.8-1.5l-3.3-1c-0.1,0.5-0.3,1-0.5,1.6c0.6-2,1.1-4,1.6-6
      c0.7-3,0,0.1,0,0.1l10.4,2.5c0.6,0,1.1-0.6,1.1-1.2c0-0.4-0.2-0.8-0.6-0.9l-10.4-2.5c0.3-1.4,0.6-2.9,0.9-4.4l24.7,4.5
      c0.6,0.1,1.3-0.3,1.4-1c0,0,0,0,0,0c0.1-0.7-0.3-1.3-1-1.4c0,0,0,0,0,0l-24.7-4.5c0.2-1.4,0.4-2.9,0.6-4.3l34.9,4.5
      c0.7,0.1,1.3-0.4,1.4-1c0.1-0.7-0.4-1.3-1-1.4c0,0,0,0,0,0l-34.9-4.6c0.2-1.4,0.3-2.8,0.4-4.2l51.1,3.9c0.7,0.1,1.3-0.5,1.4-1.2
      c0.1-0.7-0.5-1.3-1.2-1.4l-51.1-3.9c0.1-1.4,0.2-2.8,0.2-4.2l35.2,0.9c0.7,0,1.2-0.5,1.3-1.2c0-0.7-0.5-1.2-1.2-1.3c0,0,0,0,0,0
      l-35.2-0.8c0-1.5,0-2.9,0-4.3l25.1-0.7c0.7-0.1,1.1-0.7,1.1-1.3c-0.1-0.6-0.5-1-1.1-1.1l-25.2,0.7c-0.1-1.6-0.2-3.2-0.3-4.8
      c0,0.1,0,0.3,0,0.4l10.5-0.8c0.6,0,1.1-0.6,1-1.2c0-0.6-0.6-1.1-1.2-1l-10.5,0.9c-0.2-1.5-0.3-3-0.5-4.5l3.4-0.4
      c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-0.9l-3.4,0.5c-0.3-2.2-0.7-4.4-1.2-6.5l3.9-0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c-0.1-0.5-0.7-0.9-1.2-0.8l-3.9,0.8c-0.3-1.5-0.7-2.9-1.1-4.4l10.9-2.8c0.6-0.2,1-0.8,0.8-1.4c0,0,0,0,0,0c-0.1-0.6-0.8-1-1.4-0.8
      c0,0,0,0,0,0l-11,2.9c-0.4-1.4-0.8-2.8-1.3-4.3l24.5-7.8c0.6-0.2,1-0.9,0.8-1.5c0,0,0,0,0,0c-0.2-0.6-0.9-1-1.5-0.8l-24.6,7.8
      c-0.5-1.4-0.9-2.7-1.4-4.1l33.6-12.6c0.6-0.2,1-0.9,0.8-1.6c-0.2-0.6-0.9-1-1.6-0.8c0,0,0,0-0.1,0l-33.6,12.7
      c-0.5-1.3-1-2.6-1.6-3.9l47.6-20.8c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1-0.8-1.6-0.6l-47.7,20.9c-0.6-1.3-1.2-2.5-1.8-3.8l32.2-16.1
      c0.6-0.3,0.8-1,0.5-1.6c-0.3-0.6-1-0.9-1.7-0.5l-32.2,16.1c-0.5-1-1.1-2-1.6-3c-0.1-0.2-0.3-0.5-0.4-0.7l22.6-12.8
      c0.6-0.3,0.8-1.1,0.5-1.6c-0.3-0.6-1.1-0.8-1.6-0.5l-22.7,12.9c0.3,0.5,0.6,0.9,0.8,1.4c-1-1.7-2.1-3.4-3.1-5.1l9.6-6.2
      c0.5-0.3,0.7-1,0.4-1.6c-0.3-0.5-1-0.7-1.6-0.3c0,0,0,0,0,0l-9.7,6.2c-0.8-1.2-1.7-2.5-2.6-3.7l3.5-2.5c0.5-0.4,0.6-1,0.3-1.5
      c-0.4-0.5-1-0.6-1.5-0.3l-3.5,2.5l0.4,0.6c-1.5-1.9-3-3.9-4.5-5.7l3.8-3.1c0.5-0.4,0.5-1.1,0.2-1.5c0,0,0,0,0,0
      c-0.4-0.5-1.1-0.5-1.6-0.1l-3.8,3.1c-1-1.1-2-2.2-3-3.3l9-8.3c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2
      c-0.1,0-0.1,0.1-0.1,0.1l-9,8.3c-1-1.1-2.1-2.1-3.1-3.1l18.7-19.1c0.5-0.5,0.5-1.2,0-1.7c0,0,0,0,0,0c-0.5-0.5-1.2-0.5-1.7,0
      l-18.7,19.2c-1-1-2.1-1.9-3.2-2.8l24.3-27.7c0.5-0.5,0.4-1.3-0.1-1.7c-0.5-0.5-1.3-0.4-1.7,0.1l-24.3,27.7c-1.1-0.9-2.2-1.8-3.3-2.6
      l32.9-41.5c0.4-0.6,0.1-1.4-0.5-1.8c-0.5-0.3-1.1-0.2-1.5,0.2l-32.9,41.5c-1.1-0.8-2.2-1.7-3.4-2.4l21.4-30.1
      c0.4-0.6,0.3-1.3-0.3-1.7c-0.6-0.4-1.3-0.3-1.7,0.3c0,0,0,0,0,0l-21.4,30.2c-1.2-0.8-2.4-1.6-3.6-2.3l14.4-22.7
      c0.3-0.6,0-1.3-0.6-1.6c-0.5-0.2-1.1-0.1-1.4,0.3l-14.5,22.8l0.7,0.4c-2.1-1.3-4.2-2.5-6.4-3.6c0.6,0.3,1.3,0.6,1.9,1l6.1-10.8
      c0.3-0.6,0.1-1.2-0.4-1.5c-0.5-0.3-1.2-0.1-1.5,0.4c0,0,0,0,0,0l-6.1,10.9c-1.3-0.7-2.6-1.4-3.9-2l2.4-4.8c0.3-0.5,0-1.2-0.5-1.5
      c-0.5-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0l-2.4,4.8c-2-0.9-4-1.8-5.9-2.6l2.3-5.5c0.1-0.6-0.2-1.2-0.8-1.3c-0.5-0.1-0.9,0.1-1.2,0.5
      l-2.3,5.5c-1.4-0.5-2.8-1-4.2-1.5h0.1l4.4-12.6c0.2-0.6-0.1-1.2-0.7-1.4c-0.6-0.2-1.2,0.1-1.4,0.7l-4.5,12.6
      c-1.4-0.4-2.8-0.9-4.1-1.3l7.8-26.7c0.2-0.6-0.2-1.3-0.8-1.5c-0.6-0.2-1.3,0.2-1.5,0.8l-7.8,26.7c-1.4-0.4-2.7-0.7-4.1-1l8.8-36.9
      c0.2-0.7-0.2-1.3-0.8-1.5c-0.7-0.2-1.3,0.2-1.5,0.8c0,0,0,0.1,0,0.1l-8.8,36.9c-1.3-0.3-2.7-0.6-4-0.8l9.7-53.1
      c0.1-0.7-0.3-1.3-1-1.5c-0.7-0.1-1.4,0.3-1.5,1l-9.7,53.2l-4-0.6l4.9-37.7c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.4,1
      c0,0,0,0,0,0l-4.9,37.7c-1.4-0.1-2.8-0.3-4.2-0.4l2.1-27.9c0.1-0.6-0.4-1.2-1.1-1.3c0,0,0,0,0,0c-0.7-0.1-1.2,0.4-1.3,1.1
      c0,0,0,0,0,0l-2.2,28l-4.3-0.2l0.3-13.4c0-0.6-0.5-1.1-1.1-1.2c-0.6,0-1.1,0.5-1.2,1.1c0,0,0,0,0,0l-0.3,13.5c-1.5,0-2.9,0-4.4,0.1
      L90.2,97c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0l0.2,6.3c-2.1,0.1-4.3,0.3-6.4,0.5l-0.7-6.8
      c-0.1-0.6-0.6-1.1-1.2-1c-0.6,0.1-1.1,0.6-1,1.2l0.7,6.8c-1.4,0.2-2.9,0.4-4.3,0.6l-2.1-14c-0.1-0.6-0.6-1.1-1.3-1c0,0,0,0,0,0
      c-0.6,0.1-1.1,0.6-1,1.2c0,0,0,0,0,0.1l2.1,14c-1.4,0.2-2.8,0.5-4.2,0.8l-5.8-28c-0.1-0.6-0.8-1.1-1.4-0.9c-0.6,0.2-0.9,0.8-0.8,1.3
      l5.8,28c-1.3,0.3-2.7,0.6-4,1l-9.8-37.4c-0.2-0.7-0.8-1.1-1.5-0.9c0,0,0,0,0,0c-0.7,0.2-1,0.9-0.9,1.5l9.8,37.5
      c-1.3,0.4-2.6,0.8-3.9,1.2L39.6,56.9c-0.2-0.7-0.9-1-1.6-0.8c-0.7,0.2-1,0.9-0.8,1.6l16.6,52.2c-1.3,0.4-2.6,0.9-3.8,1.4L36.2,75
      c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.2-1,0.9-0.7,1.6l13.7,36.3c-1.3,0.5-2.6,1-3.8,1.6L32.3,87.5c-0.3-0.6-1-0.9-1.6-0.6
      c-0.6,0.3-0.9,1-0.6,1.6c0,0,0,0,0,0l11.5,26.3c-1.3,0.6-2.6,1.2-3.8,1.8L31.5,104c-0.3-0.6-1-0.8-1.5-0.5c-0.6,0.3-0.8,1-0.5,1.5
      l6.3,12.7c-1.3,0.7-2.5,1.3-3.8,2l0,0l-3.4-6c-0.3-0.5-1-0.7-1.5-0.4c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0l3.4,6
      c0,0,0,0,0,0c-2.2,1.3-4.3,2.6-6.3,4c0.3-0.2,0.6-0.4,0.9-0.6l-4.1-6.2c-0.3-0.5-1-0.7-1.5-0.3c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.3,1.5
      l4.1,6.2c-1.2,0.8-2.4,1.6-3.5,2.5l-8.8-11.8c-0.4-0.5-1.1-0.6-1.6-0.2c-0.5,0.4-0.6,1.1-0.2,1.6c0,0,0,0,0,0l8.8,11.8
      c-1.1,0.9-2.2,1.7-3.3,2.6l-18.6-22.4c-0.4-0.5-1.2-0.6-1.7-0.2c0,0,0,0,0,0c-0.5,0.4-0.6,1.2-0.2,1.7l18.6,22.4l0.7-0.6
      c-1.3,1.1-2.5,2.2-3.8,3.3l-26.5-28.8c-0.4-0.5-1.2-0.5-1.7-0.1c0,0,0,0,0,0c-0.5,0.5-0.5,1.2-0.1,1.8l26.5,28.8l1.1-1
      c-1.4,1.2-2.7,2.5-4,3.8l-39.4-38.6c-0.5-0.5-1.3-0.5-1.8,0c0,0,0,0,0,0c-0.5,0.5-0.5,1.3,0,1.8l39.5,38.6c0.3-0.4,0.7-0.7,1.1-1.1
      c-1.3,1.3-2.6,2.7-3.8,4l-29.3-25.8c-0.5-0.4-1.3-0.4-1.7,0.1c-0.5,0.5-0.4,1.3,0.1,1.7c0,0,0,0,0,0l29.3,25.8
      c0.2-0.2,0.4-0.5,0.6-0.7c-1.1,1.3-2.2,2.6-3.3,3.9l-22.7-18c-0.5-0.4-1.3-0.3-1.7,0.2c0,0,0,0,0,0c-0.4,0.5-0.3,1.3,0.2,1.7
      l22.7,18c-0.9,1.1-1.7,2.3-2.5,3.4l-11.7-8.3c-0.5-0.4-1.2-0.2-1.6,0.3c-0.4,0.5-0.2,1.2,0.3,1.6l11.7,8.3c-0.9,1.3-1.7,2.5-2.5,3.8
      l0.1-0.2l-6-3.8c-0.5-0.3-1.2-0.2-1.5,0.3c0,0,0,0,0,0c-0.3,0.5-0.2,1.2,0.3,1.5l6,3.8c-1.5,2.3-2.8,4.7-4.1,7.1
      c0.3-0.5,0.6-1.1,0.9-1.6l-6.6-3.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.5,1.5l6.6,3.6c-1,1.9-2,3.9-2.9,5.9
      c0.3-0.7,0.6-1.4,0.9-2l-13.3-6.4c-0.6-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0c-0.3,0.6,0,1.2,0.5,1.5c0,0,0,0,0,0l13.3,6.3
      c-0.6,1.3-1.2,2.6-1.7,3.9l-26.9-11.1c-0.6-0.2-1.3,0-1.6,0.7c-0.2,0.6,0,1.3,0.7,1.6l26.9,11.1c-0.5,1.3-1,2.6-1.5,3.9l-36.9-13
      c-0.6-0.2-1.3,0.1-1.6,0.7c0,0,0,0,0,0c-0.2,0.6,0.1,1.3,0.8,1.6l36.9,13c-0.4,1.3-0.9,2.6-1.3,3.8l-52.9-15.5
      c-0.7-0.2-1.4,0.2-1.6,0.9c0,0,0,0,0,0c-0.2,0.7,0.2,1.4,0.9,1.6l52.9,15.5c0-0.1,0-0.2,0.1-0.3c-0.4,1.4-0.8,2.9-1.2,4.3l-37.9-9.2
      c-0.7-0.1-1.3,0.3-1.5,0.9c-0.2,0.7,0.3,1.3,0.9,1.5c0,0,0,0,0,0l37.9,9.2c-0.3,1.3-0.6,2.6-0.8,3.9l-28.3-5.2
      c-0.7-0.1-1.3,0.3-1.4,1c0,0,0,0,0,0c-0.1,0.6,0.3,1.3,1,1.4c0,0,0,0,0,0l28.3,5.2c0.1-0.4,0.2-0.8,0.2-1.1
      c-0.4,2.2-0.8,4.4-1.1,6.6c0.1-0.4,0.1-0.9,0.2-1.3l-14-1.8c-0.6-0.1-1.2,0.4-1.3,1c-0.1,0.6,0.4,1.2,1,1.3c0,0,0,0,0,0l14,1.8
      c-0.2,1.4-0.3,2.9-0.5,4.3l-6.8-0.5c-0.6,0-1.1,0.4-1.2,1c0,0.6,0.4,1.1,1,1.2l6.7,0.5c-0.2,2.1-0.3,4.2-0.3,6.4l-7.2,0
      c-0.6,0-1.1,0.5-1.1,1.1v0c0,0.6,0.5,1.1,1.1,1.1l7.2,0c0,1.4,0,2.9,0,4.3l-14.3,0.7c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1
      c0,0,0,0,0,0l14.3-0.7c0.1,1.8,0.2,3.5,0.3,5.2l-0.1-1l-28.5,2.8c-0.6,0.1-1.1,0.6-1.1,1.3c0,0,0,0,0,0c0.1,0.7,0.6,1.1,1.3,1.1
      l28.5-2.8c0.1,1.5,0.3,2.9,0.5,4.4l-38.2,5.5c-0.7,0.1-1.1,0.7-1,1.4c0.1,0.7,0.7,1.2,1.4,1.1l38.2-5.5c0.3,1.9,0.6,3.8,1,5.7
      c-0.1-0.6-0.3-1.3-0.4-1.9l-53.4,11c-0.7,0.2-1.1,0.8-1,1.5c0.2,0.7,0.8,1.1,1.5,1l53.4-11c0.3,1.4,0.6,2.8,1,4.2l-37.2,9.4
      c-0.6,0.2-1,0.9-0.9,1.5c0.2,0.7,0.8,1.1,1.5,0.9l37.2-9.4c0.3,1.3,0.7,2.5,1,3.8l-26.8,8.5c-0.6,0.2-1,0.9-0.8,1.5c0,0,0,0,0,0
      c0.2,0.6,0.9,1,1.5,0.8l26.8-8.5c0.4,1.4,0.9,2.7,1.4,4.1l-12.6,4.7c-0.6,0.2-0.9,0.9-0.7,1.5c0,0,0,0,0,0c0.2,0.6,0.9,0.9,1.5,0.7
      c0,0,0,0,0,0l12.5-4.7c0.5,1.4,1,2.7,1.6,4.1l-5.6,2.4c-0.6,0.2-0.8,0.9-0.6,1.5c0.3,0.6,0.9,0.8,1.5,0.6l5.5-2.4
      c-0.2-0.5-0.4-0.9-0.6-1.4c1,2.4,2.1,4.8,3.3,7.2l-5.7,3c-0.5,0.3-0.7,0.9-0.5,1.5c0.3,0.5,1,0.7,1.5,0.5l5.7-3
      c0.7,1.3,1.3,2.6,2.1,3.9l-11.7,6.9c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0c0.3,0.5,1,0.7,1.5,0.4c0,0,0,0,0,0l11.6-6.9
      c0.7,1.2,1.5,2.5,2.2,3.7l-23.2,15.4c-0.5,0.4-0.7,1.1-0.4,1.6c0,0,0,0,0,0c0.4,0.5,1.1,0.7,1.7,0.3l23.1-15.4
      c0.8,1.2,1.6,2.3,2.4,3.5l-30.3,22.5c-0.5,0.4-0.7,1.2-0.3,1.7c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.7,0.3l30.3-22.6
      c0.8,1.1,1.6,2.2,2.5,3.3l-41.3,34.2c-0.5,0.5-0.6,1.3-0.2,1.8c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.8,0.2l41.3-34.2
      c0.9,1.1,1.7,2.2,2.6,3.2l-27.5,25.3c-0.5,0.5-0.5,1.2-0.1,1.7c0.5,0.5,1.2,0.5,1.7,0.1c0,0,0,0,0,0l27.5-25.3c1,1,1.9,2.1,2.9,3.1
      l-19,19.4c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0,0c0.5,0.5,1.2,0.5,1.7,0c0,0,0,0,0,0l19-19.4c1,1,2,2,3.1,3l-8.2,9.3
      c-0.4,0.5-0.4,1.2,0.1,1.6c0.5,0.4,1.2,0.4,1.6-0.1c0,0,0,0,0,0l8.2-9.3c1.1,1,2.2,2,3.4,2.9l-3.1,4c-0.4,0.5-0.3,1.2,0.2,1.5
      c0.5,0.4,1.2,0.3,1.6-0.2l3.1-3.9c1.7,1.3,3.4,2.7,5.2,4l-3,4.4c-0.3,0.5-0.2,1.2,0.3,1.5c0.5,0.3,1.2,0.2,1.5-0.3l3-4.4l-0.8-0.6
      c1.5,1,3,2.1,4.5,3l-6.5,10.7c-0.3,0.5-0.1,1.2,0.4,1.6c0.5,0.3,1.2,0.2,1.6-0.4c0,0,0,0,0,0l6.5-10.6c1.3,0.8,2.5,1.5,3.8,2.2
      l-12.7,23.5c-0.3,0.6-0.1,1.3,0.5,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0.1,1.6-0.5l12.7-23.5c1.3,0.7,2.5,1.4,3.8,2l-15.7,33.1
      c-0.3,0.6,0,1.3,0.6,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0,1.6-0.6l15.7-33.1c1.3,0.6,2.5,1.2,3.8,1.8l-20,48.6c-0.3,0.7,0,1.4,0.7,1.7
      c0.7,0.3,1.4,0,1.7-0.7c0,0,0,0,0,0l20-48.6c1.3,0.5,2.6,1.1,3.9,1.6l-12.2,34.3c-0.2,0.6,0.1,1.3,0.8,1.6c0.6,0.2,1.3-0.1,1.6-0.7
      c0,0,0,0,0,0l12.1-34.3l-0.8-0.3c1.8,0.7,3.6,1.3,5.5,1.8c-0.2-0.1-0.4-0.1-0.6-0.2l-7.4,25.1c-0.2,0.6,0.2,1.3,0.8,1.5c0,0,0,0,0,0
      c0.6,0.2,1.3-0.2,1.5-0.8c0,0,0,0,0,0l7.4-25.1c-0.2-0.1-0.5-0.2-0.7-0.2c2.2,0.7,4.5,1.3,6.7,1.9l-1.8-0.4l-2.6,11.1
      c-0.1,0.6,0.2,1.2,0.8,1.4c0,0,0,0,0,0c0.6,0.1,1.2-0.2,1.4-0.8c0,0,0,0,0,0l2.6-11.1c1.7,0.4,3.4,0.8,5.2,1.2l-0.8-0.2l-0.7,4
      c-0.1,0.6,0.3,1.2,0.9,1.3c0.6,0.1,1.2-0.3,1.3-0.9l0.7-4c2.2,0.4,4.3,0.8,6.5,1.1l-0.5,4.4c-0.1,0.6,0.4,1.2,1,1.2c0,0,0,0,0,0
      c0.6,0.1,1.2-0.4,1.2-1c0,0,0,0,0,0l0.5-4.3c1.5,0.2,3,0.3,4.5,0.5l-0.7,11.5c0,0.6,0.4,1.2,1.1,1.2c0.6,0,1.2-0.4,1.2-1.1
      c0,0,0,0,0,0l0.7-11.5c1.5,0.1,2.9,0.2,4.4,0.2l-0.2,25.8c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2l0.2-25.7c2,0,4,0,6-0.1
      c-0.6,0-1.1,0.1-1.7,0.1l1.6,35.7c0,0.7,0.6,1.2,1.3,1.2c0,0,0,0,0,0c0.7,0,1.2-0.6,1.2-1.3c0,0,0,0,0,0l-1.6-35.7
      c1.4-0.1,2.8-0.1,4.2-0.2l5,51.3c0.1,0.7,0.7,1.2,1.4,1.2c0.7-0.1,1.2-0.7,1.2-1.4c0,0,0,0,0,0l-5-51.3c1.4-0.1,2.8-0.3,4.2-0.5
      l5.2,35.1c0.1,0.6,0.7,1.1,1.4,1c0.7-0.1,1.1-0.7,1-1.4l-5.3-35l-1.5,0.2c1.9-0.3,3.8-0.6,5.7-0.9l5.1,24.7c0.1,0.6,0.7,1.1,1.4,0.9
      c0,0,0,0,0,0c0.6-0.1,1.1-0.8,0.9-1.4l-5.1-24.7c-0.4,0.1-0.8,0.2-1.2,0.2c1.9-0.4,3.7-0.8,5.6-1.2l2.7,10.2c0.2,0.6,0.8,1,1.4,0.8
      c0,0,0,0,0,0c0.6-0.2,1-0.8,0.8-1.4l-2.7-10.2c1.4-0.4,2.8-0.8,4.2-1.2l1.1,3.5c0.2,0.6,0.8,0.9,1.4,0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c0-0.2,0-0.4,0-0.6l-1-3c1.4-0.4,2.8-0.9,4.1-1.4l3.8,10.2c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5l-3.8-10.2
      c1.4-0.5,2.8-1.1,4.1-1.7l10.1,23.1c0.3,0.6,1,0.9,1.6,0.6c0.6-0.3,0.9-1,0.6-1.6l-10.1-23c-0.7,0.3-1.4,0.6-2.1,0.9
      c2-0.9,4-1.8,6-2.7l15.7,31.5c0.3,0.6,1,0.9,1.7,0.6s0.9-1,0.6-1.7l-15.7-31.4c1.3-0.6,2.5-1.3,3.8-2 M155.9,338
      c-59.5,33.8-135.1,13-169-46.5c-33.8-59.5-13-135.1,46.5-169c59.5-33.8,135.1-13,169,46.5C236.1,228.5,215.3,304.1,155.9,338z"
      />
      <g class="op op2 pc">
        <path
          class="st0 st1"
          d="M177.9,464.8c6,10.6,19.4,14.3,30,8.3c0,0,0,0,0,0c1.4-0.8,2.7-1.7,3.8-2.8c2.6,4,6.8,6.7,11.5,7.3l13.1,3.6
        L240,468c1.9-4.4,1.8-9.3-0.3-13.6c1.5-0.5,3-1.1,4.4-1.9c10.5-6,14.2-19.5,8.2-30c-4.2-7.3-12.1-11.5-20.4-11l-5.1-9l-13.3,7.5
        c-7.3,4.2-9.9,13.5-5.7,20.8c-4.2-7.3-13.5-9.9-20.8-5.7l-13.3,7.5l5.2,9.1C174.1,448.5,173.7,457.5,177.9,464.8z"
        />
        <path
          class="st0"
          d="M186.8,464.9c4.9,8.7,15.9,11.7,24.5,6.8c0,0,0,0,0.1,0c1.1-0.6,2.2-1.4,3.1-2.3c2.1,3.3,5.6,5.5,9.5,6
      l10.8,2.9l3-10.8c1.6-3.6,1.5-7.7-0.3-11.2c1.3-0.4,2.5-0.9,3.6-1.6c8.7-4.8,11.8-15.8,7-24.5c-3.4-6.1-10-9.7-17-9.2l-4.2-7.4
      l-10.9,6.2c-6,3.4-8.1,11-4.7,17c-3.4-6-11-8.1-17-4.8c0,0,0,0-0.1,0l-10.9,6.2l4.2,7.5C183.6,451.6,183.3,459,186.8,464.9z"
        />
        <path
          class="st0"
          d="M248.3,451.6c-11.8,6.7-26.8,2.6-33.5-9.2c-6.7-11.8-2.6-26.8,9.2-33.5s26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C264.2,429.8,260.1,444.8,248.3,451.6 M249.2,453.1c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.7,7.2-17.1,23.3-9.9,36c0,0,0,0,0,0C220.4,455.9,236.5,460.3,249.2,453.1C249.2,453.2,249.2,453.1,249.2,453.1L249.2,453.1z"
        />
        <path
          class="st0"
          d="M204.9,476.2c-11.8,6.7-26.8,2.6-33.5-9.2s-2.6-26.8,9.2-33.5c11.8-6.7,26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C220.8,454.5,216.7,469.5,204.9,476.2 M205.7,477.8c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.6,7.2-17.1,23.3-9.9,35.9C176.9,480.6,193,485,205.7,477.8C205.7,477.8,205.7,477.8,205.7,477.8z"
        />
        <path
          class="st0"
          d="M215.2,440.5l-8.2-14.4c-4.5-7.9-1.7-18,6.2-22.5l14.4-8.2l8.1,14.4c4.5,7.9,1.7,18-6.2,22.5L215.2,440.5
      M214.6,442.9l15.9-9c8.8-5,11.9-16.2,6.9-25l-9-15.9l-15.9,9c-8.8,5-11.9,16.2-6.9,24.9c0,0,0,0,0,0L214.6,442.9z"
        />
        <path
          class="st0"
          d="M197.8,450.4c-7.9,4.5-18,1.8-22.5-6.1c0,0,0,0,0,0l-8.2-14.4l14.4-8.2c7.9-4.5,18-1.7,22.5,6.2l8.2,14.3
      L197.8,450.4 M198.7,451.9l15.9-9l-9-15.9c-5-8.8-16.2-11.9-25-6.9c0,0,0,0,0,0l-15.9,9l9,15.9C178.7,453.9,189.8,457,198.7,451.9
      C198.6,452,198.7,452,198.7,451.9L198.7,451.9z"
        />
        <path
          class="st0"
          d="M238.7,485.3l-14-3.8h-0.1h-0.1c-9.6-1.2-16.4-10-15.2-19.6c0.2-1.3,0.5-2.6,0.9-3.9v-0.1
      c0.2-0.5,0.4-1.1,0.7-1.6l0,0l0,0l4.8-11.4l12.2,1.7h0.1c0.5,0,1.1,0.1,1.7,0.2h0.1c9.5,1.8,15.8,11,14,20.5
      c-0.2,1.3-0.6,2.6-1.2,3.8v0.1v0.1L238.7,485.3 M240,487.5l4.4-15.8c4.3-9.8-0.2-21.2-10-25.4c-1.3-0.6-2.7-1-4.2-1.3l0,0
      c-0.6-0.1-1.3-0.2-1.9-0.3l-13.6-1.8l-5.5,12.6c-0.3,0.6-0.5,1.2-0.8,1.8l0,0l0,0c-3.7,10,1.4,21.2,11.4,24.9c1.4,0.5,2.8,0.9,4.3,1
      L240,487.5L240,487.5z"
        />
        <path
          class="st2"
          d="M204.9,451.9c-3.5-6.2-1.4-14,4.8-17.6c6.2-3.5,14-1.4,17.6,4.8s1.4,14-4.8,17.6c0,0,0,0,0,0
      C216.2,460.2,208.4,458.1,204.9,451.9z"
        />
        <polygon
          class="st2"
          points="215.7,445.7 216.5,445.3 226.7,463.4 226,463.8 "
        />
        <path
          class="st2"
          d="M224.3,464.8c-0.6-1.1-0.2-2.6,0.9-3.2s2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c0,0,0,0,0,0
      C226.4,466.3,225,465.9,224.3,464.8z"
        />
        <polygon
          class="st2"
          points="197.8,455.4 215.9,445.1 216.3,445.9 198.2,456.2 "
        />
        <path
          class="st2"
          d="M196.8,453.8c1.1-0.6,2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c-1.1,0.6-2.6,0.2-3.2-0.9c0,0,0,0,0,0
      C195.3,455.9,195.7,454.4,196.8,453.8C196.8,453.8,196.8,453.8,196.8,453.8z"
        />
        <rect
          x="214.8"
          y="439.9"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -188.1656 168.7628)"
          class="st2"
          width="20.8"
          height="0.9"
        />
        <path
          class="st2"
          d="M233,433.2c-1.1,0.6-1.5,2.1-0.9,3.2c0.6,1.1,2.1,1.5,3.2,0.9s1.5-2.1,0.9-3.2c0,0,0,0,0,0
      C235.6,433,234.2,432.6,233,433.2z"
        />
        <path
          class="st0"
          d="M213.1,447.2c-0.9-1.7-0.4-3.8,1.3-4.7c1.7-0.9,3.8-0.4,4.7,1.3c0.9,1.7,0.4,3.8-1.3,4.7c0,0,0,0,0,0
      C216.1,449.5,214,448.9,213.1,447.2C213.1,447.3,213.1,447.2,213.1,447.2z"
        />
        <path
          class="st0"
          d="M194.1,406.8c3.5-2,8.3-1.2,12.6,2.2l-1.1,1.4c-3.6-2.9-7.7-3.6-10.6-2c-2.9,1.6-4.3,5.5-3.7,10.2l-1.8,0.2
      C188.8,413.5,190.6,408.9,194.1,406.8z"
        />
        <rect
          x="191.3"
          y="404.7"
          transform="matrix(0.2654 -0.9641 0.9641 0.2654 -250.5467 488.2404)"
          class="st0"
          width="7.7"
          height="7.7"
        />
        <path
          class="st0"
          d="M250.6,509c-2-2.1-2.8-4.6-1.8-5.5s3.4,0,5.4,2.1s2.8,4.6,1.9,5.5C255.1,512.1,252.7,511.2,250.6,509z"
        />
        <path
          class="st0"
          d="M241.8,506.5c-1.5,2.1-3.6,3.3-4.6,2.6c-1-0.7-0.6-3,1-5.2c1.6-2.1,3.6-3.3,4.6-2.6S243.4,504.4,241.8,506.5z"
        />
        <path
          class="st0"
          d="M253.1,496.9c-2.1,0.8-4.2,0.5-4.5-0.5s1.1-2.4,3.2-3.2c2.1-0.8,4.2-0.5,4.5,0.5
      C256.7,494.7,255.2,496.1,253.1,496.9z"
        />
        <rect
          x="243.7"
          y="484.7"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -212.8196 185.46)"
          class="st0"
          width="1.5"
          height="21.2"
        />
        <path
          class="st0"
          d="M240.8,487.4c6.7,11.8,2,14.7,1.8,14.8l-0.8-1.3c0,0,3.5-2.5-2.3-12.7L240.8,487.4z"
        />
        <path
          class="st0"
          d="M242.7,491.1c3.1,5.5,7.3,4.4,7.5,4.4l0.4,1.5c-0.1,0-5.5,1.4-9.2-5.1L242.7,491.1z"
        />
        <path
          class="st0"
          d="M152.6,477.5c2.8-0.8,5.3-0.3,5.7,1s-1.6,3-4.4,3.7c-2.8,0.7-5.3,0.3-5.7-1C147.9,480,149.8,478.3,152.6,477.5z
      "
        />
        <path
          class="st0"
          d="M156.2,469.2c-1.5-2.2-1.8-4.6-0.8-5.3s3.1,0.5,4.6,2.8s1.8,4.6,0.8,5.3S157.8,471.5,156.2,469.2z"
        />
        <path
          class="st0"
          d="M165.6,483.3c-1-2.1-1-4.1,0-4.6s2.6,0.8,3.6,2.9s1,4.1,0,4.6S166.6,485.4,165.6,483.3z"
        />
        <rect
          x="156.2"
          y="473.8"
          transform="matrix(0.8687 -0.4953 0.4953 0.8687 -213.4897 143.7556)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M171.2,469.9l0.8,1.3c-5.1,2.9-8.8,3.8-10.9,2.6c-0.9-0.5-1.6-1.5-1.6-2.6h1.5c0,0.6,0.4,1.1,0.9,1.3
      C162.8,473,165.2,473.3,171.2,469.9z"
        />
        <path
          class="st0"
          d="M170.1,470.5l0.8,1.3c-5.5,3.1-4.5,7.3-4.5,7.3l-1.5,0.4C164.8,479.4,163.5,474.2,170.1,470.5z"
        />
        <path
          class="st0"
          d="M275.6,407.1c-2.4,2.4-3.5,5.2-2.4,6.3c1.1,1.1,4,0.2,6.4-2.2c2.4-2.4,3.5-5.2,2.4-6.3S278,404.8,275.6,407.1z"
        />
        <path
          class="st0"
          d="M266.7,407c-1.3-2-3-3.1-3.9-2.5s-0.7,2.6,0.6,4.6s3,3.1,3.9,2.5S267.9,408.9,266.7,407z"
        />
        <path
          class="st0"
          d="M274.7,422.5c-1.2-2.5-3-4.1-4-3.6s-0.8,3,0.4,5.5s3.1,4.1,4,3.6C276.1,427.5,276,425,274.7,422.5z"
        />
        <rect
          x="259.1"
          y="415.4"
          transform="matrix(0.8691 -0.4945 0.4945 0.8691 -170.8213 186.6468)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M266.9,410.1c0.9,0.6,1.4,1.6,1.4,2.7c0,2.4-2.7,5.1-7.8,8l-0.8-1.3c6-3.4,7-5.7,7-6.7c0-0.5-0.2-1.1-0.7-1.4
      L266.9,410.1z"
        />
        <path
          class="st0"
          d="M266.5,417.2c1.8,0,3.5,0.6,4.9,1.8l-1.1,1.1c-0.1-0.1-3.1-3-8.6,0.1l-0.8-1.3
      C262.6,417.9,264.5,417.3,266.5,417.2z"
        />
      </g>
      <rect
        x="254.7"
        y="325"
        transform="matrix(0.8777 -0.4792 0.4792 0.8777 -125.6418 163.5741)"
        class="st0 op op10"
        width="6"
        height="6"
      />
      <rect
        x="277.7"
        y="330.1"
        transform="matrix(0.789 -0.6144 0.6144 0.789 -145.3814 242.6519)"
        class="st0 op op9"
        width="5.7"
        height="5.7"
      />
      <rect
        x="302.5"
        y="333.2"
        transform="matrix(0.7508 -0.6605 0.6605 0.7508 -145.8386 285.39)"
        class="st0 op op8"
        width="5.6"
        height="5.6"
      />
      <rect
        x="323.5"
        y="332.9"
        transform="matrix(0.6543 -0.7562 0.7562 0.6543 -141.0644 362.7884)"
        class="st0 op op7"
        width="5.6"
        height="5.6"
      />
      <rect
        x="395.2"
        y="313.8"
        transform="matrix(0.316 -0.9488 0.9488 0.316 -28.186 594.4554)"
        class="st0 op op5"
        width="5.9"
        height="5.9"
      />
      <rect
        x="358.4"
        y="326.7"
        transform="matrix(0.5219 -0.853 0.853 0.5219 -108.4008 465.6359)"
        class="st0 op op6"
        width="5.6"
        height="5.6"
      />
      <rect
        x="411.8"
        y="303.3"
        transform="matrix(0.2103 -0.9776 0.9776 0.2103 27.9992 647.9564)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="460.6"
        y="334.4"
        transform="matrix(4.710640e-02 -0.9989 0.9989 4.710640e-02 104.6518 785.1971)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="470.1"
        y="243.4"
        transform="matrix(0.9596 -0.2813 0.2813 0.9596 -50.2987 143.1508)"
        class="st0 op op2"
        width="6.6"
        height="6.6"
      />
      <rect
        x="480.3"
        y="219.2"
        transform="matrix(0.9117 -0.4108 0.4108 0.9117 -48.7281 218.2997)"
        class="st0 op op1"
        width="6.6"
        height="6.6"
      />
      <g class="r30">
        <path
          class="st0 op op6"
          d="M770.7,358.9c-1.6-1.5-4.1-1.4-5.7,0.2c-1.5,1.6-1.4,4.1,0.2,5.7c1.6,1.5,4.1,1.4,5.6-0.2
      C772.4,363,772.3,360.5,770.7,358.9C770.7,359,770.7,358.9,770.7,358.9z"
        />
        <path
          class="st0 op op5"
          d="M729.6,381c-3-3.8,1-5.4,1-5.4c1.1-0.3,1.9-1.3,1.9-2.4c-0.2-1.3-0.7-2.6-1.4-3.8c-2.4-3.2-5.3-6.1-8.6-8.5
      l-0.4-0.3l0.4-0.2c1.8-0.7,3.6-1.6,5.3-2.6l0,0c1.5-0.9,2.9-1.9,4.2-3c0.9-0.9,1.6-2.1,2-3.4c0,0,0.3-1.5-1.6-2.9
      c0,0-3.7-2.7-0.4-5.6c0.8-0.8,0.8-2.1,0-3c-0.1-0.1-0.2-0.2-0.4-0.3c0,0,9.5,5.1,5.7,10.5l0,0c-3.4,4.8-8.1,8.5-13.6,10.6
      c5,3.6,9.1,8.4,11.8,14c3.8,6.8-6.6,9.1-6.6,9.1c1-0.4,1.5-1.6,1.1-2.6C729.8,381.3,729.7,381.1,729.6,381z"
        />
        <path
          class="st0 op op4"
          d="M704.2,392.2c-2.8-5.8,2.5-7.9,2.5-7.9c2.1-0.8,3.7-2.5,4.3-4.7c0.6-3.1,0.4-6.4-0.6-9.4
      c-2.2-5.3-5.5-10.1-9.7-14l-0.3-0.3l0.4-0.1c5-1.7,9.6-4.7,13.2-8.6c2-2.5,3.2-5.5,3.6-8.6c0.2-2.4-0.8-4.7-2.5-6.3
      c0,0-4.3-4.4,0-8.5c1.3-1.5,1.2-3.6-0.2-5c0,0,8.7,6.6,7.2,13.7l-0.1,0.4c-1.7,10.7-9.2,19.4-20.1,23.4c9,8.5,13.3,20,11.6,30.6
      c0,0.1,0,0.3-0.1,0.4c-0.8,7.2-10.8,9.5-10.8,9.5C704.2,395.9,704.9,394,704.2,392.2z"
        />
        <path
          class="st0 op op3"
          d="M669.9,384.9c-1.2-3.5,0.6-7.4,4-8.8c2.4-0.9,4.3-2.8,5.2-5.2c1.2-3.4,1.5-7,1-10.5c-1.3-5.8-3.9-11.3-7.7-15.9
      l-0.3-0.4l0.5-0.1c5.7-1.9,10.9-5.1,15.1-9.3c2.5-2.7,4.3-6,5.2-9.5c0.6-2.5,0-5.1-1.5-7.1c-2.2-3-1.5-7.2,1.5-9.5
      c1.5-1.5,1.8-3.8,0.7-5.6c0,0,7.9,7.6,5.1,15.4l-0.1,0.5c-3.7,12-13,21.5-24.9,25.6c7.8,9.7,10.4,22.6,6.8,34.5
      c0,0.2-0.1,0.3-0.2,0.5c-2,8-12.9,10.3-12.9,10.3C669.3,389.1,670.4,387,669.9,384.9z"
        />
        <rect
          x="725.5"
          y="358.5"
          transform="matrix(0.8026 -0.5965 0.5965 0.8026 -71.8132 505.9555)"
          class="st0 op op5"
          width="6.1"
          height="6.1"
        />
        <rect
          x="703.7"
          y="354.2"
          transform="matrix(0.875 -0.4842 0.4842 0.875 -84.5816 386.8404)"
          class="st0 op op4"
          width="6.1"
          height="6.1"
        />
        <path
          class="st0 op op2"
          d="M640.4,356.5c-0.7-5.4,4.2-5.8,4.2-5.8c1.9,0,3.7-1.1,4.7-2.7c1.3-2.4,2-5.1,2-7.9c-0.3-4.9-1.8-9.7-4.2-14
      l-0.2-0.4h0.4c4.6-0.1,9-1.2,13-3.5c2.3-1.5,4.1-3.6,5.2-6.1c0.8-1.9,0.6-4-0.3-5.8c0,0-2.3-4.7,2.3-6.9c1.4-0.8,1.9-2.6,1.2-4.1
      c0,0,5.3,7.7,2.2,13.1l-0.2,0.4c-4.2,8.2-12.6,13.4-22.6,13.6c5,9.4,5.5,19.9,1.2,28.1c0,0.1-0.1,0.2-0.2,0.3
      c-2.6,5.6-11.4,4.8-11.4,4.8C639.5,359.5,640.6,358.1,640.4,356.5z"
        />
        <rect
          x="649.2"
          y="325.6"
          transform="matrix(0.9825 -0.1865 0.1865 0.9825 -49.8557 127.4308)"
          class="st0 op op2"
          width="6.1"
          height="6.1"
        />
        <rect
          x="674.6"
          y="342.5"
          transform="matrix(0.9275 -0.3738 0.3738 0.9275 -80.0531 278.3417)"
          class="st0 op op3"
          width="6.1"
          height="6.1"
        />
        <path
          class="st3 an2"
          d="M613,155.1c-41.5,80,10.9,217,155.5,207.4"
        />
      </g>
      <g>
        <line
          class="st3 an1"
          x1="656.5"
          y1="168.9"
          x2="580.6"
          y2="271.1"
        />

        <path
          class="st3 an3-1"
          d="M493.6,141.7C480.7,62.1,408.6,4.3,322.3,4.3c-10.3,0-20.6,0.8-30.7,2.5c-46.9,7.6-88.2,31.8-116.3,68 s-39.7,80.2-32.6,123.9c12.9,79.6,84.9,137.4,171.2,137.4v0c10.3,0,20.6-0.9,30.7-2.5c46.9-7.6,88.2-31.8,116.3-68S500.7,185.4,493.6,141.7"
        />
        <path
          class="st3 an6"
          d="M461,265.6c-35.1,42.9,11.4,107,59.9,85.6"
        />
      </g>
      <line
        class="st3 an6"
        x1="155.8"
        y1="340"
        x2="196.1"
        y2="410.1"
      />
    </svg>
    <svg
      v-if="isMobile"
      version="1.1"
      id="svg5-1"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 799.5 523.3"
      style="enable-background:new 0 0 799.5 523.3;"
      xml:space="preserve"
    >
      <path
        class="st0 ops op10"
        d="M248.6,351.2c4.4-3.7,0.8-7,0.8-7c-1.1-0.9-1.5-2.3-1.1-3.6c0.7-1.6,1.6-3,2.8-4.2c3.6-3.1,7.6-5.6,11.9-7.5
      l0.5-0.2l-0.4-0.3c-1.7-1.5-3.2-3.2-4.6-5l0,0c-1.2-1.6-2.3-3.4-3.2-5.2c-0.6-1.5-0.9-3.2-0.8-4.8c0.3-1.4,1.3-2.6,2.7-3.1
      c0,0,4.8-2,2.3-6.7c-0.6-1.5,0-3.2,1.5-3.9c0,0-11.6,3.1-9.6,11.1l0,0c1.6,6.8,5.3,13,10.5,17.7c-6.7,2.9-12.7,7.5-17.2,13.2
      c-6.3,7.1,3.7,13.5,3.7,13.5C246.5,353.2,248.6,351.2,248.6,351.2z"
      />
      <path
        class="st0 ops op9"
        d="M271.1,370.2c4.7-5.1-0.2-9-0.2-9c-2-1.4-3.1-3.8-3-6.3c0.3-3.5,1.6-6.8,3.6-9.7c3.9-4.9,8.9-8.8,14.5-11.6
      l0.5-0.2l-0.4-0.3c-4.7-3.4-8.5-7.9-11.1-13.1c-1.3-3.2-1.6-6.7-1-10.1c0.6-2.5,2.3-4.7,4.6-5.8c0,0,5.9-3.2,2.6-8.9
      c-0.9-1.9-0.1-4.1,1.7-5.1c0,0-11.1,4.2-11.8,12c0,0.1,0,0.3-0.1,0.5c-1.6,11.7,3.6,23.1,13.8,30.7c-12,6.1-20.1,16.7-21.6,28.4
      c0,0.2,0,0.3,0,0.5c-1.4,7.7,8.4,13.3,8.4,13.3C270,374.1,269.8,371.8,271.1,370.2z"
      />
      <path
        class="st0 ops op8"
        d="M294.6,291.4L294.6,291.4c-1.3,3.8-2,7.7-2.2,11.8c-0.4,13.1,5.9,25.5,16.7,32.9c-11.4,7.9-18.5,20.5-18.9,33.6
      c-0.1,4,0.4,7.9,1.4,11.7l0,0c1.9,7.2,10.2,11.4,10.2,11.4c-3.1-2.8-0.9-6.4-0.9-6.4c4.6-6.7-1.2-11-1.2-11
      c-4.6-3.5-4.8-7.8-4.8-7.8c-0.6-6.4,0.5-12.8,3-18.7c3.2-5,7.5-9.3,12.4-12.7l0.4-0.3l-0.4-0.3c-4.7-3.1-8.7-7.3-11.6-12.2
      c-2.2-5.9-2.8-12.3-1.8-18.6c0,0,0.5-4.3,5.3-8c0,0,6.1-4.5,1.9-11.1c-1.1-2.2-0.6-4.9,1.3-6.5C305.6,279.5,297,284.1,294.6,291.4z"
      />
      <path
        class="st0 ops op7"
        d="M310.9,308L310.9,308c0,0.4,0,0.8,0,1.2c0.8,11.1,8.1,20.8,19.7,26c-10.7,7.7-16.5,18.9-15.8,30
      c0,0.4,0.1,0.8,0.1,1.2l0,0c0,0.2,0,0.4,0.1,0.6l0,0c1.3,6.5,10.8,9.5,10.8,9.5c-1.7-0.9-2.3-3-1.4-4.7c0,0,0-0.1,0-0.1
      c3.6-5.6-2-8.3-2-8.3c-2.2-0.9-3.8-2.9-4.2-5.3c-0.4-3.4,0.3-6.9,1.8-9.9c0.2-0.3,0.4-0.7,0.6-1c0.2-0.3,0.4-0.6,0.6-0.9l-0.1,0.1
      c2.8-4.3,6.5-8,10.7-11l0.4-0.3l-0.4-0.2c-4.6-2-8.7-4.8-12.1-8.5l0.1,0.1l-0.7-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-2-2.7-3.1-6-3.2-9.4
      c0.1-2.5,1.3-4.8,3.4-6.1c0,0,5.2-4,0.8-8.6c-1.2-1.6-0.9-3.8,0.7-5c0,0,0,0,0,0C320.1,295.7,310.8,301.1,310.9,308z"
      />
      <g class="ops op3">
        <path
          class="st0 st1"
          d="M515,279.4c-8.1,11-5.7,26.4,5.3,34.5c1.4,1,3,1.9,4.6,2.7c-2.9,4.5-3.7,10-2.1,15.1l2.4,15.1l15.1-2.3
        c5.3-0.1,10.4-2.5,13.8-6.6c1.2,1.4,2.5,2.6,4,3.6c11,8.1,26.4,5.7,34.5-5.3c5.6-7.6,6.3-17.7,1.9-26.1l6.9-9.4l-13.8-10.1
        c-7.6-5.6-18.3-4-23.9,3.6c0,0,0,0,0,0.1c5.6-7.6,4-18.3-3.7-23.9l-13.8-10.1l-6.9,9.5C530,268,520.6,271.8,515,279.4z"
        />
        <path
          class="st0"
          d="M519,288.6c-6.6,9-4.7,21.7,4.3,28.3c0,0,0,0,0,0c1.2,0.9,2.4,1.6,3.8,2.2c-2.4,3.7-3,8.2-1.8,12.4l1.9,12.4
      l12.4-1.9c4.4,0,8.5-2,11.3-5.4c7.4,8.4,20.1,9.3,28.6,1.9c7-6.2,8.9-16.4,4.6-24.6l5.7-7.7l-11.3-8.3c-6.2-4.6-15-3.3-19.6,2.9
      c0,0,0,0,0,0.1c4.6-6.2,3.3-15-2.9-19.6c0,0,0,0-0.1,0l-11.3-8.3l-5.7,7.8C531.2,279.2,523.5,282.3,519,288.6z"
        />
        <path
          class="st0"
          d="M560.9,345.5c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.6c0,0,0,0,0,0
      C590.4,351.8,573.1,354.5,560.9,345.5 M559.7,347.1c13.2,9.7,31.7,6.8,41.4-6.3c9.7-13.2,6.8-31.7-6.3-41.4
      c-13.2-9.7-31.7-6.8-41.4,6.3c0,0,0,0,0,0C543.7,318.9,546.5,337.4,559.7,347.1C559.7,347.1,559.7,347.1,559.7,347.1z"
        />
        <path
          class="st0"
          d="M515.7,312.2c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.5
      C545.2,318.6,527.9,321.2,515.7,312.2 M514.5,313.9c13.2,9.7,31.7,6.8,41.4-6.3s6.8-31.7-6.3-41.4c-13.2-9.7-31.7-6.8-41.4,6.3
      c0,0,0,0,0,0C498.5,285.7,501.3,304.2,514.5,313.9z"
        />
        <path
          class="st0"
          d="M557.1,306.5l11-14.9c6.1-8.2,17.6-10,25.9-4l14.9,11l-11,14.9c-6,8.2-17.6,10-25.9,4L557.1,306.5 M554.2,306.9
      l16.5,12.1c9.1,6.7,22,4.7,28.7-4.4l12.1-16.5L595.1,286c-9.1-6.7-22-4.7-28.7,4.4c0,0,0,0,0,0L554.2,306.9L554.2,306.9z"
        />
        <path
          class="st0"
          d="M538.9,293.2c-8.3-6-10-17.6-4-25.9l11-14.9l14.9,11c8.2,6,10,17.6,4,25.9l-11,14.9L538.9,293.2 M537.8,294.9
      l16.5,12.1l12.1-16.5c6.7-9.1,4.7-22-4.4-28.7c0,0,0,0,0,0l-16.5-12.1l-12.1,16.5C526.6,275.2,528.5,288.1,537.8,294.9
      C537.7,294.8,537.7,294.8,537.8,294.9L537.8,294.9z"
        />
        <path
          class="st0"
          d="M521.9,351.1l-2.5-16.1v-0.1v-0.1c-3.2-10.4,2.6-21.4,13-24.6c1.4-0.4,2.9-0.7,4.4-0.8h0.1c0.6,0,1.3,0,1.9,0
      h0.1l13.9-0.3l3.9,13.3v0l0,0c0.2,0.6,0.4,1.2,0.5,1.8v0.1c2.5,10.6-4,21.2-14.5,23.7c-1.4,0.3-2.9,0.5-4.4,0.5h0h-0.1L521.9,351.1
      M520.2,353.3l18.2-2.7c12-0.1,21.6-9.9,21.4-21.9c0-1.6-0.2-3.2-0.6-4.8l0,0v-0.1c-0.2-0.7-0.4-1.4-0.6-2.1l-4.4-14.8l-15.4,0.3
      c-0.7,0-1.5,0-2.2,0l0,0c-12,0.8-21,11.2-20.2,23.1c0.1,1.7,0.4,3.3,0.9,4.9L520.2,353.3z"
        />
        <rect
          x="533.7"
          y="318.6"
          transform="matrix(0.591 -0.8067 0.8067 0.591 -34.3336 570.4008)"
          class="st2"
          width="23.3"
          height="1"
        />
        <path
          class="st2"
          d="M536.3,326.9c0.9-1.2,2.5-1.4,3.7-0.6s1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6
      C535.7,329.8,535.5,328.1,536.3,326.9C536.3,327,536.3,327,536.3,326.9z"
        />
        <rect
          x="542.4"
          y="291.1"
          transform="matrix(0.5917 -0.8061 0.8061 0.5917 -22.4438 561.2402)"
          class="st2"
          width="1"
          height="23.3"
        />
        <path
          class="st2"
          d="M535,293.8c1.2,0.9,1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6c-1.2-0.9-1.4-2.5-0.6-3.7c0,0,0,0,0,0
      C532.2,293.2,533.8,292.9,535,293.8C535,293.8,535,293.8,535,293.8z"
        />
        <polygon
          class="st2"
          points="552.6,309.3 571.4,323.1 570.8,323.9 552,310.1 "
        />
        <path
          class="st2"
          d="M572.7,321.4c-1.2-0.9-2.8-0.6-3.7,0.6c-0.9,1.2-0.6,2.8,0.6,3.7s2.8,0.6,3.7-0.6c0,0,0,0,0,0
      C574.1,323.9,573.9,322.3,572.7,321.4C572.7,321.4,572.7,321.4,572.7,321.4z"
        />
        <path
          class="st2"
          d="M540.6,301.1c4.7-6.4,13.8-7.8,20.2-3.1c6.4,4.7,7.8,13.8,3.1,20.2c-4.7,6.4-13.8,7.8-20.2,3.1
      C537.3,316.6,535.9,307.6,540.6,301.1C540.6,301.1,540.6,301.1,540.6,301.1z"
        />
        <path
          class="st0"
          d="M549.2,307.4c1.3-1.7,3.7-2.1,5.4-0.8c1.7,1.3,2.1,3.7,0.8,5.4c-1.3,1.7-3.7,2.1-5.4,0.8
      C548.3,311.5,547.9,309.1,549.2,307.4z"
        />
        <path
          class="st0"
          d="M581.8,269.5c3.7,2.7,5,8,3.5,13.9l-1.9-0.5c1.3-5,0.2-9.5-2.8-11.8s-7.6-1.9-12.1,0.9l-1.1-1.7
      C572.6,267.1,578.1,266.8,581.8,269.5z"
        />
        <rect
          x="576.2"
          y="267"
          transform="matrix(0.9885 -0.1511 0.1511 0.9885 -34.3258 90.823)"
          class="st0"
          width="8.6"
          height="8.6"
        />
      </g>
      <rect
        x="612.2"
        y="220.5"
        transform="matrix(0.9885 -0.1511 0.1511 0.9885 -26.8084 95.6392)"
        class="st0 ops op1"
        width="7.5"
        height="7.5"
      />
      <path
        class="st0 ops op3"
        d="M456.8,265.7c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C459.2,269.9,457,268.1,456.8,265.7C456.8,265.7,456.8,265.7,456.8,265.7z"
      />
      <path
        class="st0 op op11"
        d="M196.6,297.6c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C199.1,301.7,196.9,300,196.6,297.6z"
      />
      <path
        class="st0 op op3"
        d="M516.9,352.8c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C519.3,357,517.2,355.2,516.9,352.8C516.9,352.8,516.9,352.8,516.9,352.8z"
      />
      <path
        class="st0 op op2"
        d="M596.9,244.4c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C599.4,248.5,597.2,246.8,596.9,244.4C596.9,244.4,596.9,244.4,596.9,244.4z"
      />
      <path
        class="st0 op op5"
        d="M405.1,342.7c1.5-6.2-3.9-6.3-3.9-6.3c-1.5,0.1-2.8-0.8-3.4-2.2c-0.4-1.8-0.5-3.6-0.3-5.4
      c1.2-5.1,3.1-10.1,5.7-14.6l0.3-0.5h-0.5c-2.5-0.1-4.9-0.4-7.3-0.9l0,0c-2.1-0.5-4.2-1.1-6.3-2c-1.6-0.8-2.9-2-3.9-3.4
      c0,0-1.2-1.8,0.3-4.4c0,0,3-5.1-2.3-7.2c-1.5-0.7-2.1-2.5-1.4-4c0-0.1,0.1-0.2,0.2-0.3c0,0-8.3,10.8-1.3,16l0,0
      c6.1,4.5,13.4,7.1,20.9,7.3c-4,7.1-6.3,15-6.6,23.1c-0.9,10.3,12.2,8.6,12.2,8.6C404.5,345.9,405.1,342.7,405.1,342.7z"
      />
      <path
        class="st0 op op2"
        d="M489.7,260.5c-1.4-5.1-5.3-2.6-5.3-2.6c-2.1,1.2-3.3,0.1-3.3,0.1c-1-1.1-1.8-2.3-2.4-3.7
      c-1.2-4.2-1.8-8.7-1.7-13.1v-0.5l-0.4,0.2c-1.8,1.1-3.7,2.1-5.7,2.9l0,0c-1.7,0.7-3.5,1.2-5.4,1.6c-1.4,0.2-2.9,0-4.2-0.5
      c0,0-1.5-0.7-1.5-3.3c0,0,0.1-5-4.6-4c0,0-2.4,0.4-2.6-2.5c0,0-1.8,11.6,5.4,11.9l0,0c6.4,0.3,12.8-1.5,18.2-4.9
      c-0.2,6.8,1.4,13.5,4.4,19.5c3.5,7.7,12.3,0.2,12.3,0.2C490.5,263,489.7,260.5,489.7,260.5z"
      />
      <path
        class="st0 op op1"
        d="M516.1,245.8c-3.2-6.2-8.5-3-8.5-3c-2.1,1.4-4.7,1.6-6.9,0.6c-3.1-1.5-5.8-3.9-7.8-6.7
      c-3.2-5.4-5.2-11.4-5.8-17.6l-0.1-0.5l-0.4,0.3c-4.8,3.3-10.4,5.2-16.1,5.8c-3.5,0.1-6.9-0.8-9.8-2.6c-2.2-1.4-3.6-3.7-3.8-6.3
      c0,0-1-6.6-7.4-5.6c-2.1,0.1-3.9-1.3-4.2-3.4c0,0,0.1,11.9,7.2,15.2l0.4,0.2c10.4,5.6,22.9,4.7,33.5-2.1c1.5,13.4,8.7,24.7,19,30.2
      l0.4,0.2c6.8,4,15.4-3.2,15.4-3.2C519.3,248.2,517.1,247.5,516.1,245.8z"
      />
      <path
        class="st0 op op1"
        d="M157.8,340.4c1.2-0.7,2.4-1.5,3.6-2.2l18.9,29.5c0.4,0.6,1.1,0.7,1.7,0.4c0.6-0.4,0.7-1.1,0.4-1.7c0,0,0,0,0,0
      l-18.8-29.4c1.2-0.8,2.4-1.6,3.6-2.4l14.4,20.2c0.4,0.5,1.1,0.7,1.6,0.3c0,0,0,0,0,0c0.5-0.4,0.7-1.1,0.3-1.7l-14.5-20.1
      c1.2-0.9,2.4-1.8,3.6-2.7l6.3,7.9c0.4,0.5,1.2,0.5,1.6,0c0.4-0.4,0.5-1,0.2-1.5l-6.3-7.9c1.2-1,2.4-1.9,3.5-2.9l1.9,2.1
      c0.4,0.5,1.1,0.5,1.6,0.1c0.5-0.4,0.5-1.1,0.1-1.6l-1.9-2.1c1.7-1.5,3.3-3,4.8-4.6l2.3,2.3c0.4,0.4,1.1,0.4,1.6,0c0,0,0,0,0,0
      c0.4-0.4,0.4-1.1,0-1.6l-2.3-2.3c1.1-1.1,2.1-2.2,3.1-3.3l7.9,7c0.5,0.4,1.2,0.3,1.6-0.2c0.3-0.5,0.3-1.1-0.1-1.5l-7.9-7l-1.3,1.4
      c1.5-1.7,2.9-3.4,4.3-5.1c-0.1,0.1-0.2,0.2-0.2,0.3l19.5,15.5c0.6,0.4,1.3,0.2,1.7-0.3c0.3-0.5,0.2-1.1-0.2-1.5l-19.5-15.4
      c0.9-1.1,1.8-2.3,2.6-3.5l28.5,20.2c0.6,0.4,1.3,0.2,1.7-0.3c0.4-0.5,0.2-1.3-0.3-1.7l-28.4-20.2c0.8-1.2,1.6-2.4,2.3-3.6l1.4-2.2
      c0.8-1.2,1.5-2.4,2.2-3.7l30.3,17.1c0.6,0.3,1.3,0.1,1.7-0.5c0.3-0.6,0.1-1.3-0.5-1.7l-30.3-17c0.7-1.3,1.4-2.6,2-3.9l22.1,11
      c0.6,0.3,1.3,0.1,1.6-0.5c0,0,0,0,0,0c0.3-0.6,0-1.3-0.5-1.6l-22.1-11c0.6-1.3,1.3-2.7,1.9-4l9.3,4c0.6,0.2,1.2-0.1,1.5-0.7
      c0.2-0.5,0-1.1-0.6-1.4l-9.3-4c0.6-1.4,1.2-2.8,1.7-4.2l2.7,1c0.6,0.2,1.2-0.1,1.4-0.6c0.2-0.6-0.1-1.2-0.7-1.4l-2.7-1l-0.1,0.4
      c0.8-2.2,1.5-4.5,2.2-6.7l3.3,1c0.6,0.2,1.3-0.2,1.5-0.8c0.2-0.6-0.2-1.3-0.8-1.5l-3.3-1c-0.1,0.5-0.3,1-0.5,1.6c0.6-2,1.1-4,1.6-6
      c0.7-3,0,0.1,0,0.1l10.4,2.5c0.6,0,1.1-0.6,1.1-1.2c0-0.4-0.2-0.8-0.6-0.9l-10.4-2.5c0.3-1.4,0.6-2.9,0.9-4.4l24.7,4.5
      c0.6,0.1,1.3-0.3,1.4-1c0,0,0,0,0,0c0.1-0.7-0.3-1.3-1-1.4c0,0,0,0,0,0l-24.7-4.5c0.2-1.4,0.4-2.9,0.6-4.3l34.9,4.5
      c0.7,0.1,1.3-0.4,1.4-1c0.1-0.7-0.4-1.3-1-1.4c0,0,0,0,0,0l-34.9-4.6c0.2-1.4,0.3-2.8,0.4-4.2l51.1,3.9c0.7,0.1,1.3-0.5,1.4-1.2
      c0.1-0.7-0.5-1.3-1.2-1.4l-51.1-3.9c0.1-1.4,0.2-2.8,0.2-4.2l35.2,0.9c0.7,0,1.2-0.5,1.3-1.2c0-0.7-0.5-1.2-1.2-1.3c0,0,0,0,0,0
      l-35.2-0.8c0-1.5,0-2.9,0-4.3l25.1-0.7c0.7-0.1,1.1-0.7,1.1-1.3c-0.1-0.6-0.5-1-1.1-1.1l-25.2,0.7c-0.1-1.6-0.2-3.2-0.3-4.8
      c0,0.1,0,0.3,0,0.4l10.5-0.8c0.6,0,1.1-0.6,1-1.2c0-0.6-0.6-1.1-1.2-1l-10.5,0.9c-0.2-1.5-0.3-3-0.5-4.5l3.4-0.4
      c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-0.9l-3.4,0.5c-0.3-2.2-0.7-4.4-1.2-6.5l3.9-0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c-0.1-0.5-0.7-0.9-1.2-0.8l-3.9,0.8c-0.3-1.5-0.7-2.9-1.1-4.4l10.9-2.8c0.6-0.2,1-0.8,0.8-1.4c0,0,0,0,0,0c-0.1-0.6-0.8-1-1.4-0.8
      c0,0,0,0,0,0l-11,2.9c-0.4-1.4-0.8-2.8-1.3-4.3l24.5-7.8c0.6-0.2,1-0.9,0.8-1.5c0,0,0,0,0,0c-0.2-0.6-0.9-1-1.5-0.8l-24.6,7.8
      c-0.5-1.4-0.9-2.7-1.4-4.1l33.6-12.6c0.6-0.2,1-0.9,0.8-1.6c-0.2-0.6-0.9-1-1.6-0.8c0,0,0,0-0.1,0l-33.6,12.7
      c-0.5-1.3-1-2.6-1.6-3.9l47.6-20.8c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1-0.8-1.6-0.6l-47.7,20.9c-0.6-1.3-1.2-2.5-1.8-3.8l32.2-16.1
      c0.6-0.3,0.8-1,0.5-1.6c-0.3-0.6-1-0.9-1.7-0.5l-32.2,16.1c-0.5-1-1.1-2-1.6-3c-0.1-0.2-0.3-0.5-0.4-0.7l22.6-12.8
      c0.6-0.3,0.8-1.1,0.5-1.6c-0.3-0.6-1.1-0.8-1.6-0.5l-22.7,12.9c0.3,0.5,0.6,0.9,0.8,1.4c-1-1.7-2.1-3.4-3.1-5.1l9.6-6.2
      c0.5-0.3,0.7-1,0.4-1.6c-0.3-0.5-1-0.7-1.6-0.3c0,0,0,0,0,0l-9.7,6.2c-0.8-1.2-1.7-2.5-2.6-3.7l3.5-2.5c0.5-0.4,0.6-1,0.3-1.5
      c-0.4-0.5-1-0.6-1.5-0.3l-3.5,2.5l0.4,0.6c-1.5-1.9-3-3.9-4.5-5.7l3.8-3.1c0.5-0.4,0.5-1.1,0.2-1.5c0,0,0,0,0,0
      c-0.4-0.5-1.1-0.5-1.6-0.1l-3.8,3.1c-1-1.1-2-2.2-3-3.3l9-8.3c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2
      c-0.1,0-0.1,0.1-0.1,0.1l-9,8.3c-1-1.1-2.1-2.1-3.1-3.1l18.7-19.1c0.5-0.5,0.5-1.2,0-1.7c0,0,0,0,0,0c-0.5-0.5-1.2-0.5-1.7,0
      l-18.7,19.2c-1-1-2.1-1.9-3.2-2.8l24.3-27.7c0.5-0.5,0.4-1.3-0.1-1.7c-0.5-0.5-1.3-0.4-1.7,0.1l-24.3,27.7c-1.1-0.9-2.2-1.8-3.3-2.6
      l32.9-41.5c0.4-0.6,0.1-1.4-0.5-1.8c-0.5-0.3-1.1-0.2-1.5,0.2l-32.9,41.5c-1.1-0.8-2.2-1.7-3.4-2.4l21.4-30.1
      c0.4-0.6,0.3-1.3-0.3-1.7c-0.6-0.4-1.3-0.3-1.7,0.3c0,0,0,0,0,0l-21.4,30.2c-1.2-0.8-2.4-1.6-3.6-2.3l14.4-22.7
      c0.3-0.6,0-1.3-0.6-1.6c-0.5-0.2-1.1-0.1-1.4,0.3l-14.5,22.8l0.7,0.4c-2.1-1.3-4.2-2.5-6.4-3.6c0.6,0.3,1.3,0.6,1.9,1l6.1-10.8
      c0.3-0.6,0.1-1.2-0.4-1.5c-0.5-0.3-1.2-0.1-1.5,0.4c0,0,0,0,0,0l-6.1,10.9c-1.3-0.7-2.6-1.4-3.9-2l2.4-4.8c0.3-0.5,0-1.2-0.5-1.5
      c-0.5-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0l-2.4,4.8c-2-0.9-4-1.8-5.9-2.6l2.3-5.5c0.1-0.6-0.2-1.2-0.8-1.3c-0.5-0.1-0.9,0.1-1.2,0.5
      l-2.3,5.5c-1.4-0.5-2.8-1-4.2-1.5h0.1l4.4-12.6c0.2-0.6-0.1-1.2-0.7-1.4c-0.6-0.2-1.2,0.1-1.4,0.7l-4.5,12.6
      c-1.4-0.4-2.8-0.9-4.1-1.3l7.8-26.7c0.2-0.6-0.2-1.3-0.8-1.5c-0.6-0.2-1.3,0.2-1.5,0.8l-7.8,26.7c-1.4-0.4-2.7-0.7-4.1-1l8.8-36.9
      c0.2-0.7-0.2-1.3-0.8-1.5c-0.7-0.2-1.3,0.2-1.5,0.8c0,0,0,0.1,0,0.1l-8.8,36.9c-1.3-0.3-2.7-0.6-4-0.8l9.7-53.1
      c0.1-0.7-0.3-1.3-1-1.5c-0.7-0.1-1.4,0.3-1.5,1l-9.7,53.2l-4-0.6l4.9-37.7c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.4,1
      c0,0,0,0,0,0l-4.9,37.7c-1.4-0.1-2.8-0.3-4.2-0.4l2.1-27.9c0.1-0.6-0.4-1.2-1.1-1.3c0,0,0,0,0,0c-0.7-0.1-1.2,0.4-1.3,1.1
      c0,0,0,0,0,0l-2.2,28l-4.3-0.2l0.3-13.4c0-0.6-0.5-1.1-1.1-1.2c-0.6,0-1.1,0.5-1.2,1.1c0,0,0,0,0,0l-0.3,13.5c-1.5,0-2.9,0-4.4,0.1
      L90.2,97c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0l0.2,6.3c-2.1,0.1-4.3,0.3-6.4,0.5l-0.7-6.8
      c-0.1-0.6-0.6-1.1-1.2-1c-0.6,0.1-1.1,0.6-1,1.2l0.7,6.8c-1.4,0.2-2.9,0.4-4.3,0.6l-2.1-14c-0.1-0.6-0.6-1.1-1.3-1c0,0,0,0,0,0
      c-0.6,0.1-1.1,0.6-1,1.2c0,0,0,0,0,0.1l2.1,14c-1.4,0.2-2.8,0.5-4.2,0.8l-5.8-28c-0.1-0.6-0.8-1.1-1.4-0.9c-0.6,0.2-0.9,0.8-0.8,1.3
      l5.8,28c-1.3,0.3-2.7,0.6-4,1l-9.8-37.4c-0.2-0.7-0.8-1.1-1.5-0.9c0,0,0,0,0,0c-0.7,0.2-1,0.9-0.9,1.5l9.8,37.5
      c-1.3,0.4-2.6,0.8-3.9,1.2L39.6,56.9c-0.2-0.7-0.9-1-1.6-0.8c-0.7,0.2-1,0.9-0.8,1.6l16.6,52.2c-1.3,0.4-2.6,0.9-3.8,1.4L36.2,75
      c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.2-1,0.9-0.7,1.6l13.7,36.3c-1.3,0.5-2.6,1-3.8,1.6L32.3,87.5c-0.3-0.6-1-0.9-1.6-0.6
      c-0.6,0.3-0.9,1-0.6,1.6c0,0,0,0,0,0l11.5,26.3c-1.3,0.6-2.6,1.2-3.8,1.8L31.5,104c-0.3-0.6-1-0.8-1.5-0.5c-0.6,0.3-0.8,1-0.5,1.5
      l6.3,12.7c-1.3,0.7-2.5,1.3-3.8,2l0,0l-3.4-6c-0.3-0.5-1-0.7-1.5-0.4c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0l3.4,6
      c0,0,0,0,0,0c-2.2,1.3-4.3,2.6-6.3,4c0.3-0.2,0.6-0.4,0.9-0.6l-4.1-6.2c-0.3-0.5-1-0.7-1.5-0.3c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.3,1.5
      l4.1,6.2c-1.2,0.8-2.4,1.6-3.5,2.5l-8.8-11.8c-0.4-0.5-1.1-0.6-1.6-0.2c-0.5,0.4-0.6,1.1-0.2,1.6c0,0,0,0,0,0l8.8,11.8
      c-1.1,0.9-2.2,1.7-3.3,2.6l-18.6-22.4c-0.4-0.5-1.2-0.6-1.7-0.2c0,0,0,0,0,0c-0.5,0.4-0.6,1.2-0.2,1.7l18.6,22.4l0.7-0.6
      c-1.3,1.1-2.5,2.2-3.8,3.3l-26.5-28.8c-0.4-0.5-1.2-0.5-1.7-0.1c0,0,0,0,0,0c-0.5,0.5-0.5,1.2-0.1,1.8l26.5,28.8l1.1-1
      c-1.4,1.2-2.7,2.5-4,3.8l-39.4-38.6c-0.5-0.5-1.3-0.5-1.8,0c0,0,0,0,0,0c-0.5,0.5-0.5,1.3,0,1.8l39.5,38.6c0.3-0.4,0.7-0.7,1.1-1.1
      c-1.3,1.3-2.6,2.7-3.8,4l-29.3-25.8c-0.5-0.4-1.3-0.4-1.7,0.1c-0.5,0.5-0.4,1.3,0.1,1.7c0,0,0,0,0,0l29.3,25.8
      c0.2-0.2,0.4-0.5,0.6-0.7c-1.1,1.3-2.2,2.6-3.3,3.9l-22.7-18c-0.5-0.4-1.3-0.3-1.7,0.2c0,0,0,0,0,0c-0.4,0.5-0.3,1.3,0.2,1.7
      l22.7,18c-0.9,1.1-1.7,2.3-2.5,3.4l-11.7-8.3c-0.5-0.4-1.2-0.2-1.6,0.3c-0.4,0.5-0.2,1.2,0.3,1.6l11.7,8.3c-0.9,1.3-1.7,2.5-2.5,3.8
      l0.1-0.2l-6-3.8c-0.5-0.3-1.2-0.2-1.5,0.3c0,0,0,0,0,0c-0.3,0.5-0.2,1.2,0.3,1.5l6,3.8c-1.5,2.3-2.8,4.7-4.1,7.1
      c0.3-0.5,0.6-1.1,0.9-1.6l-6.6-3.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.5,1.5l6.6,3.6c-1,1.9-2,3.9-2.9,5.9
      c0.3-0.7,0.6-1.4,0.9-2l-13.3-6.4c-0.6-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0c-0.3,0.6,0,1.2,0.5,1.5c0,0,0,0,0,0l13.3,6.3
      c-0.6,1.3-1.2,2.6-1.7,3.9l-26.9-11.1c-0.6-0.2-1.3,0-1.6,0.7c-0.2,0.6,0,1.3,0.7,1.6l26.9,11.1c-0.5,1.3-1,2.6-1.5,3.9l-36.9-13
      c-0.6-0.2-1.3,0.1-1.6,0.7c0,0,0,0,0,0c-0.2,0.6,0.1,1.3,0.8,1.6l36.9,13c-0.4,1.3-0.9,2.6-1.3,3.8l-52.9-15.5
      c-0.7-0.2-1.4,0.2-1.6,0.9c0,0,0,0,0,0c-0.2,0.7,0.2,1.4,0.9,1.6l52.9,15.5c0-0.1,0-0.2,0.1-0.3c-0.4,1.4-0.8,2.9-1.2,4.3l-37.9-9.2
      c-0.7-0.1-1.3,0.3-1.5,0.9c-0.2,0.7,0.3,1.3,0.9,1.5c0,0,0,0,0,0l37.9,9.2c-0.3,1.3-0.6,2.6-0.8,3.9l-28.3-5.2
      c-0.7-0.1-1.3,0.3-1.4,1c0,0,0,0,0,0c-0.1,0.6,0.3,1.3,1,1.4c0,0,0,0,0,0l28.3,5.2c0.1-0.4,0.2-0.8,0.2-1.1
      c-0.4,2.2-0.8,4.4-1.1,6.6c0.1-0.4,0.1-0.9,0.2-1.3l-14-1.8c-0.6-0.1-1.2,0.4-1.3,1c-0.1,0.6,0.4,1.2,1,1.3c0,0,0,0,0,0l14,1.8
      c-0.2,1.4-0.3,2.9-0.5,4.3l-6.8-0.5c-0.6,0-1.1,0.4-1.2,1c0,0.6,0.4,1.1,1,1.2l6.7,0.5c-0.2,2.1-0.3,4.2-0.3,6.4l-7.2,0
      c-0.6,0-1.1,0.5-1.1,1.1v0c0,0.6,0.5,1.1,1.1,1.1l7.2,0c0,1.4,0,2.9,0,4.3l-14.3,0.7c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1
      c0,0,0,0,0,0l14.3-0.7c0.1,1.8,0.2,3.5,0.3,5.2l-0.1-1l-28.5,2.8c-0.6,0.1-1.1,0.6-1.1,1.3c0,0,0,0,0,0c0.1,0.7,0.6,1.1,1.3,1.1
      l28.5-2.8c0.1,1.5,0.3,2.9,0.5,4.4l-38.2,5.5c-0.7,0.1-1.1,0.7-1,1.4c0.1,0.7,0.7,1.2,1.4,1.1l38.2-5.5c0.3,1.9,0.6,3.8,1,5.7
      c-0.1-0.6-0.3-1.3-0.4-1.9l-53.4,11c-0.7,0.2-1.1,0.8-1,1.5c0.2,0.7,0.8,1.1,1.5,1l53.4-11c0.3,1.4,0.6,2.8,1,4.2l-37.2,9.4
      c-0.6,0.2-1,0.9-0.9,1.5c0.2,0.7,0.8,1.1,1.5,0.9l37.2-9.4c0.3,1.3,0.7,2.5,1,3.8l-26.8,8.5c-0.6,0.2-1,0.9-0.8,1.5c0,0,0,0,0,0
      c0.2,0.6,0.9,1,1.5,0.8l26.8-8.5c0.4,1.4,0.9,2.7,1.4,4.1l-12.6,4.7c-0.6,0.2-0.9,0.9-0.7,1.5c0,0,0,0,0,0c0.2,0.6,0.9,0.9,1.5,0.7
      c0,0,0,0,0,0l12.5-4.7c0.5,1.4,1,2.7,1.6,4.1l-5.6,2.4c-0.6,0.2-0.8,0.9-0.6,1.5c0.3,0.6,0.9,0.8,1.5,0.6l5.5-2.4
      c-0.2-0.5-0.4-0.9-0.6-1.4c1,2.4,2.1,4.8,3.3,7.2l-5.7,3c-0.5,0.3-0.7,0.9-0.5,1.5c0.3,0.5,1,0.7,1.5,0.5l5.7-3
      c0.7,1.3,1.3,2.6,2.1,3.9l-11.7,6.9c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0c0.3,0.5,1,0.7,1.5,0.4c0,0,0,0,0,0l11.6-6.9
      c0.7,1.2,1.5,2.5,2.2,3.7l-23.2,15.4c-0.5,0.4-0.7,1.1-0.4,1.6c0,0,0,0,0,0c0.4,0.5,1.1,0.7,1.7,0.3l23.1-15.4
      c0.8,1.2,1.6,2.3,2.4,3.5l-30.3,22.5c-0.5,0.4-0.7,1.2-0.3,1.7c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.7,0.3l30.3-22.6
      c0.8,1.1,1.6,2.2,2.5,3.3l-41.3,34.2c-0.5,0.5-0.6,1.3-0.2,1.8c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.8,0.2l41.3-34.2
      c0.9,1.1,1.7,2.2,2.6,3.2l-27.5,25.3c-0.5,0.5-0.5,1.2-0.1,1.7c0.5,0.5,1.2,0.5,1.7,0.1c0,0,0,0,0,0l27.5-25.3c1,1,1.9,2.1,2.9,3.1
      l-19,19.4c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0,0c0.5,0.5,1.2,0.5,1.7,0c0,0,0,0,0,0l19-19.4c1,1,2,2,3.1,3l-8.2,9.3
      c-0.4,0.5-0.4,1.2,0.1,1.6c0.5,0.4,1.2,0.4,1.6-0.1c0,0,0,0,0,0l8.2-9.3c1.1,1,2.2,2,3.4,2.9l-3.1,4c-0.4,0.5-0.3,1.2,0.2,1.5
      c0.5,0.4,1.2,0.3,1.6-0.2l3.1-3.9c1.7,1.3,3.4,2.7,5.2,4l-3,4.4c-0.3,0.5-0.2,1.2,0.3,1.5c0.5,0.3,1.2,0.2,1.5-0.3l3-4.4l-0.8-0.6
      c1.5,1,3,2.1,4.5,3l-6.5,10.7c-0.3,0.5-0.1,1.2,0.4,1.6c0.5,0.3,1.2,0.2,1.6-0.4c0,0,0,0,0,0l6.5-10.6c1.3,0.8,2.5,1.5,3.8,2.2
      l-12.7,23.5c-0.3,0.6-0.1,1.3,0.5,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0.1,1.6-0.5l12.7-23.5c1.3,0.7,2.5,1.4,3.8,2l-15.7,33.1
      c-0.3,0.6,0,1.3,0.6,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0,1.6-0.6l15.7-33.1c1.3,0.6,2.5,1.2,3.8,1.8l-20,48.6c-0.3,0.7,0,1.4,0.7,1.7
      c0.7,0.3,1.4,0,1.7-0.7c0,0,0,0,0,0l20-48.6c1.3,0.5,2.6,1.1,3.9,1.6l-12.2,34.3c-0.2,0.6,0.1,1.3,0.8,1.6c0.6,0.2,1.3-0.1,1.6-0.7
      c0,0,0,0,0,0l12.1-34.3l-0.8-0.3c1.8,0.7,3.6,1.3,5.5,1.8c-0.2-0.1-0.4-0.1-0.6-0.2l-7.4,25.1c-0.2,0.6,0.2,1.3,0.8,1.5c0,0,0,0,0,0
      c0.6,0.2,1.3-0.2,1.5-0.8c0,0,0,0,0,0l7.4-25.1c-0.2-0.1-0.5-0.2-0.7-0.2c2.2,0.7,4.5,1.3,6.7,1.9l-1.8-0.4l-2.6,11.1
      c-0.1,0.6,0.2,1.2,0.8,1.4c0,0,0,0,0,0c0.6,0.1,1.2-0.2,1.4-0.8c0,0,0,0,0,0l2.6-11.1c1.7,0.4,3.4,0.8,5.2,1.2l-0.8-0.2l-0.7,4
      c-0.1,0.6,0.3,1.2,0.9,1.3c0.6,0.1,1.2-0.3,1.3-0.9l0.7-4c2.2,0.4,4.3,0.8,6.5,1.1l-0.5,4.4c-0.1,0.6,0.4,1.2,1,1.2c0,0,0,0,0,0
      c0.6,0.1,1.2-0.4,1.2-1c0,0,0,0,0,0l0.5-4.3c1.5,0.2,3,0.3,4.5,0.5l-0.7,11.5c0,0.6,0.4,1.2,1.1,1.2c0.6,0,1.2-0.4,1.2-1.1
      c0,0,0,0,0,0l0.7-11.5c1.5,0.1,2.9,0.2,4.4,0.2l-0.2,25.8c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2l0.2-25.7c2,0,4,0,6-0.1
      c-0.6,0-1.1,0.1-1.7,0.1l1.6,35.7c0,0.7,0.6,1.2,1.3,1.2c0,0,0,0,0,0c0.7,0,1.2-0.6,1.2-1.3c0,0,0,0,0,0l-1.6-35.7
      c1.4-0.1,2.8-0.1,4.2-0.2l5,51.3c0.1,0.7,0.7,1.2,1.4,1.2c0.7-0.1,1.2-0.7,1.2-1.4c0,0,0,0,0,0l-5-51.3c1.4-0.1,2.8-0.3,4.2-0.5
      l5.2,35.1c0.1,0.6,0.7,1.1,1.4,1c0.7-0.1,1.1-0.7,1-1.4l-5.3-35l-1.5,0.2c1.9-0.3,3.8-0.6,5.7-0.9l5.1,24.7c0.1,0.6,0.7,1.1,1.4,0.9
      c0,0,0,0,0,0c0.6-0.1,1.1-0.8,0.9-1.4l-5.1-24.7c-0.4,0.1-0.8,0.2-1.2,0.2c1.9-0.4,3.7-0.8,5.6-1.2l2.7,10.2c0.2,0.6,0.8,1,1.4,0.8
      c0,0,0,0,0,0c0.6-0.2,1-0.8,0.8-1.4l-2.7-10.2c1.4-0.4,2.8-0.8,4.2-1.2l1.1,3.5c0.2,0.6,0.8,0.9,1.4,0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c0-0.2,0-0.4,0-0.6l-1-3c1.4-0.4,2.8-0.9,4.1-1.4l3.8,10.2c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5l-3.8-10.2
      c1.4-0.5,2.8-1.1,4.1-1.7l10.1,23.1c0.3,0.6,1,0.9,1.6,0.6c0.6-0.3,0.9-1,0.6-1.6l-10.1-23c-0.7,0.3-1.4,0.6-2.1,0.9
      c2-0.9,4-1.8,6-2.7l15.7,31.5c0.3,0.6,1,0.9,1.7,0.6s0.9-1,0.6-1.7l-15.7-31.4c1.3-0.6,2.5-1.3,3.8-2 M155.9,338
      c-59.5,33.8-135.1,13-169-46.5c-33.8-59.5-13-135.1,46.5-169c59.5-33.8,135.1-13,169,46.5C236.1,228.5,215.3,304.1,155.9,338z"
      />
      <g class="op op2 pc">
        <path
          class="st0 st1"
          d="M177.9,464.8c6,10.6,19.4,14.3,30,8.3c0,0,0,0,0,0c1.4-0.8,2.7-1.7,3.8-2.8c2.6,4,6.8,6.7,11.5,7.3l13.1,3.6
        L240,468c1.9-4.4,1.8-9.3-0.3-13.6c1.5-0.5,3-1.1,4.4-1.9c10.5-6,14.2-19.5,8.2-30c-4.2-7.3-12.1-11.5-20.4-11l-5.1-9l-13.3,7.5
        c-7.3,4.2-9.9,13.5-5.7,20.8c-4.2-7.3-13.5-9.9-20.8-5.7l-13.3,7.5l5.2,9.1C174.1,448.5,173.7,457.5,177.9,464.8z"
        />
        <path
          class="st0"
          d="M186.8,464.9c4.9,8.7,15.9,11.7,24.5,6.8c0,0,0,0,0.1,0c1.1-0.6,2.2-1.4,3.1-2.3c2.1,3.3,5.6,5.5,9.5,6
      l10.8,2.9l3-10.8c1.6-3.6,1.5-7.7-0.3-11.2c1.3-0.4,2.5-0.9,3.6-1.6c8.7-4.8,11.8-15.8,7-24.5c-3.4-6.1-10-9.7-17-9.2l-4.2-7.4
      l-10.9,6.2c-6,3.4-8.1,11-4.7,17c-3.4-6-11-8.1-17-4.8c0,0,0,0-0.1,0l-10.9,6.2l4.2,7.5C183.6,451.6,183.3,459,186.8,464.9z"
        />
        <path
          class="st0"
          d="M248.3,451.6c-11.8,6.7-26.8,2.6-33.5-9.2c-6.7-11.8-2.6-26.8,9.2-33.5s26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C264.2,429.8,260.1,444.8,248.3,451.6 M249.2,453.1c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.7,7.2-17.1,23.3-9.9,36c0,0,0,0,0,0C220.4,455.9,236.5,460.3,249.2,453.1C249.2,453.2,249.2,453.1,249.2,453.1L249.2,453.1z"
        />
        <path
          class="st0"
          d="M204.9,476.2c-11.8,6.7-26.8,2.6-33.5-9.2s-2.6-26.8,9.2-33.5c11.8-6.7,26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C220.8,454.5,216.7,469.5,204.9,476.2 M205.7,477.8c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.6,7.2-17.1,23.3-9.9,35.9C176.9,480.6,193,485,205.7,477.8C205.7,477.8,205.7,477.8,205.7,477.8z"
        />
        <path
          class="st0"
          d="M215.2,440.5l-8.2-14.4c-4.5-7.9-1.7-18,6.2-22.5l14.4-8.2l8.1,14.4c4.5,7.9,1.7,18-6.2,22.5L215.2,440.5
      M214.6,442.9l15.9-9c8.8-5,11.9-16.2,6.9-25l-9-15.9l-15.9,9c-8.8,5-11.9,16.2-6.9,24.9c0,0,0,0,0,0L214.6,442.9z"
        />
        <path
          class="st0"
          d="M197.8,450.4c-7.9,4.5-18,1.8-22.5-6.1c0,0,0,0,0,0l-8.2-14.4l14.4-8.2c7.9-4.5,18-1.7,22.5,6.2l8.2,14.3
      L197.8,450.4 M198.7,451.9l15.9-9l-9-15.9c-5-8.8-16.2-11.9-25-6.9c0,0,0,0,0,0l-15.9,9l9,15.9C178.7,453.9,189.8,457,198.7,451.9
      C198.6,452,198.7,452,198.7,451.9L198.7,451.9z"
        />
        <path
          class="st0"
          d="M238.7,485.3l-14-3.8h-0.1h-0.1c-9.6-1.2-16.4-10-15.2-19.6c0.2-1.3,0.5-2.6,0.9-3.9v-0.1
      c0.2-0.5,0.4-1.1,0.7-1.6l0,0l0,0l4.8-11.4l12.2,1.7h0.1c0.5,0,1.1,0.1,1.7,0.2h0.1c9.5,1.8,15.8,11,14,20.5
      c-0.2,1.3-0.6,2.6-1.2,3.8v0.1v0.1L238.7,485.3 M240,487.5l4.4-15.8c4.3-9.8-0.2-21.2-10-25.4c-1.3-0.6-2.7-1-4.2-1.3l0,0
      c-0.6-0.1-1.3-0.2-1.9-0.3l-13.6-1.8l-5.5,12.6c-0.3,0.6-0.5,1.2-0.8,1.8l0,0l0,0c-3.7,10,1.4,21.2,11.4,24.9c1.4,0.5,2.8,0.9,4.3,1
      L240,487.5L240,487.5z"
        />
        <path
          class="st2"
          d="M204.9,451.9c-3.5-6.2-1.4-14,4.8-17.6c6.2-3.5,14-1.4,17.6,4.8s1.4,14-4.8,17.6c0,0,0,0,0,0
      C216.2,460.2,208.4,458.1,204.9,451.9z"
        />
        <polygon
          class="st2"
          points="215.7,445.7 216.5,445.3 226.7,463.4 226,463.8 "
        />
        <path
          class="st2"
          d="M224.3,464.8c-0.6-1.1-0.2-2.6,0.9-3.2s2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c0,0,0,0,0,0
      C226.4,466.3,225,465.9,224.3,464.8z"
        />
        <polygon
          class="st2"
          points="197.8,455.4 215.9,445.1 216.3,445.9 198.2,456.2 "
        />
        <path
          class="st2"
          d="M196.8,453.8c1.1-0.6,2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c-1.1,0.6-2.6,0.2-3.2-0.9c0,0,0,0,0,0
      C195.3,455.9,195.7,454.4,196.8,453.8C196.8,453.8,196.8,453.8,196.8,453.8z"
        />
        <rect
          x="214.8"
          y="439.9"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -188.1656 168.7628)"
          class="st2"
          width="20.8"
          height="0.9"
        />
        <path
          class="st2"
          d="M233,433.2c-1.1,0.6-1.5,2.1-0.9,3.2c0.6,1.1,2.1,1.5,3.2,0.9s1.5-2.1,0.9-3.2c0,0,0,0,0,0
      C235.6,433,234.2,432.6,233,433.2z"
        />
        <path
          class="st0"
          d="M213.1,447.2c-0.9-1.7-0.4-3.8,1.3-4.7c1.7-0.9,3.8-0.4,4.7,1.3c0.9,1.7,0.4,3.8-1.3,4.7c0,0,0,0,0,0
      C216.1,449.5,214,448.9,213.1,447.2C213.1,447.3,213.1,447.2,213.1,447.2z"
        />
        <path
          class="st0"
          d="M194.1,406.8c3.5-2,8.3-1.2,12.6,2.2l-1.1,1.4c-3.6-2.9-7.7-3.6-10.6-2c-2.9,1.6-4.3,5.5-3.7,10.2l-1.8,0.2
      C188.8,413.5,190.6,408.9,194.1,406.8z"
        />
        <rect
          x="191.3"
          y="404.7"
          transform="matrix(0.2654 -0.9641 0.9641 0.2654 -250.5467 488.2404)"
          class="st0"
          width="7.7"
          height="7.7"
        />
        <path
          class="st0"
          d="M250.6,509c-2-2.1-2.8-4.6-1.8-5.5s3.4,0,5.4,2.1s2.8,4.6,1.9,5.5C255.1,512.1,252.7,511.2,250.6,509z"
        />
        <path
          class="st0"
          d="M241.8,506.5c-1.5,2.1-3.6,3.3-4.6,2.6c-1-0.7-0.6-3,1-5.2c1.6-2.1,3.6-3.3,4.6-2.6S243.4,504.4,241.8,506.5z"
        />
        <path
          class="st0"
          d="M253.1,496.9c-2.1,0.8-4.2,0.5-4.5-0.5s1.1-2.4,3.2-3.2c2.1-0.8,4.2-0.5,4.5,0.5
      C256.7,494.7,255.2,496.1,253.1,496.9z"
        />
        <rect
          x="243.7"
          y="484.7"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -212.8196 185.46)"
          class="st0"
          width="1.5"
          height="21.2"
        />
        <path
          class="st0"
          d="M240.8,487.4c6.7,11.8,2,14.7,1.8,14.8l-0.8-1.3c0,0,3.5-2.5-2.3-12.7L240.8,487.4z"
        />
        <path
          class="st0"
          d="M242.7,491.1c3.1,5.5,7.3,4.4,7.5,4.4l0.4,1.5c-0.1,0-5.5,1.4-9.2-5.1L242.7,491.1z"
        />
        <path
          class="st0"
          d="M152.6,477.5c2.8-0.8,5.3-0.3,5.7,1s-1.6,3-4.4,3.7c-2.8,0.7-5.3,0.3-5.7-1C147.9,480,149.8,478.3,152.6,477.5z
      "
        />
        <path
          class="st0"
          d="M156.2,469.2c-1.5-2.2-1.8-4.6-0.8-5.3s3.1,0.5,4.6,2.8s1.8,4.6,0.8,5.3S157.8,471.5,156.2,469.2z"
        />
        <path
          class="st0"
          d="M165.6,483.3c-1-2.1-1-4.1,0-4.6s2.6,0.8,3.6,2.9s1,4.1,0,4.6S166.6,485.4,165.6,483.3z"
        />
        <rect
          x="156.2"
          y="473.8"
          transform="matrix(0.8687 -0.4953 0.4953 0.8687 -213.4897 143.7556)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M171.2,469.9l0.8,1.3c-5.1,2.9-8.8,3.8-10.9,2.6c-0.9-0.5-1.6-1.5-1.6-2.6h1.5c0,0.6,0.4,1.1,0.9,1.3
      C162.8,473,165.2,473.3,171.2,469.9z"
        />
        <path
          class="st0"
          d="M170.1,470.5l0.8,1.3c-5.5,3.1-4.5,7.3-4.5,7.3l-1.5,0.4C164.8,479.4,163.5,474.2,170.1,470.5z"
        />
        <path
          class="st0"
          d="M275.6,407.1c-2.4,2.4-3.5,5.2-2.4,6.3c1.1,1.1,4,0.2,6.4-2.2c2.4-2.4,3.5-5.2,2.4-6.3S278,404.8,275.6,407.1z"
        />
        <path
          class="st0"
          d="M266.7,407c-1.3-2-3-3.1-3.9-2.5s-0.7,2.6,0.6,4.6s3,3.1,3.9,2.5S267.9,408.9,266.7,407z"
        />
        <path
          class="st0"
          d="M274.7,422.5c-1.2-2.5-3-4.1-4-3.6s-0.8,3,0.4,5.5s3.1,4.1,4,3.6C276.1,427.5,276,425,274.7,422.5z"
        />
        <rect
          x="259.1"
          y="415.4"
          transform="matrix(0.8691 -0.4945 0.4945 0.8691 -170.8213 186.6468)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M266.9,410.1c0.9,0.6,1.4,1.6,1.4,2.7c0,2.4-2.7,5.1-7.8,8l-0.8-1.3c6-3.4,7-5.7,7-6.7c0-0.5-0.2-1.1-0.7-1.4
      L266.9,410.1z"
        />
        <path
          class="st0"
          d="M266.5,417.2c1.8,0,3.5,0.6,4.9,1.8l-1.1,1.1c-0.1-0.1-3.1-3-8.6,0.1l-0.8-1.3
      C262.6,417.9,264.5,417.3,266.5,417.2z"
        />
      </g>
      <rect
        x="254.7"
        y="325"
        transform="matrix(0.8777 -0.4792 0.4792 0.8777 -125.6418 163.5741)"
        class="st0 op op10"
        width="6"
        height="6"
      />
      <rect
        x="277.7"
        y="330.1"
        transform="matrix(0.789 -0.6144 0.6144 0.789 -145.3814 242.6519)"
        class="st0 op op9"
        width="5.7"
        height="5.7"
      />
      <rect
        x="302.5"
        y="333.2"
        transform="matrix(0.7508 -0.6605 0.6605 0.7508 -145.8386 285.39)"
        class="st0 op op8"
        width="5.6"
        height="5.6"
      />
      <rect
        x="323.5"
        y="332.9"
        transform="matrix(0.6543 -0.7562 0.7562 0.6543 -141.0644 362.7884)"
        class="st0 op op7"
        width="5.6"
        height="5.6"
      />
      <rect
        x="395.2"
        y="313.8"
        transform="matrix(0.316 -0.9488 0.9488 0.316 -28.186 594.4554)"
        class="st0 op op5"
        width="5.9"
        height="5.9"
      />
      <rect
        x="358.4"
        y="326.7"
        transform="matrix(0.5219 -0.853 0.853 0.5219 -108.4008 465.6359)"
        class="st0 op op6"
        width="5.6"
        height="5.6"
      />
      <rect
        x="411.8"
        y="303.3"
        transform="matrix(0.2103 -0.9776 0.9776 0.2103 27.9992 647.9564)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="460.6"
        y="334.4"
        transform="matrix(4.710640e-02 -0.9989 0.9989 4.710640e-02 104.6518 785.1971)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="470.1"
        y="243.4"
        transform="matrix(0.9596 -0.2813 0.2813 0.9596 -50.2987 143.1508)"
        class="st0 op op2"
        width="6.6"
        height="6.6"
      />
      <rect
        x="480.3"
        y="219.2"
        transform="matrix(0.9117 -0.4108 0.4108 0.9117 -48.7281 218.2997)"
        class="st0 op op1"
        width="6.6"
        height="6.6"
      />
      <g class="r30">
        <path
          class="st0 op op6"
          d="M770.7,358.9c-1.6-1.5-4.1-1.4-5.7,0.2c-1.5,1.6-1.4,4.1,0.2,5.7c1.6,1.5,4.1,1.4,5.6-0.2
      C772.4,363,772.3,360.5,770.7,358.9C770.7,359,770.7,358.9,770.7,358.9z"
        />
        <path
          class="st0 op op5"
          d="M729.6,381c-3-3.8,1-5.4,1-5.4c1.1-0.3,1.9-1.3,1.9-2.4c-0.2-1.3-0.7-2.6-1.4-3.8c-2.4-3.2-5.3-6.1-8.6-8.5
      l-0.4-0.3l0.4-0.2c1.8-0.7,3.6-1.6,5.3-2.6l0,0c1.5-0.9,2.9-1.9,4.2-3c0.9-0.9,1.6-2.1,2-3.4c0,0,0.3-1.5-1.6-2.9
      c0,0-3.7-2.7-0.4-5.6c0.8-0.8,0.8-2.1,0-3c-0.1-0.1-0.2-0.2-0.4-0.3c0,0,9.5,5.1,5.7,10.5l0,0c-3.4,4.8-8.1,8.5-13.6,10.6
      c5,3.6,9.1,8.4,11.8,14c3.8,6.8-6.6,9.1-6.6,9.1c1-0.4,1.5-1.6,1.1-2.6C729.8,381.3,729.7,381.1,729.6,381z"
        />
        <path
          class="st0 op op4"
          d="M704.2,392.2c-2.8-5.8,2.5-7.9,2.5-7.9c2.1-0.8,3.7-2.5,4.3-4.7c0.6-3.1,0.4-6.4-0.6-9.4
      c-2.2-5.3-5.5-10.1-9.7-14l-0.3-0.3l0.4-0.1c5-1.7,9.6-4.7,13.2-8.6c2-2.5,3.2-5.5,3.6-8.6c0.2-2.4-0.8-4.7-2.5-6.3
      c0,0-4.3-4.4,0-8.5c1.3-1.5,1.2-3.6-0.2-5c0,0,8.7,6.6,7.2,13.7l-0.1,0.4c-1.7,10.7-9.2,19.4-20.1,23.4c9,8.5,13.3,20,11.6,30.6
      c0,0.1,0,0.3-0.1,0.4c-0.8,7.2-10.8,9.5-10.8,9.5C704.2,395.9,704.9,394,704.2,392.2z"
        />
        <path
          class="st0 op op3"
          d="M669.9,384.9c-1.2-3.5,0.6-7.4,4-8.8c2.4-0.9,4.3-2.8,5.2-5.2c1.2-3.4,1.5-7,1-10.5c-1.3-5.8-3.9-11.3-7.7-15.9
      l-0.3-0.4l0.5-0.1c5.7-1.9,10.9-5.1,15.1-9.3c2.5-2.7,4.3-6,5.2-9.5c0.6-2.5,0-5.1-1.5-7.1c-2.2-3-1.5-7.2,1.5-9.5
      c1.5-1.5,1.8-3.8,0.7-5.6c0,0,7.9,7.6,5.1,15.4l-0.1,0.5c-3.7,12-13,21.5-24.9,25.6c7.8,9.7,10.4,22.6,6.8,34.5
      c0,0.2-0.1,0.3-0.2,0.5c-2,8-12.9,10.3-12.9,10.3C669.3,389.1,670.4,387,669.9,384.9z"
        />
        <rect
          x="725.5"
          y="358.5"
          transform="matrix(0.8026 -0.5965 0.5965 0.8026 -71.8132 505.9555)"
          class="st0 op op5"
          width="6.1"
          height="6.1"
        />
        <rect
          x="703.7"
          y="354.2"
          transform="matrix(0.875 -0.4842 0.4842 0.875 -84.5816 386.8404)"
          class="st0 op op4"
          width="6.1"
          height="6.1"
        />
        <path
          class="st0 op op2"
          d="M640.4,356.5c-0.7-5.4,4.2-5.8,4.2-5.8c1.9,0,3.7-1.1,4.7-2.7c1.3-2.4,2-5.1,2-7.9c-0.3-4.9-1.8-9.7-4.2-14
      l-0.2-0.4h0.4c4.6-0.1,9-1.2,13-3.5c2.3-1.5,4.1-3.6,5.2-6.1c0.8-1.9,0.6-4-0.3-5.8c0,0-2.3-4.7,2.3-6.9c1.4-0.8,1.9-2.6,1.2-4.1
      c0,0,5.3,7.7,2.2,13.1l-0.2,0.4c-4.2,8.2-12.6,13.4-22.6,13.6c5,9.4,5.5,19.9,1.2,28.1c0,0.1-0.1,0.2-0.2,0.3
      c-2.6,5.6-11.4,4.8-11.4,4.8C639.5,359.5,640.6,358.1,640.4,356.5z"
        />
        <rect
          x="649.2"
          y="325.6"
          transform="matrix(0.9825 -0.1865 0.1865 0.9825 -49.8557 127.4308)"
          class="st0 op op2"
          width="6.1"
          height="6.1"
        />
        <rect
          x="674.6"
          y="342.5"
          transform="matrix(0.9275 -0.3738 0.3738 0.9275 -80.0531 278.3417)"
          class="st0 op op3"
          width="6.1"
          height="6.1"
        />
        <path
          class="st3 an2"
          d="M613,155.1c-41.5,80,10.9,217,155.5,207.4"
        />
      </g>
      <g>
        <line
          class="st3 an1"
          x1="656.5"
          y1="168.9"
          x2="580.6"
          y2="271.1"
        />

        <path
          class="st3 an3-1"
          d="M493.6,141.7C480.7,62.1,408.6,4.3,322.3,4.3c-10.3,0-20.6,0.8-30.7,2.5c-46.9,7.6-88.2,31.8-116.3,68	s-39.7,80.2-32.6,123.9c12.9,79.6,84.9,137.4,171.2,137.4v0c10.3,0,20.6-0.9,30.7-2.5c46.9-7.6,88.2-31.8,116.3-68	S500.7,185.4,493.6,141.7"
        />
        <path
          class="st3 an6"
          d="M461,265.6c-35.1,42.9,11.4,107,59.9,85.6"
        />
      </g>
      <line
        class="st3 an6"
        x1="155.8"
        y1="340"
        x2="196.1"
        y2="410.1"
      />
    </svg>
    <svg
      v-if="isMobile"
      version="1.1"
      id="svg5-2"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 799.5 523.3"
      style="enable-background:new 0 0 799.5 523.3;"
      xml:space="preserve"
    >
      <path
        class="st0 ops op10"
        d="M248.6,351.2c4.4-3.7,0.8-7,0.8-7c-1.1-0.9-1.5-2.3-1.1-3.6c0.7-1.6,1.6-3,2.8-4.2c3.6-3.1,7.6-5.6,11.9-7.5
      l0.5-0.2l-0.4-0.3c-1.7-1.5-3.2-3.2-4.6-5l0,0c-1.2-1.6-2.3-3.4-3.2-5.2c-0.6-1.5-0.9-3.2-0.8-4.8c0.3-1.4,1.3-2.6,2.7-3.1
      c0,0,4.8-2,2.3-6.7c-0.6-1.5,0-3.2,1.5-3.9c0,0-11.6,3.1-9.6,11.1l0,0c1.6,6.8,5.3,13,10.5,17.7c-6.7,2.9-12.7,7.5-17.2,13.2
      c-6.3,7.1,3.7,13.5,3.7,13.5C246.5,353.2,248.6,351.2,248.6,351.2z"
      />
      <path
        class="st0 ops op9"
        d="M271.1,370.2c4.7-5.1-0.2-9-0.2-9c-2-1.4-3.1-3.8-3-6.3c0.3-3.5,1.6-6.8,3.6-9.7c3.9-4.9,8.9-8.8,14.5-11.6
      l0.5-0.2l-0.4-0.3c-4.7-3.4-8.5-7.9-11.1-13.1c-1.3-3.2-1.6-6.7-1-10.1c0.6-2.5,2.3-4.7,4.6-5.8c0,0,5.9-3.2,2.6-8.9
      c-0.9-1.9-0.1-4.1,1.7-5.1c0,0-11.1,4.2-11.8,12c0,0.1,0,0.3-0.1,0.5c-1.6,11.7,3.6,23.1,13.8,30.7c-12,6.1-20.1,16.7-21.6,28.4
      c0,0.2,0,0.3,0,0.5c-1.4,7.7,8.4,13.3,8.4,13.3C270,374.1,269.8,371.8,271.1,370.2z"
      />
      <path
        class="st0 ops op8"
        d="M294.6,291.4L294.6,291.4c-1.3,3.8-2,7.7-2.2,11.8c-0.4,13.1,5.9,25.5,16.7,32.9c-11.4,7.9-18.5,20.5-18.9,33.6
      c-0.1,4,0.4,7.9,1.4,11.7l0,0c1.9,7.2,10.2,11.4,10.2,11.4c-3.1-2.8-0.9-6.4-0.9-6.4c4.6-6.7-1.2-11-1.2-11
      c-4.6-3.5-4.8-7.8-4.8-7.8c-0.6-6.4,0.5-12.8,3-18.7c3.2-5,7.5-9.3,12.4-12.7l0.4-0.3l-0.4-0.3c-4.7-3.1-8.7-7.3-11.6-12.2
      c-2.2-5.9-2.8-12.3-1.8-18.6c0,0,0.5-4.3,5.3-8c0,0,6.1-4.5,1.9-11.1c-1.1-2.2-0.6-4.9,1.3-6.5C305.6,279.5,297,284.1,294.6,291.4z"
      />
      <path
        class="st0 ops op7"
        d="M310.9,308L310.9,308c0,0.4,0,0.8,0,1.2c0.8,11.1,8.1,20.8,19.7,26c-10.7,7.7-16.5,18.9-15.8,30
      c0,0.4,0.1,0.8,0.1,1.2l0,0c0,0.2,0,0.4,0.1,0.6l0,0c1.3,6.5,10.8,9.5,10.8,9.5c-1.7-0.9-2.3-3-1.4-4.7c0,0,0-0.1,0-0.1
      c3.6-5.6-2-8.3-2-8.3c-2.2-0.9-3.8-2.9-4.2-5.3c-0.4-3.4,0.3-6.9,1.8-9.9c0.2-0.3,0.4-0.7,0.6-1c0.2-0.3,0.4-0.6,0.6-0.9l-0.1,0.1
      c2.8-4.3,6.5-8,10.7-11l0.4-0.3l-0.4-0.2c-4.6-2-8.7-4.8-12.1-8.5l0.1,0.1l-0.7-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-2-2.7-3.1-6-3.2-9.4
      c0.1-2.5,1.3-4.8,3.4-6.1c0,0,5.2-4,0.8-8.6c-1.2-1.6-0.9-3.8,0.7-5c0,0,0,0,0,0C320.1,295.7,310.8,301.1,310.9,308z"
      />
      <g class="ops op3">
        <path
          class="st0 st1"
          d="M515,279.4c-8.1,11-5.7,26.4,5.3,34.5c1.4,1,3,1.9,4.6,2.7c-2.9,4.5-3.7,10-2.1,15.1l2.4,15.1l15.1-2.3
        c5.3-0.1,10.4-2.5,13.8-6.6c1.2,1.4,2.5,2.6,4,3.6c11,8.1,26.4,5.7,34.5-5.3c5.6-7.6,6.3-17.7,1.9-26.1l6.9-9.4l-13.8-10.1
        c-7.6-5.6-18.3-4-23.9,3.6c0,0,0,0,0,0.1c5.6-7.6,4-18.3-3.7-23.9l-13.8-10.1l-6.9,9.5C530,268,520.6,271.8,515,279.4z"
        />
        <path
          class="st0"
          d="M519,288.6c-6.6,9-4.7,21.7,4.3,28.3c0,0,0,0,0,0c1.2,0.9,2.4,1.6,3.8,2.2c-2.4,3.7-3,8.2-1.8,12.4l1.9,12.4
      l12.4-1.9c4.4,0,8.5-2,11.3-5.4c7.4,8.4,20.1,9.3,28.6,1.9c7-6.2,8.9-16.4,4.6-24.6l5.7-7.7l-11.3-8.3c-6.2-4.6-15-3.3-19.6,2.9
      c0,0,0,0,0,0.1c4.6-6.2,3.3-15-2.9-19.6c0,0,0,0-0.1,0l-11.3-8.3l-5.7,7.8C531.2,279.2,523.5,282.3,519,288.6z"
        />
        <path
          class="st0"
          d="M560.9,345.5c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.6c0,0,0,0,0,0
      C590.4,351.8,573.1,354.5,560.9,345.5 M559.7,347.1c13.2,9.7,31.7,6.8,41.4-6.3c9.7-13.2,6.8-31.7-6.3-41.4
      c-13.2-9.7-31.7-6.8-41.4,6.3c0,0,0,0,0,0C543.7,318.9,546.5,337.4,559.7,347.1C559.7,347.1,559.7,347.1,559.7,347.1z"
        />
        <path
          class="st0"
          d="M515.7,312.2c-12.3-9-14.9-26.3-5.9-38.6s26.3-14.9,38.6-5.9c12.3,9,14.9,26.3,5.9,38.5
      C545.2,318.6,527.9,321.2,515.7,312.2 M514.5,313.9c13.2,9.7,31.7,6.8,41.4-6.3s6.8-31.7-6.3-41.4c-13.2-9.7-31.7-6.8-41.4,6.3
      c0,0,0,0,0,0C498.5,285.7,501.3,304.2,514.5,313.9z"
        />
        <path
          class="st0"
          d="M557.1,306.5l11-14.9c6.1-8.2,17.6-10,25.9-4l14.9,11l-11,14.9c-6,8.2-17.6,10-25.9,4L557.1,306.5 M554.2,306.9
      l16.5,12.1c9.1,6.7,22,4.7,28.7-4.4l12.1-16.5L595.1,286c-9.1-6.7-22-4.7-28.7,4.4c0,0,0,0,0,0L554.2,306.9L554.2,306.9z"
        />
        <path
          class="st0"
          d="M538.9,293.2c-8.3-6-10-17.6-4-25.9l11-14.9l14.9,11c8.2,6,10,17.6,4,25.9l-11,14.9L538.9,293.2 M537.8,294.9
      l16.5,12.1l12.1-16.5c6.7-9.1,4.7-22-4.4-28.7c0,0,0,0,0,0l-16.5-12.1l-12.1,16.5C526.6,275.2,528.5,288.1,537.8,294.9
      C537.7,294.8,537.7,294.8,537.8,294.9L537.8,294.9z"
        />
        <path
          class="st0"
          d="M521.9,351.1l-2.5-16.1v-0.1v-0.1c-3.2-10.4,2.6-21.4,13-24.6c1.4-0.4,2.9-0.7,4.4-0.8h0.1c0.6,0,1.3,0,1.9,0
      h0.1l13.9-0.3l3.9,13.3v0l0,0c0.2,0.6,0.4,1.2,0.5,1.8v0.1c2.5,10.6-4,21.2-14.5,23.7c-1.4,0.3-2.9,0.5-4.4,0.5h0h-0.1L521.9,351.1
      M520.2,353.3l18.2-2.7c12-0.1,21.6-9.9,21.4-21.9c0-1.6-0.2-3.2-0.6-4.8l0,0v-0.1c-0.2-0.7-0.4-1.4-0.6-2.1l-4.4-14.8l-15.4,0.3
      c-0.7,0-1.5,0-2.2,0l0,0c-12,0.8-21,11.2-20.2,23.1c0.1,1.7,0.4,3.3,0.9,4.9L520.2,353.3z"
        />
        <rect
          x="533.7"
          y="318.6"
          transform="matrix(0.591 -0.8067 0.8067 0.591 -34.3336 570.4008)"
          class="st2"
          width="23.3"
          height="1"
        />
        <path
          class="st2"
          d="M536.3,326.9c0.9-1.2,2.5-1.4,3.7-0.6s1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6
      C535.7,329.8,535.5,328.1,536.3,326.9C536.3,327,536.3,327,536.3,326.9z"
        />
        <rect
          x="542.4"
          y="291.1"
          transform="matrix(0.5917 -0.8061 0.8061 0.5917 -22.4438 561.2402)"
          class="st2"
          width="1"
          height="23.3"
        />
        <path
          class="st2"
          d="M535,293.8c1.2,0.9,1.4,2.5,0.6,3.7c-0.9,1.2-2.5,1.4-3.7,0.6c-1.2-0.9-1.4-2.5-0.6-3.7c0,0,0,0,0,0
      C532.2,293.2,533.8,292.9,535,293.8C535,293.8,535,293.8,535,293.8z"
        />
        <polygon
          class="st2"
          points="552.6,309.3 571.4,323.1 570.8,323.9 552,310.1 "
        />
        <path
          class="st2"
          d="M572.7,321.4c-1.2-0.9-2.8-0.6-3.7,0.6c-0.9,1.2-0.6,2.8,0.6,3.7s2.8,0.6,3.7-0.6c0,0,0,0,0,0
      C574.1,323.9,573.9,322.3,572.7,321.4C572.7,321.4,572.7,321.4,572.7,321.4z"
        />
        <path
          class="st2"
          d="M540.6,301.1c4.7-6.4,13.8-7.8,20.2-3.1c6.4,4.7,7.8,13.8,3.1,20.2c-4.7,6.4-13.8,7.8-20.2,3.1
      C537.3,316.6,535.9,307.6,540.6,301.1C540.6,301.1,540.6,301.1,540.6,301.1z"
        />
        <path
          class="st0"
          d="M549.2,307.4c1.3-1.7,3.7-2.1,5.4-0.8c1.7,1.3,2.1,3.7,0.8,5.4c-1.3,1.7-3.7,2.1-5.4,0.8
      C548.3,311.5,547.9,309.1,549.2,307.4z"
        />
        <path
          class="st0"
          d="M581.8,269.5c3.7,2.7,5,8,3.5,13.9l-1.9-0.5c1.3-5,0.2-9.5-2.8-11.8s-7.6-1.9-12.1,0.9l-1.1-1.7
      C572.6,267.1,578.1,266.8,581.8,269.5z"
        />
        <rect
          x="576.2"
          y="267"
          transform="matrix(0.9885 -0.1511 0.1511 0.9885 -34.3258 90.823)"
          class="st0"
          width="8.6"
          height="8.6"
        />
      </g>
      <rect
        x="612.2"
        y="220.5"
        transform="matrix(0.9885 -0.1511 0.1511 0.9885 -26.8084 95.6392)"
        class="st0 ops op1"
        width="7.5"
        height="7.5"
      />
      <path
        class="st0 ops op3"
        d="M456.8,265.7c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C459.2,269.9,457,268.1,456.8,265.7C456.8,265.7,456.8,265.7,456.8,265.7z"
      />
      <path
        class="st0 op op11"
        d="M196.6,297.6c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C199.1,301.7,196.9,300,196.6,297.6z"
      />
      <path
        class="st0 op op3"
        d="M516.9,352.8c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C519.3,357,517.2,355.2,516.9,352.8C516.9,352.8,516.9,352.8,516.9,352.8z"
      />
      <path
        class="st0 op op2"
        d="M596.9,244.4c-0.3-2.4,1.5-4.6,3.9-4.8c2.4-0.3,4.6,1.5,4.8,3.9c0.3,2.4-1.5,4.6-3.9,4.8
      C599.4,248.5,597.2,246.8,596.9,244.4C596.9,244.4,596.9,244.4,596.9,244.4z"
      />
      <path
        class="st0 op op5"
        d="M405.1,342.7c1.5-6.2-3.9-6.3-3.9-6.3c-1.5,0.1-2.8-0.8-3.4-2.2c-0.4-1.8-0.5-3.6-0.3-5.4
      c1.2-5.1,3.1-10.1,5.7-14.6l0.3-0.5h-0.5c-2.5-0.1-4.9-0.4-7.3-0.9l0,0c-2.1-0.5-4.2-1.1-6.3-2c-1.6-0.8-2.9-2-3.9-3.4
      c0,0-1.2-1.8,0.3-4.4c0,0,3-5.1-2.3-7.2c-1.5-0.7-2.1-2.5-1.4-4c0-0.1,0.1-0.2,0.2-0.3c0,0-8.3,10.8-1.3,16l0,0
      c6.1,4.5,13.4,7.1,20.9,7.3c-4,7.1-6.3,15-6.6,23.1c-0.9,10.3,12.2,8.6,12.2,8.6C404.5,345.9,405.1,342.7,405.1,342.7z"
      />
      <path
        class="st0 op op2"
        d="M489.7,260.5c-1.4-5.1-5.3-2.6-5.3-2.6c-2.1,1.2-3.3,0.1-3.3,0.1c-1-1.1-1.8-2.3-2.4-3.7
      c-1.2-4.2-1.8-8.7-1.7-13.1v-0.5l-0.4,0.2c-1.8,1.1-3.7,2.1-5.7,2.9l0,0c-1.7,0.7-3.5,1.2-5.4,1.6c-1.4,0.2-2.9,0-4.2-0.5
      c0,0-1.5-0.7-1.5-3.3c0,0,0.1-5-4.6-4c0,0-2.4,0.4-2.6-2.5c0,0-1.8,11.6,5.4,11.9l0,0c6.4,0.3,12.8-1.5,18.2-4.9
      c-0.2,6.8,1.4,13.5,4.4,19.5c3.5,7.7,12.3,0.2,12.3,0.2C490.5,263,489.7,260.5,489.7,260.5z"
      />
      <path
        class="st0 op op1"
        d="M516.1,245.8c-3.2-6.2-8.5-3-8.5-3c-2.1,1.4-4.7,1.6-6.9,0.6c-3.1-1.5-5.8-3.9-7.8-6.7
      c-3.2-5.4-5.2-11.4-5.8-17.6l-0.1-0.5l-0.4,0.3c-4.8,3.3-10.4,5.2-16.1,5.8c-3.5,0.1-6.9-0.8-9.8-2.6c-2.2-1.4-3.6-3.7-3.8-6.3
      c0,0-1-6.6-7.4-5.6c-2.1,0.1-3.9-1.3-4.2-3.4c0,0,0.1,11.9,7.2,15.2l0.4,0.2c10.4,5.6,22.9,4.7,33.5-2.1c1.5,13.4,8.7,24.7,19,30.2
      l0.4,0.2c6.8,4,15.4-3.2,15.4-3.2C519.3,248.2,517.1,247.5,516.1,245.8z"
      />
      <path
        class="st0 op op1"
        d="M157.8,340.4c1.2-0.7,2.4-1.5,3.6-2.2l18.9,29.5c0.4,0.6,1.1,0.7,1.7,0.4c0.6-0.4,0.7-1.1,0.4-1.7c0,0,0,0,0,0
      l-18.8-29.4c1.2-0.8,2.4-1.6,3.6-2.4l14.4,20.2c0.4,0.5,1.1,0.7,1.6,0.3c0,0,0,0,0,0c0.5-0.4,0.7-1.1,0.3-1.7l-14.5-20.1
      c1.2-0.9,2.4-1.8,3.6-2.7l6.3,7.9c0.4,0.5,1.2,0.5,1.6,0c0.4-0.4,0.5-1,0.2-1.5l-6.3-7.9c1.2-1,2.4-1.9,3.5-2.9l1.9,2.1
      c0.4,0.5,1.1,0.5,1.6,0.1c0.5-0.4,0.5-1.1,0.1-1.6l-1.9-2.1c1.7-1.5,3.3-3,4.8-4.6l2.3,2.3c0.4,0.4,1.1,0.4,1.6,0c0,0,0,0,0,0
      c0.4-0.4,0.4-1.1,0-1.6l-2.3-2.3c1.1-1.1,2.1-2.2,3.1-3.3l7.9,7c0.5,0.4,1.2,0.3,1.6-0.2c0.3-0.5,0.3-1.1-0.1-1.5l-7.9-7l-1.3,1.4
      c1.5-1.7,2.9-3.4,4.3-5.1c-0.1,0.1-0.2,0.2-0.2,0.3l19.5,15.5c0.6,0.4,1.3,0.2,1.7-0.3c0.3-0.5,0.2-1.1-0.2-1.5l-19.5-15.4
      c0.9-1.1,1.8-2.3,2.6-3.5l28.5,20.2c0.6,0.4,1.3,0.2,1.7-0.3c0.4-0.5,0.2-1.3-0.3-1.7l-28.4-20.2c0.8-1.2,1.6-2.4,2.3-3.6l1.4-2.2
      c0.8-1.2,1.5-2.4,2.2-3.7l30.3,17.1c0.6,0.3,1.3,0.1,1.7-0.5c0.3-0.6,0.1-1.3-0.5-1.7l-30.3-17c0.7-1.3,1.4-2.6,2-3.9l22.1,11
      c0.6,0.3,1.3,0.1,1.6-0.5c0,0,0,0,0,0c0.3-0.6,0-1.3-0.5-1.6l-22.1-11c0.6-1.3,1.3-2.7,1.9-4l9.3,4c0.6,0.2,1.2-0.1,1.5-0.7
      c0.2-0.5,0-1.1-0.6-1.4l-9.3-4c0.6-1.4,1.2-2.8,1.7-4.2l2.7,1c0.6,0.2,1.2-0.1,1.4-0.6c0.2-0.6-0.1-1.2-0.7-1.4l-2.7-1l-0.1,0.4
      c0.8-2.2,1.5-4.5,2.2-6.7l3.3,1c0.6,0.2,1.3-0.2,1.5-0.8c0.2-0.6-0.2-1.3-0.8-1.5l-3.3-1c-0.1,0.5-0.3,1-0.5,1.6c0.6-2,1.1-4,1.6-6
      c0.7-3,0,0.1,0,0.1l10.4,2.5c0.6,0,1.1-0.6,1.1-1.2c0-0.4-0.2-0.8-0.6-0.9l-10.4-2.5c0.3-1.4,0.6-2.9,0.9-4.4l24.7,4.5
      c0.6,0.1,1.3-0.3,1.4-1c0,0,0,0,0,0c0.1-0.7-0.3-1.3-1-1.4c0,0,0,0,0,0l-24.7-4.5c0.2-1.4,0.4-2.9,0.6-4.3l34.9,4.5
      c0.7,0.1,1.3-0.4,1.4-1c0.1-0.7-0.4-1.3-1-1.4c0,0,0,0,0,0l-34.9-4.6c0.2-1.4,0.3-2.8,0.4-4.2l51.1,3.9c0.7,0.1,1.3-0.5,1.4-1.2
      c0.1-0.7-0.5-1.3-1.2-1.4l-51.1-3.9c0.1-1.4,0.2-2.8,0.2-4.2l35.2,0.9c0.7,0,1.2-0.5,1.3-1.2c0-0.7-0.5-1.2-1.2-1.3c0,0,0,0,0,0
      l-35.2-0.8c0-1.5,0-2.9,0-4.3l25.1-0.7c0.7-0.1,1.1-0.7,1.1-1.3c-0.1-0.6-0.5-1-1.1-1.1l-25.2,0.7c-0.1-1.6-0.2-3.2-0.3-4.8
      c0,0.1,0,0.3,0,0.4l10.5-0.8c0.6,0,1.1-0.6,1-1.2c0-0.6-0.6-1.1-1.2-1l-10.5,0.9c-0.2-1.5-0.3-3-0.5-4.5l3.4-0.4
      c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-0.9l-3.4,0.5c-0.3-2.2-0.7-4.4-1.2-6.5l3.9-0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c-0.1-0.5-0.7-0.9-1.2-0.8l-3.9,0.8c-0.3-1.5-0.7-2.9-1.1-4.4l10.9-2.8c0.6-0.2,1-0.8,0.8-1.4c0,0,0,0,0,0c-0.1-0.6-0.8-1-1.4-0.8
      c0,0,0,0,0,0l-11,2.9c-0.4-1.4-0.8-2.8-1.3-4.3l24.5-7.8c0.6-0.2,1-0.9,0.8-1.5c0,0,0,0,0,0c-0.2-0.6-0.9-1-1.5-0.8l-24.6,7.8
      c-0.5-1.4-0.9-2.7-1.4-4.1l33.6-12.6c0.6-0.2,1-0.9,0.8-1.6c-0.2-0.6-0.9-1-1.6-0.8c0,0,0,0-0.1,0l-33.6,12.7
      c-0.5-1.3-1-2.6-1.6-3.9l47.6-20.8c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1-0.8-1.6-0.6l-47.7,20.9c-0.6-1.3-1.2-2.5-1.8-3.8l32.2-16.1
      c0.6-0.3,0.8-1,0.5-1.6c-0.3-0.6-1-0.9-1.7-0.5l-32.2,16.1c-0.5-1-1.1-2-1.6-3c-0.1-0.2-0.3-0.5-0.4-0.7l22.6-12.8
      c0.6-0.3,0.8-1.1,0.5-1.6c-0.3-0.6-1.1-0.8-1.6-0.5l-22.7,12.9c0.3,0.5,0.6,0.9,0.8,1.4c-1-1.7-2.1-3.4-3.1-5.1l9.6-6.2
      c0.5-0.3,0.7-1,0.4-1.6c-0.3-0.5-1-0.7-1.6-0.3c0,0,0,0,0,0l-9.7,6.2c-0.8-1.2-1.7-2.5-2.6-3.7l3.5-2.5c0.5-0.4,0.6-1,0.3-1.5
      c-0.4-0.5-1-0.6-1.5-0.3l-3.5,2.5l0.4,0.6c-1.5-1.9-3-3.9-4.5-5.7l3.8-3.1c0.5-0.4,0.5-1.1,0.2-1.5c0,0,0,0,0,0
      c-0.4-0.5-1.1-0.5-1.6-0.1l-3.8,3.1c-1-1.1-2-2.2-3-3.3l9-8.3c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2
      c-0.1,0-0.1,0.1-0.1,0.1l-9,8.3c-1-1.1-2.1-2.1-3.1-3.1l18.7-19.1c0.5-0.5,0.5-1.2,0-1.7c0,0,0,0,0,0c-0.5-0.5-1.2-0.5-1.7,0
      l-18.7,19.2c-1-1-2.1-1.9-3.2-2.8l24.3-27.7c0.5-0.5,0.4-1.3-0.1-1.7c-0.5-0.5-1.3-0.4-1.7,0.1l-24.3,27.7c-1.1-0.9-2.2-1.8-3.3-2.6
      l32.9-41.5c0.4-0.6,0.1-1.4-0.5-1.8c-0.5-0.3-1.1-0.2-1.5,0.2l-32.9,41.5c-1.1-0.8-2.2-1.7-3.4-2.4l21.4-30.1
      c0.4-0.6,0.3-1.3-0.3-1.7c-0.6-0.4-1.3-0.3-1.7,0.3c0,0,0,0,0,0l-21.4,30.2c-1.2-0.8-2.4-1.6-3.6-2.3l14.4-22.7
      c0.3-0.6,0-1.3-0.6-1.6c-0.5-0.2-1.1-0.1-1.4,0.3l-14.5,22.8l0.7,0.4c-2.1-1.3-4.2-2.5-6.4-3.6c0.6,0.3,1.3,0.6,1.9,1l6.1-10.8
      c0.3-0.6,0.1-1.2-0.4-1.5c-0.5-0.3-1.2-0.1-1.5,0.4c0,0,0,0,0,0l-6.1,10.9c-1.3-0.7-2.6-1.4-3.9-2l2.4-4.8c0.3-0.5,0-1.2-0.5-1.5
      c-0.5-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0l-2.4,4.8c-2-0.9-4-1.8-5.9-2.6l2.3-5.5c0.1-0.6-0.2-1.2-0.8-1.3c-0.5-0.1-0.9,0.1-1.2,0.5
      l-2.3,5.5c-1.4-0.5-2.8-1-4.2-1.5h0.1l4.4-12.6c0.2-0.6-0.1-1.2-0.7-1.4c-0.6-0.2-1.2,0.1-1.4,0.7l-4.5,12.6
      c-1.4-0.4-2.8-0.9-4.1-1.3l7.8-26.7c0.2-0.6-0.2-1.3-0.8-1.5c-0.6-0.2-1.3,0.2-1.5,0.8l-7.8,26.7c-1.4-0.4-2.7-0.7-4.1-1l8.8-36.9
      c0.2-0.7-0.2-1.3-0.8-1.5c-0.7-0.2-1.3,0.2-1.5,0.8c0,0,0,0.1,0,0.1l-8.8,36.9c-1.3-0.3-2.7-0.6-4-0.8l9.7-53.1
      c0.1-0.7-0.3-1.3-1-1.5c-0.7-0.1-1.4,0.3-1.5,1l-9.7,53.2l-4-0.6l4.9-37.7c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.4,1
      c0,0,0,0,0,0l-4.9,37.7c-1.4-0.1-2.8-0.3-4.2-0.4l2.1-27.9c0.1-0.6-0.4-1.2-1.1-1.3c0,0,0,0,0,0c-0.7-0.1-1.2,0.4-1.3,1.1
      c0,0,0,0,0,0l-2.2,28l-4.3-0.2l0.3-13.4c0-0.6-0.5-1.1-1.1-1.2c-0.6,0-1.1,0.5-1.2,1.1c0,0,0,0,0,0l-0.3,13.5c-1.5,0-2.9,0-4.4,0.1
      L90.2,97c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0l0.2,6.3c-2.1,0.1-4.3,0.3-6.4,0.5l-0.7-6.8
      c-0.1-0.6-0.6-1.1-1.2-1c-0.6,0.1-1.1,0.6-1,1.2l0.7,6.8c-1.4,0.2-2.9,0.4-4.3,0.6l-2.1-14c-0.1-0.6-0.6-1.1-1.3-1c0,0,0,0,0,0
      c-0.6,0.1-1.1,0.6-1,1.2c0,0,0,0,0,0.1l2.1,14c-1.4,0.2-2.8,0.5-4.2,0.8l-5.8-28c-0.1-0.6-0.8-1.1-1.4-0.9c-0.6,0.2-0.9,0.8-0.8,1.3
      l5.8,28c-1.3,0.3-2.7,0.6-4,1l-9.8-37.4c-0.2-0.7-0.8-1.1-1.5-0.9c0,0,0,0,0,0c-0.7,0.2-1,0.9-0.9,1.5l9.8,37.5
      c-1.3,0.4-2.6,0.8-3.9,1.2L39.6,56.9c-0.2-0.7-0.9-1-1.6-0.8c-0.7,0.2-1,0.9-0.8,1.6l16.6,52.2c-1.3,0.4-2.6,0.9-3.8,1.4L36.2,75
      c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.2-1,0.9-0.7,1.6l13.7,36.3c-1.3,0.5-2.6,1-3.8,1.6L32.3,87.5c-0.3-0.6-1-0.9-1.6-0.6
      c-0.6,0.3-0.9,1-0.6,1.6c0,0,0,0,0,0l11.5,26.3c-1.3,0.6-2.6,1.2-3.8,1.8L31.5,104c-0.3-0.6-1-0.8-1.5-0.5c-0.6,0.3-0.8,1-0.5,1.5
      l6.3,12.7c-1.3,0.7-2.5,1.3-3.8,2l0,0l-3.4-6c-0.3-0.5-1-0.7-1.5-0.4c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0l3.4,6
      c0,0,0,0,0,0c-2.2,1.3-4.3,2.6-6.3,4c0.3-0.2,0.6-0.4,0.9-0.6l-4.1-6.2c-0.3-0.5-1-0.7-1.5-0.3c0,0,0,0,0,0c-0.5,0.3-0.7,1-0.3,1.5
      l4.1,6.2c-1.2,0.8-2.4,1.6-3.5,2.5l-8.8-11.8c-0.4-0.5-1.1-0.6-1.6-0.2c-0.5,0.4-0.6,1.1-0.2,1.6c0,0,0,0,0,0l8.8,11.8
      c-1.1,0.9-2.2,1.7-3.3,2.6l-18.6-22.4c-0.4-0.5-1.2-0.6-1.7-0.2c0,0,0,0,0,0c-0.5,0.4-0.6,1.2-0.2,1.7l18.6,22.4l0.7-0.6
      c-1.3,1.1-2.5,2.2-3.8,3.3l-26.5-28.8c-0.4-0.5-1.2-0.5-1.7-0.1c0,0,0,0,0,0c-0.5,0.5-0.5,1.2-0.1,1.8l26.5,28.8l1.1-1
      c-1.4,1.2-2.7,2.5-4,3.8l-39.4-38.6c-0.5-0.5-1.3-0.5-1.8,0c0,0,0,0,0,0c-0.5,0.5-0.5,1.3,0,1.8l39.5,38.6c0.3-0.4,0.7-0.7,1.1-1.1
      c-1.3,1.3-2.6,2.7-3.8,4l-29.3-25.8c-0.5-0.4-1.3-0.4-1.7,0.1c-0.5,0.5-0.4,1.3,0.1,1.7c0,0,0,0,0,0l29.3,25.8
      c0.2-0.2,0.4-0.5,0.6-0.7c-1.1,1.3-2.2,2.6-3.3,3.9l-22.7-18c-0.5-0.4-1.3-0.3-1.7,0.2c0,0,0,0,0,0c-0.4,0.5-0.3,1.3,0.2,1.7
      l22.7,18c-0.9,1.1-1.7,2.3-2.5,3.4l-11.7-8.3c-0.5-0.4-1.2-0.2-1.6,0.3c-0.4,0.5-0.2,1.2,0.3,1.6l11.7,8.3c-0.9,1.3-1.7,2.5-2.5,3.8
      l0.1-0.2l-6-3.8c-0.5-0.3-1.2-0.2-1.5,0.3c0,0,0,0,0,0c-0.3,0.5-0.2,1.2,0.3,1.5l6,3.8c-1.5,2.3-2.8,4.7-4.1,7.1
      c0.3-0.5,0.6-1.1,0.9-1.6l-6.6-3.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.5,1.5l6.6,3.6c-1,1.9-2,3.9-2.9,5.9
      c0.3-0.7,0.6-1.4,0.9-2l-13.3-6.4c-0.6-0.3-1.2,0-1.5,0.5c0,0,0,0,0,0c-0.3,0.6,0,1.2,0.5,1.5c0,0,0,0,0,0l13.3,6.3
      c-0.6,1.3-1.2,2.6-1.7,3.9l-26.9-11.1c-0.6-0.2-1.3,0-1.6,0.7c-0.2,0.6,0,1.3,0.7,1.6l26.9,11.1c-0.5,1.3-1,2.6-1.5,3.9l-36.9-13
      c-0.6-0.2-1.3,0.1-1.6,0.7c0,0,0,0,0,0c-0.2,0.6,0.1,1.3,0.8,1.6l36.9,13c-0.4,1.3-0.9,2.6-1.3,3.8l-52.9-15.5
      c-0.7-0.2-1.4,0.2-1.6,0.9c0,0,0,0,0,0c-0.2,0.7,0.2,1.4,0.9,1.6l52.9,15.5c0-0.1,0-0.2,0.1-0.3c-0.4,1.4-0.8,2.9-1.2,4.3l-37.9-9.2
      c-0.7-0.1-1.3,0.3-1.5,0.9c-0.2,0.7,0.3,1.3,0.9,1.5c0,0,0,0,0,0l37.9,9.2c-0.3,1.3-0.6,2.6-0.8,3.9l-28.3-5.2
      c-0.7-0.1-1.3,0.3-1.4,1c0,0,0,0,0,0c-0.1,0.6,0.3,1.3,1,1.4c0,0,0,0,0,0l28.3,5.2c0.1-0.4,0.2-0.8,0.2-1.1
      c-0.4,2.2-0.8,4.4-1.1,6.6c0.1-0.4,0.1-0.9,0.2-1.3l-14-1.8c-0.6-0.1-1.2,0.4-1.3,1c-0.1,0.6,0.4,1.2,1,1.3c0,0,0,0,0,0l14,1.8
      c-0.2,1.4-0.3,2.9-0.5,4.3l-6.8-0.5c-0.6,0-1.1,0.4-1.2,1c0,0.6,0.4,1.1,1,1.2l6.7,0.5c-0.2,2.1-0.3,4.2-0.3,6.4l-7.2,0
      c-0.6,0-1.1,0.5-1.1,1.1v0c0,0.6,0.5,1.1,1.1,1.1l7.2,0c0,1.4,0,2.9,0,4.3l-14.3,0.7c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1
      c0,0,0,0,0,0l14.3-0.7c0.1,1.8,0.2,3.5,0.3,5.2l-0.1-1l-28.5,2.8c-0.6,0.1-1.1,0.6-1.1,1.3c0,0,0,0,0,0c0.1,0.7,0.6,1.1,1.3,1.1
      l28.5-2.8c0.1,1.5,0.3,2.9,0.5,4.4l-38.2,5.5c-0.7,0.1-1.1,0.7-1,1.4c0.1,0.7,0.7,1.2,1.4,1.1l38.2-5.5c0.3,1.9,0.6,3.8,1,5.7
      c-0.1-0.6-0.3-1.3-0.4-1.9l-53.4,11c-0.7,0.2-1.1,0.8-1,1.5c0.2,0.7,0.8,1.1,1.5,1l53.4-11c0.3,1.4,0.6,2.8,1,4.2l-37.2,9.4
      c-0.6,0.2-1,0.9-0.9,1.5c0.2,0.7,0.8,1.1,1.5,0.9l37.2-9.4c0.3,1.3,0.7,2.5,1,3.8l-26.8,8.5c-0.6,0.2-1,0.9-0.8,1.5c0,0,0,0,0,0
      c0.2,0.6,0.9,1,1.5,0.8l26.8-8.5c0.4,1.4,0.9,2.7,1.4,4.1l-12.6,4.7c-0.6,0.2-0.9,0.9-0.7,1.5c0,0,0,0,0,0c0.2,0.6,0.9,0.9,1.5,0.7
      c0,0,0,0,0,0l12.5-4.7c0.5,1.4,1,2.7,1.6,4.1l-5.6,2.4c-0.6,0.2-0.8,0.9-0.6,1.5c0.3,0.6,0.9,0.8,1.5,0.6l5.5-2.4
      c-0.2-0.5-0.4-0.9-0.6-1.4c1,2.4,2.1,4.8,3.3,7.2l-5.7,3c-0.5,0.3-0.7,0.9-0.5,1.5c0.3,0.5,1,0.7,1.5,0.5l5.7-3
      c0.7,1.3,1.3,2.6,2.1,3.9l-11.7,6.9c-0.5,0.3-0.7,1-0.4,1.5c0,0,0,0,0,0c0.3,0.5,1,0.7,1.5,0.4c0,0,0,0,0,0l11.6-6.9
      c0.7,1.2,1.5,2.5,2.2,3.7l-23.2,15.4c-0.5,0.4-0.7,1.1-0.4,1.6c0,0,0,0,0,0c0.4,0.5,1.1,0.7,1.7,0.3l23.1-15.4
      c0.8,1.2,1.6,2.3,2.4,3.5l-30.3,22.5c-0.5,0.4-0.7,1.2-0.3,1.7c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.7,0.3l30.3-22.6
      c0.8,1.1,1.6,2.2,2.5,3.3l-41.3,34.2c-0.5,0.5-0.6,1.3-0.2,1.8c0,0,0,0,0,0c0.4,0.5,1.2,0.6,1.8,0.2l41.3-34.2
      c0.9,1.1,1.7,2.2,2.6,3.2l-27.5,25.3c-0.5,0.5-0.5,1.2-0.1,1.7c0.5,0.5,1.2,0.5,1.7,0.1c0,0,0,0,0,0l27.5-25.3c1,1,1.9,2.1,2.9,3.1
      l-19,19.4c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0,0c0.5,0.5,1.2,0.5,1.7,0c0,0,0,0,0,0l19-19.4c1,1,2,2,3.1,3l-8.2,9.3
      c-0.4,0.5-0.4,1.2,0.1,1.6c0.5,0.4,1.2,0.4,1.6-0.1c0,0,0,0,0,0l8.2-9.3c1.1,1,2.2,2,3.4,2.9l-3.1,4c-0.4,0.5-0.3,1.2,0.2,1.5
      c0.5,0.4,1.2,0.3,1.6-0.2l3.1-3.9c1.7,1.3,3.4,2.7,5.2,4l-3,4.4c-0.3,0.5-0.2,1.2,0.3,1.5c0.5,0.3,1.2,0.2,1.5-0.3l3-4.4l-0.8-0.6
      c1.5,1,3,2.1,4.5,3l-6.5,10.7c-0.3,0.5-0.1,1.2,0.4,1.6c0.5,0.3,1.2,0.2,1.6-0.4c0,0,0,0,0,0l6.5-10.6c1.3,0.8,2.5,1.5,3.8,2.2
      l-12.7,23.5c-0.3,0.6-0.1,1.3,0.5,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0.1,1.6-0.5l12.7-23.5c1.3,0.7,2.5,1.4,3.8,2l-15.7,33.1
      c-0.3,0.6,0,1.3,0.6,1.6c0,0,0,0,0,0c0.6,0.3,1.3,0,1.6-0.6l15.7-33.1c1.3,0.6,2.5,1.2,3.8,1.8l-20,48.6c-0.3,0.7,0,1.4,0.7,1.7
      c0.7,0.3,1.4,0,1.7-0.7c0,0,0,0,0,0l20-48.6c1.3,0.5,2.6,1.1,3.9,1.6l-12.2,34.3c-0.2,0.6,0.1,1.3,0.8,1.6c0.6,0.2,1.3-0.1,1.6-0.7
      c0,0,0,0,0,0l12.1-34.3l-0.8-0.3c1.8,0.7,3.6,1.3,5.5,1.8c-0.2-0.1-0.4-0.1-0.6-0.2l-7.4,25.1c-0.2,0.6,0.2,1.3,0.8,1.5c0,0,0,0,0,0
      c0.6,0.2,1.3-0.2,1.5-0.8c0,0,0,0,0,0l7.4-25.1c-0.2-0.1-0.5-0.2-0.7-0.2c2.2,0.7,4.5,1.3,6.7,1.9l-1.8-0.4l-2.6,11.1
      c-0.1,0.6,0.2,1.2,0.8,1.4c0,0,0,0,0,0c0.6,0.1,1.2-0.2,1.4-0.8c0,0,0,0,0,0l2.6-11.1c1.7,0.4,3.4,0.8,5.2,1.2l-0.8-0.2l-0.7,4
      c-0.1,0.6,0.3,1.2,0.9,1.3c0.6,0.1,1.2-0.3,1.3-0.9l0.7-4c2.2,0.4,4.3,0.8,6.5,1.1l-0.5,4.4c-0.1,0.6,0.4,1.2,1,1.2c0,0,0,0,0,0
      c0.6,0.1,1.2-0.4,1.2-1c0,0,0,0,0,0l0.5-4.3c1.5,0.2,3,0.3,4.5,0.5l-0.7,11.5c0,0.6,0.4,1.2,1.1,1.2c0.6,0,1.2-0.4,1.2-1.1
      c0,0,0,0,0,0l0.7-11.5c1.5,0.1,2.9,0.2,4.4,0.2l-0.2,25.8c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2l0.2-25.7c2,0,4,0,6-0.1
      c-0.6,0-1.1,0.1-1.7,0.1l1.6,35.7c0,0.7,0.6,1.2,1.3,1.2c0,0,0,0,0,0c0.7,0,1.2-0.6,1.2-1.3c0,0,0,0,0,0l-1.6-35.7
      c1.4-0.1,2.8-0.1,4.2-0.2l5,51.3c0.1,0.7,0.7,1.2,1.4,1.2c0.7-0.1,1.2-0.7,1.2-1.4c0,0,0,0,0,0l-5-51.3c1.4-0.1,2.8-0.3,4.2-0.5
      l5.2,35.1c0.1,0.6,0.7,1.1,1.4,1c0.7-0.1,1.1-0.7,1-1.4l-5.3-35l-1.5,0.2c1.9-0.3,3.8-0.6,5.7-0.9l5.1,24.7c0.1,0.6,0.7,1.1,1.4,0.9
      c0,0,0,0,0,0c0.6-0.1,1.1-0.8,0.9-1.4l-5.1-24.7c-0.4,0.1-0.8,0.2-1.2,0.2c1.9-0.4,3.7-0.8,5.6-1.2l2.7,10.2c0.2,0.6,0.8,1,1.4,0.8
      c0,0,0,0,0,0c0.6-0.2,1-0.8,0.8-1.4l-2.7-10.2c1.4-0.4,2.8-0.8,4.2-1.2l1.1,3.5c0.2,0.6,0.8,0.9,1.4,0.8c0.6-0.2,0.9-0.8,0.8-1.4
      c0-0.2,0-0.4,0-0.6l-1-3c1.4-0.4,2.8-0.9,4.1-1.4l3.8,10.2c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.5l-3.8-10.2
      c1.4-0.5,2.8-1.1,4.1-1.7l10.1,23.1c0.3,0.6,1,0.9,1.6,0.6c0.6-0.3,0.9-1,0.6-1.6l-10.1-23c-0.7,0.3-1.4,0.6-2.1,0.9
      c2-0.9,4-1.8,6-2.7l15.7,31.5c0.3,0.6,1,0.9,1.7,0.6s0.9-1,0.6-1.7l-15.7-31.4c1.3-0.6,2.5-1.3,3.8-2 M155.9,338
      c-59.5,33.8-135.1,13-169-46.5c-33.8-59.5-13-135.1,46.5-169c59.5-33.8,135.1-13,169,46.5C236.1,228.5,215.3,304.1,155.9,338z"
      />
      <g class="op op2 pc">
        <path
          class="st0 st1"
          d="M177.9,464.8c6,10.6,19.4,14.3,30,8.3c0,0,0,0,0,0c1.4-0.8,2.7-1.7,3.8-2.8c2.6,4,6.8,6.7,11.5,7.3l13.1,3.6
        L240,468c1.9-4.4,1.8-9.3-0.3-13.6c1.5-0.5,3-1.1,4.4-1.9c10.5-6,14.2-19.5,8.2-30c-4.2-7.3-12.1-11.5-20.4-11l-5.1-9l-13.3,7.5
        c-7.3,4.2-9.9,13.5-5.7,20.8c-4.2-7.3-13.5-9.9-20.8-5.7l-13.3,7.5l5.2,9.1C174.1,448.5,173.7,457.5,177.9,464.8z"
        />
        <path
          class="st0"
          d="M186.8,464.9c4.9,8.7,15.9,11.7,24.5,6.8c0,0,0,0,0.1,0c1.1-0.6,2.2-1.4,3.1-2.3c2.1,3.3,5.6,5.5,9.5,6
      l10.8,2.9l3-10.8c1.6-3.6,1.5-7.7-0.3-11.2c1.3-0.4,2.5-0.9,3.6-1.6c8.7-4.8,11.8-15.8,7-24.5c-3.4-6.1-10-9.7-17-9.2l-4.2-7.4
      l-10.9,6.2c-6,3.4-8.1,11-4.7,17c-3.4-6-11-8.1-17-4.8c0,0,0,0-0.1,0l-10.9,6.2l4.2,7.5C183.6,451.6,183.3,459,186.8,464.9z"
        />
        <path
          class="st0"
          d="M248.3,451.6c-11.8,6.7-26.8,2.6-33.5-9.2c-6.7-11.8-2.6-26.8,9.2-33.5s26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C264.2,429.8,260.1,444.8,248.3,451.6 M249.2,453.1c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.7,7.2-17.1,23.3-9.9,36c0,0,0,0,0,0C220.4,455.9,236.5,460.3,249.2,453.1C249.2,453.2,249.2,453.1,249.2,453.1L249.2,453.1z"
        />
        <path
          class="st0"
          d="M204.9,476.2c-11.8,6.7-26.8,2.6-33.5-9.2s-2.6-26.8,9.2-33.5c11.8-6.7,26.8-2.6,33.5,9.2c0,0,0,0,0,0
      C220.8,454.5,216.7,469.5,204.9,476.2 M205.7,477.8c12.7-7.2,17.1-23.3,9.9-36c-7.2-12.7-23.3-17.1-36-9.9
      c-12.6,7.2-17.1,23.3-9.9,35.9C176.9,480.6,193,485,205.7,477.8C205.7,477.8,205.7,477.8,205.7,477.8z"
        />
        <path
          class="st0"
          d="M215.2,440.5l-8.2-14.4c-4.5-7.9-1.7-18,6.2-22.5l14.4-8.2l8.1,14.4c4.5,7.9,1.7,18-6.2,22.5L215.2,440.5
      M214.6,442.9l15.9-9c8.8-5,11.9-16.2,6.9-25l-9-15.9l-15.9,9c-8.8,5-11.9,16.2-6.9,24.9c0,0,0,0,0,0L214.6,442.9z"
        />
        <path
          class="st0"
          d="M197.8,450.4c-7.9,4.5-18,1.8-22.5-6.1c0,0,0,0,0,0l-8.2-14.4l14.4-8.2c7.9-4.5,18-1.7,22.5,6.2l8.2,14.3
      L197.8,450.4 M198.7,451.9l15.9-9l-9-15.9c-5-8.8-16.2-11.9-25-6.9c0,0,0,0,0,0l-15.9,9l9,15.9C178.7,453.9,189.8,457,198.7,451.9
      C198.6,452,198.7,452,198.7,451.9L198.7,451.9z"
        />
        <path
          class="st0"
          d="M238.7,485.3l-14-3.8h-0.1h-0.1c-9.6-1.2-16.4-10-15.2-19.6c0.2-1.3,0.5-2.6,0.9-3.9v-0.1
      c0.2-0.5,0.4-1.1,0.7-1.6l0,0l0,0l4.8-11.4l12.2,1.7h0.1c0.5,0,1.1,0.1,1.7,0.2h0.1c9.5,1.8,15.8,11,14,20.5
      c-0.2,1.3-0.6,2.6-1.2,3.8v0.1v0.1L238.7,485.3 M240,487.5l4.4-15.8c4.3-9.8-0.2-21.2-10-25.4c-1.3-0.6-2.7-1-4.2-1.3l0,0
      c-0.6-0.1-1.3-0.2-1.9-0.3l-13.6-1.8l-5.5,12.6c-0.3,0.6-0.5,1.2-0.8,1.8l0,0l0,0c-3.7,10,1.4,21.2,11.4,24.9c1.4,0.5,2.8,0.9,4.3,1
      L240,487.5L240,487.5z"
        />
        <path
          class="st2"
          d="M204.9,451.9c-3.5-6.2-1.4-14,4.8-17.6c6.2-3.5,14-1.4,17.6,4.8s1.4,14-4.8,17.6c0,0,0,0,0,0
      C216.2,460.2,208.4,458.1,204.9,451.9z"
        />
        <polygon
          class="st2"
          points="215.7,445.7 216.5,445.3 226.7,463.4 226,463.8 "
        />
        <path
          class="st2"
          d="M224.3,464.8c-0.6-1.1-0.2-2.6,0.9-3.2s2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c0,0,0,0,0,0
      C226.4,466.3,225,465.9,224.3,464.8z"
        />
        <polygon
          class="st2"
          points="197.8,455.4 215.9,445.1 216.3,445.9 198.2,456.2 "
        />
        <path
          class="st2"
          d="M196.8,453.8c1.1-0.6,2.6-0.2,3.2,0.9c0.6,1.1,0.2,2.6-0.9,3.2c-1.1,0.6-2.6,0.2-3.2-0.9c0,0,0,0,0,0
      C195.3,455.9,195.7,454.4,196.8,453.8C196.8,453.8,196.8,453.8,196.8,453.8z"
        />
        <rect
          x="214.8"
          y="439.9"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -188.1656 168.7628)"
          class="st2"
          width="20.8"
          height="0.9"
        />
        <path
          class="st2"
          d="M233,433.2c-1.1,0.6-1.5,2.1-0.9,3.2c0.6,1.1,2.1,1.5,3.2,0.9s1.5-2.1,0.9-3.2c0,0,0,0,0,0
      C235.6,433,234.2,432.6,233,433.2z"
        />
        <path
          class="st0"
          d="M213.1,447.2c-0.9-1.7-0.4-3.8,1.3-4.7c1.7-0.9,3.8-0.4,4.7,1.3c0.9,1.7,0.4,3.8-1.3,4.7c0,0,0,0,0,0
      C216.1,449.5,214,448.9,213.1,447.2C213.1,447.3,213.1,447.2,213.1,447.2z"
        />
        <path
          class="st0"
          d="M194.1,406.8c3.5-2,8.3-1.2,12.6,2.2l-1.1,1.4c-3.6-2.9-7.7-3.6-10.6-2c-2.9,1.6-4.3,5.5-3.7,10.2l-1.8,0.2
      C188.8,413.5,190.6,408.9,194.1,406.8z"
        />
        <rect
          x="191.3"
          y="404.7"
          transform="matrix(0.2654 -0.9641 0.9641 0.2654 -250.5467 488.2404)"
          class="st0"
          width="7.7"
          height="7.7"
        />
        <path
          class="st0"
          d="M250.6,509c-2-2.1-2.8-4.6-1.8-5.5s3.4,0,5.4,2.1s2.8,4.6,1.9,5.5C255.1,512.1,252.7,511.2,250.6,509z"
        />
        <path
          class="st0"
          d="M241.8,506.5c-1.5,2.1-3.6,3.3-4.6,2.6c-1-0.7-0.6-3,1-5.2c1.6-2.1,3.6-3.3,4.6-2.6S243.4,504.4,241.8,506.5z"
        />
        <path
          class="st0"
          d="M253.1,496.9c-2.1,0.8-4.2,0.5-4.5-0.5s1.1-2.4,3.2-3.2c2.1-0.8,4.2-0.5,4.5,0.5
      C256.7,494.7,255.2,496.1,253.1,496.9z"
        />
        <rect
          x="243.7"
          y="484.7"
          transform="matrix(0.8694 -0.4941 0.4941 0.8694 -212.8196 185.46)"
          class="st0"
          width="1.5"
          height="21.2"
        />
        <path
          class="st0"
          d="M240.8,487.4c6.7,11.8,2,14.7,1.8,14.8l-0.8-1.3c0,0,3.5-2.5-2.3-12.7L240.8,487.4z"
        />
        <path
          class="st0"
          d="M242.7,491.1c3.1,5.5,7.3,4.4,7.5,4.4l0.4,1.5c-0.1,0-5.5,1.4-9.2-5.1L242.7,491.1z"
        />
        <path
          class="st0"
          d="M152.6,477.5c2.8-0.8,5.3-0.3,5.7,1s-1.6,3-4.4,3.7c-2.8,0.7-5.3,0.3-5.7-1C147.9,480,149.8,478.3,152.6,477.5z
      "
        />
        <path
          class="st0"
          d="M156.2,469.2c-1.5-2.2-1.8-4.6-0.8-5.3s3.1,0.5,4.6,2.8s1.8,4.6,0.8,5.3S157.8,471.5,156.2,469.2z"
        />
        <path
          class="st0"
          d="M165.6,483.3c-1-2.1-1-4.1,0-4.6s2.6,0.8,3.6,2.9s1,4.1,0,4.6S166.6,485.4,165.6,483.3z"
        />
        <rect
          x="156.2"
          y="473.8"
          transform="matrix(0.8687 -0.4953 0.4953 0.8687 -213.4897 143.7556)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M171.2,469.9l0.8,1.3c-5.1,2.9-8.8,3.8-10.9,2.6c-0.9-0.5-1.6-1.5-1.6-2.6h1.5c0,0.6,0.4,1.1,0.9,1.3
      C162.8,473,165.2,473.3,171.2,469.9z"
        />
        <path
          class="st0"
          d="M170.1,470.5l0.8,1.3c-5.5,3.1-4.5,7.3-4.5,7.3l-1.5,0.4C164.8,479.4,163.5,474.2,170.1,470.5z"
        />
        <path
          class="st0"
          d="M275.6,407.1c-2.4,2.4-3.5,5.2-2.4,6.3c1.1,1.1,4,0.2,6.4-2.2c2.4-2.4,3.5-5.2,2.4-6.3S278,404.8,275.6,407.1z"
        />
        <path
          class="st0"
          d="M266.7,407c-1.3-2-3-3.1-3.9-2.5s-0.7,2.6,0.6,4.6s3,3.1,3.9,2.5S267.9,408.9,266.7,407z"
        />
        <path
          class="st0"
          d="M274.7,422.5c-1.2-2.5-3-4.1-4-3.6s-0.8,3,0.4,5.5s3.1,4.1,4,3.6C276.1,427.5,276,425,274.7,422.5z"
        />
        <rect
          x="259.1"
          y="415.4"
          transform="matrix(0.8691 -0.4945 0.4945 0.8691 -170.8213 186.6468)"
          class="st0"
          width="16.5"
          height="1.5"
        />
        <path
          class="st0"
          d="M266.9,410.1c0.9,0.6,1.4,1.6,1.4,2.7c0,2.4-2.7,5.1-7.8,8l-0.8-1.3c6-3.4,7-5.7,7-6.7c0-0.5-0.2-1.1-0.7-1.4
      L266.9,410.1z"
        />
        <path
          class="st0"
          d="M266.5,417.2c1.8,0,3.5,0.6,4.9,1.8l-1.1,1.1c-0.1-0.1-3.1-3-8.6,0.1l-0.8-1.3
      C262.6,417.9,264.5,417.3,266.5,417.2z"
        />
      </g>
      <rect
        x="254.7"
        y="325"
        transform="matrix(0.8777 -0.4792 0.4792 0.8777 -125.6418 163.5741)"
        class="st0 op op10"
        width="6"
        height="6"
      />
      <rect
        x="277.7"
        y="330.1"
        transform="matrix(0.789 -0.6144 0.6144 0.789 -145.3814 242.6519)"
        class="st0 op op9"
        width="5.7"
        height="5.7"
      />
      <rect
        x="302.5"
        y="333.2"
        transform="matrix(0.7508 -0.6605 0.6605 0.7508 -145.8386 285.39)"
        class="st0 op op8"
        width="5.6"
        height="5.6"
      />
      <rect
        x="323.5"
        y="332.9"
        transform="matrix(0.6543 -0.7562 0.7562 0.6543 -141.0644 362.7884)"
        class="st0 op op7"
        width="5.6"
        height="5.6"
      />
      <rect
        x="395.2"
        y="313.8"
        transform="matrix(0.316 -0.9488 0.9488 0.316 -28.186 594.4554)"
        class="st0 op op5"
        width="5.9"
        height="5.9"
      />
      <rect
        x="358.4"
        y="326.7"
        transform="matrix(0.5219 -0.853 0.853 0.5219 -108.4008 465.6359)"
        class="st0 op op6"
        width="5.6"
        height="5.6"
      />
      <rect
        x="411.8"
        y="303.3"
        transform="matrix(0.2103 -0.9776 0.9776 0.2103 27.9992 647.9564)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="460.6"
        y="334.4"
        transform="matrix(4.710640e-02 -0.9989 0.9989 4.710640e-02 104.6518 785.1971)"
        class="st0 op op4"
        width="6.6"
        height="6.6"
      />
      <rect
        x="470.1"
        y="243.4"
        transform="matrix(0.9596 -0.2813 0.2813 0.9596 -50.2987 143.1508)"
        class="st0 op op2"
        width="6.6"
        height="6.6"
      />
      <rect
        x="480.3"
        y="219.2"
        transform="matrix(0.9117 -0.4108 0.4108 0.9117 -48.7281 218.2997)"
        class="st0 op op1"
        width="6.6"
        height="6.6"
      />
      <g class="r30">
        <path
          class="st0 op op6"
          d="M770.7,358.9c-1.6-1.5-4.1-1.4-5.7,0.2c-1.5,1.6-1.4,4.1,0.2,5.7c1.6,1.5,4.1,1.4,5.6-0.2
      C772.4,363,772.3,360.5,770.7,358.9C770.7,359,770.7,358.9,770.7,358.9z"
        />
        <path
          class="st0 op op5"
          d="M729.6,381c-3-3.8,1-5.4,1-5.4c1.1-0.3,1.9-1.3,1.9-2.4c-0.2-1.3-0.7-2.6-1.4-3.8c-2.4-3.2-5.3-6.1-8.6-8.5
      l-0.4-0.3l0.4-0.2c1.8-0.7,3.6-1.6,5.3-2.6l0,0c1.5-0.9,2.9-1.9,4.2-3c0.9-0.9,1.6-2.1,2-3.4c0,0,0.3-1.5-1.6-2.9
      c0,0-3.7-2.7-0.4-5.6c0.8-0.8,0.8-2.1,0-3c-0.1-0.1-0.2-0.2-0.4-0.3c0,0,9.5,5.1,5.7,10.5l0,0c-3.4,4.8-8.1,8.5-13.6,10.6
      c5,3.6,9.1,8.4,11.8,14c3.8,6.8-6.6,9.1-6.6,9.1c1-0.4,1.5-1.6,1.1-2.6C729.8,381.3,729.7,381.1,729.6,381z"
        />
        <path
          class="st0 op op4"
          d="M704.2,392.2c-2.8-5.8,2.5-7.9,2.5-7.9c2.1-0.8,3.7-2.5,4.3-4.7c0.6-3.1,0.4-6.4-0.6-9.4
      c-2.2-5.3-5.5-10.1-9.7-14l-0.3-0.3l0.4-0.1c5-1.7,9.6-4.7,13.2-8.6c2-2.5,3.2-5.5,3.6-8.6c0.2-2.4-0.8-4.7-2.5-6.3
      c0,0-4.3-4.4,0-8.5c1.3-1.5,1.2-3.6-0.2-5c0,0,8.7,6.6,7.2,13.7l-0.1,0.4c-1.7,10.7-9.2,19.4-20.1,23.4c9,8.5,13.3,20,11.6,30.6
      c0,0.1,0,0.3-0.1,0.4c-0.8,7.2-10.8,9.5-10.8,9.5C704.2,395.9,704.9,394,704.2,392.2z"
        />
        <path
          class="st0 op op3"
          d="M669.9,384.9c-1.2-3.5,0.6-7.4,4-8.8c2.4-0.9,4.3-2.8,5.2-5.2c1.2-3.4,1.5-7,1-10.5c-1.3-5.8-3.9-11.3-7.7-15.9
      l-0.3-0.4l0.5-0.1c5.7-1.9,10.9-5.1,15.1-9.3c2.5-2.7,4.3-6,5.2-9.5c0.6-2.5,0-5.1-1.5-7.1c-2.2-3-1.5-7.2,1.5-9.5
      c1.5-1.5,1.8-3.8,0.7-5.6c0,0,7.9,7.6,5.1,15.4l-0.1,0.5c-3.7,12-13,21.5-24.9,25.6c7.8,9.7,10.4,22.6,6.8,34.5
      c0,0.2-0.1,0.3-0.2,0.5c-2,8-12.9,10.3-12.9,10.3C669.3,389.1,670.4,387,669.9,384.9z"
        />
        <rect
          x="725.5"
          y="358.5"
          transform="matrix(0.8026 -0.5965 0.5965 0.8026 -71.8132 505.9555)"
          class="st0 op op5"
          width="6.1"
          height="6.1"
        />
        <rect
          x="703.7"
          y="354.2"
          transform="matrix(0.875 -0.4842 0.4842 0.875 -84.5816 386.8404)"
          class="st0 op op4"
          width="6.1"
          height="6.1"
        />
        <path
          class="st0 op op2"
          d="M640.4,356.5c-0.7-5.4,4.2-5.8,4.2-5.8c1.9,0,3.7-1.1,4.7-2.7c1.3-2.4,2-5.1,2-7.9c-0.3-4.9-1.8-9.7-4.2-14
      l-0.2-0.4h0.4c4.6-0.1,9-1.2,13-3.5c2.3-1.5,4.1-3.6,5.2-6.1c0.8-1.9,0.6-4-0.3-5.8c0,0-2.3-4.7,2.3-6.9c1.4-0.8,1.9-2.6,1.2-4.1
      c0,0,5.3,7.7,2.2,13.1l-0.2,0.4c-4.2,8.2-12.6,13.4-22.6,13.6c5,9.4,5.5,19.9,1.2,28.1c0,0.1-0.1,0.2-0.2,0.3
      c-2.6,5.6-11.4,4.8-11.4,4.8C639.5,359.5,640.6,358.1,640.4,356.5z"
        />
        <rect
          x="649.2"
          y="325.6"
          transform="matrix(0.9825 -0.1865 0.1865 0.9825 -49.8557 127.4308)"
          class="st0 op op2"
          width="6.1"
          height="6.1"
        />
        <rect
          x="674.6"
          y="342.5"
          transform="matrix(0.9275 -0.3738 0.3738 0.9275 -80.0531 278.3417)"
          class="st0 op op3"
          width="6.1"
          height="6.1"
        />
        <path
          class="st3 an2"
          d="M613,155.1c-41.5,80,10.9,217,155.5,207.4"
        />
      </g>
      <g>
        <line
          class="st3 an1"
          x1="656.5"
          y1="168.9"
          x2="580.6"
          y2="271.1"
        />

        <path
          class="st3 an3-1"
          d="M493.6,141.7C480.7,62.1,408.6,4.3,322.3,4.3c-10.3,0-20.6,0.8-30.7,2.5c-46.9,7.6-88.2,31.8-116.3,68 s-39.7,80.2-32.6,123.9c12.9,79.6,84.9,137.4,171.2,137.4v0c10.3,0,20.6-0.9,30.7-2.5c46.9-7.6,88.2-31.8,116.3-68 S500.7,185.4,493.6,141.7"
        />
        <path
          class="st3 an6"
          d="M461,265.6c-35.1,42.9,11.4,107,59.9,85.6"
        />
      </g>
      <line
        class="st3 an6"
        x1="155.8"
        y1="340"
        x2="196.1"
        y2="410.1"
      />
    </svg>
    <div v-if="!isMobile">
      <div class="container flex-jb ">
        <div>
          <h3 class="title-block flex-ac flex-jb">
            <img
              src="./s2/l.png"
              alt=""
              class="title-l"
            >
            <div class="title">區域環境</div>
            <img
              src="./s2/l.png"
              alt=""
              class="title-r"
            >
          </h3>
          <div class="content">
            <h3 class="title">完全學區</h3>
            <h3 class="desc">明星雙語學區，全齡優生</h3>
            <div class="text">完整十二年國教一應俱全，除了五育俱全明星新北高中之外，碧華國中，碧華國小以及五華國小皆為區域內口碑名校！ 四座全能國中小及高中加持，另有新北最大雙語幼兒園《加州森林國際》都選擇在此駐點，讓孩子精緻就學，學區步行距離5分鐘，小孩安全，爸媽放心</div>
          </div>
        </div>
        <div
          class="slide relative"
          data-aos="fade"
          data-aos-delay="400"
        >
          <div class="border"></div>
          <div class="slide-text">{{slideList1[slideIndex1].text}}</div>
          <img
            :src="slide.src"
            v-for="(slide, index) in slideList1"
            :class="`slide-img ${slideIndex1 === index ? 'active' : ''}`"
            :key="`s2-1-slide-${index}`"
          />
          <div class="arrows absolute">
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-l"
              @click="addMultiIndex(1)"
            >
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-r"
              @click="decMultiIndex(1)"
            >
          </div>
          <div class="indigator-list flex-c">
            <div
              @click="setIndex(1, index)"
              :class="`indigator ${slideIndex1 === index ? 'active' : ''}`"
              v-for="(item, index) in slideList1"
              :key="`indigator-1-${index}`"
            ></div>
          </div>
        </div>
      </div>
      <div class="container flex-ac flex-jb">
        <div
          class="slide wide relative"
          data-aos="fade"
          data-aos-delay="400"
        >
          <div class="border"></div>
          <div class="slide-text">{{slideList2[slideIndex2].text}}</div>
          <img
            :src="slide.src"
            v-for="(slide, index) in slideList2"
            :class="`slide-img ${slideIndex2 === index ? 'active' : ''}`"
            :key="`s2-1-slide-${index}`"
          />
          <div class="arrows absolute">
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-l"
              @click="addMultiIndex(2)"
            >
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-r"
              @click="decMultiIndex(2)"
            >
          </div>
          <div class="indigator-list flex-c">
            <div
              @click="setIndex(2, index)"
              :class="`indigator ${slideIndex2 === index ? 'active' : ''}`"
              v-for="(item, index) in slideList2"
              :key="`indigator-1-${index}`"
            ></div>
          </div>
        </div>
        <div class="content short">
          <h3 class="title">燙金雙捷，漲幅無限</h3>
          <h3 class="desc">一橋牽雙北，效率交通</h3>
          <div class="text">環狀線X新莊蘆洲線燙金雙捷。未來捷運一站横跨淡水河，一橋重陽即達士林，天母，悠遊雙首都零距離。菁華交通匯集集賢路，三信街，五華街;行經環河快速道路與64號快速道路高速接軌，距離中山交流道，重慶北路交流道及三重交流道皆5分鐘車程。直達繁華，效率無與倫比。</div>
        </div>
      </div>
      <div class="container flex-ac flex-jb ">
        <div class="content">
          <h3 class="title">水岸綠地</h3>
          <h3 class="desc">淡水重陽公園，水岸美地</h3>
          <div class="text">萬坪淡水河畔，懂生活的人最想追求的沃美之地。傍水岸，享清新的新鮮空氣無限暢飲，重陽橋重劃區陽光公園，集智綠地集賢環保公園，淡水河畔公園等..共8900多坪綠地。小散步，大渡假，自由切換生活節奏。</div>
        </div>
        <div
          class="slide relative"
          data-aos="fade"
          data-aos-delay="400"
        >
          <div class="border"></div>
          <div class="slide-text">{{slideList3[slideIndex3].text}}</div>
          <img
            :src="slide.src"
            v-for="(slide, index) in slideList3"
            :class="`slide-img ${slideIndex3 === index ? 'active' : ''}`"
            :key="`s2-1-slide-${index}`"
          />
          <div class="arrows absolute">
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-l"
              @click="addMultiIndex(3)"
            >
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-r"
              @click="decMultiIndex(3)"
            >
          </div>
          <div class="indigator-list flex-c">
            <div
              @click="setIndex(3, index)"
              :class="`indigator ${slideIndex3 === index ? 'active' : ''}`"
              v-for="(item, index) in slideList3"
              :key="`indigator-1-${index}`"
            ></div>
          </div>
        </div>
      </div>
      <div class="container flex-ac flex-jb">
        <div
          class="slide wide relative"
          data-aos="fade"
          data-aos-delay="400"
        >
          <div class="border"></div>
          <div class="slide-text">{{slideList4[slideIndex4].text}}</div>
          <img
            :src="slide.src"
            v-for="(slide, index) in slideList4"
            :class="`slide-img ${slideIndex4 === index ? 'active' : ''}`"
            :key="`s2-1-slide-${index}`"
          />
          <div class="arrows absolute">
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-l"
              @click="addMultiIndex(4)"
            >
            <img
              src="./s2/arrow.png"
              alt=""
              class="arrow-r"
              @click="decMultiIndex(4)"
            >
          </div>
          <div class="indigator-list flex-c">
            <div
              @click="setIndex(4, index)"
              :class="`indigator ${slideIndex4 === index ? 'active' : ''}`"
              v-for="(item, index) in slideList4"
              :key="`indigator-1-${index}`"
            ></div>
          </div>
        </div>
        <div class="content short">
          <h3 class="title">繁華商圈</h3>
          <h3 class="desc">家樂福五華街，繁華商圈</h3>
          <div class="text">前有24小時家樂福，後有五華街傳統商圈，現代與傳統一併俱全。整齊蒼綠的街廓，綠化公園及開放空間，重劃區超低建蔽率，市容之美無話可說，離囂不離塵，這才是真正的俱生活機能的「現代化重劃區」，是您宜居移居的新選擇。
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>
    <div
      v-else
      class=""
    >
      <h3 class="title-block flex-ac flex-jb">
        <img
          src="./s2/l.png"
          alt=""
          class="title-l"
        >
        <div class="title">區域環境</div>
        <img
          src="./s2/l.png"
          alt=""
          class="title-r"
        >
      </h3>
      <div
        class="slide relative"
        data-aos="fade"
        data-aos-delay="400"
      >
        <div class="slide-text">{{slideList1[slideIndex1].text}}</div>
        <img
          :src="slide.src"
          v-for="(slide, index) in slideList1"
          :class="`slide-img ${slideIndex1 === index ? 'active' : ''}`"
          :key="`s2-1-slide-${index}`"
        />
        <div class="arrows absolute">
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-l"
            @click="addMultiIndex(1)"
          >
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-r"
            @click="decMultiIndex(1)"
          >
        </div>
        <div class="indigator-list flex-c">
          <div
            @click="setIndex(1, index)"
            :class="`indigator ${slideIndex1 === index ? 'active' : ''}`"
            v-for="(item, index) in slideList1"
            :key="`indigator-1-${index}`"
          ></div>
        </div>
      </div>
      <div class="content">
        <h3 class="title">完全學區</h3>
        <h3 class="desc">明星雙語學區，全齡優生</h3>
        <div class="text">完整十二年國教一應俱全，除了五育俱全明星新北高中之外，碧華國中，碧華國小以及五華國小皆為區域內口碑名校！ 四座全能國中小及高中加持，另有新北最大雙語幼兒園《加州森林國際》都選擇在此駐點，讓孩子精緻就學，學區步行距離5分鐘，小孩安全，爸媽放心</div>
      </div>

      <div
        class="slide relative"
        data-aos="fade"
        data-aos-delay="400"
      >
        <div class="slide-text">{{slideList2[slideIndex2].text}}</div>
        <img
          :src="slide.src"
          v-for="(slide, index) in slideList2"
          :class="`slide-img ${slideIndex2 === index ? 'active' : ''}`"
          :key="`s2-1-slide-${index}`"
        />
        <div class="arrows absolute">
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-l"
            @click="addMultiIndex(2)"
          >
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-r"
            @click="decMultiIndex(2)"
          >
        </div>
        <div class="indigator-list flex-c">
          <div
            @click="setIndex(2, index)"
            :class="`indigator ${slideIndex2 === index ? 'active' : ''}`"
            v-for="(item, index) in slideList2"
            :key="`indigator-1-${index}`"
          ></div>
        </div>
      </div>

      <div class="content short">
        <h3 class="title">燙金雙捷，漲幅無限</h3>
        <h3 class="desc">一橋牽雙北，效率交通</h3>
        <div class="text">環狀線X新莊蘆洲線燙金雙捷。未來捷運一站横跨淡水河，一橋重陽即達士林，天母，悠遊雙首都零距離。菁華交通匯集集賢路，三信街，五華街;行經環河快速道路與64號快速道路高速接軌，距離中山交流道，重慶北路交流道及三重交流道皆5分鐘車程。直達繁華，效率無與倫比。</div>
      </div>
      <div
        class="slide relative"
        data-aos="fade"
        data-aos-delay="400"
      >
        <div class="slide-text">{{slideList3[slideIndex3].text}}</div>
        <img
          :src="slide.src"
          v-for="(slide, index) in slideList3"
          :class="`slide-img ${slideIndex3 === index ? 'active' : ''}`"
          :key="`s2-1-slide-${index}`"
        />
        <div class="arrows absolute">
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-l"
            @click="addMultiIndex(3)"
          >
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-r"
            @click="decMultiIndex(3)"
          >
        </div>
        <div class="indigator-list flex-c">
          <div
            @click="setIndex(3, index)"
            :class="`indigator ${slideIndex3 === index ? 'active' : ''}`"
            v-for="(item, index) in slideList3"
            :key="`indigator-1-${index}`"
          ></div>
        </div>
      </div>
      <div class="content">
        <h3 class="title">水岸綠地</h3>
        <h3 class="desc">淡水重陽公園，水岸美地</h3>
        <div class="text">萬坪淡水河畔，懂生活的人最想追求的沃美之地。傍水岸，享清新的新鮮空氣無限暢飲，重陽橋重劃區陽光公園，集智綠地集賢環保公園，淡水河畔公園等..共8900多坪綠地。小散步，大渡假，自由切換生活節奏。</div>
      </div>
      <div
        class="slide relative"
        data-aos="fade"
        data-aos-delay="400"
      >
        <div class="slide-text">{{slideList4[slideIndex4].text}}</div>
        <img
          :src="slide.src"
          v-for="(slide, index) in slideList4"
          :class="`slide-img ${slideIndex4 === index ? 'active' : ''}`"
          :key="`s2-1-slide-${index}`"
        />
        <div class="arrows absolute">
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-l"
            @click="addMultiIndex(4)"
          >
          <img
            src="./s4/arrow.png"
            alt=""
            class="arrow-r"
            @click="decMultiIndex(4)"
          >
        </div>
        <div class="indigator-list flex-c">
          <div
            @click="setIndex(4, index)"
            :class="`indigator ${slideIndex4 === index ? 'active' : ''}`"
            v-for="(item, index) in slideList4"
            :key="`indigator-1-${index}`"
          ></div>
        </div>
      </div>
      <div class="content bot">
        <h3 class="title">繁華商圈</h3>
        <h3 class="desc">家樂福五華街，繁華商圈</h3>
        <div class="text">前有24小時家樂福，後有五華街傳統商圈，現代與傳統一併俱全。整齊蒼綠的街廓，綠化公園及開放空間，重劃區超低建蔽率，市容之美無話可說，離囂不離塵，這才是真正的俱生活機能的「現代化重劃區」，是您宜居移居的新選擇。
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#section2 .svg {
  .st0 {
    fill: #3b5662;
  }
  .st1 {
    opacity: 0.45;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    stroke: #3b5662;
    stroke-width: 3;
  }
}

@media screen and (max-width: 767px) {
  .svg {
    .pc {
      display: none;
    }
    .r30 {
      transform: rotate(-30deg);
      transform-origin: 68% 54%;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.relative {
  // height: 100vh;
  overflow: hidden;
  background: #d9d7d2;
}

#svg3 {
  top: 0;
  left: auto;
  right:0;
  transform:translate(9%, -50%) scaleX(-1);
  width: size(840);
}
#svg4 {
  top: size(1700);
  left:0;
  transform:translate(-10%, -50%);
  width: size(840);
}
#svg5 {
  top:auto;
  bottom: 0;
  left: auto;
  right: size(-30);
  transform:translate(0%, 25%) scale(-1, -1);
  width: size(840);
}
.bg1,
.bg2,
.bg3 {
  width: 100vw;
  height: auto;
  position: absolute;
  display: block;
  object-fit: cover;
}

.bg1 {
  top: 0;
  left: 0;
}

.bg2 {
  left: 0;
  top: size(1400);
}

.bg3 {
  bottom: 0;
  right: 0;
}

.bottom {
  width: 100vw;
  height: size(470);
}

.container {
  position: relative;
  width: size(1660);
  margin-left: size(150);
  margin-top: size(145);
}

.title-block {
  margin-bottom: size(75);
  .title {
    font-size: size(59);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.61;
    letter-spacing: normal;
    text-align: center;
    color: #3b5662;
  }
  .title-l {
    width: size(77);
  }

  .title-r {
    width: size(77);
    transform: rotate(180deg);
  }
}

.content {
  width: size(545);

  &.short {
    width: size(472);
  }

  > .title {
    font-size: size(44);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: 1.2px;
    text-align: justify;
    color: #3b5662;
    margin-bottom: size(20);
  }

  .desc {
    font-size: size(24);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: 1.2px;
    text-align: justify;
    color: #3b5662;
    margin-bottom: size(25);
  }

  .text {
    font-size: size(18);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.9px;
    text-align: justify;
    color: #3b5662;
  }
}

.slide {
  width: size(990);
  height: size(680);
  overflow: visible;

  &.wide {
    width: size(1100);
    height: size(680);

    & .border {
      width: size(1100);
    }
  }

  & .slide-img {
    width: 100%;
    height: size(680);
    transform: translate(5px, 5px);
  }

  .border {
    border: 1px solid #3b5662;
    width: size(990);
    height: size(680);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .slide-text {
    position: absolute;
    z-index: 4;
    background-color: #3b5662;
    right: size(-10);
    bottom: size(10);
    color: #fff;
    font-size: size(17);
    padding: 8px 30px;
  }
}

.indigator-list {
  width: 100%;
  margin-top: size(20);
  .indigator {
    width: size(21);
    height: size(21);
    border-radius: size(21);
    margin: 0 20px;
    background-color: rgba(59, 86, 98, 0.6);
    cursor: pointer;

    &.active {
      background-color: rgba(59, 86, 98, 1);
    }
  }
}

.arrows {
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .arrow-l {
    width: size(35);
    position: absolute;
    left: size(-55);
    cursor: pointer;
  }

  .arrow-r {
    width: size(35);
    transform: rotate(180deg);
    position: absolute;
    right: size(-55);
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
  .bg-img {
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  #svg3 {
    width: 120vw;
    height: size-m(336);
    top: 0;
    left: 0;
    transform: translate(0%, -44%) scaleX(-1);
  }
  #svg4 {
    width: 120vw;
    height: size-m(336);
    top: size-m(830);
    left: 0;
    transform: translate(-47%, -44%) scale(1, -1);
  }
  #svg5 {
    width: 120vw;
    height: size-m(336);
    top: size-m(1530);
    left: 0;
    transform: translate(23%, -44%) scale(-1, -1);
  }
  #svg5-1 {
    width: 120vw;
    height: size-m(336);
    top: size-m(2200);
    left: 0;
    transform: translate(-47%, -44%) scale(1, -1);
  }
  #svg5-2 {
    width: 120vw;
    height: size-m(336);
    top: size-m(2880);
    left: 0;
    transform: translate(20%, -44%) scale(-1, -1);
  }
  .title-block {
    width: 60vw;
    margin: 0 auto;
    margin-top: size-m(106);
    margin-bottom: size-m(22);
    .title {
      font-size: size-m(30);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: center;
      color: #3b5662;
    }
    .title-l {
      width: size-m(33);
    }

    .title-r {
      width: size-m(33);
      transform: rotate(180deg);
    }
  }

  .slide {
    width: 100vw !important;
    height: size-m(257);
    margin-bottom: size-m(30);

    & .slide-img {
      object-fit: cover;
      height: 100%;
      transform: none;
    }
  .slide-text {
    font-size: size-m(13);
    bottom: 0;
  }
  }

  .content {
    width: size-m(289);
    margin: 0 auto size-m(130);
    position: relative;

    &.short {
      width: size-m(289);
    }
&.bot{
      margin-bottom: size-m(300);}
    > .title {
      font-size: size-m(25);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.77;
      letter-spacing: 1.2px;
      text-align: justify;
      color: #3b5662;
      margin-bottom: size-m(20);
    }

    .desc {
      font-size: size-m(18);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.77;
      letter-spacing: 1.2px;
      text-align: justify;
      color: #3b5662;
      margin-bottom: size-m(25);
    }

    .text {
      font-size: size-m(15);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.9px;
      text-align: justify;
      color: #3b5662;
    }
  }


  .indigator-list {
    display: none;
  }

  .bg2 {
    top: auto;
    bottom: size-m(-100);
    left: size-m(-43);
  }

  .arrows {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow-l {
      width: size-m(14);
      position: absolute;
      left: size-m(5);
      cursor: pointer;
    }

    .arrow-r {
      width: size-m(14);
      transform: rotate(180deg);
      position: absolute;
      right: size-m(5);
      cursor: pointer;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import slider from '@/mixins/slider.js'

export default {
  name: 'section2',

  mixins: [slider],
  props: ['showIcon'],

  components: {},
  data() {
    return {
      isMobile,
      slideIndex1: 0,
      slideIndex2: 0,
      slideIndex3: 0,
      slideIndex4: 0,
      slideList1: [
        { text: '五華國小', src: require('./s2/1-1五華國小.jpg') },
        { text: '新北高中', src: require('./s2/1-2新北高中.jpg') },
        { text: '碧華國小', src: require('./s2/1-3碧華國小.jpg') },
        {
          text: '碧華國中(興建中)',
          src: require('./s2/1-4碧華國中(興建中).jpg'),
        },
        { text: '加州森林國際幼兒園', src: require('./s2/1-5加州.jpg') },
      ],
      slideList2: [
        { text: '重陽橋', src: require('./s2/2-1重陽橋.jpg') },
       // { text: '士林商圈', src: require('./s2/2-2士林商圈.jpg') },
        { text: '台北車站', src: require('./s2/2-3台北車站.jpg') },
        { text: '徐匯廣場站', src: require('./s2/2-4徐匯廣場站.jpg') },
        { text: '高速公路', src: require('./s2/2-5.jpg') },
      ],
      slideList3: [
        { text: '集賢環保公園',src: require('./s2/3-7集賢.jpg') },
        { text: '太陽公園', src: require('./s2/3-2.jpg') },
      //  { text: '淡水河畔重陽公園', src: require('./s2/3-3.jpg') },
      //  { text: '淡水河畔重陽公園', src: require('./s2/3-4.jpg') },
        { text: '淡水河畔重陽公園', src: require('./s2/3-6河岸.jpg') },
      ],
      slideList4: [
        { text: '徐匯廣場', src: require('./s2/4-1徐匯廣場.jpg') },
        { text: '家樂福', src: require('./s2/4-2家樂福.jpg') },
        { text: '士林夜市商圈', src: require('./s2/4-3士林夜市.jpg') },
      ],
    }
  },

  methods: {
    setIndex(index, current) {
      this[`slideIndex${index}`] = current
    },
  },

  created() {},

  mounted() {},

  computed: {},
}
</script>
